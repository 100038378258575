import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, type UseFormReturn } from 'react-hook-form';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { type PriceCenterFilter } from '@store/store/thunk/pricecenter/Models';
import { type RootState } from '@store/store';
import {
    CALC_METHODS_DICT,
    ISSUER_SECTOR_DICT,
    LISTING_LEVEL_DICT,
    SECTOR_DICT,
    STATUS_DICT,
} from '@store/store/thunk/pricecenter/consts';
import { parse } from 'date-fns';
import { ReactComponent as CalendarIcon } from '@modules/PriceCenterProduct/img/CalendarIcon.svg';
import { Button } from 'primereact/button';

import styles from './styles.module.scss';
import multiSelect from '../ExtendedSearchMediumForm/styles.module.scss';
import { getFilterIsinsGrouped, getIsinsVirtualGroupedByEmitter } from '../../utils';

type PriceCenterMainMediumFormProps = {
    openModal: () => void;
    isLoading: boolean;
    form: UseFormReturn<PriceCenterFilter>;
    getFormErrorMessage: (name: string) => '' | JSX.Element;
    isDemo: boolean;
    filtersCount: number;
};

export const PriceCenterMainMediumForm = ({
    openModal,
    form,
    isLoading,
    getFormErrorMessage,
    isDemo,
    filtersCount,
}: PriceCenterMainMediumFormProps) => {
    const [activeIndexes, setActiveIndexes] = useState<number[]>([0, 1, 2]);
    const [allNames, setAllNames] = useState<any[]>([]);

    const { allBonds } = useSelector((state: RootState) => state.priceCenter.charts.data);

    const { watch } = form;

    useEffect(() => {
        if (allBonds?.bonds?.length) {
            const names = getIsinsVirtualGroupedByEmitter(allBonds.bonds, allBonds.emitters);
            setAllNames([...names]);
        }
    }, [allBonds]);

    const handleNumberInput = (
        e: { value: number | null },
        field: { onChange: (arg: any) => void },
        maxAllowedValue: number,
    ) => {
        if (e.value || e.value === 0) {
            e.value > maxAllowedValue ? field.onChange(maxAllowedValue) : field.onChange(e.value);
        } else if (e.value === null) {
            field.onChange(e.value);
        }
    };

    const multiSelectPt = {
        header: () => ({ className: styles.isinMultiselectHeader }),
        filterContainer: () => ({ className: styles.isInMultiSelectFilterInput }),
        list: () => ({ className: multiSelect.multiSelectList }),
        item: () => ({ className: multiSelect.multiSelectListItem }),
        itemGroup: () => ({ className: multiSelect.multiSelectListItemGroup }),
        panel: () => ({ className: multiSelect.multiSelectPanel }),
        closeButton: () => ({ className: multiSelect.multiSelectCloseButton }),
        headerCheckbox: {
            root: () => ({ className: styles.rootCheckbox }),
            box: () => ({ className: styles.multiSelectHeaderCheckbox }),
        },
    };

    const itemTemplate = (option: any) => {
        if (option.label.startsWith('Group-')) {
            return (
                <div className={classNames('flex', styles.dropdownGroup)}>
                    <p>{option.label.slice(6)}</p>
                    <div className={styles.divider} />
                </div>
            );
        } else {
            return <p className={classNames('flex', styles.dropdownItem)}>{option.label}</p>;
        }
    };

    return (
        <>
            <div className={classNames(styles.calendarContainer)}>
                <p className={styles.inputLabel}>Дата актуальности</p>
                <Controller
                    control={form.control}
                    name="tradeDate"
                    render={({ field }) => (
                        <Calendar
                            id={field.name}
                            value={field.value}
                            onChange={(e) => {
                                field.onChange(e.value);
                            }}
                            dateFormat="dd.mm.yy"
                            className={classNames(styles.calendarInput)}
                            appendTo={'self'}
                            locale="ru"
                            readOnlyInput
                            showIcon
                            minDate={isDemo ? parse('01.06.23', 'dd.MM.yy', new Date()) : undefined}
                            maxDate={isDemo ? parse('29.12.23', 'dd.MM.yy', new Date()) : undefined}
                            icon={<CalendarIcon />}
                        />
                    )}
                />
            </div>
            <div className={styles.blockDivider} />
            <Accordion
                multiple
                activeIndex={activeIndexes}
                onTabChange={(e) => setActiveIndexes((e.index ?? []) as number[])}
                className={classNames(styles.accordion)}
            >
                <AccordionTab
                    headerTemplate={
                        <div className={styles.blockTitle}>
                            <div className={classNames(styles.accordionHeader)}>
                                <label htmlFor="form-0">Метод расчета</label>
                            </div>
                            <i
                                className={classNames({
                                    'pi pi-chevron-up pb-1 text-sm': activeIndexes.includes(0),
                                    'pi pi-chevron-down pb-1 text-sm': !activeIndexes.includes(0),
                                })}
                            />
                        </div>
                    }
                    pt={ACCORDION_PT}
                >
                    <Controller
                        control={form.control}
                        name="methodNumber"
                        render={({ field }) => (
                            <Dropdown
                                value={field.value}
                                onChange={field.onChange}
                                options={CALC_METHODS_DICT}
                                dataKey="code"
                                optionLabel="name"
                                placeholder="Выбрать"
                                className={classNames(styles.dropdownInput, 'w-full')}
                                disabled={isLoading}
                            />
                        )}
                    />
                    <div className={classNames(styles.blockDivider, 'pt-5')} />
                </AccordionTab>

                <AccordionTab
                    headerTemplate={
                        <div className={styles.blockTitle}>
                            <div className={classNames(styles.accordionHeader)}>
                                <label htmlFor="form-1">Сформировать список</label>
                            </div>
                            <i
                                className={classNames({
                                    'pi pi-chevron-up pb-1 text-sm': activeIndexes.includes(1),
                                    'pi pi-chevron-down pb-1 text-sm': !activeIndexes.includes(1),
                                })}
                            />
                        </div>
                    }
                    pt={ACCORDION_PT}
                >
                    <div className={classNames(styles.isinInputContainer)}>
                        <Controller
                            control={form?.control}
                            name="searchNames"
                            render={({ field }) => (
                                <MultiSelect
                                    value={field.value}
                                    id={field.name}
                                    options={allNames}
                                    onChange={field.onChange}
                                    className={classNames(styles.isinBlockParamSearchInput)}
                                    pt={multiSelectPt}
                                    focusOnHover={false}
                                    optionLabel="label"
                                    optionGroupTemplate={itemTemplate}
                                    itemTemplate={itemTemplate}
                                    virtualScrollerOptions={{ itemSize: 25, step: 1, autoSize: true }}
                                    disabled={allNames.length === 0}
                                    placeholder="ISIN, название инструмента"
                                    appendTo="self"
                                    filter
                                    filterBy="label,value"
                                    emptyFilterMessage="Ничего не найдено"
                                    emptyMessage="Нет данных"
                                    resetFilterOnHide
                                />
                            )}
                        />
                    </div>
                    <div className={classNames(styles.blockDivider, 'pt-5')} />
                </AccordionTab>

                <AccordionTab
                    headerTemplate={
                        <div className={styles.blockTitle}>
                            <div className={classNames(styles.accordionHeader)}>
                                <label htmlFor="form-2">Поиск по параметрам</label>
                            </div>
                            <i
                                className={classNames({
                                    'pi pi-chevron-up pb-1 text-sm': activeIndexes.includes(2),
                                    'pi pi-chevron-down pb-1 text-sm': !activeIndexes.includes(2),
                                })}
                            />
                        </div>
                    }
                    pt={ACCORDION_PT}
                >
                    <div className={styles.blockParamSearch}>
                        <div className={classNames(styles.selectContainer)}>
                            <p className={styles.inputLabel}>Статус</p>
                            <Controller
                                control={form.control}
                                name="status"
                                render={({ field }) => (
                                    <MultiSelect
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={STATUS_DICT}
                                        pt={multiSelectPt}
                                        dataKey="code"
                                        optionLabel="name"
                                        className={styles.blockParamSearchInput}
                                        multiple
                                        disabled={isLoading}
                                        appendTo="self"
                                        placeholder="Выбрать"
                                        selectAllLabel="Выбрать все"
                                    />
                                )}
                            />
                        </div>

                        <div className={classNames(styles.selectContainer)}>
                            <p className={styles.inputLabel}>Листинг</p>
                            <Controller
                                control={form.control}
                                name="listingLevel"
                                render={({ field }) => (
                                    <Dropdown
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={LISTING_LEVEL_DICT}
                                        dataKey="code"
                                        optionLabel="name"
                                        className={styles.blockParamSearchInput}
                                        disabled={isLoading}
                                        appendTo="self"
                                    />
                                )}
                            />
                        </div>

                        <div className={classNames(styles.selectContainer)}>
                            <p className={styles.inputLabel}>Сектор</p>
                            <Controller
                                control={form.control}
                                name="sector"
                                render={({ field }) => (
                                    <Dropdown
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={SECTOR_DICT}
                                        dataKey="code"
                                        optionLabel="name"
                                        className={styles.blockParamSearchInput}
                                        disabled={isLoading}
                                        appendTo="self"
                                    />
                                )}
                            />
                        </div>

                        <div className={classNames(styles.selectContainer)}>
                            <p className={styles.inputLabel}>Отрасль</p>
                            <Controller
                                control={form.control}
                                name="issuerSector"
                                render={({ field }) => (
                                    <Dropdown
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={ISSUER_SECTOR_DICT}
                                        dataKey="code"
                                        optionLabel="name"
                                        className={styles.blockParamSearchInput}
                                        disabled={isLoading}
                                        appendTo="self"
                                    />
                                )}
                            />
                        </div>

                        <div className={styles.profitParam}>
                            <p className={styles.inputLabel}>Доходность (%)</p>
                            <div className={classNames(styles.selectContainer)}>
                                <Controller
                                    control={form.control}
                                    name="minYield"
                                    rules={{
                                        validate: {
                                            hasBothValues: (value) => {
                                                const max = watch('maxYield');

                                                if (value && !max) {
                                                    return value === 0 || 'Введите максимальное значение доходности';
                                                }
                                            },
                                        },
                                        deps: ['maxYield'],
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            id={field.name}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(e) => {
                                                handleNumberInput(e, field, 10000);
                                            }}
                                            placeholder="от"
                                            className={classNames(
                                                { 'p-invalid': fieldState.invalid },
                                                styles.profitInput,
                                            )}
                                            max={10000}
                                            maxLength={6}
                                            min={0}
                                        />
                                    )}
                                />
                                <i className="pi pi-minus" />
                                <Controller
                                    control={form.control}
                                    name="maxYield"
                                    rules={{
                                        validate: {
                                            lowerThanMax: (value) => {
                                                const min = watch('minYield');

                                                if (value && min) {
                                                    return (
                                                        value > min ||
                                                        'Значение доходности не должно быть меньше начального уровня'
                                                    );
                                                }
                                            },
                                        },
                                        deps: ['minYield'],
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            id={field.name}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(e) => handleNumberInput(e, field, 10000)}
                                            placeholder="до"
                                            className={classNames(
                                                { 'p-invalid': fieldState.invalid },
                                                styles.profitInput,
                                            )}
                                            max={10000}
                                            maxLength={6}
                                            min={0}
                                        />
                                    )}
                                />
                            </div>

                            {getFormErrorMessage('minYield')}
                            {getFormErrorMessage('maxYield')}
                        </div>

                        <div className={classNames(styles.extendedSeachButtonWrapper)}>
                            <Button
                                className={styles.extendedSearchLabel}
                                label="Расширенный поиск"
                                onClick={openModal}
                            />
                            {filtersCount > 0 && <div className={styles.pinButtonCount}>{filtersCount}</div>}
                        </div>
                    </div>
                    <div className={classNames(styles.blockDivider, 'pt-5')} />
                </AccordionTab>
            </Accordion>
        </>
    );
};

const ACCORDION_PT = {
    headerIcon: () => ({
        className: 'hidden',
    }),
    headerAction: () => ({
        className: 'bg-white border-none w-full',
    }),
    content: () => ({
        className: 'border-none pt-3',
    }),
};
