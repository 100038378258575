import { createAsyncThunk } from '@reduxjs/toolkit';
import { PageableType } from '@libs/types';
import { getArticles } from '@libs/services/article.service';

export const getArticlesThunk = createAsyncThunk(
    'articles/getArticlesThunk',
    async (body: { pageable: PageableType }, { rejectWithValue }) => {
        try {
            const { data } = await getArticles(body);

            return data.data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
