import classNames from 'classnames';
import { ReactComponent as DotsMenuIcon } from '@shared/images/personalAccount/svg/dots_menu_icon.svg';
import { ReactComponent as StarIcon } from '@shared/images/personalAccount/svg/star_blue.svg';
import { ReactComponent as TrashIcon } from '@shared/images/personalAccount/svg/trash.svg';
import { type SavedCard } from '@libs/types';
import MasterCard from '@shared/images/svg/cardIcons/MasterCard.svg';
import Mir from '@shared/images/svg/cardIcons/Mir.svg';
import UnionPay from '@shared/images/svg/cardIcons/UnionPay.svg';
import Visa from '@shared/images/svg/cardIcons/Visa.svg';
import Maestro from '@shared/images/svg/cardIcons/Maestro.svg';
import NewCard from '@shared/images/svg/cardIcons/NewCard.svg';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { deleteSavedCardThunk, getSavedCardsThunk, setDefaultSavedCardThunk } from '@store/store/thunk/card.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { ConfirmModal, ModalWindow } from '@libs/components';
import { useModal } from '@libs/utils';

import styles from './styles.module.scss';

const icons: Record<string, string> = {
    MASTER_CARD: MasterCard,
    MIR: Mir,
    UNION_PAY: UnionPay,
    VISA: Visa,
    MAESTRO: Maestro,
    UNKNOWN: NewCard,
};

const paymentSystemName: Record<string, string> = {
    VISA: 'Visa',
    MASTER_CARD: 'MasterCard',
    MAESTRO: 'Maestro',
    MIR: 'Mir',
    UNION_PAY: 'UnionPay',
    UNKNOWN: 'Карта',
};

export const CardComponent = ({ id, cardNumber, paymentSystem, isDefault }: SavedCard) => {
    const dispatch = useDispatch<AppDispatch>();
    const menuRef = useRef<OverlayPanel>(null);
    const { isOpen, openModal, closeModal } = useModal();
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [isCardDeleting, setCardDeleting] = useState(false);

    const setDefaultCard = () => {
        dispatch(setDefaultSavedCardThunk(id))
            .then(() => {
                dispatch(getSavedCardsThunk());
            })
            .catch((e) => console.error(e));
    };

    const deleteSavedCard = () => {
        dispatch(deleteSavedCardThunk(id))
            .then((res) => {
                const result = unwrapResult(res);

                if (result?.data?.status?.errorCode.toLowerCase() === 'card_required_for_renewals') {
                    openModal();
                } else {
                    dispatch(getSavedCardsThunk());
                }
            })
            .catch((e) => console.error(e))
            .finally(() => {
                closeDeleteConfirmModal();
                setCardDeleting(false);
            });
    };

    const openDeleteConfirmModal = () => {
        setDeleteConfirmOpen(true);
    };

    const closeDeleteConfirmModal = () => {
        setDeleteConfirmOpen(false);
    };

    const confirmDeleteCard = () => {
        deleteSavedCard();
        setCardDeleting(true);
    };

    return (
        <div className={classNames(styles.cardWrapper, 'flex', 'flex-column')}>
            <div
                className={classNames(
                    styles.cardInfoWrapper,
                    'flex',
                    'justify-content-between',
                    'align-items-center',
                    'flex-nowrap',
                    'md:flex-wrap',
                )}
            >
                <img src={icons[paymentSystem]} alt="payment-card-logo" />
                <div className={classNames('flex', 'flex-column')}>
                    <span>{paymentSystemName[paymentSystem]}</span>
                    <span>····· {cardNumber.substring(12, 16)}</span>
                </div>
                <Button
                    className={styles.menuBtn}
                    icon={<DotsMenuIcon />}
                    onClick={(e) => menuRef.current && menuRef.current.toggle(e)}
                />
                <OverlayPanel ref={menuRef}>
                    <div className={classNames(styles.menuPanel, 'flex', 'flex-column')}>
                        {!isDefault && (
                            <Button
                                label={'Использовать по умолчанию'}
                                icon={<StarIcon />}
                                onClick={setDefaultCard}
                                className={classNames(styles.defaultBtn, 'flex')}
                            />
                        )}
                        <Button
                            label={'Удалить карту'}
                            icon={<TrashIcon />}
                            onClick={openDeleteConfirmModal}
                            className={classNames(styles.deleteBtn, 'flex')}
                        />
                    </div>
                </OverlayPanel>
            </div>
            {isDefault && <p className={styles.defaultCartDescription}>Оплата по умолчанию</p>}
            <ModalWindow isVisible={isOpen} closeModal={closeModal}>
                <div className={styles.notificationModalWrapper}>
                    <h2>данный способ оплаты удалить нельзя</h2>
                    <p>
                        Пока у вас запланировано продление подписки на продукты MOEX Datashop, мы не можем удалить
                        последний сохраненный способ оплаты
                    </p>
                    <Button label={'Закрыть'} onClick={closeModal} />
                </div>
            </ModalWindow>
            <ConfirmModal
                header={'Подтвердить действие'}
                confirmButtonText={'Да'}
                backButtonName={'Оставить'}
                onConfirm={confirmDeleteCard}
                isCloseButtonDisable={isCardDeleting}
                isConfirmButtonDisable={isCardDeleting}
                onClose={closeDeleteConfirmModal}
                isVisible={isDeleteConfirmOpen}
                children={<p>Вы уверены, что хотите удалить данный способ оплаты?</p>}
            />
        </div>
    );
};
