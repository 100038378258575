/* eslint-disable no-useless-escape */
import { decode, encode } from 'js-base64';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { store } from '@store/store';
import _ from 'lodash';
import { TPrimitiveRecord } from 'react-oauth2-code-pkce/dist/Types';
import { MINUTES } from '@libs/constants';

export const getNumberWithSpacing = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const objectToBase64String = (obj: unknown) => encode(JSON.stringify(obj));
export const base64StringToObject = (base64str: string) => JSON.parse(decode(base64str));

export const readAsDataURL = (file: File | Blob): Promise<{ data: string | ArrayBuffer | null }> => {
    return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = function () {
            return resolve({ data: fileReader.result });
        };
        fileReader.readAsDataURL(file);
    });
};

export const withLoading = async (serviceRequest: () => Promise<any>) => {
    store.dispatch(showLoader());
    try {
        const res = await serviceRequest();
        store.dispatch(hideLoader());
        return res;
    } catch (e) {
        console.error(e);
        store.dispatch(hideLoader());
    }
};

export const generateRandomString = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()=+-';
    const charactersLength = characters.length;
    let result = '';

    const randomValues = new Uint32Array(length);

    // Generate random values
    crypto.getRandomValues(randomValues);
    randomValues.forEach((value) => {
        result += characters.charAt(value % charactersLength);
    });

    return result;
};

export const getUuid = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 10);

export const generateState = (additionalParameters: TPrimitiveRecord = {}): string => {
    const stateObj = JSON.stringify({
        simple: 'true',
        authState: getUuid(),
        browserId: localStorage?.getItem('browserId') || window.Cookies?.get('browserId') || window.browserIdValue,
        ...additionalParameters,
    });

    return encode(stateObj);
};

export const isMobileDevice = () => {
    const isMobile =
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4),
        );
    return isMobile;
};

export const validateEmail = (email: string): boolean => {
    const tester =
        /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    if (!email) return false;

    const emailParts = email.split('@');

    if (emailParts.length !== 2) return false;

    const account = emailParts[0];
    const address = emailParts[1];

    if (account.length > 64) return false;
    else if (address.length > 255) return false;

    const domainParts = address.split('.');

    if (
        domainParts.some(function (part) {
            return part.length > 63;
        })
    ) {
        return false;
    }

    return tester.test(email);
};

export const scrollElementByRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    }
};

export const roundArrToTenths = (arr: string[] | number[]) => {
    return arr.map((item: string | number) => _.round(Number(item), 2));
};

//Функция для склоненений ['минута', 'минуты', минут]. В массив пишем слова для 1, 2, 5
export const declensionByNum = (number: number, words: string[]) => {
    const value = Math.abs(number) % 100;
    const num = value % 10;

    if (value > 10 && value < 20) {
        return words[2];
    }

    if (num > 1 && num < 5) {
        return words[1];
    }

    if (num === 1) {
        return words[0];
    }

    return words[2];
};

export const getFormCleanedFromNullValues = (obj: object) => {
    return Object.entries(obj).reduce((acc: { [key: string]: any }, [key, value]) => {
        const valueIsExisted = value !== null && value !== undefined && value?.code !== null;
        const valueIsDate = value instanceof Date;

        if (valueIsExisted || valueIsDate) {
            if (Array.isArray(value)) {
                const filteredArray = value?.filter((item) => item !== null && item !== undefined);
                if (filteredArray.length > 0) {
                    acc[key] = filteredArray;
                }
            } else if (typeof value === 'object' && !valueIsDate) {
                const cleanedObject = getFormCleanedFromNullValues(value);
                if (Object.keys(cleanedObject).length > 0) {
                    acc[key] = cleanedObject;
                }
            } else {
                acc[key] = value;
            }
        }
        return acc;
    }, {});
};

export const addMetaTag = (title: string, content: string) => {
    const metaIsExisted = document.querySelector(`meta[name="${title}"]`);

    if (metaIsExisted) {
        return;
    }

    const metaTag = document.createElement('meta');

    metaTag.name = title;
    metaTag.content = content;

    document.head.appendChild(metaTag);
};

export const removeMetaTag = (title: string) => {
    const metaIsExisted = document.querySelector(`meta[name="${title}"]`);

    if (metaIsExisted) {
        document.head.removeChild(metaIsExisted);
    }
};

export const timeToReadArticleString = (timeToRead: number) => {
    return timeToRead < 1
        ? 'менее минуты на чтение'
        : `${timeToRead} ${declensionByNum(timeToRead, MINUTES)} на чтение`;
};
