import { Content } from '@modules/PaymentInfoPage/Content';
import { Footer } from '@libs/components';

export const PaymentInfoPage = () => {
    return (
        <>
            <div className="main-wrapper">
                <div className="min-h-screen flex item-center align-items-center">
                    <Content />
                </div>
            </div>
            <Footer />
        </>
    );
};
