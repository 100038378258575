import classNames from 'classnames';
import React from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import styles from './styles.module.scss';

export type SubItem = {
    text?: string;
    li?: string;
    subItems?: SubItem[];
};

export type MainItem = {
    text: string;
    imageUrl?: string;
    subItems?: SubItem[];
};

export type LineItemProps = {
    item: MainItem;
    isOpen: boolean;
    onClick: () => void;
};

export type SubItemComponentProps = {
    item: SubItem;
};

export const LineItem: React.FC<LineItemProps> = ({ item, isOpen, onClick }) => {
    return (
        <div className={styles.wrapper}>
            <div onClick={onClick} className={styles.info}>
                <div onClick={onClick} className={styles.topline}>
                    <span>{isOpen ? '×' : '+'}</span>
                    <div onClick={onClick} className={classNames(styles.title, isOpen && styles.titleOpen)}>
                        {item.text}
                    </div>
                </div>

                {isOpen && (
                    <div className={styles.list}>
                        {item.subItems && (
                            <ul>
                                {item.subItems.map((subItem, index) => (
                                    <SubItemComponent key={index} item={subItem} />
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
            {isOpen && item.imageUrl && (
                <div className={styles.imageWrapper}>
                    <img src={item.imageUrl} alt="image" />
                </div>
            )}
        </div>
    );
};

export const SubItemComponent: React.FC<{ item: SubItem }> = ({ item }) => {
    return (
        <div className={styles.text}>
            {item.text && <p>{item.text}</p>}
            {item.li && (
                <ul>
                    <li>{item.li}</li>
                </ul>
            )}
            {item.subItems && (
                <ul>
                    {item.subItems.map((subItem, index) => (
                        <SubItemComponent key={index} item={subItem} />
                    ))}
                </ul>
            )}
        </div>
    );
};
