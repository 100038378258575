import { createSlice } from '@reduxjs/toolkit';
import { getTariffsThunk } from '@store/store/thunk/tariff.thunk';
import { DatashopTariff } from '@libs/types';

type IState = {
    datashopTariffs: DatashopTariff[];
};

export const initialState: IState = {
    datashopTariffs: [],
};

export const tariffSlice = createSlice({
    name: 'tariff',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTariffsThunk.fulfilled, (state, action) => {
            state.datashopTariffs = action.payload;
        });
    },
});
export const tariffReducer = tariffSlice.reducer;
