import { Footer, Navigation } from '@libs/components';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Header } from '@modules/PriceCenterProduct/Header';
import { InteractiveMap } from '@modules/PriceCenterProduct/InteractiveMap';
import { DataUpload } from '@modules/PriceCenterProduct/DataUpload';
import { ApiDescription } from '@modules/PriceCenterProduct/ApiDescription';
import { useEffect, useState } from 'react';
import { type PriceCentrePermissions } from '@libs/types/subscription.type';
import { getPermissions } from '@libs/services';
import { PriceCenterProductTabs } from '@modules/PriceCenterProduct/types';
import { useAuthorize } from '@libs/utils';
import { ReactComponent as PriceCenterLogo } from '@shared/images/priceCenter/svg/PriceCenterProductLogo.svg';

import styles from './styles.module.scss';

const productId = 'e58ed763-928c-4155-bee9-fdbaaadc1588';

export const PriceCenterProduct = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [permissions, setPermissions] = useState<PriceCentrePermissions[]>([]);
    const [searchParams] = useSearchParams({ tab: PriceCenterProductTabs.INTERACTIVE_MAP });
    const { isAuthorized, loginHandler } = useAuthorize();

    const getProductPermissions = async () => {
        try {
            const response = await getPermissions(productId);
            const permissionsData = response.data.permissions;

            setPermissions(permissionsData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getProductPermissions();
    }, []);

    useEffect(() => {
        if (!isAuthorized) {
            loginHandler();
        }
    }, [isAuthorized]);

    return (
        <>
            <div className="main-wrapper">
                <div className={classNames(styles.priceCenterContainer)}>
                    <Navigation
                        className={classNames(styles.navContainer)}
                        link="/products/priceCenter"
                        logo={<PriceCenterLogo style={{ height: '100%' }} />}
                    >
                        <Header permissions={permissions} />
                    </Navigation>
                    {!loading && (
                        <>
                            {searchParams.get('tab') === PriceCenterProductTabs.INTERACTIVE_MAP && (
                                <InteractiveMap permissions={permissions} />
                            )}
                            {searchParams.get('tab') === PriceCenterProductTabs.DATA_UPLOAD && (
                                <DataUpload permissions={permissions} />
                            )}
                            {searchParams.get('tab') === PriceCenterProductTabs.API_DESCRIPTION && (
                                <ApiDescription permissions={permissions} />
                            )}
                        </>
                    )}
                </div>
            </div>

            <Footer />
        </>
    );
};
