export const MAX_ACTIVES = 5;

export const SCREEN_OPTIONS = ['Улыбка волатильности', 'Поверхность волатильности'];

export const dateFormat = 'yyyy-mm-dd';

export const FIRST_DATE_WITH_DATA = new Date('2024-06-05');

export const VOLAT_TABLE_TITLES = [
    { id: 'asset', field: 'asset', label: 'Актив', sortable: false },
    { id: 'last_trade_date', field: 'last_trade_date', label: 'Дата экспирации', sortable: false },
    { id: 'volat_rub', field: 'volat_rub', label: 'Объем торгов', sortable: false },
    { id: 'open_interest', field: 'open_interest', label: 'Открытый интерес', sortable: true },
];

export const SMILE_ACTIVES_COLORS = ['#48A31A', '#3793FF', '#FF4143', '#7854ED', '#F1B458'];

export const MAX_CURVES_EXCEEDED_MSG = 'К сожалению, на график нельзя добавить более 5 инструментов.';
