import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse } from 'axios';
import { type SmileCoordsRoot } from './types';

const URL = '/moex-propack-integrationservice/api/curves/v1/volat-points';

export const getSmileChartCoords = (
    assetType: string,
    assetName: string,
    tradeDate: string,
    expirationDate: string,
): Promise<AxiosResponse<SmileCoordsRoot>> => {
    return httpClient.post(URL, {
        'asset-type': assetType,
        asset: assetName,
        'trade-date': tradeDate,
        expirationDate,
    });
};
