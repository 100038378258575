import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { SearchDropdown } from '@libs/components/misc/SearchDropdown';
import { useSearch } from '@libs/utils/hooks/useSearch';
import { useAuthorize } from '@libs/utils';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';

import styles from './styles.module.css';

type Props = {
    setIsOpen: (value: boolean) => void;
};

const placeholderText = 'Поиск по Datashop';

export const Search = ({ setIsOpen }: Props) => {
    const { isAuthorized } = useAuthorize();
    const [placeholder, setPlaceholder] = useState(placeholderText);
    const bgRef = useRef<HTMLDivElement>(null);

    const {
        debouncedSearch,
        searchValue,
        setSearchValue,
        entitySearch,
        setEntitySearch,
        resultList,
        handleInputChange,
        isOpenDropdown,
    } = useSearch(4);

    useEffect(() => {
        debouncedSearch(searchValue || '');
    }, [searchValue, entitySearch]);

    useEffect(() => {
        const onClick = (e: MouseEvent) => {
            if (bgRef && bgRef.current && bgRef.current.contains(e.target as Element)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', onClick);

        return () => document.removeEventListener('click', onClick);
    }, []);

    return (
        <>
            <div className={classNames(styles.searchWrapper, !isAuthorized && styles.nonAuth)}>
                <div>
                    <IconField className={styles.inputWrapper}>
                        {searchValue && <InputIcon className="pi pi-times" onClick={() => setSearchValue('')} />}
                        <InputText
                            value={searchValue}
                            onChange={handleInputChange}
                            placeholder={placeholder}
                            onFocus={() => setPlaceholder('')}
                            onBlur={() => setPlaceholder(placeholderText)}
                            maxLength={250}
                        />
                    </IconField>

                    {isOpenDropdown && (
                        <SearchDropdown
                            list={resultList}
                            searchValue={searchValue}
                            entitySearch={entitySearch}
                            changeEntitySearch={setEntitySearch}
                            setIsOpen={setIsOpen}
                            isMainSearch={true}
                        />
                    )}
                </div>
            </div>
            <div ref={bgRef} className={classNames(styles.bgWrapper)} />
        </>
    );
};
