import { type ArticleListItem } from '@libs/types/articles/articleListItem.type';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { BigImageCard } from '@modules/Articles/ArticleListPage/components/ArticlesList/ArticleCard/BigImageCard';
import { getDate } from '@modules/Articles/ArticleListPage/components/ArticlesList/ArticleCard/utils';
import { Link } from 'react-router-dom';
import { timeToReadArticleString } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    item: ArticleListItem;
    isFifthBlock?: boolean;
};

export const ArticleCard = ({ item, isFifthBlock = false }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [image, setImage] = useState<string>();

    useEffect(() => {
        if (item.image) {
            dispatch(downloadFileThunk({ bucket: item.image.bucket, ticket: item.image.field_id }))
                .then((resp) => {
                    const result = unwrapResult(resp);
                    setImage(result);
                })
                .catch((e) => console.error(e));
        }
    }, []);

    return isFifthBlock
? (
        <BigImageCard item={item} />
    )
: (
        <div className={classNames(styles.articleCardWrapper, !item.image && styles.borderTop, 'flex', 'flex-column')}>
            {item.image && <img src={image} />}
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.infoWrapper, 'flex', 'align-items-baseline')}>
                    {item.category && (
                        <>
                            <span className={styles.categoryWrapper}>{item.category}</span>
                            <div className={styles.divider} />
                        </>
                    )}
                    <span className={styles.dateWrapper}>{getDate(item.titleDate)}</span>
                </div>

                <div className={classNames(styles.mainWrapper, !item.image && styles.nonImage, 'flex', 'flex-column')}>
                    <Link to={`/articles/${item.id}`} className={styles.linkWrapper}>
                        <h4>{item.title}</h4>
                    </Link>
                    <p>{item.subtitle}</p>
                </div>

                <div className={classNames(styles.timeWrapper, 'flex')}>
                    <span className={classNames(styles.icon, 'pi pi-clock')} />
                    <span className={styles.text}>{timeToReadArticleString(item.timeToRead)}</span>
                </div>
            </div>
        </div>
    );
};
