import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse, type AxiosRequestHeaders } from 'axios';
import { type SurfaceCoordsRoot } from './types';

const URL = '/moex-propack-integrationservice/api/curves/v1/volat-surface';

export const getSurfaceChartCoords = (asset: string, tradeDate: string): Promise<AxiosResponse<SurfaceCoordsRoot>> => {
    return httpClient.post(URL, {
        'asset-type': asset,
        'trade-date': tradeDate,
    });
};
