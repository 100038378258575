import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { useEffect, useState } from 'react';
import { getBanksThunk, getAmountRefundThunk, createRefundOrderThunk } from '@store/store/thunk/order.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { Dropdown } from 'primereact/dropdown';
import { type BankItem } from '@libs/types/bank.type';
import { Button } from 'primereact/button';
import { type CreateRefundReq } from '@libs/types/refundOrder.type';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';

import styles from './styles.module.scss';

type Props = {
    onClose: () => void;
    onConfirm: () => void;
    onError: () => void;
    orderId: string;
    productGuid: string;
    productName: string;
    subscriptionId: number;
};

export const CancelProductModal = ({
    onClose,
    onConfirm,
    onError,
    orderId,
    productGuid,
    productName,
    subscriptionId,
}: Props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [amount, setAmount] = useState<number>(0);
    const [bankList, setBankList] = useState<BankItem[]>([]);
    const [selectedBank, setSelectedBank] = useState<BankItem>();

    useEffect(() => {
        dispatch(getAmountRefundThunk({ orderId, productGuid })).then((res) => setAmount(unwrapResult(res).amount));
        dispatch(getBanksThunk()).then((res) => setBankList(unwrapResult(res)));
    }, []);

    const confirm = () => {
        const body: CreateRefundReq = {
            productGuid,
            subscriptionId,
        };

        if (selectedBank) {
            body.bankId = selectedBank.bankId;
            body.bankName = selectedBank.bankName;
        }

        dispatch(showLoader());
        dispatch(createRefundOrderThunk({ orderId, body }))
            .then((res) => {
                const result = unwrapResult(res);

                if (result?.status?.errorCode) {
                    onError();
                    dispatch(hideLoader());
                } else {
                    onConfirm();
                }
            })
            .catch(() => {
                onError();
                dispatch(hideLoader());
            });
    };

    return (
        <div className={classNames(styles.cancelProductModalWrapper, 'flex', 'flex-column')}>
            <div className={classNames(styles.descriptionWrapper, 'flex', 'flex-column')}>
                <h2>Отказ от услуги</h2>
                <p>
                    Нажимая кнопку «Отказ» вы отказываетесь от оказания услуги по предоставлению информационного
                    продукта.
                </p>
                <p>
                    Срок прекращения оказания услуги — день, следующий за днём отправки настоящего уведомления об
                    отказе.
                </p>
                {amount >= 0.01 && (
                    <p>
                        Денежные средства за вычетом стоимости части услуги, фактически оказанной на дату отказа от её
                        оказания, будут возвращены на банковскую карту, выбранного банка
                    </p>
                )}
            </div>
            <div className={classNames(styles.amountWrapper, 'flex', 'flex-column')}>
                <div className={classNames('flex', 'justify-content-between')}>
                    <span>Продукт</span>
                    <span>{productName}</span>
                </div>
                <div className={classNames('flex', 'justify-content-between')}>
                    <span>Сумма к возврату</span>
                    <span>
                        {amount}
                        <span>₽</span>
                    </span>
                </div>
            </div>
            {amount >= 0.01 && (
                <div className={classNames(styles.paymentWrapper, 'flex', 'flex-column')}>
                    <p>Выберите банк для вывода средств</p>
                    <Dropdown
                        options={bankList}
                        optionValue="bankId"
                        optionLabel="bankName"
                        value={selectedBank?.bankId}
                        onChange={(e) => setSelectedBank(bankList.find((item) => item.bankId === e.value))}
                        placeholder={'Выбрать'}
                    />
                </div>
            )}
            <div className={classNames(styles.btnWrapper, 'flex')}>
                <Button label={'Отказ'} className={styles.blackBtn} onClick={confirm} />
                <Button label={'Отменить'} className={styles.whiteBtn} onClick={onClose} />
            </div>
        </div>
    );
};
