export const COUNTERIES_DICT = [
    { code: null, name: 'Неважно' },
    { code: 'RU', name: 'Россия' },
    { code: 'GB', name: 'Англия' },
    { code: 'KG', name: 'Кыргызстан' },
    { code: 'LU', name: 'Люксебург' },
    { code: 'CY', name: 'Кипр' },
    { code: 'IE', name: 'Ирландия' },
    { code: 'AM', name: 'Армения' },
    { code: 'NL', name: 'Нидерланды' },
    { code: 'AT', name: 'Австрия' },
    { code: 'CH', name: 'Швейцария' },
];

export const CURRENCIES_DICT = [
    { code: null, name: 'Все' },
    { code: 'RUB', name: 'RUB, рубль ₽' },
    { code: 'USD', name: ' USD, доллар $' },
    { code: 'EUR', name: 'EUR, евро €' },
    { code: 'BYN', name: 'BYN, белорусский рубль Br' },
    { code: 'AMD', name: 'AMD, армянский драм ֏' },
    { code: 'GBP', name: 'GBP, фунт стерлингов £' },
    { code: 'CNY', name: 'CNY, китайский юань 元' },
    { code: 'Прочие', name: 'Прочие' },
];

export const COUPONTYPES_DICT = [
    { code: 'Прочий', name: 'Прочие' },
    { code: 'Дисконтный', name: 'Дисконтный' },
    { code: 'Ипотечный', name: 'Ипотечный' },
    { code: 'Плавающий', name: 'Плавающий' },
    { code: 'Постоянный', name: 'Постоянный' },
    { code: 'Фиксированный', name: 'Фиксированный' },
];

export const ISSUETYPES_DICT = [
    { code: null, name: 'Все выпуски' },
    { code: 'Коммерческие', name: 'Коммерческие' },
    { code: 'Биржевые', name: 'Биржевые' },
    { code: 'Классические', name: 'Классические' },
    { code: 'Другие', name: 'Другие' },
];

export const RATING_DICT = [
    { code: 'Высокий', name: 'Высокий' },
    { code: 'Низкий', name: 'Низкий' },
    { code: 'Без рейтинга', name: 'Без рейтинга' },
    { code: null, name: 'Не имеет значения' },
];

export const RADIOTBUTTON_DICT = {
    NULL: null,
    YES: true,
    NO: false,
};
