export const duplicateIISData = [
    {
        monthPrice: '0',
        onePrice: '100',
        recommendation: '1 – 5 запросов в месяц',
    },
    {
        monthPrice: '100',
        onePrice: '80',
        recommendation: '5 – 100 запросов в месяц',
    },
    {
        monthPrice: '3 100',
        onePrice: '50',
        recommendation: '100 – 1000 запросов в месяц',
    },
    {
        monthPrice: '33 100',
        onePrice: '20',
        recommendation: '1000 – 4595 запросов в месяц',
    },
    {
        monthPrice: '125 000',
        onePrice: '0',
        recommendation: 'более 4595 запросов в месяц',
    },
];
