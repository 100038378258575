import { ReactComponent as RingIcon } from '@shared/images/svg/Ring.svg';
import { ReactComponent as RingEmptyIcon } from '@shared/images/svg/RingEmpty.svg';
import { ReactComponent as HeaderLogoXlIcon } from '@shared/images/svg/HeaderLogoXl.svg';
import { ReactComponent as HeaderLogoIcon } from '@shared/images/svg/HeaderLogo.svg';
import { ReactComponent as BurgerMenuIcon } from '@shared/images/svg/BurgerMenu.svg';
import { ReactComponent as CrossIcon } from '@shared/images/svg/CrossIcon.svg';
import { ReactComponent as SearchIcon } from '@shared/images/svg/Search.svg';
import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuthorize, useResize } from '@libs/utils';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, avatarDownloadThunk, editUserThunk, getUserThunk, type RootState } from '@store/store';
import { updateIsSubscribedOnExit } from '@store/store/slices/algopack.slice';
import { getCartCountThunk } from '@store/store/thunk/cart.thunk';
import { SCREEN_XL } from '@libs/constants';
import { Notifications } from '@libs/components/pageBlocks/Navigation/Notifications';
import {
    getNotificationsThunk,
    getUnreadNotificationsCountThunk,
    setNotificationStatusThunk,
} from '@store/store/thunk/notification.thunk';
import { getReadNotificationFromLocalStorage } from '@libs/components/pageBlocks/Navigation/Notifications/utils';
import { type AxiosError } from 'axios';
import { type NotificationArgs } from '@libs/types';
import { unwrapResult } from '@reduxjs/toolkit';

import { DropdownUserPanel } from './DropdownUserPanel';
import { AvatarButton } from './AvatarButton';
import { BurgerMenu } from './BurgerMenu';
import { Search } from './Search';
import styles from './styles.module.scss';

const navigations = [
    // {
    //     text: 'Продукты',
    //     link: '/products',
    // },
    {
        text: 'Статьи',
        link: '/articles',
    },
    // {
    //     text: 'Партнерам',
    //     link: '',
    // },
    {
        text: 'Справочный центр',
        link: '/help-center',
    },
];

const products = {
    INTERACTIVE_MAP: 'interactiveMap',
    VOLATILITY: 'volatility',
    DATA_UPLOAD: 'dataUpload',
};

type Props = {
    children?: ReactNode;
    className?: string;
    withUnderLine?: boolean;
    logo?: ReactNode;
    link?: string;
};

export const Navigation = ({ children, className, withUnderLine = true, logo, link }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { loginHandler, isAuthorized, logoutHandler, loginInProgress } = useAuthorize();
    const { isScreenSm, isScreenXl, width } = useResize();
    const [searchParams] = useSearchParams();

    const productsWithNavigationOnly =
        searchParams.get('tab') === products.INTERACTIVE_MAP ||
        searchParams.get('tab') === products.VOLATILITY ||
        searchParams.get('tab') === products.DATA_UPLOAD;

    const op = useRef<OverlayPanel>(null);

    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [isHideIcons, setIsHideIcons] = useState<boolean>(false);
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState<boolean>(false);

    const imageAvatarSelector = useSelector((state: RootState) => state.user.userAvatar);
    const userSelector = useSelector((state: RootState) => state.user.user);
    const { unreadNotificationsCount, notifications } = useSelector((state: RootState) => state.notifications);
    const [withMenuBurger, setWithMenuBurgerStatus] = useState<boolean>(false);
    const [haveUnread, setHaveUnread] = useState<boolean>(false);

    const isMobileScreen = width <= SCREEN_XL;

    useEffect(() => {
        if (isSearchOpen) {
            setMenuOpen(false);
            setIsNotificationsOpen(false);
        }
    }, [isSearchOpen]);

    useEffect(() => {
        if (isNotificationsOpen) {
            setMenuOpen(false);
            setIsSearchOpen(false);
        } else {
            readNotifications();
        }
    }, [isNotificationsOpen]);

    useEffect(() => {
        if (isMenuOpen) {
            setIsNotificationsOpen(false);
            setIsSearchOpen(false);
        }
    }, [isMenuOpen]);

    useEffect(() => {
        const notificationIds: number[] = getReadNotificationFromLocalStorage();

        if (notifications.length > 0) {
            const filtered = notifications.filter(({ id }) => !notificationIds.includes(id));
            setHaveUnread(filtered.length > 0);
        }
    }, [notifications]);

    useEffect(() => {
        (async () => {
            if (isAuthorized) {
                try {
                    await dispatch(getUserThunk()).then((res) => {
                        const result = unwrapResult(res);

                        if (!result.onboardingWasShown) {
                            dispatch(editUserThunk({ onboardingWasShown: true }));
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
                dispatch(getCartCountThunk());
            }
        })();
    }, [isAuthorized]);

    useEffect(() => {
        (async () => {
            if (userSelector?.photoUrl) {
                try {
                    await dispatch(
                        avatarDownloadThunk({
                            bucket: userSelector.photoUrl.bucket,
                            ticket: userSelector.photoUrl.fileId,
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [userSelector]);

    useEffect(() => {
        if (isMobileScreen) {
            setWithMenuBurgerStatus(true);
        } else {
            setWithMenuBurgerStatus(false);
        }
    }, [isMobileScreen]);

    useEffect(() => {
        const res = isSearchOpen && isScreenSm;
        setIsHideIcons(res);
        setScroll(!res);
    }, [isSearchOpen, isScreenSm]);

    const readNotifications = async () => {
        if (isAuthorized) {
            const notificationIds: number[] = getReadNotificationFromLocalStorage();

            if (notificationIds.length) {
                await dispatch(setNotificationStatusThunk({ notificationIds }));
                localStorage.removeItem('read_notifications');
            }
        }
    };

    const logoutWithUnsub = () => {
        logoutHandler();
        dispatch(updateIsSubscribedOnExit());
    };

    const toggleMenuCallback = useCallback(() => {
        setScroll(isMenuOpen);
        setMenuOpen((prev) => !prev);
    }, [isMenuOpen]);

    const setScroll = (showScroll: boolean) => {
        if (showScroll) {
            document.body.style.overflowY = 'auto';
        } else {
            document.body.style.overflowY = 'hidden';
        }
    };

    const handleToggleNotifications = () => {
        setIsNotificationsOpen((prev) => !prev);
        window.scrollTo(0, 0);
    };

    const notAuthorizedRing = !haveUnread ? <RingEmptyIcon cursor={'pointer'} /> : <RingIcon cursor={'pointer'} />;
    const authorizedRing =
        unreadNotificationsCount === 0 ? <RingEmptyIcon cursor={'pointer'} /> : <RingIcon cursor={'pointer'} />;

    const notificationIconView = () => {
        if (isNotificationsOpen) {
            return <CrossIcon cursor={'pointer'} />;
        } else {
            if (isAuthorized) {
                return authorizedRing;
            } else {
                return notAuthorizedRing;
            }
        }
    };

    const handleClickAvatar = (e: React.SyntheticEvent) => {
        op?.current?.toggle?.(e);
        setIsSearchOpen(false);
    };

    const getLogo = () => {
        if (logo) {
            return logo;
        }

        return isScreenXl ? <HeaderLogoXlIcon /> : <HeaderLogoIcon />;
    };

    return (
        <div className={classNames(styles.navigationWrapper, className, isSearchOpen && styles.stickNavigation)}>
            <div className={classNames(styles.navigationPanelWrapper, withMenuBurger && styles.withHiddenNavigation)}>
                {(!isMobileScreen && children) || (
                    <div className={styles.navigationLInksBlock}>
                        <Link to={link || '/'}>{getLogo()}</Link>
                        <nav>
                            <ul>
                                {navigations.map(({ link, text }, index) => link
? (
                                        <Link
                                            key={link + text}
                                            to={link}
                                            className={classNames(
                                                styles.navigationItem,
                                                !isAuthorized && styles.smallSize,
                                            )}
                                        >
                                            {text}
                                        </Link>
                                    )
: (
                                        <li key={index} className={classNames(styles.navigationItem)}>
                                            {text}
                                        </li>
                                    ),
                                )}
                            </ul>
                        </nav>
                    </div>
                )}

                <div className={styles.navigationIcons}>
                    {(productsWithNavigationOnly && isMobileScreen) || !productsWithNavigationOnly
? (
                        <>
                            {isSearchOpen
? (
                                <CrossIcon onClick={() => setIsSearchOpen(false)} />
                            )
: (
                                <SearchIcon onClick={() => setIsSearchOpen(true)} className={styles.searchIcon} />
                            )}
                            <div onClick={handleToggleNotifications}>{notificationIconView()}</div>
                        </>
                    )
: null}

                    <>
                        {/* todo: removed from april release */}
                        {/*                            {isAuthorized && (
                                <>
                                    {width > 1359 && (
                                        <Link to={'/cart'}>{cartCount ? <ShoppingCartRed /> : <ShoppingCart />}</Link>
                                    )}
                                </>
                            )} */}

                        {isMenuOpen
? (
                            <CrossIcon
                                className={classNames(styles.burgerMenuButton, !withMenuBurger && styles.hide)}
                                onClick={toggleMenuCallback}
                            />
                        )
: !isAuthorized
? (
                            <BurgerMenuIcon
                                className={classNames(styles.burgerMenuButton, !withMenuBurger && styles.hide)}
                                onClick={toggleMenuCallback}
                            />
                        )
: (
                            <BurgerMenuIcon
                                className={classNames(styles.burgerMenuButton, !withMenuBurger && styles.hide)}
                                onClick={toggleMenuCallback}
                            />
                        )}
                    </>
                </div>

                <div className={classNames(styles.divider, withMenuBurger && styles.hide)} />

                <div className={classNames(styles.navigationButtons, withMenuBurger && styles.hide)}>
                    {!isAuthorized
? (
                        <>
                            <Button
                                className={styles.whiteBtn}
                                label={'Регистрация'}
                                onClick={() => loginHandler({ isRegistration: true })}
                            />
                            <Button className={styles.redBtn} label={'Вход'} onClick={() => loginHandler()} />
                        </>
                    )
: (
                        <>
                            <AvatarButton img={imageAvatarSelector} avatarSize={36} onClick={handleClickAvatar} />

                            <OverlayPanel className={styles.overlayUserPanel} ref={op}>
                                <DropdownUserPanel />
                            </OverlayPanel>
                        </>
                    )}
                </div>
            </div>

            {withMenuBurger && withUnderLine && !isHideIcons && <hr className={styles.headerUnderline} />}

            {isSearchOpen && <Search setIsOpen={(value: boolean) => setIsSearchOpen(value)} />}

            <Notifications isOpen={isNotificationsOpen} setIsNotificationsOpen={setIsNotificationsOpen} />

            {isMenuOpen && (
                <BurgerMenu
                    loginHandler={loginHandler}
                    logoutHandler={logoutWithUnsub}
                    setMenuOpen={toggleMenuCallback}
                    isAuthorized={isAuthorized}
                    children={children}
                />
            )}
        </div>
    );
};
