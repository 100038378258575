import contentImg1 from '@shared/images/marketDive/png/contentImg1.png';
import contentImg2 from '@shared/images/marketDive/png/contentImg2.png';
import contentImg3 from '@shared/images/marketDive/png/contentImg3.png';
import contentImg4 from '@shared/images/marketDive/png/contentImg4.png';
import contentImg5 from '@shared/images/marketDive/png/contentImg5.png';
import contentImg6 from '@shared/images/marketDive/png/contentImg6.png';

export const contentSliderList = [
    {
        customHtmlText:
            '<ul><li>Доля рынка по разным группам активов.</li><li>Подробный анализ изменений конкурентного ландшафта</li></ul>',
        text: '',
        heading: 'возможности',
        title: 'ОБЩАЯ СТАТИСТИКА',
        image: contentImg1,
    },
    {
        text: 'LTV, время до первой транзакции, отток. Оценка ценности клиента во времени и скорость вовлечения новых клиентов, что полезно для прогнозирования и распределения ресурсов',
        heading: 'возможности',
        title: 'ПОКАЗАТЕЛИ ЭФФЕКТИВНОСТИ',
        image: contentImg2,
    },
    {
        text: 'Информация по новым счетам. Взгляд на источники поступления и уровень опыта новых клиентов, позволяющий оптимизировать маркетинговые стратегии',
        heading: 'возможности',
        title: 'АНАЛИЗ ПРИТОКА',
        image: contentImg3,
    },
    {
        text: 'Глубокий анализ клиентов, сегментированных по объему активов и с учетом наличия счетов клиентов у более чем одного профессионального участника',
        heading: 'возможности',
        title: 'ДЕТАЛИЗАЦИЯ ПО ВЕЛИЧИНЕ АКТИВОВ',
        image: contentImg4,
    },
    {
        text: 'Открытие счетов у конкурирующих брокеров. Анализ лояльности клиентов и факторов риска ухода, необходимый для стратегий удержания',
        heading: 'возможности',
        title: 'РИСК ОТТОКА',
        image: contentImg5,
    },
    {
        text: 'Сравнительный анализ основных брокеров, способствующий конкурентному позиционированию',
        heading: 'возможности',
        title: 'РЕЙТИНГИ ПРОФ УЧАСТНИКОВ',
        image: contentImg6,
    },
];
