import { ReactComponent as RingEmptyIcon } from '@shared/images/svg/RingEmpty.svg';

import styles from './styles.module.scss';

export const EmptyNotifications = () => {
    return (
        <div className={styles.notificationsWrapper}>
            <div>
                <RingEmptyIcon className={styles.ringIcon} />
            </div>
            <div>
                <p className={styles.emptyText}>Уведомлений нет</p>
            </div>
        </div>
    );
};
