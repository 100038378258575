import classNames from 'classnames';
import { Button } from 'primereact/button';
import { type SubscriptionData } from '@libs/types/subscription.type';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { getProductByGuidThunk } from '@store/store/thunk/product.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { productBtnName } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/data';
import { type Product, type TariffFilter as TariffFilterReq } from '@libs/types';
import { getTariffsThunk } from '@store/store/thunk/tariff.thunk';
import { Link } from 'react-router-dom';
import { useModal } from '@libs/utils';
import { ModalWindow } from '@libs/components';
import { ApiKeyModal } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/SubscriptionBlock/SubscriptionCard/ApiKeyModal';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import { format } from 'date-fns';
import { useConfig } from '@dynamic-env/useConfig';
import { IS_INVESTORPRO_REDIRECT_PARAMETER } from '@libs/utils/hooks/useAuthorize';
import { useRoutes } from '@libs/utils/hooks/useRoutes';

import styles from './styles.module.scss';

type ProductCard = {
    item: SubscriptionData;
};

const bodyReq: TariffFilterReq = {
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'id',
        sortOrder: 'asc',
    },
};

export const SubscriptionCard = ({ item }: ProductCard) => {
    const { config } = useConfig();
    const dispatch = useDispatch<AppDispatch>();

    const [product, setProduct] = useState<Product>();
    const [logo, setLogo] = useState<string>();
    const [tariffName, setTariffName] = useState<string>();

    const { isOpen: isOpenApiKeyModal, openModal: openApiKeyModal, closeModal: closeApiKeyModal } = useModal();

    useEffect(() => {
        dispatch(getProductByGuidThunk(item.productGuid)).then((res) => {
            const product = unwrapResult(res);
            setProduct(product);

            if (product.cartImage) {
                dispatch(downloadFileThunk({ bucket: product.cartImage.bucket, ticket: product.cartImage.fileId }))
                    .then((resp) => {
                        const result = unwrapResult(resp);
                        setLogo(result);
                    })
                    .catch((e) => console.error(e));
            }
        });
        bodyReq.productGuid = item.productGuid;
        bodyReq.tariffGuid = item.tariffGuid;
        dispatch(getTariffsThunk(bodyReq)).then((res) => {
            const result = unwrapResult(res);

            if (result.length) {
                setTariffName(result[0].name);
            }
        });
    }, []);

    const productRoute = useRoutes(true);

    return (
        <article className={styles.wrapper}>
            <div className={classNames(styles.topPart)}>
                <img src={logo} />
            </div>
            <div className={classNames(styles.bottomPart, 'flex', 'flex-column')}>
                <div className={classNames(styles.infoContainer, 'flex', 'flex-column')}>
                    <p>Подробности тарифа</p>
                    <div className={classNames('flex', 'flex-column')}>
                        <div className={classNames(styles.itemWrapper, 'flex', 'justify-content-between')}>
                            <span>Ваш тариф</span>
                            <span>{tariffName}</span>
                        </div>
                        <div className={classNames(styles.itemWrapper, 'flex', 'justify-content-between')}>
                            <span>Стоимость</span>
                            <span className={classNames('flex', 'align-items-baseline')}>
                                {item.cost || '0'}
                                <span>₽</span>
                            </span>
                        </div>
                        <div className={classNames(styles.itemWrapper, 'flex', 'justify-content-between')}>
                            <span>Оплачен по</span>
                            <span>{item.endDate ? format(new Date(item.endDate), 'dd.MM.yyyy') : '—'}</span>
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.buttonContainer, 'flex', 'flex-column')}>
                    {product?.mnemoCode === ProductMnemoCodes.TPC_ALGO_PACK
? (
                        <Button
                            className={styles.redBtn}
                            label={productBtnName[product?.mnemoCode]}
                            onClick={openApiKeyModal}
                        />
                    )
: (
                        <Link
                            className={styles.redBtn}
                            to={
                                product?.mnemoCode === ProductMnemoCodes.INVESTOR_PRO
                                    ? `${config.REACT_APP_INVESTOR_PRO_LINK}?${IS_INVESTORPRO_REDIRECT_PARAMETER}`
                                    : `${productRoute(config)[product?.mnemoCode!]}`
                            }
                        >
                            <span>{productBtnName[product?.mnemoCode!]}</span>
                        </Link>
                    )}
                    <Link
                        className={styles.greyBtn}
                        to={`/personal-account?productGuid=${item.productGuid}&tariffGuid=${item.tariffGuid}`}
                    >
                        <span>Управление подпиской</span>
                    </Link>
                </div>
            </div>
            <ModalWindow isVisible={isOpenApiKeyModal} closeModal={closeApiKeyModal}>
                <ApiKeyModal apiKey={item.apiKey || ''} subscriptionId={item.id} />
            </ModalWindow>
        </article>
    );
};
