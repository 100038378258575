import classNames from 'classnames';

import { CapabilityItem } from './CapabilityItem';
import styles from './styles.module.scss';
import { type ProductCapability } from '../ProductCapabilities/type';

type Props = {
    list: ProductCapability[];
};

export const ProductCapabilities = ({ list }: Props) => {
    return (
        <section className={classNames(styles.productCapabilitiesWrapper, 'flex', 'flex-column')}>
            {list.map((item, index) => (
                <CapabilityItem key={item.title} {...item} imagePosition={index % 2 ? 'left' : 'right'} />
            ))}
        </section>
    );
};
