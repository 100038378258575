import { ReactComponent as MainLogo } from '@modules/Investorpro/shared/images/svg/InvestorProLogoNew.svg';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { ReactComponent as PlayWhiteIcon } from '@shared/images/svg/playWhite.svg';
import { Button } from 'primereact/button';
import type { RefObjectType } from '@libs/types';
import { scrollElementByRef, useAuthorize, useModal } from '@libs/utils';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';
import { useConfig } from '@dynamic-env/useConfig';
import { IS_INVESTORPRO_REDIRECT_PARAMETER } from '@libs/utils/hooks/useAuthorize';
import { InfoModal } from '@modules/Investorpro/shared/components/InfoModal';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const Header = ({ refs }: Props) => {
    const { config } = useConfig();
    const { isAuthorized } = useAuthorize();
    const activeTariff = useSelector((state: RootState) => state.investorPro.activeTariff);
    const { closeModal, openModal, isOpen } = useModal();

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <section className={styles.headerWrapper}>
            <div className={styles.contentWrapper}>
                <div className={styles.logoWrapper}>
                    <MainLogo />
                </div>
                <p>Аналитический сервис для работы с инвестиционными портфелями</p>
                {isAuthorized && !!activeTariff
? (
                    <Button
                        label={'Открыть'}
                        icon={<PlayWhiteIcon />}
                        onClick={() => (window.location.href =
                                `${config.REACT_APP_INVESTOR_PRO_LINK}?${IS_INVESTORPRO_REDIRECT_PARAMETER}` || '')}
                    />
                )
: (
                    <Button
                        className={'flex align-items-end'}
                        icon={<StarIcon />}
                        label={'Получить доступ'}
                        onClick={openModal}
                    />
                )}
            </div>
            <InfoModal isOpen={isOpen} closeModal={closeModal} />
        </section>
    );
};
