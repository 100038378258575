import { getDateTransformed } from './../../../../modules/PriceCenterProduct/InteractiveMap/utils';
import { getDataUpload } from '@libs/services';
import { GetProfitPointsRespBody } from '@libs/types';
import { getTradeDate } from '@modules/PriceCenterProduct/InteractiveMap/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getDataUploadThunk = createAsyncThunk(
    'priceCenter/getDataUploadThunk',
    async (data: string | Date | string[] | Date[], { rejectWithValue }) => {
        try {
            let data: GetProfitPointsRespBody = {};

            let count = 0;
            let bonds = [];

            while (count <= 5 || !bonds.length) {
                const dataResponse = await getDataUpload({ tradeDate: getDateTransformed(getTradeDate(count)) });

                data = dataResponse.data;
                bonds = data.bonds;
                count++;

                if (bonds.length) {
                    break;
                }
            }
            return {
                data: data,
            };
        } catch (e) {
            console.error('e', e);
            return rejectWithValue(e);
        }
    },
);

export { getDataUploadThunk };
