import { Dialog } from 'primereact/dialog';
import React, { type ReactNode } from 'react';
import { ReactComponent as CrossModalIcon } from '@shared/images/svg/CrossModalIcon.svg';
import classNames from 'classnames';
import { isMobileDevice } from '@libs/utils';

import styles from './styles.module.scss';

export type ModalWindowProps = {
    children?: ReactNode;
    isVisible: boolean;
    closeModal: () => void;
    className?: string;
    dismissableMask?: boolean;
};

export const ModalWindow = ({
    children,
    isVisible,
    closeModal,
    className = '',
    dismissableMask = false,
}: ModalWindowProps) => {
    const isMobile = isMobileDevice();

    return (
        <Dialog
            showHeader={false}
            onHide={closeModal}
            visible={isVisible}
            keepInViewport={false}
            className={classNames(className, styles.modal)}
            dismissableMask={dismissableMask}
            pt={isMobile ? { mask: { className: styles.dialogMobile } } : {}}
        >
            <CrossModalIcon onClick={closeModal} className={styles.closeIcon} />
            {children}
        </Dialog>
    );
};
