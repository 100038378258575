import { ServiceItem } from '@modules/ProPackLanding/shared/components/ServiceItem';
import { productCapabilities } from '@modules/ProPackLanding/components/RiskRates/data';
import type { RefObjectType } from '@libs/types';
import { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { scrollElementByRef, useResize } from '@libs/utils';
import classNames from 'classnames';
import serviceItemStyles from '@modules/ProPackLanding/shared/components/ServiceItem/styles.module.scss';

import { ReactComponent as StarIcon } from '../../../../shared/images/svg/star.svg';
import headerBg from '../../shared/images/png/riskRates/headerBg.png';
import { ProductCapabilities } from '../../shared/components/ProductCapabilities';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
    setRefs: (refs: RefObjectType<HTMLDivElement>) => void;
};

export const RiskRates = ({ refs, setRefs }: Props) => {
    const { isScreenXl } = useResize();
    const riskRatesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setRefs({ riskRatesRef });
    }, [setRefs]);

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <ServiceItem
            ref={riskRatesRef}
            image={headerBg}
            title={'Ставки риска'}
            description={`Индикативные ставки риска, рассчитываемые НКЦ для инструментов, с которыми могут совершаться сделки купли-продажи и/или РЕПО на рынках Московской Биржи ${isScreenXl ? ':' : ''}`}
            points={isScreenXl ? ['Фондовый рынок', 'Валютный рынок', 'Срочный рынок'] : []}
            buttons={[
                <Button
                    key={0}
                    className={classNames(serviceItemStyles.redBtn, serviceItemStyles.widthBtn)}
                    label={'Получить доступ'}
                    icon={<StarIcon />}
                    onClick={() => handleButtonClick('gainAccessRef')}
                />,
            ]}
        >
            <ProductCapabilities list={productCapabilities} />
        </ServiceItem>
    );
};
