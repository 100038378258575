import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useResize } from '@libs/utils';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ReactComponent as DirectoryWhiteIcon } from '@shared/images/svg/DirectoryWhiteIcon.svg';
import { ReactComponent as ArrowBottomWhite } from '@shared/images/svg/ArrowBottomWhite.svg';
import { type Reference } from '@modules/HelpCenter/HelpCenterPage/services/helpCenter.types';
import { useParams } from 'react-router-dom';
import DisplayContent from '@modules/Editor/DisplayContent/DisplayContent';
import { type Article } from '@libs/types/articles/article.types';

import styles from './styles.module.scss';
import { AccordionComponent } from './Accordion';
import { ChangePageTabs } from './ChangePageTabs';

type MainContentProps = {
    reference?: Reference;
    article?: Article;
};

export const MainContent = ({ reference, article }: MainContentProps) => {
    const { referenceId } = useParams<{ referenceId: string }>();
    const { articleId } = useParams<{ articleId: string }>();
    const [activeIndex, setActiveIndex] = useState<number | undefined>(0);
    const [isOpen, setOpen] = useState<boolean>(false);
    const { width } = useResize();
    const isMobile = width < 1024;

    const refDisplayContent = useRef<HTMLDivElement>(null);

    const AccordionOuterHeader = () => {
        return (
            <div className={classNames(styles.accordionOuterHeader, 'flex', 'align-items-center')}>
                <DirectoryWhiteIcon />
                <p>Разделы справки</p>
                <ArrowBottomWhite className={classNames(isOpen && styles.rotated)} />
            </div>
        );
    };

    if (!reference || !referenceId) {
        return <></>;
    }

    return (
        <section className={classNames(styles.wrapper, 'flex', isMobile && 'flex-column')}>
            {isMobile
? (
                <Accordion
                    className={styles.accordionOuterWrapper}
                    onTabClose={() => setOpen(false)}
                    onTabOpen={() => setOpen(true)}
                >
                    <AccordionTab
                        pt={{
                            content: {
                                className: styles.contentOuter,
                            },
                        }}
                        header={<AccordionOuterHeader />}
                    >
                        <AccordionComponent
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            refDisplayContent={refDisplayContent}
                            reference={reference}
                            referenceId={referenceId}
                            articleId={articleId || ''}
                        />
                    </AccordionTab>
                </Accordion>
            )
: (
                <div>
                    <h4>Содержание</h4>
                    <AccordionComponent
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        refDisplayContent={refDisplayContent}
                        reference={reference}
                        referenceId={referenceId}
                        articleId={articleId || ''}
                    />
                </div>
            )}
            <div ref={refDisplayContent}>
                {article && JSON.parse(article.content) && (
                    <DisplayContent content={JSON.parse(article.content).content} />
                )}
                <ChangePageTabs
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    reference={reference}
                    articleId={articleId || ''}
                />
            </div>
        </section>
    );
};
