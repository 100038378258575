import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { type Nullable } from 'primereact/ts-helpers';

import { FIRST_DATE_WITH_DATA, SCREEN_OPTIONS } from '../../dataConfig';
import styles from './styles.module.scss';
import { type AssetTypeModelList, type GroupModelList } from '../../services/types';

type ControlPanelProps = {
    selectedScreen: string;
    groupList: GroupModelList[];
    handleScreenChange: (screenName: string) => void;
    activesGroupsValue: GroupModelList | undefined;
    handleActivesGroupsChange: (groupName: string) => void;
    activesTypesValue: AssetTypeModelList | undefined;
    handleActivesTypesChange: (typeName: string) => void;
    surfaceChartDate: Nullable<Date>;
    setSurfaceChartDate: (arg: Nullable<Date>) => void;
    smileChartDate: Nullable<Date>;
    setSmileChartDate: (arg: Nullable<Date>) => void;
};

export const ControlPanel = ({
    selectedScreen,
    groupList,
    handleScreenChange,
    activesGroupsValue,
    handleActivesGroupsChange,
    activesTypesValue,
    handleActivesTypesChange,
    surfaceChartDate,
    setSurfaceChartDate,
    smileChartDate,
    setSmileChartDate,
}: ControlPanelProps) => {
    return (
        <div className={classNames(styles.controlsWrapper)}>
            <div className={styles.selectorWrapper}>
                {SCREEN_OPTIONS.map((screen) => (
                    <div
                        key={screen}
                        onClick={() => handleScreenChange(screen)}
                        className={classNames(styles.labelSelector, selectedScreen === screen && styles.activeSelector)}
                    >
                        {screen}
                    </div>
                ))}
            </div>
            <div className={classNames(styles.dropdownsBlockWrapper)}>
                <div className={classNames(styles.dropdownWrapper)}>
                    <div className={classNames(styles.titleWrapper)}>
                        <p>Группа</p>
                    </div>
                    <Dropdown
                        className={classNames(styles.dropdown)}
                        placeholder="Выберите группу"
                        options={groupList ?? []}
                        value={activesGroupsValue?.groupName}
                        onChange={(e) => handleActivesGroupsChange(e.value)}
                        optionValue="groupName"
                        optionLabel="groupName"
                        emptyFilterMessage="Ничего не найдено"
                        emptyMessage="Нет данных"
                    />
                </div>
                <div className={classNames(styles.dropdownWrapper)}>
                    <div className={classNames(styles.titleWrapper)}>
                        <p>Тип актива</p>
                    </div>
                    <Dropdown
                        className={classNames(styles.dropdown)}
                        placeholder="Выберите тип"
                        options={activesGroupsValue?.assetTypeModelList ?? []}
                        value={activesTypesValue?.assetTypeName}
                        onChange={(e) => handleActivesTypesChange(e.value)}
                        optionValue="assetTypeName"
                        optionLabel="assetTypeName"
                        emptyFilterMessage="Ничего не найдено"
                        emptyMessage="Нет данных"
                    />
                </div>
                <p className={styles.divider} />
                <div className={classNames(styles.dropdownWrapper)}>
                    <div className={classNames(styles.titleWrapper)}>
                        <p>На дату</p>
                    </div>
                    <div className={classNames(styles.calendarWrapper)}>
                        {selectedScreen === SCREEN_OPTIONS[0]
? (
                            <Calendar
                                className={classNames(styles.calendar)}
                                value={smileChartDate}
                                selectionMode="single"
                                onChange={(e) => setSmileChartDate(e.value)}
                                readOnlyInput
                                dateFormat="dd.mm.yy"
                                locale="ru"
                                showIcon
                                minDate={FIRST_DATE_WITH_DATA}
                                maxDate={new Date()}
                                inputClassName={classNames(styles.calendarInput)}
                                panelClassName={classNames(styles.volatCalendarPanel)}
                                pt={{
                                    header: {
                                        className: styles.calendarHeader,
                                    },
                                    tableBody: { className: styles.calendarTableBody },
                                    dropdownButton: { root: { className: styles.calendarDropdownButton } },
                                }}
                            />
                        )
: (
                            <Calendar
                                className={classNames(styles.calendar)}
                                value={surfaceChartDate}
                                selectionMode="single"
                                onChange={(e) => setSurfaceChartDate(e.value)}
                                readOnlyInput
                                dateFormat="dd.mm.yy"
                                locale="ru"
                                showIcon
                                minDate={FIRST_DATE_WITH_DATA}
                                maxDate={new Date()}
                                inputClassName={classNames(styles.calendarInput)}
                                panelClassName={classNames(styles.volatCalendarPanel)}
                                pt={{
                                    header: {
                                        className: styles.calendarHeader,
                                    },
                                    tableBody: { className: styles.calendarTableBody },
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
