import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { PaginationController } from '@libs/components';
import { type SearchEntityType, type SearchObject } from '@libs/types/search.type';
import { SEARCH_TABS } from '@libs/constants';
import { SearchItem } from '@modules/SearchPage/components/SearchResultsList/SearchItem';
import { useResize } from '@libs/utils';
import { Dropdown } from 'primereact/dropdown';

import styles from './styles.module.scss';

type Props = {
    searchValue: string;
    searchType: SearchEntityType[];
    setSearchType: (value: SearchEntityType[]) => void;
    list: SearchObject[];
    currentPage: number;
    setCurrentPage: (value: number) => void;
    totalPages: number;
    debouncedSearch: (value: string) => void;
};

export const SearchResultsList = ({
    searchValue,
    searchType,
    setSearchType,
    list,
    currentPage,
    setCurrentPage,
    totalPages,
    debouncedSearch,
}: Props) => {
    const { isScreenSm } = useResize();

    const [currentFilter, setCurrentFilter] = useState(SEARCH_TABS[0]);

    useEffect(() => {
        setCurrentFilter(SEARCH_TABS.find((item) => item.entities.toString() === searchType.toString())!);
    }, []);

    useEffect(() => {
        setSearchType(currentFilter.entities);
    }, [currentFilter]);

    useEffect(() => {
        debouncedSearch(searchValue);
    }, [searchValue, searchType, currentPage]);

    return (
        <section className={classNames(styles.searchResultsWrapper, 'flex', 'flex-column')}>
            <div className={classNames(styles.filterWrapper, 'flex')}>
                {!isScreenSm
? (
                    SEARCH_TABS.map((tab) => (
                        <div
                            key={tab.index}
                            className={classNames(
                                styles.tabWrapper,
                                currentFilter.index === tab.index && styles.selected,
                                'flex',
                                'align-items-center',
                            )}
                            onClick={() => setCurrentFilter(tab)}
                        >
                            <span>{tab.title}</span>
                        </div>
                    ))
                )
: (
                    <Dropdown
                        options={SEARCH_TABS}
                        optionValue="index"
                        optionLabel="title"
                        value={currentFilter.index}
                        onChange={(e) => setCurrentFilter(SEARCH_TABS.find((item) => item.index === e.target.value)!)}
                    />
                )}
            </div>
            {list.length
? (
                <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                    <div className={classNames('flex', 'flex-column')}>
                        {list.map((item) => (
                            <SearchItem key={item.objectId} item={item} searchValue={searchValue} />
                        ))}
                    </div>
                    <PaginationController current={currentPage} total={totalPages} setAsyncCurrent={setCurrentPage} />
                </div>
            )
: (
                <span className={styles.noContent}>Ничего не найдено</span>
            )}
        </section>
    );
};
