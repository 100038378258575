import classNames from 'classnames';
import { Button } from 'primereact/button';

import styles from './styles.module.scss';

type Props = {
    closeModal: () => void;
    productName: string;
};

export const InfoModal = ({ closeModal, productName }: Props) => {
    return (
        <div className={classNames(styles.infoModalWrapper)}>
            <h2>Отказ от оказания услуг по предоставлению Информационного продукта «{productName}» оформлен.</h2>
            <p>Срок зачисления денежных средств зависит от условий выбранного Вами банка.</p>
            <p>
                Если возврат средств на счет, который Вы указали — невозможен (утрата платежной карты, смены её номера
                при перевыпуске и т.д.), с Вами свяжется специалист технической поддержки.
            </p>
            <p>
                Для уточнения деталей можете обратиться в техническую поддержку: <span>HelpDS@moex.com</span>
            </p>
            <Button label={'Закрыть'} onClick={closeModal} />
        </div>
    );
};
