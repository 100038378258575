import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { SliderButtons } from '@libs/components';

import styles from './styles.module.scss';

type Props = {
    images: string[];
};

export const Carousel = ({ images }: Props) => {
    const [activeSlide, setActiveSlide] = useState(1);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const carouselRef = useRef<HTMLDivElement | null>(null);

    // В начале и конце добавляем клон последних и первых слайдов
    const extendedSlides = [images[images.length - 1], ...images, images[0]];

    // Убираем анимацию и "прыгаем" на реальный слайд после перехода
    useEffect(() => {
        if (isTransitioning) {
            const handleTransitionEnd = () => {
                setIsTransitioning(false);

                if (activeSlide === extendedSlides.length - 1) {
                    setActiveSlide(1); // возвращаемся к первому реальному слайду
                } else if (activeSlide === 0) {
                    setActiveSlide(images.length); // возвращаемся к последнему реальному слайду
                }
            };

            const carousel = carouselRef.current;
            carousel!.addEventListener('transitionend', handleTransitionEnd);

            return () => {
                carousel!.removeEventListener('transitionend', handleTransitionEnd);
            };
        }
    }, [activeSlide, isTransitioning, extendedSlides.length]);

    return (
        <div className={styles.carouselWrapper}>
            <div
                ref={carouselRef}
                className={classNames('flex', styles.imagesWrapper)}
                style={{
                    transform: `translateX(-${activeSlide * 100}%)`,
                    transition: isTransitioning ? 'transform 0.5s ease' : 'none',
                }}
            >
                {extendedSlides.map((image, index) => (
                    <img key={index} src={image} alt={`Slide ${index}`} />
                ))}
            </div>
            <div className={styles.sliderWrapper}>
                <SliderButtons
                    className={styles.sliderButton}
                    setActiveSlide={setActiveSlide}
                    isTransitioning={isTransitioning}
                    setIsTransitioning={setIsTransitioning}
                />
            </div>
        </div>
    );
};
