import { DataTable, type DataTableSortEvent, type DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ReactComponent as SortIcon } from '@shared/images/priceCenter/svg/sortIcon.svg';
import { ReactComponent as SortIconUp } from '@shared/images/priceCenter/svg/sortIconUp.svg';
import { ReactComponent as SortIconDown } from '@shared/images/priceCenter/svg/sortIconDown.svg';
import { type Nullable } from 'primereact/ts-helpers';
import classNames from 'classnames';
import { useState } from 'react';

import styles from './styles.module.scss';
import { VOLAT_TABLE_TITLES } from '../../dataConfig';

type SurfaceActiveProps = {
    data: DataTableValue[];
    selectedActives: Nullable<DataTableValue | null>;
    handleActivesChange: (asset: DataTableValue) => void;
};

export const SurfaceActivesTable = ({ data, selectedActives, handleActivesChange }: SurfaceActiveProps) => {
    const [sortField, setSortField] = useState<DataTableSortEvent['sortField']>('');
    const [sortOrder, setSortOrder] = useState<DataTableSortEvent['sortOrder']>(null);

    const onSort = (e: DataTableSortEvent) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    const getSortIcon = () => {
        if (sortField && sortOrder) {
            return sortOrder > 0 ? <SortIconUp /> : <SortIconDown />;
        } else {
            return <SortIcon />;
        }
    };

    return (
        <div>
            <DataTable
                value={data}
                selectionMode="radiobutton"
                selection={selectedActives}
                onSelectionChange={(e) => handleActivesChange(e.value)}
                dataKey="assetId"
                scrollable
                scrollHeight="526px"
                emptyMessage="Нет данных"
                pt={{
                    thead: { className: classNames(styles.tableHeader) },
                    headerRow: { className: classNames(styles.tableHeaderRow) },
                    tbody: { className: classNames(styles.content) },
                }}
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={onSort}
                sortIcon={getSortIcon()}
            >
                <Column selectionMode="single" headerStyle={{ width: '3rem' }} />
                {VOLAT_TABLE_TITLES.map(({ id, label, sortable }) => (
                    <Column
key={id} field={id} header={label}
sortable={sortable} />
                ))}
            </DataTable>
        </div>
    );
};
