import classNames from 'classnames';
import { descriptions } from '@modules/CKI/components/DescriptionBlock/data';
import { DescriptionItem } from '@modules/CKI/components/DescriptionBlock/DescriptionItem';

import styles from './styles.module.scss';

export const DescriptionBlock = () => {
    return (
        <section className={classNames(styles.descriptionBlockWrapper, 'flex', 'flex-column')}>
            <div className={classNames(styles.titleWrapper, 'flex', 'flex-column')}>
                <h2>
                    <span>Надежный ориентир</span>
                    <br /> в принятии решений
                </h2>
                <p>
                    Для профучастников и их подразделений, вендоров, аудиторов, управляющих и финансовых консультантов
                </p>
            </div>
            <div className={classNames(styles.contentWrapper)}>
                {descriptions.map((item) => (
                    <DescriptionItem key={item.title} {...item} />
                ))}
            </div>
        </section>
    );
};
