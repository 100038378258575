import { Button } from 'primereact/button';
import { ReactComponent as MarketDive } from '@shared/images/marketDive/logo.svg';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import classNames from 'classnames';
import { type RefObjectType } from '@libs/types';
import { scrollElementByRef } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const LogoBlock = ({ refs }: Props) => {
    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <section className={styles.logoBlockWrapper}>
            <div className={styles.contentWrapper}>
                <MarketDive />
                <p>Всесторонний аналитический взгляд на розничных клиентов на финансовом рынке</p>
                <div className={classNames('flex')}>
                    <Button onClick={() => handleButtonClick('gainAccessRef')}>
                        <StarIcon />
                        <span>Получить доступ</span>
                    </Button>
                </div>
            </div>
        </section>
    );
};
