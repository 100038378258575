import allDataRatingAgencies from '@shared/images/cki/productCapability/allDataRatingAgencies.png';
import deliveryReport from '@shared/images/cki/productCapability/deliveryReport.png';
import reportingFormat from '@shared/images/cki/productCapability/reportingFormat.png';
import expandingReportingFormat from '@shared/images/cki/productCapability/expandingReportingFormat.png';

export const capabilities = [
    {
        image: allDataRatingAgencies,
        title: 'Все данные рейтинговых агентств о кредитных рейтингах',
        description:
            'Сырые и агрегированные данные по типам, прогнозам и изменениям рейтинга эмитентов и выпусков ценных бумаг от всех кредитных рейтинговых агентств',
        points: [],
    },
    {
        image: deliveryReport,
        title: 'Оперативно поставляемая качественная отчетность РСБУ',
        description: '',
        points: [
            'Данные обновляются из нескольких источников, двухуровневая система контроля гарантирует высокое качество данных',
            'Поставляется уже с рассчитанными коэффициентами, что сокращает время на анализ отчетности',
        ],
    },
    {
        image: reportingFormat,
        title: 'Краткий формат отчетности МСФО',
        description:
            'Легкий и быстро интегрируемый формат, в котором предусмотрены ключевые показатели для финансового анализа и историчность для сравнения внесенных изменений',
        points: [],
    },
    {
        image: expandingReportingFormat,
        title: 'Развернутый формат отчетности МСФО',
        description:
            'Все необходимые данные для подробного финансового анализа позволят кратно сократить затраты на сбор финансовой отчетности',
        points: [
            'Содержит сведения об аудиторе с идентификатором',
            'В выгрузке присутствуют наиболее популярные мультипликаторы',
        ],
    },
];
