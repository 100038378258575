import type Plot from 'react-plotly.js';
import { type RefObject, useEffect, useRef, useState } from 'react';
import { ReactComponent as PanIcon } from '@modules/ProPack/shared/images/svg/PanIcon.svg';
import { ReactComponent as ResetIcon } from '@modules/ProPack/shared/images/svg/ResetCameraIcon.svg';
import { ReactComponent as ZoomIcon } from '@modules/ProPack/shared/images/svg/ZoomIcon.svg';
import { ReactComponent as CameraIcon } from '@modules/ProPack/shared/images/svg/CameraIcon.svg';
import { ReactComponent as TurntableRotationIcon } from '@modules/ProPack/shared/images/svg/TurntableRotationIcon.svg';
import { ReactComponent as OrbitalRotationIcon } from '@modules/ProPack/shared/images/svg/OrbitalRotationIcon.svg';
import classNames from 'classnames';

import styles from './style.module.scss';

type Props = {
    chartRef: RefObject<Plot>;
};

export const ModeBar = ({ chartRef }: Props) => {
    const buttons = useRef<HTMLDivElement[]>([]);
    const [activeBtn, setActiveBtn] = useState<number>();

    useEffect(() => {
        if (chartRef.current) {
            // @ts-expect-error Plot type error
            const modebar = chartRef.current?.el.querySelector('.modebar-group');

            if (modebar) {
                buttons.current = modebar.children;
            }
        }
    }, [chartRef.current]);

    const handleDownloadPlot = () => {
        buttons.current?.[0]?.click();
        setActiveBtn(undefined);
    };

    const handleZoom = () => {
        buttons.current?.[1]?.click();
        setActiveBtn(1);
    };

    const handlePan = () => {
        buttons.current?.[2]?.click();
        setActiveBtn(2);
    };

    const handleOrbitalRotation = () => {
        buttons.current?.[3]?.click();
        setActiveBtn(3);
    };

    const handleTurntableRotation = () => {
        buttons.current?.[4]?.click();
        setActiveBtn(4);
    };

    const handleReset = () => {
        buttons.current?.[5]?.click();
        setActiveBtn(5);
    };

    return (
        <div className={styles.modebar}>
            <CameraIcon onClick={handleDownloadPlot} className={classNames(activeBtn === 0 && styles.active)} />
            <ZoomIcon onClick={handleZoom} className={classNames(activeBtn === 1 && styles.active)} />
            <PanIcon onClick={handlePan} className={classNames(activeBtn === 2 && styles.active)} />
            <OrbitalRotationIcon
                onClick={handleOrbitalRotation}
                className={classNames(activeBtn === 3 && styles.active)}
            />
            <TurntableRotationIcon
                onClick={handleTurntableRotation}
                className={classNames(activeBtn === 4 && styles.active)}
            />
            <ResetIcon onClick={handleReset} className={classNames(activeBtn === 5 && styles.active)} />
        </div>
    );
};
