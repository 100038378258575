import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { RangeChart } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/RangeChart';
import { PriceProfitHeader } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/ChartHeaders/PriceProfitHeader';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { type PriceCenterBond, type PriceCenterValuation } from '@store/store/thunk/pricecenter/Models';
import { selectMarketPointThunk } from '@store/store/thunk/pricecenter/getChartsSelectors.thunk';
import {
    selectPricePoint,
    selectProfitPoint,
    toggleChartVisibility,
    tossMarketSelectedPoints,
    tossPriceSelectedPoints,
    tossProfitSelectedPoints,
    unSelectMarketPoint,
    unSelectPricePoint,
    unSelectProfitPoint,
} from '@store/store/slices/pricecenter.slice';
import { VisibilityToggles } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/VisibilityToggles';
import { MarketHeader } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/ChartHeaders/MarketHeader';
import {
    PointHover,
    type PointHoverData,
    priceCenterClickCallback,
    priceCenterTooltipCallback,
} from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/ChartTooltips';
import { useResize } from '@libs/utils';

import {
    SEPARATED_DECIMAL_2_FORMAT,
    mapToMarketDatasets,
    mapToPriceDatasets,
    priceCenterMarketColors,
    getPriceProfitCheckboxColor,
    getXAxisDatesForPriceProfitChart,
    getShortMonthLabels,
} from './utils';
import { getMinMaxValuesOfBonds } from '../utils';
import styles from './styles.module.scss';

import 'chartjs-adapter-date-fns';
import { type Tick } from 'chart.js';
import { PriceCentrePermissions } from '@libs/types/subscription.type';

type MapsContainerProps = {
    permissions: PriceCentrePermissions[];
};

export const MapsContainer = ({ permissions }: MapsContainerProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const charts = useSelector((state: RootState) => state.priceCenter.charts);

    const [marketChartData, setMarketChartData] = useState<object | undefined>(undefined);
    const [marketHover, setMarketHover] = useState<PointHoverData<PriceCenterBond> | null>(null);

    const [priceChartData, setPriceChartData] = useState<object | undefined>(undefined);
    const [priceHover, setPriceHover] = useState<PointHoverData<PriceCenterValuation> | null>(null);

    const [profitChartData, setProfitChartData] = useState<object | undefined>(undefined);
    const [profitHover, setProfitHover] = useState<PointHoverData<PriceCenterValuation> | null>(null);

    const [profitSelected, setProfitSelected] = useState<boolean>(false);

    const { isScreenSm, isScreenMd, isScreenLessSm } = useResize();

    const mobileDevice = isScreenLessSm || isScreenSm || isScreenMd;

    const marketOptions = {
        responsive: true,
        aspectRatio: mobileDevice ? 1 : 2,
        animation: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                external: priceCenterTooltipCallback(
                    'market',
                    charts.data.marketCharts,
                    charts.data.marketSelectedPoints,
                    setMarketHover,
                ),
            },
        },
        onClick: priceCenterClickCallback(
            charts.data.marketCharts,
            charts.data.marketSelectedPoints,
            (point) => {
                if (charts.data.marketSelectedPoints.length < 5) {
                    dispatch(
                        selectMarketPointThunk({
                            ...point,
                            isDemo: !permissions.includes(PriceCentrePermissions.REAL_POINTS),
                        }),
                    );
                }
            },
            (point) => {
                dispatch(unSelectMarketPoint(point));
            },
        ),
        scales: {
            x: {
                max: () => {
                    if (charts.data.marketCharts[0]) {
                        const result = Math.max(
                            ...charts.data.marketCharts[0]?.data.map((i) => i.valDate).sort((a, b) => a - b),
                        );

                        return Math.ceil(result);
                    }
                },
                min: () => {
                    if (charts.data.marketCharts[0]) {
                        const result = Math.min(
                            ...charts.data.marketCharts[0]?.data.map((i) => i.valDate).sort((a, b) => a - b),
                        );

                        return Math.floor(result);
                    }
                },
                title: {
                    display: false,
                    text: 'Лет до погашения',
                },
            },
            y: {
                max: () => {
                    getMinMaxValuesOfBonds(charts.data.marketCharts).max;
                },
                min: () => {
                    getMinMaxValuesOfBonds(charts.data.marketCharts).min;
                },
                title: {
                    display: false,
                    text: '% годовых',
                },
                position: 'right',
                ticks: {
                    callback: (value: any) => `${SEPARATED_DECIMAL_2_FORMAT.format(value)}%`,
                },
            },
        },
    };

    const priceOptions = {
        animation: false,
        responsive: true,
        aspectRatio: mobileDevice ? 1 : 2,

        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                external: priceCenterTooltipCallback(
                    'price',
                    charts.data.priceCharts,
                    charts.data.priceSelectedPoints,
                    setPriceHover,
                ),
            },
        },
        onClick: priceCenterClickCallback(
            charts.data.priceCharts,
            charts.data.priceSelectedPoints,
            (point) => {
                if (charts.data.priceSelectedPoints.length < 5) {
                    dispatch(selectPricePoint(point));
                    setPriceHover(null);
                }
            },
            (point) => {
                dispatch(unSelectPricePoint(point));
                setPriceHover(null);
            },
        ),
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Дата',
                },
                type: 'time',
                time: {
                    unit: 'month',
                },

                ticks: {
                    minRotation: 0,
                    maxRotation: 0,
                    align: 'start',
                    source: 'labels',
                    callback: (value: number, index: number, ticks: Tick[]) => getShortMonthLabels(value, index, ticks),
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'И.П.',
                },
                position: 'right',
                ticks: {
                    callback: (value: any) => SEPARATED_DECIMAL_2_FORMAT.format(value),
                },
            },
        },
    };

    const profitOptions = {
        animation: false,
        responsive: true,
        aspectRatio: mobileDevice ? 1 : 2,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                external: priceCenterTooltipCallback(
                    'profit',
                    charts.data.profitCharts,
                    charts.data.profitSelectedPoints,
                    setProfitHover,
                ),
            },
        },
        onClick: priceCenterClickCallback(
            charts.data.profitCharts,
            charts.data.profitSelectedPoints,
            (point) => {
                if (charts.data.profitSelectedPoints.length < 5) {
                    dispatch(selectProfitPoint(point));
                    setProfitHover(null);
                }
            },
            (point) => {
                dispatch(unSelectProfitPoint(point));
                setProfitHover(null);
            },
        ),
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Дата',
                },
                type: 'time',
                time: {
                    unit: 'month',
                },

                ticks: {
                    minRotation: 0,
                    maxRotation: 0,
                    align: 'start',
                    source: 'labels',
                    callback: (value: number, index: number, ticks: Tick[]) => getShortMonthLabels(value, index, ticks),
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'И.П.',
                },
                position: 'right',
                ticks: {
                    callback: (value: any) => SEPARATED_DECIMAL_2_FORMAT.format(value),
                },
            },
        },
    };

    useEffect(() => {
        // Карта рынка
        setMarketChartData({
            datasets: mapToMarketDatasets(charts.data.marketCharts),
        });
        setPriceChartData({
            labels: getXAxisDatesForPriceProfitChart(charts),
            datasets: mapToPriceDatasets(charts.data.priceCharts),
        });
        setProfitChartData({
            labels: getXAxisDatesForPriceProfitChart(charts),
            datasets: mapToPriceDatasets(charts.data.profitCharts),
        });
    }, [charts.data]);

    const toggleVisibility = (groupChartCode: string, chartCode: string) => {
        dispatch(toggleChartVisibility({ groupChartCode, chartCode }));
    };

    const tossPoints = (groupChartCode: string, a: any) => {
        let filteredArray = [];

        switch (groupChartCode) {
            case 'market':
                filteredArray = charts.data.marketSelectedPoints.filter((item) => item.code !== a.code);
                filteredArray.push(a);

                return dispatch(tossMarketSelectedPoints(filteredArray));
            case 'price':
                filteredArray = charts.data.priceSelectedPoints.filter(
                    (item) => item.dataPointIndex !== a.dataPointIndex,
                );
                filteredArray.push(a);

                return dispatch(tossPriceSelectedPoints(filteredArray));
            case 'profit':
                filteredArray = charts.data.profitSelectedPoints.filter(
                    (item) => item.dataPointIndex !== a.dataPointIndex,
                );
                filteredArray.push(a);

                return dispatch(tossProfitSelectedPoints(filteredArray));
        }
    };

    return (
        <div className={classNames(styles.mapsContainer)}>
            <div className={classNames(styles.mapContainer)}>
                <MarketHeader />
                {marketHover && <PointHover {...marketHover} />}
                <RangeChart
                    groupChartCode="market"
                    rawChartData={marketChartData}
                    rawChartOptions={marketOptions}
                    selectedPoints={charts.data.marketSelectedPoints}
                    unSelectPoint={(point) => dispatch(unSelectMarketPoint(point))}
                    tossPoints={(point) => tossPoints('market', point)}
                    titles={{ bottom: 'Лет до погашения', top: '% годовых' }}
                />
                <VisibilityToggles
                    groupChartCode="market"
                    data={charts.data.marketCharts}
                    toggleChartVisibility={(chartCode) => toggleVisibility('market', chartCode)}
                    colors={priceCenterMarketColors}
                    getCheckboxColor={getPriceProfitCheckboxColor}
                />
            </div>

            <div className={classNames(styles.mapContainer)}>
                <PriceProfitHeader
                    profitSelected={profitSelected}
                    onChange={() => setProfitSelected((prev) => !prev)}
                />
                {!profitSelected
? (
                    <>
                        {priceHover && <PointHover {...priceHover} />}
                        <RangeChart
                            groupChartCode="price"
                            rawChartData={priceChartData}
                            rawChartOptions={priceOptions}
                            selectedPoints={charts.data.priceSelectedPoints}
                            unSelectPoint={(point) => dispatch(unSelectPricePoint(point))}
                            tossPoints={(point) => tossPoints('price', point)}
                            titles={{ bottom: 'Дата', top: '%' }}
                            plugins="drawXLine"
                        />
                        <VisibilityToggles
                            groupChartCode="price"
                            data={charts.data.priceCharts}
                            toggleChartVisibility={(chartCode) => toggleVisibility('price', chartCode)}
                            getCheckboxColor={getPriceProfitCheckboxColor}
                        />
                    </>
                )
: (
                    <>
                        {profitHover && <PointHover {...profitHover} />}
                        <RangeChart
                            groupChartCode="profit"
                            rawChartData={profitChartData}
                            rawChartOptions={profitOptions}
                            selectedPoints={charts.data.profitSelectedPoints}
                            unSelectPoint={(point) => dispatch(unSelectProfitPoint(point))}
                            tossPoints={(point) => tossPoints('profit', point)}
                            titles={{ bottom: 'Дата', top: '% годовых' }}
                            plugins="drawXLine"
                        />
                        <VisibilityToggles
                            groupChartCode="profit"
                            data={charts.data.profitCharts}
                            toggleChartVisibility={(chartCode) => toggleVisibility('profit', chartCode)}
                            getCheckboxColor={getPriceProfitCheckboxColor}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
