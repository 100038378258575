import { format, sub, endOfYesterday } from 'date-fns';

import { type Market } from './types';

export const MAX_PLOTS_NUMBER = 5;

export const markerColors: Plotly.Color[] = ['#2380EB', '#EBC713', '#48A31A', 'red', '#2af7ed'];

export const scatterOptions = ['Кривые доходностей', 'Динамика процентной ставки'];

export const marketData: Market[] = [
    {
        id: 1,
        label: 'Облигации',
        value: 'bonds',
        curveTitle: 'Вид процентной кривой ',
        xAxisTitle: 'Лет до погашения',
        curveGroups: [
            {
                id: 1,
                label: 'Процентные кривые',
                value: 'zcyc',
                curves: [{ id: 1, label: 'Кривая бескупонной доходности ОФЗ', value: 'ZCYC' }],
            },
        ],
    },
    {
        id: 2,
        label: 'РЕПО',
        value: 'repo',
        curveTitle: 'Индикатор RUSFAR ',
        xAxisTitle: 'Срок РЕПО',
        curveGroups: [
            {
                id: 1,
                label: 'Индикаторы',
                value: 'indicators',
                curves: [
                    { id: 1, label: 'RUB', value: 'RUB_RUSFAR' },
                    { id: 2, label: 'CNY', value: 'CNY_RUSFAR', firstDate: '2023-12-04' },
                ],
            },
        ],
    },
    {
        id: 3,
        label: 'СПФИ',
        value: 'spfi',
        curveTitle: 'Кривая ',
        groupTitle: 'Типы кривых ',
        xAxisTitle: 'Срок',
        curveGroups: [
            {
                id: 1,
                label: 'Форвардные кривые',
                value: 'forward_curves',
                curves: [
                    { id: 1, label: 'RUB_RUONIA', value: 'RUB_RUONIA' },
                    { id: 2, label: 'RUB_KEYRATE', value: 'RUB_KEYRATE' },
                    { id: 3, label: 'CNY_RUSFAR', value: 'CNY_RUSFAR' },
                ],
            },
            {
                id: 2,
                label: 'Дисконтные кривые',
                value: 'discount_curves',
                curves: [
                    { id: 1, label: 'CNY_RUB', value: 'CNY_RUB' },
                    { id: 2, label: 'RUB_RUB', value: 'RUB_RUB' },
                ],
            },
            {
                id: 3,
                label: 'СВОП-кривые',
                value: 'swap_prices',
                curves: [
                    { id: 1, label: 'CNY-HYB-CNYRUO', value: 'CNY-HYB-CNYRUO' },
                    { id: 2, label: 'CNY-OIS-RUSFCN', value: 'CNY-OIS-RUSFCN' },
                    { id: 3, label: 'RUB-IRS-KEYRTE', value: 'RUB-IRS-KEYRTE' },
                    { id: 4, label: 'RUB-OIS-RUONIA', value: 'RUB-OIS-RUONIA' },
                ],
                times: [
                    { id: 1, label: '16.00', value: 1600 },
                    { id: 2, label: '18.00', value: 1800 },
                ]
            },
        ],
    },
];

export const firstDateWithData = new Date('2023-01-01');

export const formatDateStr = 'yyyy-MM-dd';

export const initSurfaceEndDate = format(new Date(), formatDateStr);

export const initSurfaceStartDate = format(sub(new Date(), { years: 1 }), formatDateStr);

export const lastDate = format(endOfYesterday(), formatDateStr);
