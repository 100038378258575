import classNames from 'classnames';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';
import { ReactComponent as QuestionIcon } from '../../../shared/images/svg/question.svg';

type Props = {
    title: string;
    price: string;
    points?: string[];
    openInfoModal?: () => void;
};

export const TariffItem = ({ title, price, points = [], openInfoModal }: Props) => {
    const { isScreenSm } = useResize();

    return (
        <div
            className={classNames(
                styles.itemWrapper,
                'flex',
                !isScreenSm && 'justify-content-between',
                isScreenSm ? 'flex-column' : 'flex-row',
            )}
        >
            <p className={styles.title}>
                {title}
                {!!points.length && <QuestionIcon onClick={openInfoModal} />}
            </p>
            <p className={styles.price}>
                {price}
                <span>₽</span>
            </p>
        </div>
    );
};
