import { Node, mergeAttributes, type NodeViewProps } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import React, { useState } from 'react';

import styles from './styles.module.scss';

type CarouselComponentProps = {
    node: {
        attrs: {
            images: string[];
            position: 'left' | 'center' | 'right';
            width: string;
            height: string;
        };
    };
};

export const Carousel = Node.create({
    name: 'carousel',
    group: 'block',
    content: 'block*',
    selectable: true,
    draggable: true,

    addAttributes() {
        return {
            images: {
                default: [],
                parseHTML: (element: HTMLElement) => JSON.parse(element.getAttribute('data-images') || '[]'),
                renderHTML: (attributes: { images: string[] }) => {
                    return {
                        'data-images': JSON.stringify(attributes.images),
                    };
                },
            },
            position: {
                default: 'left',
                parseHTML: (element: HTMLElement) => element.getAttribute('data-position') || 'left',
                renderHTML: (attributes: { position: 'left' | 'center' | 'right' }) => {
                    return {
                        'data-position': attributes.position,
                    };
                },
            },
            width: {
                default: '30vw',
                parseHTML: (element: HTMLElement) => Number(element.getAttribute('data-width')) || '30vw',
                renderHTML: (attributes: { width: number }) => {
                    return {
                        'data-width': attributes.width,
                    };
                },
            },
            height: {
                default: '30vw',
                parseHTML: (element: HTMLElement) => Number(element.getAttribute('data-height')) || '30vw',
                renderHTML: (attributes: { height: number }) => {
                    return {
                        'data-height': attributes.height,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-carousel]',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(HTMLAttributes, { 'data-carousel': '' }), 0];
    },

    addNodeView() {
        return ReactNodeViewRenderer(CarouselComponent);
    },
});

const CarouselComponent: React.FC<NodeViewProps> = ({ node }) => {
    const { images } = node.attrs;
    const { position } = node.attrs;
    const { width } = node.attrs;
    const { height } = node.attrs;
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <NodeViewWrapper
            className={styles.carousel}
            contentEditable={false}
            draggable
            data-drag-handle
            style={{ justifyContent: position }}
        >
            <div className={styles.carousel_inner} style={{ width: `${width}`, height: `${height}` }}>
                <button className={styles.prev} onClick={handlePrev}>
                    &#10094;
                </button>
                <div className={styles.carousel_images} style={{ width: `${width}`, height: `${height}` }}>
                    <div
                        className={styles.carousel_images_inner}
                        style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                        }}
                    >
                        {images.map((src: string | undefined, index: number) => (
                            <img
                                key={index}
                                src={src}
                                alt={`Slide ${index}`}
                                style={{ width: `${width}`, height: `${height}` }}
                            />
                        ))}
                    </div>
                </div>
                <button className={styles.next} onClick={handleNext}>
                    &#10095;
                </button>
            </div>
        </NodeViewWrapper>
    );
};
