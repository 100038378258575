import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as LogOutIcon } from '@shared/images/svg/LogOutIcon.svg';
import { Avatar } from '@libs/components';
import { Link, useSearchParams } from 'react-router-dom';
import { type AppDispatch, type RootState } from '@store/store';
import { updateIsSubscribedOnExit } from '@store/store/slices/algopack.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { proPackNavigation } from '@modules/ProPack/shared/components/Header';
import { priceCenterNavigations } from '@modules/PriceCenterProduct/Header';
import { useAuthorize } from '@libs/utils';

import styles from './styles.module.scss';

const linkRoutes = {
    account: '/personal-account',
    cart: '/cart',
    // products: '/products',
    articles: '/articles',
    // partners: '#',
    help: '/help-center',
    // support: '#',
    logout: '#',
    empty: '#',
};

export const BurgerMenu = ({ loginHandler, isAuthorized, logoutHandler, children, setMenuOpen }: any) => {
    const dispatch = useDispatch<AppDispatch>();

    const [searchParams, setSearchParams] = useSearchParams();

    const logoutWithUnsub = () => {
        logoutHandler();
        dispatch(updateIsSubscribedOnExit());
    };

    useEffect(() => {
        const handleClick = (e: any) => {
            const priceCenterLink = priceCenterNavigations.find((item) => item.text === e.target.innerHTML);
            const proPackLink = proPackNavigation.find((item) => item.title === e.target.innerHTML);

            if (priceCenterLink) setSearchParams({ tab: priceCenterLink.tab });

            if (proPackLink) setSearchParams({ tab: proPackLink.tab });

            setMenuOpen(false);
        };
        const moduleNavigation = document.querySelectorAll('[id=module-navigation]')[0]?.childNodes;

        const t = setTimeout(() => {
            moduleNavigation?.forEach((node: ChildNode) => {
                if (node.nodeName === 'LI' && (node as Element).getAttribute('aria-disabled') === 'false') {
                    node.addEventListener('click', handleClick);
                } else {
                    return null;
                }
            });
        }, 200);

        return () => clearTimeout(t);
    }, []);

    return (
        <div className={styles.menuWrapper}>
            <div className={styles.backgroundFilter} />
            <div className={classNames(styles.navigationButtons, styles.buttonsInMenu)}>
                {!isAuthorized ? (
                    <>
                        <Button
                            className={styles.whiteBtn}
                            label={'Регистрация'}
                            onClick={() => loginHandler({ isRegistration: true })}
                        />
                        <Button className={styles.redBtn} label={'Вход'} onClick={() => loginHandler()} />
                    </>
                ) : (
                    <div className="flex flex-column">
                        {children}
                        <ul className={classNames('flex', 'flex-column', styles.userFunctionsListWrapper)}>
                            <Link
                                to={linkRoutes.account}
                                className={classNames('flex', 'justify-content-between', 'align-items-center')}
                            >
                                <p>Личный кабинет</p>
                            </Link>

                            {/* todo: removed from april release */}
                            {/* <Link
                                to={linkRoutes.cart}
                                className={classNames('flex', 'justify-content-between', 'align-items-center')}
                            >
                                <p>Корзина</p>
                                {cartCount && <span className={styles.cartCount}>{cartCount}</span>}
                            </Link> */}
                        </ul>
                    </div>
                )}
            </div>
            <hr />
            <ul className={classNames('flex', 'flex-column')}>
                {/*<li>*/}
                {/*    <Link to={linkRoutes.products}>Продукты</Link>*/}
                {/*</li>*/}
                <li>
                    <Link to={linkRoutes.articles}>Статьи</Link>
                </li>
                {/* <li> */}
                {/*    <Link to={linkRoutes.partners}>Партнерам</Link> */}
                {/* </li> */}
                <li>
                    <Link to={linkRoutes.help}>Справочный центр</Link>
                </li>
                {/* <li> */}
                {/*    <Link to={linkRoutes.support}>Написать в поддержку</Link> */}
                {/* </li> */}
                {isAuthorized
? (
                    <>
                        <li className={styles.logout}>
                            <LogOutIcon />
                            <Link to={linkRoutes.logout} onClick={logoutWithUnsub}>
                                Выйти
                            </Link>
                        </li>
                    </>
                )
: null}
            </ul>
        </div>
    );
};
