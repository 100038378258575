import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllChartsThunk } from '@store/store/thunk/pricecenter/getAllCharts.thunk';
import { PriceCenterChart, PriceCenterStateChartsData } from '@store/store/thunk/pricecenter/Models';
import { selectMarketPointThunk } from '@store/store/thunk/pricecenter/getChartsSelectors.thunk';

import { checkSubscriptionThunk } from '@store/store/thunk/pricecenter/subscription.thunk';
import { SubscriptionData } from '@libs/types/subscription.type';
import { getDataUploadThunk } from '../thunk/pricecenter/getData.thunk';
import {
    getInstrumentsDataPerPeriodThunk,
    getInstrumentsDataThunk,
} from '../thunk/pricecenter/getInstrumentsData.thunk';
import { Bond, Emitter } from '@modules/PriceCenterProduct/types';

type DataUploadData = {
    data: { bonds: Bond[]; emitters: Emitter[]; bondsSelected: Bond[]; periodSelected: Emitter[] };
    isLoading: boolean;
    error: any;
};
export type IPriceCenterState = {
    charts: IPriceCenterStateCharts;

    activeTariff: SubscriptionData | null;
    dataUpload: DataUploadData;
};

export type IPriceCenterStateCharts = {
    data: PriceCenterStateChartsData;
    isLoading: boolean;
    error: any;
};

export const initialState: IPriceCenterState = {
    charts: {
        data: {
            marketCharts: [],
            marketSelectedPoints: [],
            priceCharts: [],
            priceSelectedPoints: [],
            profitCharts: [],
            profitSelectedPoints: [],
            allBonds: {
                bonds: [],
                emitters: [],
            },
        },

        isLoading: false,
        error: {},
    },
    dataUpload: {
        data: {
            bonds: [],
            emitters: [],
            bondsSelected: [],
            periodSelected: [],
        },
        isLoading: false,
        error: null,
    },
    activeTariff: null,
};

export const priceCenterSlice = createSlice({
    name: 'priceCenter',
    initialState,
    reducers: {
        toggleChartVisibility(state, action) {
            const groupCode = action.payload.groupChartCode;
            const chartCode = action.payload.chartCode;
            const toggle = (charts: Array<PriceCenterChart<any>>) => {
                const chart = charts.find((value) => value.chartCode === chartCode);
                if (chart) {
                    chart.visible = !chart.visible;
                }
            };
            switch (groupCode) {
                case 'market':
                    toggle(state.charts.data.marketCharts);
                    break;
                case 'price':
                    toggle(state.charts.data.priceCharts);
                    break;
                case 'profit':
                    toggle(state.charts.data.profitCharts);
                    break;
                default:
                    break;
            }
        },
        unSelectMarketPoint(state, action) {
            state.charts.data.marketSelectedPoints = state.charts.data.marketSelectedPoints.filter(
                (point) => point.code !== action.payload.code,
            );
            state.charts.data.priceSelectedPoints = state.charts.data.priceSelectedPoints.filter(
                (point) => point.chartCode !== action.payload.code,
            );
            state.charts.data.profitSelectedPoints = state.charts.data.profitSelectedPoints.filter(
                (point) => point.chartCode !== action.payload.code,
            );
            state.charts.data.priceCharts = state.charts.data.priceCharts.filter(
                (chart) => chart.chartCode !== action.payload.code,
            );
            state.charts.data.profitCharts = state.charts.data.profitCharts.filter(
                (chart) => chart.chartCode !== action.payload.code,
            );
        },

        clearMarketSelectedPoints(state) {
            state.charts.data.marketSelectedPoints = [];
            state.charts.data.priceCharts = [...state.charts.data.priceCharts.splice(0, 3)];
            state.charts.data.priceSelectedPoints = [];
            state.charts.data.profitCharts = [...state.charts.data.profitCharts.splice(0, 3)];
            state.charts.data.profitSelectedPoints = [];
        },
        tossMarketSelectedPoints(state, action) {
            state.charts.data.marketSelectedPoints = [...action.payload];
        },
        selectPricePoint(state, action) {
            state.charts.data.priceSelectedPoints = [...state.charts.data.priceSelectedPoints, action.payload];
        },
        unSelectPricePoint(state, action) {
            state.charts.data.priceSelectedPoints = state.charts.data.priceSelectedPoints.filter(
                (point) => point.dataPointIndex !== action.payload.dataPointIndex,
            );
        },
        clearPriceSelectedPoints(state) {
            state.charts.data.priceSelectedPoints = [];
        },
        tossPriceSelectedPoints(state, action) {
            state.charts.data.priceSelectedPoints = [...action.payload];
        },
        selectProfitPoint(state, action) {
            state.charts.data.profitSelectedPoints = [...state.charts.data.profitSelectedPoints, action.payload];
        },
        unSelectProfitPoint(state, action) {
            state.charts.data.profitSelectedPoints = state.charts.data.profitSelectedPoints.filter(
                (point) => point.dataPointIndex !== action.payload.dataPointIndex,
            );
        },
        clearProfitSelectedPoints(state) {
            state.charts.data.profitSelectedPoints = [];
        },
        tossProfitSelectedPoints(state, action) {
            state.charts.data.profitSelectedPoints = [...action.payload];
        },
        setDataUploadError(state, action) {
            state.dataUpload.error = action.payload;
        },
        setDataUploadSelectedBonds(state, action) {
            state.dataUpload.data.bondsSelected = action.payload;
        },
        setDataUploadSelectedPeriod(state, action) {
            state.dataUpload.data.periodSelected = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllChartsThunk.pending, (state, action) => {
                state.charts.isLoading = true;
            })
            .addCase(getAllChartsThunk.fulfilled, (state, action: PayloadAction<any>) => {
                state.charts.isLoading = false;
                state.charts.data = action.payload;
            })
            .addCase(getAllChartsThunk.rejected, (state, action) => {
                state.charts.isLoading = false;
                state.charts.error = action.error;
            })
            .addCase(selectMarketPointThunk.pending, (state) => {
                state.charts.isLoading = true;
            })
            .addCase(selectMarketPointThunk.fulfilled, (state, action: PayloadAction<any>) => {
                state.charts.isLoading = false;
                state.charts.data.marketSelectedPoints = [
                    ...state.charts.data.marketSelectedPoints,
                    action.payload.point,
                ];
                state.charts.data.priceCharts = [...state.charts.data.priceCharts, ...action.payload.priceCharts];

                state.charts.data.profitCharts = [...state.charts.data.profitCharts, ...action.payload.profitCharts];
            })
            .addCase(checkSubscriptionThunk.fulfilled, (state, action) => {
                state.activeTariff = action.payload || null;
            })
            .addCase(getDataUploadThunk.fulfilled, (state: IPriceCenterState, action) => {
                state.dataUpload.isLoading = false;
                state.dataUpload.data.bonds = action.payload.data.bonds;
                state.dataUpload.data.emitters = action.payload.data.emitters;
            })
            .addCase(getDataUploadThunk.pending, (state) => {
                state.dataUpload.isLoading = true;
                state.dataUpload.error = false;
            })
            .addCase(getDataUploadThunk.rejected, (state, action) => {
                state.dataUpload.isLoading = false;
                state.dataUpload.error = action.error;
            })

            .addCase(getInstrumentsDataThunk.fulfilled, (state, action) => {
                state.dataUpload.isLoading = false;
                state.dataUpload.data.bondsSelected = action.payload.data.data;
            })
            .addCase(getInstrumentsDataThunk.pending, (state) => {
                state.dataUpload.isLoading = true;
                state.dataUpload.data.bondsSelected = [];
                state.dataUpload.error = false;
            })
            .addCase(getInstrumentsDataThunk.rejected, (state, action) => {
                state.dataUpload.isLoading = false;
                state.dataUpload.data.bondsSelected = [];
                state.dataUpload.error = action.error;
            })
            .addCase(getInstrumentsDataPerPeriodThunk.fulfilled, (state, action) => {
                state.dataUpload.isLoading = false;
                state.dataUpload.data.periodSelected = action.payload.data.data;
            })
            .addCase(getInstrumentsDataPerPeriodThunk.pending, (state) => {
                state.dataUpload.isLoading = true;
                state.dataUpload.data.periodSelected = [];
                state.dataUpload.error = false;
            })
            .addCase(getInstrumentsDataPerPeriodThunk.rejected, (state, action) => {
                state.dataUpload.isLoading = false;
                state.dataUpload.data.periodSelected = [];
                state.dataUpload.error = action.error;
            });
    },
});

export const priceCenterReducer = priceCenterSlice.reducer;

export const {
    toggleChartVisibility,
    unSelectMarketPoint,
    clearMarketSelectedPoints,
    tossMarketSelectedPoints,
    selectPricePoint,
    unSelectPricePoint,
    clearPriceSelectedPoints,
    tossPriceSelectedPoints,
    selectProfitPoint,
    unSelectProfitPoint,
    clearProfitSelectedPoints,
    tossProfitSelectedPoints,
    setDataUploadError,
    setDataUploadSelectedBonds,
    setDataUploadSelectedPeriod,
} = priceCenterSlice.actions;
