import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import React, { useEffect, useState } from 'react';
import { useResize } from '@libs/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SubscriptionBlock } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/SubscriptionBlock';
import { CardsBlock } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/CardsBlock';
import { ManageProductBlock } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/ManageProductBlock';
import { setShowOnboardingModal } from '@store/store/slices/user.slice';

import { AccountCard } from './components/AccountCard';
import styles from './styles.module.scss';

export const MainContent = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { width } = useResize();

    const imageAvatarSelector = useSelector((state: RootState) => state.user.userAvatar);
    const userSelector = useSelector((state: RootState) => state.user.user);

    const [showManagePage, setShowManagePage] = useState<boolean>(false);

    useEffect(() => {
        if (searchParams.get('confirmed') === 'true') {
            dispatch(setShowOnboardingModal(true));
            navigate('');
        }
    }, []);

    useEffect(() => {
        setShowManagePage(!!(searchParams.get('productGuid') && searchParams.get('tariffGuid')));
    }, [searchParams]);

    return (
        <section className={classNames('flex', width < 1599 && 'flex-column', styles.section)}>
            <div className={styles.accountCardWrapper}>
                {userSelector && (
                    <AccountCard
                        imageStr={imageAvatarSelector}
                        email={userSelector.email}
                        phoneNumber={userSelector.phoneNumber}
                        fullName={userSelector.fullName}
                        personalDataProvided={userSelector.personalDataProvided}
                        isEmailVerified={userSelector.isEmailVerified}
                    />
                )}
            </div>
            <div className={classNames('flex', 'flex-column', styles.mainContainer)}>
                {showManagePage
? (
                    <ManageProductBlock />
                )
: (
                    <>
                        <SubscriptionBlock />
                        <CardsBlock />
                    </>
                )}
            </div>
        </section>
    );
};
