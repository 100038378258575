import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCost, getTariffs } from '@libs/components/pageBlocks/TariffBlock/services/tariff.service';
import { type TariffFilter } from '@libs/types';
import { TariffPlanCostReq } from '@libs/types/tariff/tariffPlanCost.type';

export const getTariffsThunk = createAsyncThunk(
    'tariff/getTariffsThunk',
    async (body: TariffFilter, { rejectWithValue }) => {
        try {
            const { data } = await getTariffs(body);
            return data.data.rows;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getCostThunk = createAsyncThunk(
    'tariff/getCostThunk',
    async (body: TariffPlanCostReq, { rejectWithValue }) => {
        try {
            const { data } = await getCost(body);
            return data.data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
