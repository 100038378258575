import classNames from 'classnames';
import { ReactComponent as ClientBehavior } from '@shared/images/marketDive/client-behavior.svg';
import { ReactComponent as FormatData } from '@shared/images/marketDive/file-format.svg';
import { ReactComponent as EveryMonthUpdate } from '@shared/images/marketDive/every-month-update.svg';

import styles from './styles.module.scss';

export const ProductOpportunity = () => {
    return (
        <section className={classNames(styles.productOpportunityWrapper, 'flex')}>
            <div className={classNames(styles.titleContainer)}>
                <h2>возможности продукта</h2>
            </div>
            <div className={classNames(styles.listWrapper)}>
                <div className={classNames('flex')}>
                    <ClientBehavior />
                    <div>
                        <p>Динамика поведения клиентов</p>
                        <span>Наблюдайте изменения в поведении клиентов на историческом горизонте более пяти лет</span>
                    </div>
                </div>
                <div className={classNames('flex')}>
                    <FormatData />
                    <div>
                        <p>Удобный формат данных</p>
                        <span>
                            Исследуйте данные в том формате, который вам удобнее: Excel, PDF-отчеты или интерактивная
                            BI-платформа
                        </span>
                    </div>
                </div>
                <div className={classNames('flex')}>
                    <EveryMonthUpdate />
                    <div>
                        <p>Ежемесячные обновления</p>
                        <span>
                            Используйте актуальные данные для оперативной корректировки стратегии работы с клиентами
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
};
