export const activeItems = [
    {
        title: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту, торгуемых на Московской Бирже',
        price: '60 000',
        points: [
            'Федеральный закон № 39-ФЗ от 22.04.1996 «О рынке ценных бумаг»; ',
            'Федеральный закон № 395-1 от 02.12.1990 «О банках и банковской деятельности»; ',
            'Федеральный закон № 156-ФЗ от 29.11.2001 «Об инвестиционных фондах»; ',
            'Федеральный законом № 192-ФЗ от 11.06.2021 «О внесении изменений в отдельные законодательные акты Российской Федерации»; ',
            'Федеральный закон № 61-ФЗ от 25.03.2022 «О внесении изменений в статью 11 Федерального закона № 192-ФЗ от 11 июня 2021 г. «О внесении изменений в отдельные законодательные акты Российской Федерации»; ',
            'Указание Банка России № 6347-У от 09.01.2023 г. «О ценных бумагах и производных финансовых инструментах, предназначенных для квалифицированных инвесторов»; ',
            'Базовый стандарт защиты   прав и интересов физических и юридических лиц - получателей финансовых услуг, оказываемых членами саморегулируемых организаций в сфере финансового рынка, объединяющих брокеров, утвержденным Банком России (Протокол № КФНП-49 от 29.12.2022).',
        ],
    },
    {
        title: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту, торгуемых на Московской Бирже и причины выбора маркировки',
        price: '90 000',
        points: [
            'Федеральный закон № 39-ФЗ от 22.04.1996 «О рынке ценных бумаг»; ',
            'Федеральный закон № 395-1 от 02.12.1990 «О банках и банковской деятельности»; ',
            'Федеральный закон № 156-ФЗ от 29.11.2001 «Об инвестиционных фондах»; ',
            'Федеральный законом № 192-ФЗ от 11.06.2021 «О внесении изменений в отдельные законодательные акты Российской Федерации»; ',
            'Федеральный закон № 61-ФЗ от 25.03.2022 «О внесении изменений в статью 11 Федерального закона № 192-ФЗ от 11 июня 2021 г. «О внесении изменений в отдельные законодательные акты Российской Федерации»; ',
            'Указание Банка России № 6347-У от 09.01.2023 г. «О ценных бумагах и производных финансовых инструментах, предназначенных для квалифицированных инвесторов»; ',
            'Базовый стандарт защиты   прав и интересов физических и юридических лиц - получателей финансовых услуг, оказываемых членами саморегулируемых организаций в сфере финансового рынка, объединяющих брокеров, утвержденным Банком России (Протокол № КФНП-49 от 29.12.2022).',
        ],
    },
    {
        title: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту, расширенный перечень инструментов (представление включает более 40 тыс. записей)',
        price: '100 000',
        points: [
            'Федеральный закон № 39-ФЗ от 22.04.1996 «О рынке ценных бумаг»; ',
            'Федеральный закон № 395-1 от 02.12.1990 «О банках и банковской деятельности»; ',
            'Федеральный закон № 156-ФЗ от 29.11.2001 «Об инвестиционных фондах»; ',
            'Федеральный законом № 192-ФЗ от 11.06.2021 «О внесении изменений в отдельные законодательные акты Российской Федерации»; ',
            'Федеральный закон № 61-ФЗ от 25.03.2022 «О внесении изменений в статью 11 Федерального закона № 192-ФЗ от 11 июня 2021 г. «О внесении изменений в отдельные законодательные акты Российской Федерации»; ',
            'Указание Банка России № 6347-У от 09.01.2023 г. «О ценных бумагах и производных финансовых инструментах, предназначенных для квалифицированных инвесторов»; ',
            'Базовый стандарт защиты   прав и интересов физических и юридических лиц - получателей финансовых услуг, оказываемых членами саморегулируемых организаций в сфере финансового рынка, объединяющих брокеров, утвержденным Банком России (Протокол № КФНП-49 от 29.12.2022).',
        ],
    },
    {
        title: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту, расширенный перечень инструментов (представление включает более 40 тыс. записей) и причины выбора маркировки',
        price: '150 000',
        points: [
            'Федеральный закон № 39-ФЗ от 22.04.1996 «О рынке ценных бумаг»; ',
            'Федеральный закон № 395-1 от 02.12.1990 «О банках и банковской деятельности»; ',
            'Федеральный закон № 156-ФЗ от 29.11.2001 «Об инвестиционных фондах»; ',
            'Федеральный законом № 192-ФЗ от 11.06.2021 «О внесении изменений в отдельные законодательные акты Российской Федерации»; ',
            'Федеральный закон № 61-ФЗ от 25.03.2022 «О внесении изменений в статью 11 Федерального закона № 192-ФЗ от 11 июня 2021 г. «О внесении изменений в отдельные законодательные акты Российской Федерации»; ',
            'Указание Банка России № 6347-У от 09.01.2023 г. «О ценных бумагах и производных финансовых инструментах, предназначенных для квалифицированных инвесторов»; ',
            'Базовый стандарт защиты   прав и интересов физических и юридических лиц - получателей финансовых услуг, оказываемых членами саморегулируемых организаций в сфере финансового рынка, объединяющих брокеров, утвержденным Банком России (Протокол № КФНП-49 от 29.12.2022).',
        ],
    },
    {
        title: 'Маркировка «Дополнительные ограничения на иностранные ценные бумаги»',
        price: '15 000',
        points: [
            'Указ Президента Российской Федерации № 95 от 05.03.2022 "О временном порядке исполнения обязательств перед некоторыми иностранными кредиторами";',
            'Распоряжение Правительства Российской Федерации № 430-р от 05.03.2022 "Об утверждении перечня иностранных государств и территорий, совершающих недружественные действия в отношении Российской Федерации, российских юридических и физических лиц";',
            'Указание Банка России № 6347-У от 09.01.2023 "О ценных бумагах и производных финансовых инструментах, предназначенных для квалифицированных инвесторов";',
            'Информационные письма Банка России, касающихся ограничений или дополнительных требований по предложению клиентам иностранных ценных бумаг.',
        ],
    },
    {
        title: 'Маркировка «Дополнительные ограничения на российские ценные бумаги для иностранцев»',
        price: '15 000',
        points: [
            'Указ Президента Российской Федерации № 520 от 05.08.2022 "О применении специальных экономических мер в финансовой и топливно-энергетической сферах в связи с недружественными действиями некоторых иностранных государств и между народных организаций" и связанными нормативными правовыми актами.',
        ],
    },
];

export const archiveItems = [
    {
        title: 'Архивы по маркировке финансовых инструментов по 39-ФЗ и Базовому стандарту, торгуемых на Московской Бирже',
        price: '90 000',
    },
    {
        title: 'Архивы о маркировке финансовых инструментов по 39-ФЗ и Базовому стандарту, расширенный перечень инструментов',
        price: '150 000',
    },
    {
        title: 'Архивы по маркировке «Дополнительные ограничения на российские ценные бумаги для иностранцев»',
        price: '15 000',
    },
    {
        title: 'Архивы по маркировке «Дополнительные ограничения на иностранные ценные бумаги»',
        price: '15 000',
    },
];
