import { useEffect, useState } from 'react';

const latestVersions: Record<string, number> = {
    Chrome: 100,
    Edge: 110,
    Firefox: 105,
    Safari: 15,
    Opera: 106,
    'Yandex Browser': 23,
    'Internet Explorer': 11,
};

export const useCheckBrowser = () => {
    const [isUpdateBrowser, setUpdateBrowser] = useState<boolean>(false);

    useEffect(() => {
        setUpdateBrowser(isBrowserNeedToUpdate());
    }, []);

    const getBrowserInfo = () => {
        const userAgent = navigator.userAgent;
        let browserName;
        let fullVersion;

        if (/yabrowser/i.test(userAgent)) {
            browserName = 'Yandex Browser';
            fullVersion = userAgent.match(/yabrowser\/(\d+\.\d+)/i)?.[1];
        } else if (/chrome|crios|crmo/i.test(userAgent) && !/edg|yabrowser/i.test(userAgent)) {
            browserName = 'Chrome';
            fullVersion = userAgent.match(/(?:chrome|crios|crmo)\/(\d+\.\d+\.\d+\.\d+)/i)?.[1];
        } else if (/edg/i.test(userAgent)) {
            browserName = 'Edge';
            fullVersion = userAgent.match(/edg\/(\d+\.\d+\.\d+\.\d+)/i)?.[1];
        } else if (/firefox|fxios/i.test(userAgent)) {
            browserName = 'Firefox';
            fullVersion = userAgent.match(/(?:firefox|fxios)\/(\d+\.\d+)/i)?.[1];
        } else if (/safari/i.test(userAgent) && !/chrome|crios|crmo|yabrowser/i.test(userAgent)) {
            browserName = 'Safari';
            fullVersion = userAgent.match(/version\/(\d+\.\d+)/i)?.[1];
        } else if (/opr\/|opera/i.test(userAgent)) {
            browserName = 'Opera';
            fullVersion = userAgent.match(/(?:opr|opera)\/(\d+\.\d+)/i)?.[1];
        } else if (/msie|trident/i.test(userAgent)) {
            browserName = 'Internet Explorer';
            fullVersion = userAgent.match(/(?:msie |rv:)(\d+\.\d+)/i)?.[1];
        }

        return { browserName, fullVersion };
    };

    const compareVersions = (fullVersion: string, latestVersion: number) => {
        const fullVersionNumber = Number(fullVersion.split('.')[0]);

        return fullVersionNumber < latestVersion;
    };

    const isBrowserNeedToUpdate = () => {
        const { browserName, fullVersion } = getBrowserInfo();

        if (browserName && fullVersion) {
            const latestVersion = latestVersions[browserName];
            if (!latestVersion) {
                console.log('Неизвестный браузер или нет данных о последней версии.');
                return false;
            }

            return compareVersions(fullVersion, latestVersion);
        } else {
            console.log('Неизвестный браузер или нет данных о последней версии.');
            return false;
        }
    };

    return { isUpdateBrowser };
};
