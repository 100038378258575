import { getFormCleanedFromNullValues } from '@libs/utils';
import { type PriceCenterFilter } from '@store/store/thunk/pricecenter/Models';

import { type Bond, type Emitter } from '../types';
import { SelectItemOptionsType } from 'primereact/selectitem';

type FilteredFieldsType = Record<string, PriceCenterFilter[keyof PriceCenterFilter]>;

export const getDateTransformed = (date?: Date | null, type?: string) => {
    if (date) {
        const y = date.getFullYear();
        const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

        if (!type) {
            const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;

            return `${y}-${m}-${d}`;
        } else if (type === 'tooltip') {
            const m = date.toLocaleString('default', { month: 'long' }).slice(0, 3);

            return `${d} ${m} ${y}`;
        }
    }
};

export const getRequestFilters = (filter: PriceCenterFilter) => {
    const data = {
        tradeDate: getDateTransformed(filter.tradeDate),
        methodNumber: Number(filter.methodNumber?.code ?? 1),
        minYield: filter.minYield ?? 0,
        maxYield: filter.maxYield ?? 10000,
        status: filter.status?.map((status) => status.code),
        listingLevel: filter.listingLevel?.code,
        sector: filter.sector?.code,
        issuerSector: filter.issuerSector?.code,
        isin: filter.searchNames,
        securityKind: filter.securityKind?.code,
        hasOffer: filter.offer,
        hasIndexedFv: filter.indexedNominal,
        hasGuarantee: filter.guarantee,
        hasAmortization: filter.amortization,
        hasRepayment: filter.repayment,
        country: filter.country?.code,
        currency: filter.currency?.code,
        couponType: filter.couponType?.map((status) => status.code),
        minMtyDate: getDateTransformed(filter.dateStart),
        maxMtyDate: getDateTransformed(filter.dateEnd),
        minDuration: filter.durationStart,
        maxDuration: filter.durationEnd,
        minCouponsPerYear: filter.couponsPerYearStart,
        maxCouponsPerYear: filter.couponsPerYearEnd,
        minCouponRate: filter.couponsRateStart,
        maxCouponRate: filter.couponsRateEnd,
    };

    const filters = getFormCleanedFromNullValues(data);

    return filters;
};

export const getTradeDate = (offset?: number) => {
    const today = new Date();
    const day = today.getDay();
    const dateOffset = day === 0 ? 2 : day === 1 ? 3 : 1;
    const daysBefore = offset ?? 0;

    const tradeDate = new Date(today);
    tradeDate.setDate(today.getDate() - dateOffset - daysBefore);

    const year = tradeDate.getFullYear();
    const month = tradeDate.getMonth();
    const dayOfMonth = tradeDate.getDate();

    return new Date(year, month, dayOfMonth);
};

export const getInputsBalanced = (values: { [key: string]: any }) => {
    let total = Object.keys(values).length;

    const hasBoth = (arr: string[], v1: string, v2: string) => {
        return arr.includes(v1) && arr.includes(v2);
    };

    const keys = Object.keys(values);

    if (hasBoth(keys, 'dateStart', 'dateEnd')) total--;

    if (hasBoth(keys, 'couponsRateStart', 'couponsRateEnd')) total--;

    if (hasBoth(keys, 'couponsPerYearStart', 'couponsPerYearEnd')) total--;

    if (hasBoth(keys, 'durationStart', 'durationEnd')) total--;

    return total;
};

export const getSelectedFiltersCount = (excludeArray: string | string[], form: { getValues: () => any }) => {
    const fields = form.getValues();
    const filteredFields: FilteredFieldsType = {};

    Object.keys(fields).forEach((key) => {
        if (!excludeArray.includes(key)) {
            filteredFields[key] = fields[key as keyof PriceCenterFilter];
        }
    });

    const values = getFormCleanedFromNullValues(filteredFields);

    return getInputsBalanced(values);
};

export const getMinMaxValuesOfBonds = (data: any) => {
    let collector: any[] = [];

    for (let i = 1; i < data.length; i++) {
        collector = collector.concat(data[i].data);
    }

    const max = Math.ceil(Math.max(...collector.map((i) => i.value)));
    const min = Math.floor(Math.min(...collector.map((i) => i.value)));

    return { max, min };
};

export const getIsinsVirtualGroupedByEmitter = (arr: Bond[], emitters?: Emitter[]) => {
    const groupedByEmitter: Record<string, any[]> = {};

    arr?.forEach((item: any) => {
        if (!groupedByEmitter[item.emitterId]) {
            groupedByEmitter[item.emitterId] = [];
        }

        groupedByEmitter[item.emitterId].push({
            value: item?.code ?? item.isin,
            label: item?.name,
            emitterId: item.emitterId,
        });
    });

    const finalResult = Object.values(groupedByEmitter).reduce(
        (prev: SelectItemOptionsType[], curr: SelectItemOptionsType, index: number) => {
            let result: SelectItemOptionsType[] = [];
            const groupName = emitters
                ? `Group-${emitters?.find((el) => el.id === curr[0].emitterId)?.name}`
                : `Group-${Object.keys(groupedByEmitter)[index]}`;
            result = [
                ...prev,
                {
                    label: !!curr.length && groupName,
                    value: !!curr.length && groupName,
                    disabled: true,
                },
                ...curr,
            ];

            return result;
        },
        [],
    );

    return finalResult;
};

export const getIsinsGroupedByEmitter = (arr: any, emitters?: any) => {
    const groupedByEmitter: Record<string, any[]> = {};

    arr?.forEach((item: any) => {
        if (!groupedByEmitter[item.emitterId]) {
            groupedByEmitter[item.emitterId] = [];
        }
        groupedByEmitter[item.emitterId].push({ value: item?.code ?? item.isin, label: item?.name });
    });

    const finalResult = Object.keys(groupedByEmitter).map((emitter) => {
        const emitterLabel = emitters?.filter((i: any) => i.id === Number(emitter))[0]?.name;

        return {
            label: emitters ? emitterLabel : emitter,
            code: emitter,
            items: groupedByEmitter[emitter],
        };
    });

    return finalResult;
};

export const getFilterIsinsGrouped = (data: any[]) => {
    let result: any[] = [];

    for (let i = 1; i < data.length; i++) {
        result = result.concat(data[i].data);
    }

    const uniqueCodes = new Set();
    const uniqueResult = result?.filter((i) => {
        if (uniqueCodes.has(i.code)) {
            return false;
        } else {
            uniqueCodes.add(i.code);

            return true;
        }
    });

    return getIsinsVirtualGroupedByEmitter(uniqueResult);
};
