import { useEffect, useRef, useState } from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import {
    CALC_METHODS_DICT,
    ISSUER_SECTOR_DICT,
    LISTING_LEVEL_DICT,
    SECTOR_DICT,
    STATUS_DICT,
} from '@store/store/thunk/pricecenter/consts';
import { type PriceCenterFilter } from '@store/store/thunk/pricecenter/Models';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import classNames from 'classnames';
import { parse } from 'date-fns';
import { ReactComponent as CalendarIcon } from '@modules/PriceCenterProduct/img/CalendarIcon.svg';
import { useResize } from '@libs/utils';

import { ExtendedSearchMediumForm } from '../ExtendedSearchMediumForm';
import { getFilterIsinsGrouped, getIsinsVirtualGroupedByEmitter } from '../../utils';
import styles from './styles.module.scss';
import multiSelect from '../ExtendedSearchMediumForm/styles.module.scss';

type PriceCenterMainSmallFormFormProps = {
    form: UseFormReturn<PriceCenterFilter>;
    isLoading: boolean;
    getFormErrorMessage: (name: string) => '' | JSX.Element;
    modalVisible: boolean;
    isDemo: boolean;
    filtersCount: number;
};

const multiSelectPt = {
    header: () => ({ className: styles.isinMultiselectHeader }),
    filterContainer: () => ({ className: styles.isinMultiSelectFilterInput }),
    list: () => ({ className: multiSelect.multiSelectList }),
    item: () => ({ className: multiSelect.multiSelectListItem }),
    itemGroup: () => ({ className: multiSelect.multiSelectListItemGroup }),
    panel: () => ({ className: multiSelect.multiSelectPanel }),
    closeButton: () => ({ className: multiSelect.multiSelectCloseButton }),
    headerCheckbox: { box: () => ({ className: styles.multiSelectHeaderCheckbox }) },
};

export const MainSmallForm = ({
    form,
    isLoading,
    getFormErrorMessage,
    isDemo,
    filtersCount,
}: PriceCenterMainSmallFormFormProps) => {
    const [activeIndexes, setActiveIndexes] = useState<number[]>([0]);
    const [searchIndex, setSearchIndex] = useState<number | undefined>();
    const [allNames, setAllNames] = useState<any[]>([]);

    const { allBonds } = useSelector((state: RootState) => state.priceCenter.charts.data);
    const { width } = useResize();
    const { watch } = form;

    useEffect(() => {
        if (allBonds?.bonds?.length) {
            const names = getIsinsVirtualGroupedByEmitter(allBonds.bonds, allBonds.emitters);
            setAllNames([...names]);
        }
    }, [allBonds]);

    const handleNumberInput = (
        e: { value: number | null },
        field: { onChange: (arg: any) => void },
        maxAllowedValue: number,
    ) => {
        if (e.value || e.value === 0) {
            e.value > maxAllowedValue ? field.onChange(maxAllowedValue) : field.onChange(e.value);
        } else if (e.value === null) {
            field.onChange(e.value);
        }
    };

    const itemTemplate = (option: any) => {
        if (option.label.startsWith('Group-')) {
            return (
                <div className={classNames('flex', styles.dropdownGroup)}>
                    <p>{option.label.slice(6)}</p>
                    <div className={styles.divider} />
                </div>
            );
        } else {
            return <p className={classNames('flex', styles.dropdownItem)}>{option.label}</p>;
        }
    };

    const dateTitle =
        width < 500
? (
            <>
                Дата
                <br />
                актуальности
            </>
        )
: (
            'Даты актуальности'
        );

    return (
        <>
            <h2 className={classNames(styles.modalMobileTitle)}>Фильтры</h2>
            <div className={classNames(styles.calendarContainer)}>
                <p className={styles.inputLabel} tabIndex={0}>
                    {dateTitle}
                </p>
                <div className="w-full">
                    <Controller
                        control={form.control}
                        name="tradeDate"
                        render={({ field }) => (
                            <Calendar
                                id={field.name}
                                value={field.value}
                                ref={field.ref}
                                onChange={field.onChange}
                                dateFormat="dd.mm.yy"
                                readOnlyInput
                                showIcon
                                className={classNames(styles.calendarInput)}
                                appendTo={'self'}
                                locale="ru"
                                minDate={isDemo ? parse('01.06.23', 'dd.MM.yy', new Date()) : undefined}
                                maxDate={isDemo ? parse('29.12.23', 'dd.MM.yy', new Date()) : undefined}
                                icon={<CalendarIcon />}
                            />
                        )}
                    />
                </div>
            </div>

            <Divider />

            <div className={classNames(styles.sectionWrapper)}>
                <p className={classNames(styles.subHeader)}>Метод расчета</p>
                <Controller
                    control={form.control}
                    name="methodNumber"
                    render={({ field }) => (
                        <Dropdown
                            value={field.value}
                            onChange={field.onChange}
                            options={CALC_METHODS_DICT}
                            optionLabel="name"
                            placeholder="Выбрать"
                            className={classNames(styles.dropdownInput)}
                            disabled={isLoading}
                            appendTo="self"
                        />
                    )}
                />
            </div>

            <div className={classNames(styles.sectionWrapper)}>
                <p className={classNames(styles.subHeader)}>Сформировать список</p>
                <Controller
                    control={form?.control}
                    name="searchNames"
                    render={({ field }) => (
                        <MultiSelect
                            value={field.value}
                            id={field.name}
                            options={allNames}
                            onChange={field.onChange}
                            className={classNames(styles.blockParamSearchInput, styles.dropdownInput)}
                            pt={multiSelectPt}
                            focusOnHover={false}
                            optionLabel="label"
                            optionGroupTemplate={itemTemplate}
                            itemTemplate={itemTemplate}
                            virtualScrollerOptions={{ itemSize: 25, step: 1, autoSize: true }}
                            disabled={allNames.length === 0}
                            placeholder="ISIN, название инструмента"
                            appendTo="self"
                            filter
                            filterBy="label,value"
                            emptyFilterMessage="Ничего не найдено"
                            emptyMessage="Нет данных"
                            resetFilterOnHide
                        />
                    )}
                />
            </div>

            <Accordion
                multiple
                activeIndex={activeIndexes}
                onTabChange={(e) => setActiveIndexes((e.index ?? []) as number[])}
                className="w-full"
            >
                <AccordionTab
                    headerTemplate={
                        <div className={classNames(styles.accordionHeader, styles.h36px)}>
                            <p>Поиск по параметрам</p>
                            <i
                                className={classNames({
                                    'pi pi-chevron-up pb-1 text-sm': activeIndexes.includes(0),
                                    'pi pi-chevron-down pb-1 text-sm': !activeIndexes.includes(0),
                                })}
                            />
                        </div>
                    }
                    pt={{
                        headerIcon: () => ({
                            className: 'hidden',
                        }),
                        headerAction: () => ({
                            className: 'bg-white border-none',
                        }),
                        content: () => ({
                            className: 'border-none pt-3',
                        }),
                    }}
                >
                    <div className={classNames(styles.columnsWrapper)}>
                        <div className={classNames(styles.blockWrapper)}>
                            <div className={classNames(styles.inputWrapper)}>
                                <p className={styles.inputLabel}>Статус</p>
                                <Controller
                                    control={form.control}
                                    name="status"
                                    render={({ field }) => (
                                        <MultiSelect
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={STATUS_DICT}
                                            pt={multiSelectPt}
                                            dataKey="code"
                                            optionLabel="name"
                                            className={classNames(styles.blockParamSearchInput, styles.dropdownInput)}
                                            placeholder="Выберите статус"
                                            multiple
                                            disabled={isLoading}
                                            appendTo="self"
                                        />
                                    )}
                                />
                            </div>

                            <div className={classNames(styles.inputWrapper)}>
                                <p className={styles.inputLabel}>Листинг</p>
                                <Controller
                                    control={form.control}
                                    name="listingLevel"
                                    render={({ field }) => (
                                        <Dropdown
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={LISTING_LEVEL_DICT}
                                            dataKey="code"
                                            optionLabel="name"
                                            className={classNames(styles.dropdownInput, 'w-full')}
                                            disabled={isLoading}
                                            appendTo="self"
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className={classNames(styles.columnsWrapper, 'md:flex-row')}>
                            <div className={classNames(styles.inputWrapper)}>
                                <p className={styles.inputLabel}>Сектор</p>
                                <Controller
                                    control={form.control}
                                    name="sector"
                                    render={({ field }) => (
                                        <Dropdown
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={SECTOR_DICT}
                                            dataKey="code"
                                            optionLabel="name"
                                            className={classNames(styles.dropdownInput, 'w-full')}
                                            disabled={isLoading}
                                            appendTo="self"
                                        />
                                    )}
                                />
                            </div>

                            <div className={classNames(styles.inputWrapper)}>
                                <p className={styles.inputLabel}>Отрасль</p>
                                <Controller
                                    control={form.control}
                                    name="issuerSector"
                                    render={({ field }) => (
                                        <Dropdown
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={ISSUER_SECTOR_DICT}
                                            dataKey="code"
                                            optionLabel="name"
                                            className={classNames(styles.dropdownInput, 'w-full')}
                                            disabled={isLoading}
                                            appendTo="self"
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className={classNames(styles.columnsWrapper)}>
                            <div className={classNames(styles.inputWrapper)}>
                                <p className={styles.inputLabel}>Доходность (%)</p>
                                <div className={classNames(styles.yieldInputsWrapper)}>
                                    <Controller
                                        control={form.control}
                                        name="minYield"
                                        rules={{
                                            validate: {
                                                hasBothValues: (value) => {
                                                    const max = watch('maxYield');

                                                    if (value && !max) {
                                                        return (
                                                            value === 0 || 'Введите максимальное значение доходности'
                                                        );
                                                    }
                                                },
                                            },
                                            deps: ['maxYield'],
                                        }}
                                        render={({ field }) => (
                                            <InputNumber
                                                placeholder="от"
                                                value={field.value}
                                                onChange={(e) => handleNumberInput(e, field, 10000)}
                                                className="flex-grow-1"
                                                inputClassName={styles.profitInput}
                                                max={10000}
                                                maxLength={6}
                                                min={0}
                                            />
                                        )}
                                    />
                                    <i className="pi pi-minus text-xs" />
                                    <Controller
                                        control={form.control}
                                        name="maxYield"
                                        rules={{
                                            validate: {
                                                lowerThanMax: (value) => {
                                                    const min = watch('minYield');

                                                    if (value && min) {
                                                        return (
                                                            value > min ||
                                                            'Значение доходности не должно быть меньше начального уровня'
                                                        );
                                                    }
                                                },
                                            },
                                            deps: ['minYield'],
                                        }}
                                        render={({ field }) => (
                                            <InputNumber
                                                placeholder="до"
                                                value={field.value}
                                                onChange={(e) => handleNumberInput(e, field, 10000)}
                                                className="flex-grow-1"
                                                inputClassName={styles.profitInput}
                                                max={10000}
                                                maxLength={6}
                                                min={0}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <Accordion
                            multiple
                            activeIndex={searchIndex}
                            onTabChange={(e) => setSearchIndex((e.index as number[])[0])}
                            className="w-full"
                        >
                            <AccordionTab
                                unstyled
                                headerTemplate={
                                    <div className={styles.accordionHeader}>
                                        <div className="flex">
                                            <div className={classNames(styles.extendedSearchLabel)}>
                                                Расширенный поиск
                                            </div>
                                            {filtersCount > 0 && (
                                                <div className={styles.pinButtonCount}>{filtersCount}</div>
                                            )}
                                        </div>

                                        <i
                                            className={classNames({
                                                'pi pi-chevron-up text-xs': searchIndex === 0,
                                                'pi pi-chevron-down text-xs': searchIndex === undefined,
                                            })}
                                        />
                                    </div>
                                }
                                pt={{
                                    headerIcon: () => ({
                                        className: 'hidden',
                                    }),
                                    headerAction: () => ({
                                        className: 'bg-white border-none',
                                    }),
                                    content: () => ({
                                        className: 'border-none pt-3',
                                    }),
                                }}
                            >
                                <div className={classNames(styles.extendedSearchWrapper)}>
                                    <ExtendedSearchMediumForm form={form} getFormErrorMessage={getFormErrorMessage} />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </AccordionTab>
            </Accordion>
            <Divider />
        </>
    );
};
