import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { SearchIcon } from 'primereact/icons/search';
import { useEffect, useRef } from 'react';
import { useSearch } from '@libs/utils/hooks/useSearch';
import { SearchDropdown } from '@libs/components/misc/SearchDropdown';

import styles from './styles.module.scss';

export const SearchMainBlock = () => {
    const helpCenterSearchRef = useRef<HTMLDivElement>(null);

    const {
        debouncedSearch,
        searchValue,
        entitySearch,
        setEntitySearch,
        resultList,
        handleInputChange,
        isOpenDropdown,
        setIsOpenDropdown,
    } = useSearch(4, '', ['REFERENCE']);

    useEffect(() => {
        const onClick = (e: MouseEvent) => {
            if (helpCenterSearchRef && helpCenterSearchRef.current) {
                setIsOpenDropdown(helpCenterSearchRef.current.contains(e.target as Node));
            }
        };
        document.addEventListener('click', onClick);

        return () => document.removeEventListener('click', onClick);
    }, []);

    useEffect(() => {
        debouncedSearch(searchValue || '');
    }, [searchValue, entitySearch]);

    return (
        <div className={classNames(styles.wrapper)}>
            <h1>Справочный центр</h1>
            <span ref={helpCenterSearchRef} className={classNames('p-input-icon-right')}>
                <SearchIcon className={styles.searchIcon} />
                <InputText value={searchValue} onChange={handleInputChange} maxLength={250} />
                {isOpenDropdown && (
                    <SearchDropdown
                        list={resultList}
                        searchValue={searchValue}
                        entitySearch={entitySearch}
                        changeEntitySearch={setEntitySearch}
                    />
                )}
            </span>
        </div>
    );
};
