import { type PriceCenterChart } from '@store/store/thunk/pricecenter/Models';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import styles from '../../styles.module.scss';

type VisibilityTogglesProps = {
    groupChartCode: string;
    data: Array<PriceCenterChart<any>>;
    toggleChartVisibility: (charCode: string) => void;
    colors?: string[];
    getCheckboxColor: (data: PriceCenterChart<any>) => void;
};

const PROFIVT_CURVE = 'PROFIT_CURVE';

export const VisibilityToggles = (props: VisibilityTogglesProps) => {
    const { groupChartCode, data, toggleChartVisibility, getCheckboxColor } = props;

    return (
        <div className={classNames(styles.visibilityTogglesContainer)}>
            {data.map((chart, index) => {
                const key = `${groupChartCode}-toggle-${index}`;
                const keyCheckbox = key + '-checkbox';

                if (chart.chartCode === PROFIVT_CURVE) {
                    return (
                        <div key={key} className={styles.chartToggleCheckboxContainer}>
                            <Button
                                onClick={() => toggleChartVisibility(chart.chartCode)}
                                className={classNames(styles.profitCurveButton)}
                            >
                                <div className={classNames(styles.profitCurveLine)} />
                            </Button>

                            <span
                                className={classNames(chart.visible ? 'opacity-100' : 'opacity-50', styles.chartName)}
                            >
                                {chart.chartName}
                            </span>
                        </div>
                    );
                }

                return (
                    <div
                        key={key}
                        className={styles.chartToggleCheckboxContainer}
                        style={{
                            opacity: !chart.visible ? 0.5 : 1,
                        }}
                        onClick={() => toggleChartVisibility(chart.chartCode)}
                    >
                        <Checkbox
                            inputId={keyCheckbox}
                            className={styles.checkbox}
                            pt={{
                                box: {
                                    style: {
                                        background: getCheckboxColor(chart),
                                        border: 'none',
                                        height: '18px',
                                    },
                                },
                            }}
                            checked={chart.visible ?? false}
                        />
                        <label className={styles.chartName} htmlFor={keyCheckbox}>
                            {chart.chartName}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};
