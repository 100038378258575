import { ModalWindow } from '@libs/components';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { useMemo, useState } from 'react';
import { type AppDispatch, updateEmailThunk, confirmEmailThunk } from '@store/store';
import { useDispatch } from 'react-redux';
import { validateEmail } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
};

export const AddEmail = ({ isOpen, closeModal }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [email, setEmail] = useState<string>('');
    const isEmailCorrect = useMemo(() => validateEmail(email), [email]);

    const sendEmail = async () => {
        await dispatch(updateEmailThunk({ email }));
        await dispatch(confirmEmailThunk());
        closeModal();
    };

    return (
        <ModalWindow isVisible={isOpen} closeModal={closeModal}>
            <div className={styles.addEmailWrapper}>
                <h2>Укажите email</h2>
                <p>Ваш email необходим для отправки информации об оплате</p>
                <IconField className={styles.inputWrapper}>
                    {email && <InputIcon className="pi pi-times" onClick={() => setEmail('')} />}
                    <InputText value={email} onChange={(e) => setEmail(e.target.value)} placeholder={'Введите email'} />
                </IconField>
                <Button label={'Сохранить'} onClick={sendEmail} disabled={!email || !isEmailCorrect} />
            </div>
        </ModalWindow>
    );
};
