import classNames from 'classnames';
import { Button } from 'primereact/button';

import styles from './styles.module.scss';

type Props = {
    closeModal: () => void;
};

export const ErrorModal = ({ closeModal }: Props) => {
    return (
        <div className={classNames(styles.errorModalWrapper)}>
            <h2>Ошибка отказа от оказания услуг.</h2>
            <p>
                Произошла ошибка при обработке вашего отказа от оказания услуг. Пожалуйста, попробуйте еще раз или
                обратитесь в службу поддержки.
            </p>
            <Button label={'Закрыть'} onClick={closeModal} />
        </div>
    );
};
