import { yupResolver } from '@hookform/resolvers/yup';
import {
    getIsinsGroupedByEmitter,
    getIsinsVirtualGroupedByEmitter,
    getTradeDate,
} from '@modules/PriceCenterProduct/InteractiveMap/utils';
import classNames from 'classnames';
import { endOfToday, endOfYesterday, format } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMemo, useState } from 'react';
import { type AppDispatch } from '@store/store';
import { getInstrumentsDataPerPeriodThunk } from '@store/store/thunk/pricecenter/getInstrumentsData.thunk';
import { useDispatch } from 'react-redux';
import { type Emitter, type Bond, type InstrumentsFormData } from '@modules/PriceCenterProduct/types';
import { ReactComponent as CalendarIcon } from '@modules/PriceCenterProduct/img/CalendarIcon.svg';
import { setDataUploadError, setDataUploadSelectedPeriod } from '@store/store/slices/pricecenter.slice';

import { ControlsPanel } from '../ControlsPanel';
import { handleFileDownload } from '../../utils';
import styles from '../../styles.module.scss';

const schema = yup.object().shape({
    dateStart: yup.date().required(),
    dateEnd: yup
        .date()
        .required()
        .test('is-greater', 'Дата окончания не может быть меньше даты начала периода', function (value) {
            const { dateStart } = this.parent;

            return dateStart && value && value > dateStart;
        }),
    isinShortList: yup.string().required('Необходимо выбрать инструмент'),
});

export const PeriodForm = ({ bonds, emitters }: { bonds: Bond[]; emitters: Emitter[] }) => {
    const [downloadError, setDonwloadError] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
        reset,
    } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const { dateStart, isinShortList, dateEnd } = watch();

    const itemTemplate = (option: any) => {
        if (option.label.startsWith('Group-')) {
            return (
                <div className={classNames('flex', styles.dropdownGroup)}>
                    <p>{option.label.slice(6)}</p>
                    <div className={styles.divider} />
                </div>
            );
        } else {
            return <p className={classNames('flex', styles.dropdownItem)}>{option.label}</p>;
        }
    };

    const onSubmit = (data: InstrumentsFormData) => {
        const { dateStart, dateEnd, isinShortList } = data;

        dispatch(
            getInstrumentsDataPerPeriodThunk({
                list: [isinShortList],
                dates: [format(dateStart, 'yyyy-MM-dd'), format(dateEnd, 'yyyy-MM-dd')],
            }),
        ).then((res) => {
            if ((res as any).payload.data.data.length === 0) {
                dispatch(setDataUploadError('NO_DATA'));
            }
        });
    };

    const opts = useMemo(() => {
        return getIsinsVirtualGroupedByEmitter(bonds, emitters);
    }, [bonds, emitters]);

    const handleDownloadCSV = async () => {
        if (!isinShortList) {
            setDonwloadError('Необходимо выбрать инструмент');

            return;
        }

        await handleFileDownload(
            [isinShortList],
            [format(dateStart, 'yyyy-MM-dd'), format(dateEnd, 'yyyy-MM-dd')],
            'csv',
            'instrument_list_period',
        );
    };

    const handleDownloadXLS = async () => {
        if (!isinShortList) {
            setDonwloadError('Необходимо выбрать инструмент');

            return;
        }

        await handleFileDownload(
            [isinShortList],
            [format(dateStart, 'yyyy-MM-dd'), format(dateEnd, 'yyyy-MM-dd')],
            'xls',
            'instrument_list_period',
        );
    };

    const handleResetForm = () => {
        reset();
        setDonwloadError(null);
        dispatch(setDataUploadSelectedPeriod([]));
    };

    const getFormErrorMessage = (name: string) => {
        return (
            (errors as Record<string, string>)[name] && (
                <small className="p-error">{(errors as Record<string, any>)[name].message}</small>
            )
        );
    };

    return (
        <form action="post" onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className={styles.mainBlock}>
                <div className={classNames(styles.instrumentsSelectWrapper)}>
                    <p>Выбор инструмента</p>
                    <div className="flex align-items-center gap-3 w-full">
                        <Controller
                            control={control}
                            name="isinShortList"
                            render={({ field, fieldState }) => (
                                <Dropdown
                                    value={field.value}
                                    id={field.name}
                                    options={opts}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setDonwloadError(null);
                                    }}
                                    focusOnHover={false}
                                    optionLabel="label"
                                    optionGroupTemplate={itemTemplate}
                                    virtualScrollerOptions={{ itemSize: 25, step: 1, autoSize: true }}
                                    placeholder="ISIN, название инструмента"
                                    className={classNames(styles.dropDown, {
                                        'border-red-500': fieldState.invalid || downloadError,
                                    })}
                                    multiple={false}
                                    tabIndex={0}
                                    filter
                                    filterBy="label,value"
                                    pt={multiSelectPt}
                                    disabled={bonds?.length === 0}
                                    appendTo={'self'}
                                    emptyMessage="Ничего не найдено"
                                    emptyFilterMessage="Ничего не найдено"
                                    itemTemplate={itemTemplate}
                                />
                            )}
                        />
                    </div>
                    {downloadError && <small className="p-error">Ошибка при скачивании: {downloadError}</small>}
                </div>

                <div className={classNames(styles.dateBlock)}>
                    <p>Данные за период</p>
                    <div className="flex align-items-center gap-2">
                        <Controller
                            name="dateStart"
                            control={control}
                            rules={{
                                deps: ['dateEnd'],
                            }}
                            defaultValue={getTradeDate(1)}
                            render={({ field }) => (
                                <Calendar
                                    id={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    dateFormat="dd.mm.yy"
                                    readOnlyInput
                                    showIcon
                                    pt={{
                                        header: { className: styles.calendarHeader },
                                    }}
                                    className={classNames(styles.calendarInput)}
                                    appendTo={'self'}
                                    locale="ru"
                                    maxDate={dateEnd ?? endOfToday()}
                                    icon={<CalendarIcon />}
                                />
                            )}
                        />
                        <i className="pi pi-minus text-xs" />
                        <Controller
                            name="dateEnd"
                            control={control}
                            defaultValue={getTradeDate()}
                            rules={{
                                validate: {
                                    lowerThanMax: (value) => {
                                        const min = watch('dateStart');

                                        if (value && min) {
                                            return (
                                                new Date(value).getTime() > new Date(min).getTime() ||
                                                'Дата окончания не может быть меньше даты начала периода'
                                            );
                                        }
                                    },
                                },
                                deps: ['dateStart'],
                            }}
                            render={({ field, fieldState }) => (
                                <Calendar
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.value);
                                    }}
                                    dateFormat="dd.mm.yy"
                                    readOnlyInput
                                    pt={{
                                        header: { className: styles.calendarHeader },
                                    }}
                                    showIcon
                                    className={classNames(styles.calendarInput, styles.translateCenter, {
                                        'border-red-500 border-1': fieldState.invalid,
                                    })}
                                    appendTo={'self'}
                                    locale="ru"
                                    maxDate={endOfToday()}
                                    icon={<CalendarIcon />}
                                />
                            )}
                        />
                    </div>
                    {getFormErrorMessage('dateEnd')}
                </div>
            </div>

            <ControlsPanel errors={errors} callbacks={{ handleDownloadCSV, handleDownloadXLS, handleResetForm }} />

            {getFormErrorMessage('isinShortList')}
        </form>
    );
};

const multiSelectPt = {
    header: () => ({ className: styles.multiSelectHeader }),
    filterContainer: () => ({ className: styles.multiSelectFilterInput }),
    list: () => ({ className: styles.multiSelectList }),
    item: () => ({ className: styles.multiSelectListItem }),
    itemGroup: () => ({ className: styles.multiSelectListItemGroup }),
    panel: () => ({ className: styles.multiSelectPanel }),
    label: () => ({ className: styles.multiSelectLabel }),
    labelContainer: () => ({ className: styles.multiSelectLabel }),
};
