import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { ReactComponent as InvestorproLogo } from '../../images/svg/InvestorProLogo.svg';

export const Header = () => {
    return (
        <section className={styles.headerWrapper}>
            <div className={styles.headerPanelWrapper}>
                <div className={styles.headerLinksBlock}>
                    <Link to={''}>
                        <InvestorproLogo className={styles.investorproLogo} />
                    </Link>
                    <nav className={styles.headerNavigationList}>
                        <ul className={styles.navigationList}>
                            <li className={styles.navigationItem}>
                                <Link to={''} className={styles.navigationLink}>
                                    Портфель
                                </Link>
                            </li>
                            <li className={styles.navigationItem}>
                                <Link to={'screeners'} className={styles.navigationLink}>
                                    Скриннер
                                </Link>
                            </li>
                            <li className={styles.navigationItem}>
                                <Link to={''} className={styles.navigationLink}>
                                    Аналитика
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>

                {/* <div className={styles.iconsBtnWrapper}>
            <Button className={styles.iconBtn} text >
              <InvestorproBell className={styles.menuIcon} />
            </Button>
            <Button className={styles.iconBtn} text >
              <InvestorproStar className={styles.menuIcon} />
            </Button>
            <Button className={styles.iconBtn} text >
              <InvestorproPerson className={styles.menuIcon} />
            </Button>
          </div> */}
            </div>
        </section>
    );
};
