import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveSubscriptions } from '@libs/services';

export const checkSubscriptionThunk = createAsyncThunk(
    'investorPro/checkSubscriptionThunk',
    async (productId: string, { rejectWithValue }) => {
        const productGuids = [productId];
        try {
            const { data } = await getActiveSubscriptions(productGuids);

            const result = data?.data?.rows.find((item) => item.productGuid === productId && item.status === 'ACTIVE');

            return result;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
