import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import { type ResponseInfo, type SavedCard } from '@libs/types';

export const CARD_URL = '/moex-datashop-orderservice/api/datashop/order-service/v1/saved-cards';

export const getSavedCards = async (): Promise<AxiosResponse<ResponseInfo<SavedCard[]>>> => (
    await httpClient.get(CARD_URL)
);

export const deleteSavedCard = async (cardId: string): Promise<AxiosResponse<ResponseInfo<unknown>>> => (
    await httpClient.delete(`${CARD_URL}/${cardId}`)
);

export const setDefaultSavedCard = async (cardId: string) => await httpClient.post(`${CARD_URL}/${cardId}/set-default`);
