import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import classNames from 'classnames';

import { extensions } from '../extensions';

import '../Tiptap/Tiptap.scss';
import styles from '../Tiptap/styles.module.scss';

type DisplayContentProps = {
    content: any;
};

const DisplayContent: React.FC<DisplayContentProps> = ({ content }) => {
    const editor = useEditor({
        extensions,
        content: {
            type: 'doc',
            content,
        },
        editable: false,
    });

    useEffect(() => {
        if (editor) {
            editor.commands.setContent(content);
        }
    }, [content]);

    return editor
? (
        <div className={classNames(styles.editor, 'tiptap_editor display_content')}>
            <EditorContent editor={editor} />
        </div>
    )
: null;
};

export default DisplayContent;
