import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import type { TariffFilterItem, TariffPlanItem } from '@libs/types';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { getPreparedTariffData } from '@libs/components/pageBlocks/TariffBlock/utils/utils';
import { updateSubscriptionThunk } from '@store/store/thunk/user/subscription.thunk';
import { type SubscriptionData, type UpdateSubscriptionReq } from '@libs/types/subscription.type';
import { RenewalCheckbox } from '@libs/components/misc/RenewalCheckbox';

import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    productName: string;
    tariffName?: string;
    period?: string;
    isRenewal: boolean;
    subscriptionId: number;
    setSubscriptionInfo: (value: SubscriptionData) => void;
};

export const RenewalModal = ({
    isOpen,
    closeModal,
    productName,
    tariffName,
    period,
    isRenewal,
    subscriptionId,
    setSubscriptionInfo,
}: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const datashopTariffs = useSelector((state: RootState) => state.tariff.datashopTariffs);

    const [tariffPlanList, setTariffPlanList] = useState<TariffPlanItem[]>([]);
    const [tariffPeriodList, setTariffPeriodList] = useState<TariffFilterItem[]>([]);

    const [selectedTariff, setSelectedTariff] = useState<TariffPlanItem>();
    const [selectedPeriod, setSelectedPeriod] = useState<TariffFilterItem>();
    const [renewal, setRenewal] = useState<boolean>(isRenewal);

    useEffect(() => {
        if (isOpen) {
            setRenewal(isRenewal);
            prepareData();
        } else {
            resetData();
        }
    }, [isOpen]);

    const prepareData = () => {
        const { resultFilterList } = getPreparedTariffData(datashopTariffs);
        setTariffPeriodList(resultFilterList);
        setSelectedPeriod(resultFilterList.find((item) => item.title === period));

        const tariffList = datashopTariffs.map((item) => ({
            title: item.name || '',
            guid: item.guid || '',
        }));
        setTariffPlanList(tariffList);
        setSelectedTariff(tariffList.find((item) => item.title === tariffName));
    };

    const resetData = () => {
        setTariffPlanList([]);
        setTariffPeriodList([]);
        setSelectedTariff(undefined);
        setSelectedPeriod(undefined);
    };

    const findPeriod = (): { paramId: string; valueId: string } => {
        const tariff = datashopTariffs.find((tariff) => tariff.guid === selectedTariff?.guid);

        if (tariff) {
            const billingTable = tariff.billingTables?.find(
                (item) => item.billingType === 'TC_BT_SUBSCRIPTION_BASED_DATA',
            );

            if (billingTable) {
                const param = billingTable.tariffParams?.find((item) => item.values?.find((item) => item.value === selectedPeriod?.title),
                );

                if (param) {
                    const value = param.values?.find((item) => item.value === selectedPeriod?.title);

                    return {
                        valueId: value ? value.guid : '',
                        paramId: param.tariffParamGuid!,
                    };
                }
            }
        }

        return { paramId: '', valueId: '' };
    };

    const updateRenewal = () => {
        const body: UpdateSubscriptionReq = {
            renewalTerms: {
                tariffId: selectedTariff?.guid!,
                renewal,
                tariffParams: [findPeriod()],
            },
        };
        dispatch(updateSubscriptionThunk({ subscriptionId, body }))
            .then((res) => {
                const result = unwrapResult(res);
                setSubscriptionInfo(result);
            })
            .finally(() => {
                closeModal();
            });
    };

    return (
        <div className={classNames(styles.renewalModalWrapper, 'flex', 'flex-column')}>
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                <h2>продление подписки</h2>
                <span>{productName}</span>
                <div className={classNames(styles.dataWrapper, 'flex', 'flex-column')}>
                    <div className={classNames('flex', 'flex-column')}>
                        <div className={classNames(styles.dropdownWrapper, 'flex', 'flex-column')}>
                            <span>{'Тариф'}</span>
                            <Dropdown
                                options={tariffPlanList}
                                optionValue="guid"
                                optionLabel="title"
                                value={selectedTariff?.guid}
                                panelClassName={styles.panelStyle}
                                onChange={(e) => {
                                    setSelectedTariff(tariffPlanList.find((item) => item.guid === e.value));
                                }}
                                className={styles.dropdownFilter}
                            />
                        </div>

                        <div className={classNames(styles.dropdownWrapper, 'flex', 'flex-column')}>
                            <span>{'Период оплаты'}</span>
                            <Dropdown
                                options={tariffPeriodList}
                                optionValue="code"
                                optionLabel="title"
                                value={selectedPeriod?.code}
                                panelClassName={styles.panelStyle}
                                onChange={(e) => {
                                    setSelectedPeriod(tariffPeriodList.find((item) => item.code === e.value));
                                }}
                                className={styles.dropdownFilter}
                            />
                        </div>
                    </div>

                    <RenewalCheckbox value={renewal} setValue={setRenewal} />
                </div>
            </div>
            <div className={classNames(styles.buttonsWrapper, 'flex')}>
                <Button label={'Сохранить'} className={styles.blackBtn} onClick={updateRenewal} />
                <Button label={'Отменить'} className={styles.whiteBtn} onClick={closeModal} />
            </div>
        </div>
    );
};
