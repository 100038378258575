import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '@shared/images/svg/arrow.svg';
import { type Dispatch, type SetStateAction } from 'react';

import styles from './styles.module.scss';

type Props = {
    referenceId: string;
    articleId: number;
    title: string;
    isActive: boolean;
    activeIndex?: number;
    childrenIndex?: number;
    setChildrenIndex: Dispatch<SetStateAction<number | undefined>>;
    withChildren: boolean;
};

export const HeaderComponent = ({
    referenceId,
    articleId,
    title,
    isActive,
    withChildren,
    activeIndex,
    childrenIndex,
    setChildrenIndex,
}: Props) => {
    const navigate = useNavigate();

    return (
        <div className={classNames('flex', styles.headerComponent, isActive && styles.active)}>
            <p onClick={() => navigate(`/help-center/references/${referenceId}/article/${articleId}`)}>{title}</p>
            {withChildren && (
                <ArrowIcon
                    className={isActive && childrenIndex === activeIndex ? styles.rotateToTop : styles.rotateToBottom}
                    onClick={() => setChildrenIndex((prev) => (prev ? undefined : activeIndex))}
                />
            )}
        </div>
    );
};
