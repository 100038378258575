import icon1 from '@shared/images/cki/descriptionBlock/icon1.svg';
import icon2 from '@shared/images/cki/descriptionBlock/icon2.svg';
import icon3 from '@shared/images/cki/descriptionBlock/icon3.svg';
import icon4 from '@shared/images/cki/descriptionBlock/icon4.svg';

export const descriptions = [
    {
        icon: icon1,
        title: 'Все рейтинги через API',
        description:
            'Доступ к данным по кредитным рейтингам от всех рейтинговых агентств реестра банка России без заключения договора с каждым из них',
    },
    {
        icon: icon2,
        title: 'Детальный анализ эмитентов и инструментов',
        description:
            'Оцените надёжность компаний и ценных бумаг, прогнозы по рейтингу и факторы риска, анализируйте отчётности по РСБУ и МСФО',
    },
    {
        icon: icon3,
        title: 'Автоматизация рабочих процессов',
        description:
            'Данные поставляются из нескольких источников с уже рассчитанными коэффициентами, в выгрузке развёрнутых отчётов также есть востребованные мультипликаторы для упрощения работы',
    },
    {
        icon: icon4,
        title: 'Исторические данные по рейтингам и отчётности',
        description:
            'Потоки данных предусматривают как актуальные показатели, так и исторические данные для сравнения внесённых изменений',
    },
];
