import operationalRiskReduction from '../../shared/images/png/duplicatesIIS/operationalRiskReduction.png';
import readyDataIntegration from '../../shared/images/png/duplicatesIIS/readyDataIntegration.png';

export const productCapabilities = [
    {
        image: operationalRiskReduction,
        title: 'Снижение операционного риска',
        description:
            'Ответ о наличии или отсутствии зарегистрированного на Московской бирже ИИС в течение нескольких секунд',
        points: [],
    },
    {
        image: readyDataIntegration,
        title: 'Готовая интеграция данных',
        description: 'Сервис встроен в API онлайн регистрации клиентов',
        points: [],
    },
];
