import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import {
    type ResponseInfo,
    type UserProfileType,
    type UserProfileUpdateResponseType,
    type EmailSendRequestType,
    type EmailSendResponseType,
    type PersonalInfo,
} from '@libs/types';

export const USER_PROFILE = '/moex-datashop-profileservice/api/userprofile/v1/';

export const ATTACH_URL = '/moex-datashop-attachmentservice/api/file-service/v1/';

const getUserProfileRequest = async (): Promise<AxiosResponse<ResponseInfo<UserProfileType>>> =>
    await httpClient.get(USER_PROFILE);

const updateUserProfileRequest = async (
    requestData: UserProfileUpdateResponseType,
): Promise<AxiosResponse<ResponseInfo<UserProfileType>>> => await httpClient.post(USER_PROFILE, requestData);

const uploadTicket = async () => await httpClient.get(USER_PROFILE + 'avatar/upload-ticket');

const removeAvatar = async () => await httpClient.delete(USER_PROFILE + 'avatar/');

const uploadAvatarRequest = async (
    requestData: {
        file: any;
    },
    ticket: number,
): Promise<AxiosResponse<ResponseInfo<{ bucket: string; ticketId: number }>>> =>
    await httpClient.post(ATTACH_URL + `upload-by-ticket/${ticket}`, requestData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });

const downloadAvatarRequest = async (bucket: string, ticket: number): Promise<AxiosResponse<string>> =>
    await httpClient.get(ATTACH_URL + `download/${bucket}/${ticket}`, {
        responseType: 'arraybuffer',
    });

const updateEmailRequest = async (
    requestData: EmailSendRequestType,
): Promise<AxiosResponse<ResponseInfo<UserProfileType>>> => await httpClient.post(USER_PROFILE + 'email', requestData);

const confirmEmailRequest = async (): Promise<AxiosResponse<ResponseInfo<EmailSendResponseType>>> =>
    await httpClient.post(USER_PROFILE + 'email/send-confirm');

const checkEmailRequest = async (token: string): Promise<AxiosResponse<ResponseInfo<any>>> =>
    await httpClient.get(`${USER_PROFILE}email/check-code/${token}`);

const getPersonalInformation = async (): Promise<AxiosResponse<ResponseInfo<PersonalInfo>>> =>
    await httpClient.get(USER_PROFILE + 'personal-information');

const checkPurchasePossibility = async (body: {
    productGuid: string;
}): Promise<AxiosResponse<ResponseInfo<{ result: 'ALLOWED' | 'FORBIDDEN'; blockedBy: string[] }>>> =>
    await httpClient.post(USER_PROFILE + 'check-purchase-possibility', body);

export {
    updateEmailRequest,
    getUserProfileRequest,
    updateUserProfileRequest,
    confirmEmailRequest,
    checkEmailRequest,
    uploadAvatarRequest,
    downloadAvatarRequest,
    uploadTicket,
    removeAvatar,
    getPersonalInformation,
    checkPurchasePossibility,
};
