export enum ProductMnemoCodes {
    CKI = 'TPC_CKI',
    MOEX_DATA = 'TPC_MOEX_DATA',
    INVESTOR_PRO = 'TPC_IPRO',
    MARKET_DIVE = 'TPC_MARKET_DIVE',
    COMPLIENCE_TOOL = 'TPC_COMPLIENCE_TOOL',
    TPC_PRO_PACK = 'TPC_PRO_PACK',
    TPC_ALGO_PACK = 'TPC_ALGO_PACK',
    ALGOPACK = 'ALGOPACK',
    TPC_CENOVOI_CENTR = 'TPC_CENOVOI_CENTR',
    TRADE_RADAR = 'TPC_TREID_RADAR',
    MARKET_VISION = 'TPC_MARKETVISION',
}
