export const options = [
    'Доступ к данным через веб-интерфейс',
    'Одна из методик расчёта',
    'Финансовый анализ',
    'Кривая бескупонной доходности ',
];

export const dopOptions = [
    {
        title: 'Каждая дополнительная методика расчёта',
        price: '10000',
    },
    {
        title: 'Оценка корпоративных еврооблигаций',
        price: '24000',
    },
];
