import classNames from 'classnames';
import { type ReactNode } from 'react';

import styles from './styles.module.scss';

type Props = {
    title: string;
    description?: string | null;
    list?: string[];
    footerBlock: ReactNode;
    isActive?: boolean;
    activePeriod?: string;
};

export const TariffCard = ({ title, description, list, footerBlock, isActive = false, activePeriod }: Props) => {
    return (
        <div
            className={classNames(
                styles.tariffCardWrapper,
                isActive && activePeriod && styles.withActivePeriod,
                'flex',
                'flex-column',
                'justify-content-between',
            )}
        >
            <div>
                <div className={styles.titleWrapper}>
                    <span>тариф</span>
                    <h4>{title}</h4>
                </div>
                <div className={styles.contentWrapper}>
                    {description && <p>{description}</p>}
                    <ul>
                        {list?.map((item, index) => (
                            <li key={index} className={classNames('flex', 'align-items-center')}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={styles.footerWrapper}>
                <div
                    className={classNames(
                        styles.contentFooterWrapper,
                        isActive && activePeriod && styles.withActivePeriod,
                    )}
                >
                    {footerBlock}
                </div>
                {isActive && activePeriod && (
                    <div
                        className={classNames(
                            styles.activePeriodWrapper,
                            'flex',
                            'justify-content-center',
                            'align-items-center',
                        )}
                    >
                        Действует до {activePeriod}
                    </div>
                )}
            </div>
        </div>
    );
};
