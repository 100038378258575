import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveSubscriptions, subscribeById } from '@libs/services';

const subscribeThunk = createAsyncThunk('algopack/subscribeThunk', async (_, { rejectWithValue }) => {
    try {
        const { data } = await subscribeById();

        return data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

const checkSubscriptionThunk = createAsyncThunk(
    'algopack/checkSubscriptionThunk',
    async (productId: string, { rejectWithValue }) => {
        const productGuids = [productId];
        try {
            const { data } = await getActiveSubscriptions(productGuids);

            const result = data?.data?.rows.find((item) => item.productGuid === productId && item.status === 'ACTIVE');

            return result;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export { subscribeThunk, checkSubscriptionThunk };
