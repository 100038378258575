import classNames from 'classnames';
import { type ForwardedRef, forwardRef, type PropsWithChildren, type ReactNode, RefObject, useCallback } from 'react';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';
import cubesXl from '../../images/svg/serviceItemCubes/cubesXl.svg';
import cubesLg from '../../images/svg/serviceItemCubes/cubesLg.svg';
import cubesMd from '../../images/svg/serviceItemCubes/cubesMd.svg';
import cubesSm from '../../images/svg/serviceItemCubes/cubesSm.svg';

type Props = {
    image: string;
    title: string;
    description: string;
    buttons: ReactNode[];
    points?: string[];
};

export const ServiceItem = forwardRef(
    (
        { image, title, description, buttons, children, points = [] }: PropsWithChildren<Props>,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const { isScreenXl, isScreenLg, isScreenMd, isScreenSm } = useResize();

        const getCubesBg = useCallback(() => {
            if (isScreenXl) {
                return cubesXl;
            } else if (isScreenLg) {
                return cubesLg;
            } else if (isScreenMd) {
                return cubesMd;
            }

            return cubesSm;
        }, [isScreenXl, isScreenLg, isScreenMd, isScreenSm]);

        return (
            <section ref={ref} className={classNames(styles.serviceItemWrapper, 'flex', 'flex-column')}>
                <div
                    className={styles.logoBlockWrapper}
                    style={{
                        background: `url("${getCubesBg()}") ${isScreenSm ? 'top' : 'center'} left / auto ${isScreenSm ? '243px' : '100%'} no-repeat, url("${image}") ${isScreenSm ? 'top' : 'center'} right / auto ${isScreenSm ? '243px' : '100%'} no-repeat`,
                    }}
                >
                    <div
                        className={classNames(
                            styles.contentWrapper,
                            'flex',
                            'flex-column',
                            !isScreenSm && 'justify-content-center',
                        )}
                    >
                        <h2>{title}</h2>
                        <div className={classNames(styles.textWrapper, 'flex', 'flex-column')}>
                            <p>{description}</p>
                            {!!points.length && (
                                <ul>
                                    {points.map((item) => (
                                        <li key={item}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className={classNames(styles.btnWrapper, 'flex')}>{buttons.map((item) => item)}</div>
                    </div>
                </div>
                {children}
            </section>
        );
    },
);
