import classNames from 'classnames';
import { navigationItems } from '@modules/ProPackLanding/components/DescriptionServices/data';
import { DescriptionItem } from '@modules/ProPackLanding/components/DescriptionServices/DescriptionItem';
import type { RefObjectType } from '@libs/types';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const DescriptionServices = ({ refs }: Props) => {
    return (
        <section className={classNames(styles.descriptionNavigationWrapper, 'flex', 'flex-column')}>
            <div className={classNames(styles.titleWrapper, 'flex', 'flex-column')}>
                <h2>Набор специализированных аналитических сервисов</h2>
                <p>для профессиональных участников рынка</p>
            </div>
            <div className={classNames(styles.contentWrapper)}>
                {navigationItems.map((item, index) => (
                    <DescriptionItem
key={item.title} {...item} showLine={index !== 2}
refs={refs} />
                ))}
            </div>
        </section>
    );
};
