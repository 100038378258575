import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllSubscriptionsThunk } from '../thunk/algopack/getAllSubscriptons.thunk';
import { checkSubscriptionThunk, subscribeThunk } from '../thunk/algopack/subscribe.thunk';
import { SubscriptionData } from '@libs/types/subscription.type';

export type IState = {
    data: SubscriptionData[] | null;
    isSubscribed: boolean;
    isLoading: boolean;
    error: any;
    activeTariff: SubscriptionData | null;
};

export const initialState: IState = {
    data: null,
    isSubscribed: false,
    isLoading: false,
    error: {},
    activeTariff: null,
};

export const algopackSlice = createSlice({
    name: 'algopack',
    initialState,
    reducers: {
        updateIsSubscribedOnExit(state) {
            state.isSubscribed = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSubscriptionsThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllSubscriptionsThunk.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.data = action.payload?.data;
                state.isSubscribed = state.data?.some((item) => item.productGuid === action.payload.productId) || false;
            })
            .addCase(getAllSubscriptionsThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })
            .addCase(subscribeThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(subscribeThunk.fulfilled, (state) => {
                state.isLoading = false;
                state.isSubscribed = true;
            })
            .addCase(subscribeThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })
            .addCase(checkSubscriptionThunk.fulfilled, (state, action) => {
                state.activeTariff = action.payload || null;
            });
    },
});

export const algopackReducer = algopackSlice.reducer;
export const { updateIsSubscribedOnExit } = algopackSlice.actions;
