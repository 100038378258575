import { type ScriptableChartContext } from 'chart.js';
import { Chart } from 'primereact/chart';
import { memo, useMemo } from 'react';
import './style.scss';
import { type FutoiGraphData, type GraphItem } from '@modules/Algopack/AlgopackPage/services/types';
import { max, min } from 'lodash';
import { format, getHours } from 'date-fns';

import { getChartOptions, getLinerGradientForLineChart } from './utils';

type FutoiChartProps = {
    data: FutoiGraphData;
};

const formatDatesToTime = (data: GraphItem[]): GraphItem[] => {
    const result = data.map(({ tradeDateTime, value }, index, array) => {
        const parsedDate = new Date(tradeDateTime);
        const hours = getHours(parsedDate);

        if (index === 0) {
            return { tradeDateTime: format(new Date(parsedDate), 'dd.MM.yyyy'), value };
        }

        const previusHour = getHours(new Date(array[index - 1].tradeDateTime));

        if (hours < previusHour) {
            return { tradeDateTime: format(new Date(parsedDate), 'dd.MM.yyyy'), value };
        } else {
            return { tradeDateTime: format(parsedDate, 'HH:mm'), value };
        }
    });

    return result;
};

const FutoiChart = memo(({ data }: FutoiChartProps) => {
    const documentStyle = getComputedStyle(document.documentElement);
    const redLineColor = documentStyle.getPropertyValue('--ds-main-red');
    const grayLineColor = documentStyle.getPropertyValue('--ds-main-gray');
    const greenLineColor = documentStyle.getPropertyValue('--ds-bg-green');

    const { longPosition, shortPosition, priceContract } = data;

    const xLabels = useMemo(
        () => formatDatesToTime(longPosition)?.map((coordsObj: GraphItem) => coordsObj.tradeDateTime),
        [longPosition],
    );
    const yLabels = useMemo(() => longPosition?.map((coordsObj: GraphItem) => coordsObj.value), [longPosition]);

    const positionAllValues = useMemo(
        () => [longPosition, shortPosition].flat().map((coordsObj: GraphItem) => coordsObj.value),
        [longPosition, shortPosition],
    );

    const yMax = useMemo(() => max(positionAllValues), [yLabels]);
    const yMin = useMemo(() => min(positionAllValues), [yLabels]);

    const y2Max = useMemo(() => max(priceContract.map((item: GraphItem) => item.value)), [yLabels]);
    const y2Min = useMemo(() => min(priceContract.map((item: GraphItem) => item.value)), [yLabels]);

    const chartOptions = getChartOptions({ yMax, y2Max, yMin, y2Min });

    const chartData = {
        xLabels,
        yLabels,
        datasets: [
            {
                yAxisID: 'y',
                label: 'Длинные позиции',
                data: formatDatesToTime(longPosition),
                borderColor: greenLineColor,
                backgroundColor: (context: ScriptableChartContext) => getLinerGradientForLineChart(context, 'green'),
                fill: 1,
            },
            {
                yAxisID: 'y2',
                label: 'Короткие позиции',
                data: formatDatesToTime(shortPosition),
                borderColor: redLineColor,
                backgroundColor: (context: ScriptableChartContext) => getLinerGradientForLineChart(context, 'red'),
                fill: true,
            },
            {
                yAxisID: 'y3',
                label: 'Цена контракта',
                borderColor: grayLineColor,
                data: formatDatesToTime(priceContract),
                fill: true,
                backgroundColor: (context: ScriptableChartContext) => getLinerGradientForLineChart(context, 'gray'),
            },
        ],
    };

    return (<Chart
style={{ width: '100%', height: '100%' }} data={chartData} type="line"
options={chartOptions} />);
});

export default FutoiChart;
