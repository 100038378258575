import { ReactComponent as PriceCenterLogo } from '@shared/images/priceCenter/svg/PriceCenterLogo.svg';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { ReactComponent as PlayBlackIcon } from '@shared/images/svg/playBlack.svg';
import { ReactComponent as PlayWhiteIcon } from '@shared/images/svg/playWhite.svg';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { scrollElementByRef, useAuthorize, useModal } from '@libs/utils';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { type RefObjectType } from '@libs/types';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { AuthorizeModal } from '@libs/components/overlay/AuthorizeModal';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const Header = ({ refs }: Props) => {
    const { isAuthorized } = useAuthorize();
    const activeTariff = useSelector((state: RootState) => state.priceCenter.activeTariff);
    const { isOpen, closeModal, openModal } = useModal();

    const [isSubscribed, setIsSubscribed] = useState<boolean>(!!activeTariff);

    useEffect(() => {
        setIsSubscribed(!!activeTariff);
    }, [activeTariff]);

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <>
            <div className={styles.headerWrapper}>
                <div className={styles.contentWrapper}>
                    <PriceCenterLogo />
                    <p>Мы делаем процесс оценки стоимости торгуемых и неторгуемых облигаций простым и удобным</p>
                    <div className={classNames(styles.buttonWrapper, 'flex')}>
                        {isAuthorized && isSubscribed
? (
                            <Link className={classNames(styles.redBtn)} to={'product?tab=interactiveMap'}>
                                <PlayWhiteIcon />
                                <span>Открыть</span>
                            </Link>
                        )
: (
                            <>
                                <Button
                                    className={classNames(styles.redBtn)}
                                    label={'Получить доступ'}
                                    icon={<StarIcon />}
                                    onClick={() => handleButtonClick('gainAccessRef')}
                                />
                                {isAuthorized
? (
                                    <Link className={classNames(styles.whiteBtn)} to={'product?tab=interactiveMap'}>
                                        <PlayBlackIcon />
                                        <span>Пробная версия</span>
                                    </Link>
                                )
: (
                                    <Button
                                        className={classNames(styles.whiteBtn)}
                                        label={'Пробная версия'}
                                        icon={<PlayBlackIcon />}
                                        onClick={openModal}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <AuthorizeModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};
