import { ModalWindow } from '@libs/components';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as CheckMark } from '@shared/images/svg/checkMarkGreen.svg';
import { ReactComponent as CrossIcon } from '@shared/images/svg/CrossIconLightGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { AddEmail } from '@libs/components/overlay/OnboardingModal/AddEmail';
import { generateCramlLink, useModal } from '@libs/utils';
import { useContext, useMemo } from 'react';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';
import { Tooltip } from 'primereact/tooltip';
import { setShowOnboardingModal } from '@store/store/slices/user.slice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import styles from './styles.module.scss';

export const OnboardingModal = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { isOpen: isOpenAddEmail, closeModal: closeAddEmailModal, openModal: openAddEmailModal } = useModal();
    const user = useSelector((state: RootState) => state.user.user);
    const showOnboardingModal = useSelector((state: RootState) => state.user.showOnboardingModal);
    const { tokenData } = useContext<IAuthContext>(AuthContext);
    const isFinished = useMemo(() => !!(user?.email && user.isEmailVerified && user.personalDataProvided), [user]);

    const goToMobileRegistration = () => {
        if (!user?.personalDataProvided) {
            window.location.href = generateCramlLink(
                process.env.REACT_APP_AUTH_REALMS,
                process.env.REACT_APP_REALM,
                process.env.REACT_APP_PROVIDER_MOBILEID,
                process.env.REACT_APP_CLIENT_ID,
                encodeURI(window.location.href.split('?')[0] + '?showOnboarding=true'),
                tokenData?.session_state,
                process.env.REACT_APP_CLIENT_ID,
            );
        }
    };

    const closeModal = () => {
        dispatch(setShowOnboardingModal(false));

        if (searchParams.get('showOnboarding') === 'true') {
            navigate(location.pathname);
        }
    };

    return (
        <>
            <ModalWindow isVisible={showOnboardingModal} closeModal={closeModal} dismissableMask={true}>
                <div className={styles.onboardingModalWrapper}>
                    <h2>{isFinished ? 'Процесс регистрации успешно завершен' : 'Процесс регистрации'}</h2>
                    {!isFinished && (
                        <p>
                            Для оформления подписки на платные услуги MOEX Datashop в соответствии с регуляторными
                            требованиями мы вынуждены запрашивать минимальный набор персональных данных и Ваш email для
                            отправки информации об оплате
                        </p>
                    )}
                    <div className={classNames(styles.pointsWrapper, 'flex', 'flex-column')}>
                        <div className={classNames(styles.point, 'flex')}>
                            <CheckMark />
                            <span>Создать аккаунт</span>
                        </div>
                        <div className={classNames(styles.point, 'flex')}>
                            {user?.email ? <CheckMark /> : <CrossIcon />}
                            <span
                                className={classNames(!user?.email && styles.notConfirmed)}
                                onClick={() => {
                                    if (!user?.email) {
                                        closeModal();
                                        openAddEmailModal();
                                    }
                                }}
                            >
                                Указать email
                            </span>
                        </div>
                        <div className={classNames(styles.point, 'flex')}>
                            {user?.isEmailVerified ? <CheckMark /> : <CrossIcon />}
                            {user?.email && !user?.isEmailVerified && (
                                <Tooltip
                                    target="#confirm-email"
                                    position={'top'}
                                    autoHide={false}
                                    pt={{
                                        root: { className: styles.tooltipWrapper },
                                        text: { className: styles.tooltipText },
                                        arrow: { className: styles.tooltipArrow },
                                    }}
                                >
                                    <div className={styles.tooltip}>
                                        Ссылка-подтверждение была отправлена на ваш email. Повторно запросить ссылку
                                        можно в Личном Кабинете
                                    </div>
                                </Tooltip>
                            )}
                            <span
                                id={'confirm-email'}
                                className={classNames(
                                    user?.email && !user?.isEmailVerified && styles.hasEmail,
                                    !user?.email && styles.notHasEmail,
                                )}
                            >
                                Подтвердить email
                            </span>
                        </div>
                        <div className={classNames(styles.point, 'flex')}>
                            {user?.personalDataProvided ? <CheckMark /> : <CrossIcon />}
                            <span
                                className={classNames(!user?.personalDataProvided && styles.notConfirmed)}
                                onClick={goToMobileRegistration}
                            >
                                Предоставить минимальный набор персональных данных
                            </span>
                        </div>
                    </div>
                    <Button label={'Закрыть'} onClick={closeModal} />
                </div>
            </ModalWindow>
            <AddEmail
                isOpen={isOpenAddEmail}
                closeModal={() => {
                    closeAddEmailModal();
                    dispatch(setShowOnboardingModal(true));
                }}
            />
        </>
    );
};
