import { ModalWindow } from '@libs/components';
import { useAuthorize, useResize } from '@libs/utils';
import classNames from 'classnames';
import { Button } from 'primereact/button';

import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
};

export const AuthorizeModal = ({ isOpen, closeModal }: Props) => {
    const { isScreenSm } = useResize();
    const { loginHandler } = useAuthorize();

    return (
        <ModalWindow isVisible={isOpen} closeModal={closeModal}>
            <div className={classNames(styles.authorizeModalWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                    <h2>Зарегистрируйтесь</h2>
                    <p>Для доступа к пробной версии Ценового Центра вам необходимо войти или зарегистрироваться.</p>
                </div>
                <div className={classNames(styles.buttonsWrapper, 'flex', isScreenSm && 'flex-column')}>
                    <Button
                        label={'Регистрация'}
                        className={styles.redBtn}
                        onClick={() => loginHandler({ isRegistration: true })}
                    />
                    <Button label={'Вход'} className={styles.blackBtn} onClick={() => loginHandler()} />
                </div>
            </div>
        </ModalWindow>
    );
};
