import { Button } from 'primereact/button';
import { ReactComponent as ProPackLogo } from '@modules/ProPackLanding/shared/images/svg/logo.svg';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { ReactComponent as PlayIcon } from '@shared/images/svg/playBlack.svg';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { type RefObjectType } from '@libs/types';
import { scrollElementByRef, useAuthorize } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const LogoBlock = ({ refs }: Props) => {
    const location = useLocation();
    const { isAuthorized, loginHandler } = useAuthorize();

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <section className={styles.logoBlockWrapper}>
            <div className={styles.contentWrapper}>
                <ProPackLogo />
                <p>
                    Набор специализированных информационно-аналитических сервисов для профессиональных участников рынка
                </p>
                <div className={classNames('flex')}>
                    <Button onClick={() => handleButtonClick('gainAccessRef')}>
                        <StarIcon />
                        <span>Получить доступ</span>
                    </Button>
                    {isAuthorized
? (
                        <Link to={location.pathname + '/examples?tab=volatility'}>
                            <PlayIcon />
                            <span>Пробная версия</span>
                        </Link>
                    )
: (
                        <Button onClick={() => loginHandler()}>
                            <PlayIcon />
                            <span>Пробная версия</span>
                        </Button>
                    )}
                </div>
            </div>
        </section>
    );
};
