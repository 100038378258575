import { ServiceItem } from '@modules/ProPackLanding/shared/components/ServiceItem';
import { productCapabilities } from '@modules/ProPackLanding/components/DuplicatesIIS/data';
import type { RefObjectType } from '@libs/types';
import { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { scrollElementByRef } from '@libs/utils';
import serviceItemStyles from '@modules/ProPackLanding/shared/components/ServiceItem/styles.module.scss';

import { ReactComponent as StarIcon } from '../../../../shared/images/svg/star.svg';
import headerBg from '../../shared/images/png/duplicatesIIS/headerBg.png';
import { ProductCapabilities } from '../../shared/components/ProductCapabilities';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
    setRefs: (refs: RefObjectType<HTMLDivElement>) => void;
};

export const DuplicateIIS = ({ refs, setRefs }: Props) => {
    const duplicatesIISRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setRefs({ duplicatesIISRef });
    }, [setRefs]);

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <ServiceItem
            ref={duplicatesIISRef}
            image={headerBg}
            title={'Дубликаты ИИС'}
            description={'Онлайн сервис проверки на задвоенность Индивидуальных Инвестиционных Счетов физических лиц'}
            buttons={[
                <Button
                    key={0}
                    className={classNames(serviceItemStyles.redBtn, serviceItemStyles.widthBtn)}
                    label={'Получить доступ'}
                    icon={<StarIcon />}
                    onClick={() => handleButtonClick('gainAccessRef')}
                />,
            ]}
        >
            <ProductCapabilities list={productCapabilities} />
        </ServiceItem>
    );
};
