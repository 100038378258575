import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as ArrowDown } from '@shared/images/svg/arrowDownGray.svg';
import { scrollElementByRef, useResize } from '@libs/utils';
import type { RefObjectType } from '@libs/types';

import styles from './styles.module.scss';

type Props = {
    icon: string;
    title: string;
    description: string;
    navigationRef: string;
    showLine: boolean;
    refs: RefObjectType<HTMLDivElement>;
};

export const DescriptionItem = ({ icon, title, description, navigationRef, showLine, refs }: Props) => {
    const { isScreenSm } = useResize();

    const handleButtonClick = (refName: string) => {
        console.log('refName =', refName);
        console.log(refs);
        const ref = refs[refName];

        if (ref && ref.current) {
            console.log('scrolling');
            scrollElementByRef(ref);
        }
    };

    return (
        <div className={classNames(styles.navigationItemWrapper, 'flex', showLine && !isScreenSm && styles.borderBtm)}>
            <img src={icon} />
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                <h4>{title}</h4>
                <p>{description}</p>
                <Button icon={<ArrowDown />} label={'Подробнее'} onClick={() => handleButtonClick(navigationRef)} />
            </div>
        </div>
    );
};
