import classNames from 'classnames';
import { CardComponent } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/CardsBlock/CardComponent';
import { DropdownWrapper } from '@libs/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@store/store';
import { ReactComponent as NewCardIcon } from '@shared/images/svg/cardIcons/NewCard.svg';
import { getSavedCardsThunk } from '@store/store/thunk/card.thunk';

import styles from './styles.module.scss';

export const CardsBlock = () => {
    const dispatch = useDispatch<AppDispatch>();
    const savedCards = useSelector((state: RootState) => state.card.savedCards);

    useEffect(() => {
        dispatch(getSavedCardsThunk());
    }, []);

    return (
        <DropdownWrapper title={'Способы оплаты'}>
            <div className={classNames(styles.cardWrapper, 'flex', 'flex-wrap')}>
                {savedCards && savedCards.length
? (
                    savedCards.map((data) => <CardComponent key={data.id} {...data} />)
                )
: (
                    <div className={classNames(styles.noCart, 'flex', 'align-items-center')}>
                        <NewCardIcon />
                        <p>Нет сохраненных карт</p>
                    </div>
                )}
            </div>
        </DropdownWrapper>
    );
};
