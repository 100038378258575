import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PinIcon } from '@shared/images/priceCenter/svg/pinIcon.svg';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { type MenuItem } from 'primereact/menuitem';
import { unSelectMarketPoint } from '@store/store/slices/pricecenter.slice';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { type UnknownAction } from '@reduxjs/toolkit';

import { MarketPointPanel } from '../ChartTooltips';
import styles from './styles.module.scss';

type ChartMarkersModalProps = {
    selectedMarkers: any[];
    handleClearPanel: () => void;
    handleUnselectPoint: (point: any) => UnknownAction;
};

export const SelectedPointsModal = (props: ChartMarkersModalProps) => {
    const { selectedMarkers, handleClearPanel, handleUnselectPoint } = props;

    const selectedMarkersMenuRef = useRef<Menu>(null);
    const dispatch = useDispatch<AppDispatch>();

    const [markersMenu, setMarkersMenu] = useState<MenuItem[]>([]);

    useEffect(() => {
        const newMenu: MenuItem[] = selectedMarkers.map((point, index) => {
            return {
                label: String(index),
                template: () => (
                    <MarketPointPanel
                        key={`${point.datasetIndex}_${point.dataPointIndex}}`}
                        selected={true}
                        point={point}
                        onClick={(point, e) => {
                            dispatch(handleUnselectPoint(point));

                            if (selectedMarkers.length === 1) {
                                selectedMarkersMenuRef?.current?.toggle(e);
                            }
                        }}
                    />
                ),
            };
        });
        newMenu.push({
            label: 'divider',
            template: () => <div className={styles.divider} />,
        });
        newMenu.push({
            label: 'clearButton',
            template: () => (
                <Button
                    type="button"
                    label="Сбросить"
                    onClick={(e) => {
                        handleClearPanel();
                        selectedMarkersMenuRef?.current?.toggle(e);
                    }}
                    className={styles.clearButton}
                />
            ),
        });

        setMarkersMenu(newMenu);
    }, [selectedMarkers, selectedMarkersMenuRef]);

    return (
        <>
            <Button
                className={styles.pinButton}
                onClick={(event) => {
                    if (selectedMarkersMenuRef && selectedMarkersMenuRef.current && selectedMarkers.length > 0) {
                        (selectedMarkersMenuRef.current as any).toggle(event);
                    }
                }}
                aria-controls="selected_markers_menu"
                aria-haspopup
            >
                <PinIcon
                    fill={selectedMarkers.length > 0 ? 'var(--ds-main-blue)' : 'var(--ds-main-light-gray)'}
                    stroke={selectedMarkers.length > 0 ? 'var(--ds-main-blue)' : 'var(--ds-main-light-gray)'}
                />
                {selectedMarkers.length > 0 && <div className={styles.pinButtonCount}>{selectedMarkers.length}</div>}
            </Button>
            <Menu
                id="selected_markers_menu"
                ref={selectedMarkersMenuRef}
                model={markersMenu}
                popup
                popupAlignment="right"
                pt={{
                    root: { className: styles.markerModal },
                    menu: { className: styles.menu },
                }}
            />
        </>
    );
};
