import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveSubscriptions, updateApiKey, updateSubscription } from '@libs/services';
import { UpdateSubscriptionReq } from '@libs/types/subscription.type';

export const subscriptionThunk = createAsyncThunk('user/subscriptionThunk', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getActiveSubscriptions();
        return data.data.rows;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const getSubscriptionByProductGuidThunk = createAsyncThunk(
    'user/getSubscriptionByProductGuidThunk',
    async (productGuid: string, { rejectWithValue }) => {
        try {
            const { data } = await getActiveSubscriptions([productGuid]);
            return data.data.rows.length ? data.data.rows[0] : undefined;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const updateSubscriptionThunk = createAsyncThunk(
    'user/updateSubscriptionThunk',
    async ({ subscriptionId, body }: { subscriptionId: number; body: UpdateSubscriptionReq }, { rejectWithValue }) => {
        try {
            const { data } = await updateSubscription(subscriptionId, body);
            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const updateApiKeyThunk = createAsyncThunk(
    'user/updateApiKeyThunk',
    async (subscriptionId: number, { rejectWithValue }) => {
        try {
            const { data } = await updateApiKey(subscriptionId);
            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
