import { useEffect } from 'react';
import { Footer, Navigation } from '@libs/components';
import { MainContent } from '@modules/ComplianceToolLanding/MainContent';
import { addMetaTag, removeMetaTag } from '@libs/utils';

export const ComplianceToolLanding = () => {
    useEffect(() => {
        document.title = 'Compliance Tool';
        addMetaTag(
            'title',
            'Compliance Tool - комплаенс-платформа для контроля и мониторинга финансовых транзакций на бирже',
        );
        addMetaTag(
            'description',
            'Инновационный сервис для комплаенса Compliance Tool обеспечивает строгий контроль и мониторинг финансовых транзакций, предотвращает недобросовестные операции (AML) и попытки манипуляции рынком.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <MainContent />
                </div>
            </div>
            <Footer />
        </>
    );
};
