import classNames from 'classnames';
import { type SearchObject } from '@libs/types/search.type';
import { Button } from 'primereact/button';
import { SEARCH_TYPE_NAME } from '@libs/constants';
import { useCallback } from 'react';
import { HighlightText } from '@libs/components';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

type Props = {
    item: SearchObject;
    searchValue: string;
};

export const SearchItem = ({ item, searchValue }: Props) => {
    const navigate = useNavigate();

    const highlight = useCallback(
        (str: string) => {
            const style = { color: '#ff0508' };

            return HighlightText({ filter: searchValue, str, style });
        },
        [searchValue],
    );

    const goToPage = () => {
        navigate(
            item.type.includes('ARTICLE')
                ? `/articles/${item.objectId}`
                : `/help-center/references/${item.referenceId}/article/${item.objectId}`,
        );
    };

    return (
        <div className={classNames(styles.searchItemWrapper, 'flex', 'flex-column')}>
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                <div>
                    <span>{SEARCH_TYPE_NAME[item.type[0]]}</span>
                    <h4>{item.title}</h4>
                </div>
                <p>{highlight(item.textSnippet)}</p>
            </div>
            <Button label={'Перейти'} onClick={goToPage} />
        </div>
    );
};
