import { Footer, Navigation } from '@libs/components';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useAuthorize } from '@libs/utils';
import { useEffect } from 'react';

import { Header } from './shared/components/Header';
import { YieldCurves } from './components/YieldCurves';
import { Volatility } from './components/Volatility';
import { Markers } from './components/Markers';
import { RiskRates } from './components/RiskRates';
import { IisDuplicates } from './components/IisDuplicates';
import { ReactComponent as ProPackLogo } from './shared/images/svg/ProPackLogo.svg';

export const ProPack = () => {
    const [searchParams] = useSearchParams();
    const { isAuthorized, loginHandler } = useAuthorize();

    useEffect(() => {
        if (!isAuthorized) {
            loginHandler();
        }
    }, [isAuthorized]);

    if (!isAuthorized) return null;

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation
                        withUnderLine={false}
                        logo={<ProPackLogo style={{ height: '100%' }} />}
                        link="/products/propack"
                    >
                        <Header />
                    </Navigation>
                    {searchParams.get('tab') === 'volatility' && <Volatility />}
                    {searchParams.get('tab') === 'yieldCurves' && <YieldCurves />}
                    {searchParams.get('tab') === 'markers' && <Markers />}
                    {searchParams.get('tab') === 'riskRates' && <RiskRates />}
                    {searchParams.get('tab') === 'IISDuplicates' && <IisDuplicates />}
                    <Outlet />
                </div>
            </div>
            <Footer />
        </>
    );
};
