import classNames from 'classnames';
import { duplicateIISData } from '@modules/ProPackLanding/components/TariffBlock/DuplicateIISBlock/data';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';

export const DuplicateIISBlock = () => {
    const { isScreenSm } = useResize();

    return (
        <div className={classNames(styles.duplicateIISBlockWrapper)}>
            <div className={classNames(styles.titleWrapper, 'flex')}>
                <span>
                    Ежемесячно
                    <br />
                    абонентская плата
                </span>
                <span>
                    Стоимость одного запроса
                    <br />
                    *без учета НДС
                </span>
                {!isScreenSm && <span>Рекомендуем если у вас:</span>}
            </div>
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                {duplicateIISData.map((item, index) => (
                    <div className={classNames(styles.itemWrapper, 'flex', 'flex-wrap')} key={index}>
                        <p>
                            {item.monthPrice}
                            <span>₽</span>
                        </p>
                        <p>
                            {item.onePrice}
                            <span>₽</span>
                        </p>
                        <p className={styles.recommendation}>
                            {isScreenSm ? `*рекомендуем если у вас ${item.recommendation}` : item.recommendation}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};
