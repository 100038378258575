export const TABLE_DATA_MOCK = {
    accInt: 5.608219178082192,
    currencyCode: 'RUB',
    isin: 'RU000A0JWM07',
    issuer: 'Минфин России',
    methodNumber: 2,
    nominal: 1000,
    nsdCode: 'SU26219RMFS4',
    instrumentName: 'ИА ДОМ11P',
    securityType: 'Государственные',
    price: 930.05767,
    rate: 93.005767,
    rateLbound: 92.750345,
    rateUbound: 93.261189,
    type: 'bond',
    valDate: '2023-09-01',
    yield: 10.765472,
};

export const ARRMOCK = new Array(24).fill(TABLE_DATA_MOCK);

export const PREPARE_MOCK = ARRMOCK.map((item) => ({
    ...item,
    price: item.price.toFixed(4),
    rate: item.rate.toFixed(4),
    rateLbound: item.rateLbound.toFixed(4),
    rateUbound: item.rateUbound.toFixed(4),
    yield: item.yield.toFixed(4),
    accInt: item.accInt.toFixed(4),
}));

export const instumentsColumns = [
    { field: 'isin', header: 'ISIN' },
    { field: 'issuer', header: 'Эмитент' },
    { field: 'name', header: 'Инcтрумент' },
    { field: 'val_date', header: 'Дата оценки' },
    { field: 'rate', header: 'Чистая цена' },
    { field: 'rate_lbound', header: 'Нижняя граница' },
    { field: 'rate_ubound', header: 'Верхняя граница' },
    { field: 'price', header: 'Чистая цена, руб.' },
    { field: 'nominal', header: 'Текущий номинал' },
    { field: 'currency_code', header: 'Валюта' },
    { field: 'yield', header: 'Доходность' },
    { field: 'acc_int', header: 'НКД' },
    { field: 'method_number', header: 'Метод оценки' },
    { field: 'type', header: 'Методика оценки' },
];

export const peridoColumns = [
    { field: 'val_date', header: 'Дата оценки' },
    { field: 'isin', header: 'ISIN' },
    { field: 'rate', header: 'Чистая цена' },
    { field: 'rate_lbound', header: 'Нижняя граница' },
    { field: 'rate_ubound', header: 'Верхняя граница' },
    { field: 'price', header: 'Чистая цена, руб.' },
    { field: 'nominal', header: 'Текущий номинал' },
    { field: 'yield', header: 'Доходность' },
    { field: 'acc_int', header: 'НКД' },
    { field: 'method_number', header: 'Метод оценки' },
    { field: 'type', header: 'Методика оценки' },
];
