import React, { useEffect } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import { PriceCentrePermissions } from '@libs/types/subscription.type';
import { PriceCenterProductTabs } from '@modules/PriceCenterProduct/types';
import { useSearchParams } from 'react-router-dom';

import styles from './styles.module.scss';

type ApiDescriptionProps = {
    permissions: PriceCentrePermissions[];
};

export const ApiDescription = ({ permissions }: ApiDescriptionProps) => {
    const [_, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = 'Ценовой центр - Оглавление';
    }, []);

    useEffect(() => {
        if (!permissions.includes(PriceCentrePermissions.API_DOCS)) {
            setSearchParams({ tab: PriceCenterProductTabs.INTERACTIVE_MAP });
        }
    }, [permissions]);

    return (
        <div className={styles.apiDescContainer}>
            <div className={styles.tableOfContents}>
                <h3>Оглавление</h3>
                <div className={styles.blockDivider} />
                <PanelMenu
                    pt={{
                        header: () => ({
                            className: styles.header,
                        }),
                        headerAction: () => ({
                            className: styles.headerAction,
                        }),
                        toggleableContent: () => ({
                            className: styles.toggleableContent,
                        }),
                    }}
                    model={MOCK_ITEMS}
                    className="w-full"
                    multiple
                />
            </div>

            <div className={styles.content}>
                <h1 className={styles.contentH1}> Описание функционала API</h1>

                <div className="flex flex-column gap-3">
                    <h2 className={styles.contentH2}>Что такое API</h2>
                    <h3 className={styles.contentH3}>
                        API — это данные, сигналы и аналитика для алгоритмической торговли через API
                    </h3>
                </div>

                <div>
                    <p>
                        Продукт предназначен для [Алго]трейдеров и для тех, кто хочет создать и запустить свой торговый
                        алгоритм. На исторических данных ALGOPACK вы сможете тестировать стратегии и делать backtest, а
                        на онлайн данных запускать торговые стратегии
                    </p>
                </div>

                <div className={styles.listWithHeader}>
                    <h5 className={styles.contentH5}>Наборы данных</h5>
                    <ul>
                        <li>Super Candles - 5 минутные супер свечи, 50+ параметров. История с 2020 года</li>
                        <li>Mega Alerts - уведомления о рыночных аномалиях [в разработке]</li>
                        <li>Market Signals - cигналы рынка на основе торговых групп [в разработке]</li>
                        <li>Market Data - cтандартная online маркет дата: стаканы и свечи [скоро]</li>
                    </ul>
                </div>

                <h2 className={styles.contentH2}>Какие задачи решает API</h2>

                <div>
                    <p>
                        Алготрейдинг — это торговля с использованием компьютерной программы, которая следует набору
                        правил для совершения сделок. Используя технические индикаторы и метрики оценки можно
                        тестировать различные стратегии и строить торговые алгоритмы
                    </p>
                </div>

                <div id="mockImage" className="w-full flex">
                    <div className="flex-grow-1 bg-gray-400 h-20rem max-w-30rem" />
                </div>

                <div className={styles.listWithHeader}>
                    <h5 className={styles.contentH5}>Основные этапы создания торгового алгоритма:</h5>
                    <ul>
                        <li>Сбор качественных данных [решает ALGOPACK]</li>
                        <li>Генерация торговых гипотез</li>
                        <li>Тестирование стратегии на истории</li>
                        <li>Запуск стратегии [ALGOPACK предоставляет онлайн данные для торговли]</li>
                    </ul>
                </div>

                <div className={styles.listWithHeader}>
                    <h5 className={styles.contentH5}>
                        Мы упростили вам задачу, чтобы вы смогли создать свой торговый алгоритм. С помощью ALGOPACK вы
                        сможете:
                    </h5>
                    <ul>
                        <li>тестировать стратегии на самых «богатых» исторических данных</li>
                        <li>находить торговые инсайты в данных и новые возможности на рынке</li>
                        <li>вовремя узнавать о рыночных аномалиях</li>
                        <li>и торговать на сигналах ALGOPACK</li>
                    </ul>
                </div>

                <div className="flex flex-column gap-3">
                    <p>Данные AlgoPack доступны через API и Python клиент.</p>
                    <p>Исторические данные доступны с 2020 года.</p>
                </div>

                <div className={styles.blockDivider} />
            </div>
        </div>
    );
};

const MOCK_ITEMS: any[] = [
    {
        key: '0',
        label: 'Что такое API',
    },
    {
        key: '1',
        label: 'Какие задачи решает API',
        items: [
            {
                key: '0_1',
                label: 'Что такое ALGOPACK',
            },
            {
                key: '0_2',
                label: 'Какие задачи решает',
            },
            {
                key: '0_3',
                label: 'ALGOPACK: SuperCandles',
            },
            {
                key: '0_4',
                label: 'ALGOPACK: MegaAlerts',
            },
        ],
    },
    {
        key: '2',
        label: 'Описание набора данных',
    },
    {
        key: '3',
        label: 'Python библиотека',
    },
    {
        key: '4',
        label: 'Полезная информация',
    },
];
