import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export type Bond = Record<string, string | number>;

export const BondTemplate = ({ bond }: { bond: Bond }) => {
    return (
        <div className={classNames(styles.singleBondTemplate)}>
            <div>
                <p>ISIN</p>
                <p className={classNames(styles.templateSubHeader500)}>{bond.isin}</p>
            </div>
            <div>
                <p>Эмитент</p>
                <p className={classNames(styles.templateSubHeader500)}>{bond.issuer}</p>
            </div>
            <div>
                <p>Инструмент</p>
                <p className={classNames(styles.templateSubHeader300)}>{bond.name}</p>
            </div>
            <div>
                <p>Тип</p>
                <p className={classNames(styles.templateSubHeader300)}>{bond.security_type}</p>
            </div>
            <div>
                <p>Валюта номинала</p>
                <p className={classNames(styles.templateSubHeader300)}>{bond.currency_code}</p>
            </div>
        </div>
    );
};
