import { getInstrumentsData } from '@libs/services';
import { createAsyncThunk } from '@reduxjs/toolkit';

export type RequestBodyProps = {
    list: string[];
    dates: string[];
};

const getInstrumentsDataThunk = createAsyncThunk(
    'priceCenter/getInstrumentsDataThunk',
    async (data: RequestBodyProps, { rejectWithValue }) => {
        try {
            const dataResponse = await getInstrumentsData({ isin: data.list, date: data.dates });

            return {
                data: dataResponse.data,
            };
        } catch (e) {
            console.error('e', e);
            return rejectWithValue(e);
        }
    },
);

const getInstrumentsDataPerPeriodThunk = createAsyncThunk(
    'priceCenter/getInstrumentsDataPerPriodThunk',
    async (data: RequestBodyProps, { rejectWithValue }) => {
        try {
            const dataResponse = await getInstrumentsData({ isin: data.list, date: data.dates });

            return {
                data: dataResponse.data,
            };
        } catch (e) {
            console.error('e', e);
            return rejectWithValue(e);
        }
    },
);

export { getInstrumentsDataThunk, getInstrumentsDataPerPeriodThunk };
