import { createSlice } from '@reduxjs/toolkit';
import { getProductListThunk } from '../thunk/product.thunk';
import { Product } from '@libs/types';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

type IState = {
    productList: Product[];
    productGuids: Record<string, string>;
    isLoading: boolean;
};

export const initialState: IState = {
    productList: [],
    productGuids: {
        [ProductMnemoCodes.TPC_ALGO_PACK]: '',
        [ProductMnemoCodes.MOEX_DATA]: '',
        [ProductMnemoCodes.INVESTOR_PRO]: '',
        [ProductMnemoCodes.MARKET_DIVE]: '',
        [ProductMnemoCodes.COMPLIENCE_TOOL]: '',
        [ProductMnemoCodes.TPC_CENOVOI_CENTR]: '',
        [ProductMnemoCodes.TPC_PRO_PACK]: '',
        [ProductMnemoCodes.CKI]: '',
    },
    isLoading: false,
};

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProductListThunk.fulfilled, (state, action) => {
            state.productList = action.payload;
            action.payload.forEach((product) => {
                if (product.mnemoCode in state.productGuids) {
                    state.productGuids[product.mnemoCode] = product.productGuid;
                }
            });
            state.isLoading = false;
        });
        builder.addCase(getProductListThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProductListThunk.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const productReducer = productSlice.reducer;
