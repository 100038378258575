import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveSubscriptions } from '@libs/services';

const getAllSubscriptionsThunk = createAsyncThunk(
    'algopack/getAllSubscriptions',
    async (productId: string, { rejectWithValue }) => {
        const productGuids = [productId];
        try {
            const { data } = await getActiveSubscriptions(productGuids);

            return { data: data?.data?.rows, productId };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export { getAllSubscriptionsThunk };
