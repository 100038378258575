import { useQueryParams } from '@libs/utils';
import { useSearch } from '@libs/utils/hooks/useSearch';

import { SearchMainBlock } from './SearchMainBlock';
import { SearchResultsList } from './SearchResultsList';

export const SearchPage = () => {
    const { params } = useQueryParams('params');
    const searchParam = params?.search || '';
    const type = params?.type || ['ARTICLE', 'REFERENCE'];

    const {
        debouncedSearch,
        searchValue,
        setSearchValue,
        entitySearch,
        setEntitySearch,
        resultList,
        currentPage,
        setCurrentPage,
        totalPages,
    } = useSearch(10, searchParam, type);

    return (
        <>
            <SearchMainBlock value={searchValue} onChangeValue={setSearchValue} />
            <SearchResultsList
                searchValue={searchValue}
                searchType={entitySearch}
                setSearchType={setEntitySearch}
                list={resultList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                debouncedSearch={debouncedSearch}
            />
        </>
    );
};
