import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { SearchIcon } from 'primereact/icons/search';

import styles from './styles.module.scss';

type Props = {
    value: string;
    onChangeValue: (value: string) => void;
};

export const SearchMainBlock = ({ value, onChangeValue }: Props) => {
    return (
        <div className={classNames(styles.wrapper)}>
            <h1>Поиск</h1>
            <span className={classNames('p-input-icon-right')}>
                <SearchIcon className={styles.searchIcon} />
                <InputText value={value} onChange={(e) => onChangeValue(e.target.value)} maxLength={250} />
            </span>
        </div>
    );
};
