import { type ArticleListItem } from '@libs/types/articles/articleListItem.type';
import classNames from 'classnames';
import { ArticleCard } from '@modules/Articles/ArticleListPage/components/ArticlesList/ArticleCard';

import styles from './styles.module.scss';

type Props = {
    list: ArticleListItem[];
};

export const ImgArticleBlock = ({ list }: Props) => {
    return (
        <div className={classNames(styles.imgArticleBlockWrapper, 'flex', 'flex-wrap')}>
            {list.map((item, index) => (
                <ArticleCard key={item.id} item={item} isFifthBlock={index % 5 === 4} />
            ))}
        </div>
    );
};
