import classNames from 'classnames';
import { activeItems, archiveItems } from '@modules/ProPackLanding/components/TariffBlock/MarkingBlock/data';
import { useModal, useResize } from '@libs/utils';
import { useState } from 'react';
import { ModalWindow } from '@libs/components';
import { DescriptionModal } from '@modules/ProPackLanding/components/TariffBlock/MarkingBlock/DescriptionModal';
import { TariffItem } from '@modules/ProPackLanding/components/TariffBlock/TariffItem';

import styles from './styles.module.scss';

export const MarkingBlock = () => {
    const [selectedItem, setSelectedItem] = useState<{ title: string; price: string; points: string[] }>();
    const { isOpen, openModal, closeModal } = useModal();
    const { isScreenSm } = useResize();

    const openInfoModal = (selectedItem: { title: string; price: string; points: string[] }) => {
        setSelectedItem(selectedItem);
        openModal();
    };

    return (
        <>
            <div className={styles.contentWrapper}>
                <TariffItem title={'Плата за регистрацию'} price={'40 000'} points={[]} />

                <div className={styles.activeItems}>
                    <span>
                        {isScreenSm
? (
                            <>Все цены указаны без учета НДС. Ежемесячно</>
                        )
: (
                            <>
                                Ежемесячно <br />
                                *без учета НДС
                            </>
                        )}
                    </span>
                    <div className={classNames('flex', 'flex-column')}>
                        {activeItems.map((item) => (
                            <TariffItem key={item.title} {...item} openInfoModal={() => openInfoModal(item)} />
                        ))}
                    </div>
                </div>

                <div className={styles.archiveItems}>
                    <h4>Архивные данные, в рамках Публичной оферты</h4>
                    <div className={classNames('flex', 'flex-column')}>
                        {archiveItems.map((item) => (
                            <TariffItem key={item.title} {...item} />
                        ))}
                    </div>
                </div>

                <p className={styles.description}>
                    *Плата за регистрацию не взимается при изменении Клиентом состава (комбинации) Информационных
                    продуктов (блоков) в рамках услуги по предоставлению информации о маркировке финансовых
                    инструментов, если между отключением всех услуг по предоставлению информации о маркировке финансовых
                    инструментов и повторным подключением любого Информационного продукта прошло менее 5 (пяти) рабочих
                    дней.
                </p>
            </div>
            <ModalWindow
                isVisible={isOpen}
                closeModal={() => {
                    closeModal();
                    setSelectedItem(undefined);
                }}
            >
                <DescriptionModal
                    title={selectedItem ? selectedItem.title : ''}
                    points={selectedItem ? selectedItem.points : []}
                    closeModal={closeModal}
                />
            </ModalWindow>
        </>
    );
};
