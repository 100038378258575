import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { isEmpty } from 'lodash';
import { parse } from 'date-fns';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { ModalWindow } from '@libs/components';
import { PriceCentrePermissions } from '@libs/types/subscription.type';
import { useResize } from '@libs/utils';
import { ExtendedSearchMediumForm } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer/ExtendedSearchMediumForm';
import { PriceCenterMainMediumForm } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer/MainMediumForm';
import { MainSmallForm } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer/MainSmallForm';
import { getAllChartsThunk } from '@store/store/thunk/pricecenter/getAllCharts.thunk';
import { type PriceCenterFilter } from '@store/store/thunk/pricecenter/Models';
import {
    CALC_METHODS_DICT,
    ISSUER_SECTOR_DICT,
    LISTING_LEVEL_DICT,
    SECTOR_DICT,
    STATUS_DICT,
} from '@store/store/thunk/pricecenter/consts';

import { COUNTERIES_DICT, CURRENCIES_DICT, ISSUETYPES_DICT } from './constants';
import { getSelectedFiltersCount, getTradeDate } from '../utils';
import styles from './styles.module.scss';

type FiltersContainerProps = {
    permissions: PriceCentrePermissions[];
};

export const FiltersContainer = ({ permissions }: FiltersContainerProps) => {
    const { width } = useResize();
    const mobileScreen = width <= 1025;

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const charts = useSelector((state: RootState) => state.priceCenter.charts);
    const isDemoUrls = !permissions.includes(PriceCentrePermissions.REAL_POINTS);
    const dispatch = useDispatch<AppDispatch>();

    const formDefaultValues = {
        tradeDate: isDemoUrls ? parse('01.06.23', 'dd.MM.yy', new Date()) : getTradeDate(),
        methodNumber: CALC_METHODS_DICT[0],
        status: [STATUS_DICT[2]],
        listingLevel: LISTING_LEVEL_DICT[0],
        sector: SECTOR_DICT[0],
        issuerSector: ISSUER_SECTOR_DICT[0],
        country: COUNTERIES_DICT[0],
        currency: CURRENCIES_DICT[0],
        securityKind: ISSUETYPES_DICT[0],
        searchNames: [],
        dateStart: null,
        dateEnd: null,
        couponsPerYearEnd: null,
        couponsPerYearStart: null,
        couponsRateEnd: null,
        couponsRateStart: null,
        durationEnd: null,
        durationStart: null,
        guarantee: null,
        amortization: null,
        repayment: null,
        offer: null,
        indexedNominal: null,
    };

    const form = useForm<PriceCenterFilter>({ defaultValues: formDefaultValues, mode: 'onChange' });
    const {
        formState: { errors },
        reset,
    } = form;

    const handleFirstLoad = () => {
        dispatch(getAllChartsThunk({ ...formDefaultValues, isDemo: isDemoUrls }));
    };

    useEffect(() => {
        handleFirstLoad();
    }, []);

    const getExtendedFiltersCount = () => {
        const excludeFields = [
            'searchNames',
            'tradeDate',
            'methodNumber',
            'status',
            'listingLevel',
            'sector',
            'issuerSector',
            'minYield',
            'maxYield',
        ];

        return getSelectedFiltersCount(excludeFields, form);
    };

    const getMobileFiltersCount = () => {
        const excludeFields = ['tradeDate', 'methodNumber'];

        return getSelectedFiltersCount(excludeFields, form);
    };

    const handleToogleModal = (e?: React.SyntheticEvent) => {
        e?.stopPropagation();
        setModalVisible((prev) => !prev);
    };

    const handleSubmitFilters = () => {
        const fields = form.getValues();
        dispatch(getAllChartsThunk({ ...fields, isDemo: isDemoUrls }));

        if (modalVisible) handleToogleModal();
    };

    const handleClearFilters = (e: React.SyntheticEvent) => {
        e?.stopPropagation();
        reset();
        handleFirstLoad();
    };

    const getFormErrorMessage = (name: string) => {
        return (
            (errors as Record<string, string>)[name] && (
                <small className="p-error">{(errors as Record<string, any>)[name].message}</small>
            )
        );
    };

    const buttons = (
        <div className={classNames(styles.buttonsContainer)}>
            <Button
                type="button"
                label="Применить"
                className={classNames(styles.showButton)}
                onClick={handleSubmitFilters}
                disabled={!isEmpty(errors)}
            />
            <Button
                type="button"
                label="Сбросить"
                className={classNames(styles.clearButton, 'p-button-outlined')}
                onClick={handleClearFilters}
            />
        </div>
    );

    const desktopForm = (
        <>
            <PriceCenterMainMediumForm
                form={form}
                isDemo={isDemoUrls}
                isLoading={charts.isLoading}
                openModal={handleToogleModal}
                getFormErrorMessage={getFormErrorMessage}
                filtersCount={getExtendedFiltersCount()}
            />
            {buttons}
        </>
    );

    const mobileButtons = (
        <div className={classNames(styles.mobileButtons)}>
            <div className={classNames(styles.filtersButtonWrapper)}>
                <Button
                    type="button"
                    label="Фильтры"
                    className={classNames(styles.filtersButton)}
                    onClick={(e) => handleToogleModal(e)}
                />
                {getMobileFiltersCount() > 0 && <div className={classNames(styles.redDot)} />}
            </div>

            <Button
                type="button"
                label="Сбросить"
                className={classNames(styles.clearMobileButton, 'p-button-outlined')}
                onClick={(e) => handleClearFilters(e)}
            />
        </div>
    );

    return (
        <div className={classNames(styles.filtersContainer)}>
            {mobileScreen ? mobileButtons : desktopForm}
            <ModalWindow
                isVisible={modalVisible}
                closeModal={handleToogleModal}
                className={classNames(styles.filterModalHeight)}
            >
                <div className={styles.filterModal}>
                    {mobileScreen
? (
                        <MainSmallForm
                            form={form}
                            modalVisible={modalVisible}
                            isDemo={isDemoUrls}
                            isLoading={charts.isLoading}
                            getFormErrorMessage={getFormErrorMessage}
                            filtersCount={getExtendedFiltersCount()}
                        />
                    )
: (
                        <ExtendedSearchMediumForm
                            form={form}
                            isLoading={charts.isLoading}
                            getFormErrorMessage={getFormErrorMessage}
                        />
                    )}
                    {!mobileScreen && <Divider />}
                    {buttons}
                </div>
            </ModalWindow>
        </div>
    );
};
