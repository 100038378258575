import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { scrollElementByRef } from '@libs/utils';
import type { RefObjectType } from '@libs/types';

import styles from './styles.module.scss';

const menu = [
    { title: 'Обзор', navigationRef: '' },
    { title: 'Процентные ставки', navigationRef: 'interestRatesRef' },
    { title: 'Волатильность', navigationRef: 'volatilityRef' },
    { title: 'Ставки риска', navigationRef: 'riskRatesRef' },
    { title: 'Маркировка', navigationRef: 'markingFinInstrumentsRef' },
    { title: 'Дубликаты иис', navigationRef: 'duplicatesIISRef' },
    { title: 'Тарифы', navigationRef: 'tariffRef' },
];

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const NavigationBlock = ({ refs }: Props) => {
    const [selectedItem, setSelectedItem] = useState(menu[0]);
    const [isScrolling, setIsScrolling] = useState(false);

    const handleButtonClick = (item: { title: string; navigationRef: string }) => {
        setSelectedItem(item);
        setIsScrolling(true);

        if (item.navigationRef) {
            const ref = refs[item.navigationRef];

            if (ref && ref.current) {
                scrollElementByRef(ref);
            }
        } else {
            window.scrollTo(0, 0);
        }

        setTimeout(() => setIsScrolling(false), 1000);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (isScrolling) return;

            let currentSection = menu[0];
            let lastVisibleSection = null;

            for (let i = 0; i < menu.length; i++) {
                const item = menu[i];
                const ref = refs[item.navigationRef];

                if (ref && ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    const thresholdTop = i === menu.length - 1 ? 500 : 100;

                    if (rect.top <= thresholdTop) {
                        lastVisibleSection = item;
                    }

                    if (rect.top <= thresholdTop && rect.bottom >= thresholdTop) {
                        currentSection = item;
                        break;
                    }
                }
            }

            if (lastVisibleSection && lastVisibleSection !== menu[0]) {
                setSelectedItem(lastVisibleSection);
            } else {
                setSelectedItem(currentSection);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [refs, isScrolling]);

    return (
        <div className={classNames(styles.navigationBlockWrapper)}>
            <div className={classNames('flex', 'flex-nowrap')}>
                {menu.map((item) => (
                    <span
                        key={item.title}
                        onClick={() => handleButtonClick(item)}
                        className={classNames(selectedItem.title === item.title && styles.selected)}
                    >
                        {item.title}
                    </span>
                ))}
            </div>
        </div>
    );
};
