import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { EmptyProductCard } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/SubscriptionBlock/EmptyProductCard';
import { type SubscriptionData } from '@libs/types/subscription.type';
import { classNames } from 'primereact/utils';

import { SubscriptionCard } from '../SubscriptionCard';
import styles from './styles.module.scss';

type Props = {
    subscriptionList: SubscriptionData[];
};

export const SubscriptionCarousel = ({ subscriptionList }: Props) => {
    return (
        <CarouselProvider
            naturalSlideWidth={300}
            naturalSlideHeight={200}
            totalSlides={subscriptionList.length + 1}
            infinite={true}
            isIntrinsicHeight={true}
            className="w-full"
        >
            <Slider className={classNames(styles.slider)}>
                {subscriptionList.map((item, index) => (
                    <Slide className={classNames(styles.slide)} key={index} index={index}>
                        <SubscriptionCard key={index} item={item} />
                    </Slide>
                ))}
                <Slide
                    className={classNames(styles.slide)}
                    key={subscriptionList.length}
                    index={subscriptionList.length}
                >
                    <EmptyProductCard />
                </Slide>
            </Slider>
        </CarouselProvider>
    );
};
