import { Controller, type ControllerRenderProps, type UseFormReturn } from 'react-hook-form';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { type PriceCenterFilter } from '@store/store/thunk/pricecenter/Models';
import { InputNumber } from 'primereact/inputnumber';
import React from 'react';
import { useResize } from '@libs/utils';
import { ReactComponent as CalendarIcon } from '@modules/PriceCenterProduct/img/CalendarIcon.svg';
import { Divider } from 'primereact/divider';

import { COUNTERIES_DICT, CURRENCIES_DICT, COUPONTYPES_DICT, ISSUETYPES_DICT } from '../constants';
import styles from './styles.module.scss';

type PriceCenterExtendedFormProps = {
    form: UseFormReturn<PriceCenterFilter>;
    getFormErrorMessage: (name: string) => '' | JSX.Element;
    isLoading?: boolean;
};
type RadioGroupList = {
    name: string;
    value: 'guarantee' | 'amortization' | 'repayment' | 'offer' | 'indexedNominal';
};

export const ExtendedSearchMediumForm = ({ form, getFormErrorMessage }: PriceCenterExtendedFormProps) => {
    const { watch } = form;
    const { width } = useResize();

    const radioGroupList: RadioGroupList[] = [
        {
            name: 'Гарантия',
            value: 'guarantee',
        },
        {
            name: 'Амортизация',
            value: 'amortization',
        },
        {
            name: 'Выкуп эмитентом',
            value: 'repayment',
        },
        {
            name: 'Оферта',
            value: 'offer',
        },
        {
            name: 'Индексируемый номинал',
            value: 'indexedNominal',
        },
    ];

    const SelectWrapper = ({ children, title }: { children: React.ReactNode; title: string }) => {
        return (
            <div className={classNames(styles.selectWrapper)}>
                <p className={styles.inputLabel}>{title}</p>
                {children}
            </div>
        );
    };

    const isDatesDiffValid = (maxDate: Date, minDate: Date) => {
        const maxYearsDiff = 30;
        const storeYear = minDate.getFullYear();
        const maxAllowedDate = minDate.setFullYear(storeYear + maxYearsDiff);

        return maxAllowedDate > maxDate.getTime();
    };

    const handleNumberInput = (
        e: { value: number | null },
        field: { onChange: (arg: any) => void },
        maxAllowedValue: number,
    ) => {
        if (e.value || e.value === 0) {
            e.value > maxAllowedValue ? field.onChange(maxAllowedValue) : field.onChange(e.value);
        } else if (e.value === null) {
            field.onChange(e.value);
        }
    };

    const title =
        width >= 1025
? (
            <>
                <h3>Расширенный поиск</h3>
                <Divider />
            </>
        )
: null;

    const multiSelectPt = {
        header: () => ({ className: styles.multiSelectHeader }),
        filterContainer: () => ({ className: styles.multiSelectFilterInput }),
        list: () => ({ className: styles.multiSelectList }),
        item: () => ({ className: styles.multiSelectListItem }),
        itemGroup: () => ({ className: styles.multiSelectListItemGroup }),
        panel: () => ({ className: styles.multiSelectPanel }),
        closeButton: () => ({ className: styles.multiSelectCloseButton }),
        headerCheckbox: {
            root: () => ({ className: styles.rootCheckbox }),
            box: () => ({ className: styles.multiSelectHeaderCheckbox }),
        },
    };

    return (
        <>
            {title}
            <div className={classNames(styles.selectsContainer)}>
                <SelectWrapper title="Страна эмитента">
                    <Controller
                        control={form?.control}
                        name="country"
                        render={({ field }) => (
                            <Dropdown
                                value={field.value}
                                options={COUNTERIES_DICT}
                                onChange={field.onChange}
                                dataKey="code"
                                optionLabel="name"
                                placeholder="Выбрать"
                                className={classNames(styles.dropdownInput)}
                                appendTo="self"
                            />
                        )}
                    />
                </SelectWrapper>
                <SelectWrapper title="Валюта номинала">
                    <Controller
                        control={form?.control}
                        name="currency"
                        render={({ field }) => (
                            <Dropdown
                                value={field.value}
                                options={CURRENCIES_DICT}
                                onChange={field.onChange}
                                dataKey="code"
                                optionLabel="name"
                                placeholder="Выбрать"
                                className={styles.dropdownInput}
                                appendTo="self"
                            />
                        )}
                    />
                </SelectWrapper>
                <SelectWrapper title="Вид купона">
                    <Controller
                        control={form.control}
                        name="couponType"
                        render={({ field }) => (
                            <MultiSelect
                                value={field.value}
                                onChange={field.onChange}
                                options={COUPONTYPES_DICT}
                                pt={multiSelectPt}
                                dataKey="code"
                                placeholder="Выбрать"
                                optionLabel="name"
                                className={styles.blockParamSearchInput}
                                selectAllLabel="Выбрать все"
                                multiple
                                appendTo="self"
                            />
                        )}
                    />
                </SelectWrapper>
                <SelectWrapper title="Вид выпуска">
                    <Controller
                        control={form?.control}
                        name="securityKind"
                        render={({ field }) => (
                            <Dropdown
                                value={field.value}
                                options={ISSUETYPES_DICT}
                                onChange={field.onChange}
                                dataKey="code"
                                optionLabel="name"
                                placeholder="Выбрать"
                                className={styles.dropdownInput}
                                appendTo="self"
                            />
                        )}
                    />
                </SelectWrapper>
            </div>
            <div className={classNames(styles.datesContainer)}>
                <p className={styles.inputLabel}>Погашение</p>
                <div className={styles.dateRangeBlock}>
                    <Controller
                        name="dateStart"
                        control={form?.control}
                        rules={{
                            deps: ['dateEnd'],
                        }}
                        render={({ field, fieldState }) => (
                            <Calendar
                                id={field.name}
                                value={field.value}
                                onChange={(e) => {
                                    field.onChange(e.value);
                                }}
                                dateFormat="dd.mm.yy"
                                readOnlyInput
                                showIcon
                                className={classNames(styles.calendarInput, {
                                    'border-red-500 border-1': fieldState.invalid,
                                })}
                                appendTo={'self'}
                                locale="ru"
                                icon={<CalendarIcon />}
                            />
                        )}
                    />
                    <i className="pi pi-minus" />
                    <Controller
                        name="dateEnd"
                        control={form?.control}
                        rules={{
                            validate: {
                                lowerThanMax: (value) => {
                                    const min = watch('dateStart');

                                    if (value && min) {
                                        return (
                                            new Date(value).getTime() > new Date(min).getTime() ||
                                            'Дата окончания не может быть меньше даты начала периода'
                                        );
                                    }
                                },
                                allowedDIff: (value) => {
                                    const min = watch('dateStart');

                                    if (value && min) {
                                        return (
                                            isDatesDiffValid(new Date(value), new Date(min)) ||
                                            'Диапазон дат не может превышать 30 лет'
                                        );
                                    }
                                },
                            },
                            deps: ['dateStart'],
                        }}
                        render={({ field, fieldState }) => (
                            <Calendar
                                id={field.name}
                                value={field.value}
                                onChange={(e) => {
                                    field.onChange(e.value);
                                }}
                                dateFormat="dd.mm.yy"
                                className={classNames(
                                    { 'border-red-500 border-1': fieldState.invalid },
                                    styles.calendarInput,
                                )}
                                appendTo={'self'}
                                locale="ru"
                                readOnlyInput
                                showIcon
                                icon={<CalendarIcon />}
                            />
                        )}
                    />
                </div>
            </div>
            <div className={classNames(styles.numbersContainer)}>
                <div className={classNames(styles.numbersInpustWrapper)}>
                    <p className={styles.inputLabel}>Купонов в год</p>
                    <div className={classNames(styles.numbersControllersWrapper)}>
                        <Controller
                            name="couponsPerYearStart"
                            control={form.control}
                            rules={{
                                validate: {
                                    hasBothValues: (value) => {
                                        const max = watch('couponsPerYearEnd');

                                        if (value && !max) {
                                            return value === 0 || 'Введите максимальное значение купонов в год';
                                        }
                                    },
                                },
                                deps: ['couponsPerYearEnd'],
                            }}
                            render={({ field, fieldState }) => (
                                <InputNumber
                                    id={field.name}
                                    ref={field.ref}
                                    value={field.value}
                                    onChange={(e) => {
                                        handleNumberInput(e, field, 10000);
                                    }}
                                    className={classNames({ 'p-invalid': fieldState.invalid }, styles.inputBoundary)}
                                    maxLength={6}
                                    max={10000}
                                    min={0}
                                />
                            )}
                        />
                        <i className="pi pi-minus" style={{ fontSize: '8px' }} />
                        <Controller
                            name="couponsPerYearEnd"
                            control={form?.control}
                            rules={{
                                min: 0,
                                max: 10000,
                                maxLength: 5,
                                validate: {
                                    lowerThanMax: (value) => {
                                        const min = watch('couponsPerYearStart');

                                        if (value && min) {
                                            return (
                                                value > min ||
                                                'Значение купонов в год не должно быть меньше начального уровня'
                                            );
                                        }
                                    },
                                },
                                deps: ['couponsPerYearStart'],
                            }}
                            render={({ field, fieldState }) => (
                                <InputNumber
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => handleNumberInput(e, field, 10000)}
                                    className={classNames(styles.inputBoundary, { 'p-invalid': fieldState.invalid })}
                                    maxLength={3}
                                    max={10000}
                                    min={0}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className={classNames(styles.numbersInpustWrapper)}>
                    <p className={styles.inputLabel}>Ставка купона (%)</p>
                    <div className={classNames(styles.numbersControllersWrapper)}>
                        <Controller
                            name="couponsRateStart"
                            control={form.control}
                            rules={{
                                min: 0,
                                max: 100,
                                maxLength: 3,
                                validate: {
                                    hasBothValues: (value) => {
                                        const max = watch('couponsRateEnd');

                                        const noValue = !max || max === null;

                                        if (value && noValue) {
                                            return value === 0 || 'Введите максимальное значение ставки купона';
                                        }
                                    },
                                },
                                deps: ['couponsRateEnd'],
                            }}
                            render={({ field, fieldState }) => (
                                <InputNumber
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => handleNumberInput(e, field, 100)}
                                    className={classNames(styles.inputBoundary, { 'p-invalid': fieldState.invalid })}
                                    maxLength={3}
                                    max={100}
                                    min={0}
                                />
                            )}
                        />
                        <i className="pi pi-minus" style={{ fontSize: '8px' }} />
                        <Controller
                            name="couponsRateEnd"
                            control={form?.control}
                            rules={{
                                min: 0,
                                max: 100,
                                maxLength: 3,
                                validate: {
                                    lowerThanMax: (value) => {
                                        const min = watch('couponsRateStart');

                                        if (value && min) {
                                            return (
                                                value > min ||
                                                'Значение ставки купона не должно быть меньше начального уровня'
                                            );
                                        }
                                    },
                                },
                                deps: ['couponsRateStart'],
                            }}
                            render={({ field, fieldState }) => (
                                <InputNumber
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => handleNumberInput(e, field, 100)}
                                    className={classNames(styles.inputBoundary, { 'p-invalid': fieldState.invalid })}
                                    maxLength={3}
                                    max={100}
                                    min={0}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className={classNames(styles.numbersInpustWrapper)}>
                    <p className={styles.inputLabel}>Дюрация Маколея (лет)</p>
                    <div className={classNames(styles.numbersControllersWrapper)}>
                        <Controller
                            name="durationStart"
                            control={form?.control}
                            rules={{
                                min: 0,
                                max: 100,
                                maxLength: 3,
                                validate: {
                                    hasBothValues: (value) => {
                                        const max = watch('durationEnd');

                                        if (value && !max) {
                                            return value === 0 || 'Введите максимальное значение дюрации Маколея';
                                        }
                                    },
                                },
                                deps: ['durationEnd'],
                            }}
                            render={({ field, fieldState }) => (
                                <InputNumber
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => handleNumberInput(e, field, 100)}
                                    className={classNames(styles.inputBoundary, { 'p-invalid': fieldState.invalid })}
                                    maxLength={3}
                                    max={100}
                                    min={0}
                                />
                            )}
                        />
                        <i className="pi pi-minus" style={{ fontSize: '8px' }} />
                        <Controller
                            name="durationEnd"
                            control={form?.control}
                            rules={{
                                min: 0,
                                maxLength: 3,
                                validate: {
                                    lowerThanMax: (value) => {
                                        const min = watch('durationStart');

                                        if (value && min) {
                                            return (
                                                value > min ||
                                                'Значение дюрации Маколея не должно быть меньше начального уровня'
                                            );
                                        }
                                    },
                                },
                                deps: ['durationStart'],
                            }}
                            render={({ field, fieldState }) => (
                                <InputNumber
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => handleNumberInput(e, field, 100)}
                                    className={classNames(styles.inputBoundary, { 'p-invalid': fieldState.invalid })}
                                    maxLength={3}
                                    min={0}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className={classNames(styles.radioButtonsSection)}>
                {radioGroupList.map((radioGroup) => {
                    const { name, value } = radioGroup;

                    return (
                        <div key={`radioGroup-${name}`} className={classNames(styles.radioButtonGroupContainer)}>
                            <p className={styles.inputLabel}>{name}</p>
                            <Controller
                                control={form.control}
                                name={value}
                                render={({ field }) => <RadioGroup field={field} name={name} />}
                            />
                        </div>
                    );
                })}
            </div>
            {getFormErrorMessage('couponsPerYearStart')}
            {getFormErrorMessage('couponsPerYearEnd')}
            {getFormErrorMessage('couponsRateStart')}
            {getFormErrorMessage('couponsRateEnd')}
            {getFormErrorMessage('durationStart')}
            {getFormErrorMessage('durationEnd')}
            {getFormErrorMessage('dateStart')}
            {getFormErrorMessage('dateEnd')}
        </>
    );
};

const RadioGroup = ({ field, name }: { field: ControllerRenderProps<any, any>; name: string }) => {
    const buttonTypes = [
        {
            id: 'radio1' + name,
            value: true,
            name: 'Есть',
        },
        {
            id: 'radio2' + name,
            value: false,
            name: 'Нет',
        },
        {
            id: 'radio3' + name,
            value: null,
            name: 'Неважно',
        },
    ];

    return (
        <div className={classNames(styles.radioButtonGroup)}>
            {buttonTypes.map((button) => {
                const { id, value, name } = button;

                return (
                    <div key={button.id} className={classNames(styles.radioButton)}>
                        <RadioButton
                            inputId={id}
                            name={name}
                            value={value}
                            onChange={field.onChange}
                            checked={field.value === value}
                            className={classNames(styles.radioButtonInput)}
                            pt={{
                                box: {
                                    className: styles.checkBox,
                                },

                                input: {
                                    className: styles.checkBoxInput,
                                },
                            }}
                        />
                        <label htmlFor={id} className={classNames(styles.inputLabel)}>
                            {name}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};
