import { MainItem } from "@modules/ProPack/shared/components/LineItem";
import markingImage1 from './images/marking_1.png';
import markingImage2 from './images/marking_2.png';
import markingImage3 from './images/marking_3.png';
import markingImage4 from './images/marking_4.png';

export const items: MainItem[] = [
  {
    text: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту',
    imageUrl: markingImage1,
    subItems: [
      {
        text: 'Проверка доступности ценной бумаги для покупки:',
      },
      {
        li: 'Для квалифицированных инвесторов',
      },
      {
        li: 'Для неквалифицированных инвесторов'
      },
      {
        subItems: [
          { li: 'Тестирование не требуется' },
          { li: 'Требуется тестирование, номер теста' },
        ],
      },
    ],
  },
  {
    text: 'Маркировка «Дополнительные ограничения на иностранные ценные бумаги»',
    imageUrl: markingImage2,
    subItems: [
      {
        text: 'Иностранная ценная бумага доступна для покупки',
      },
      {
        li: 'Для квалифицированных инвесторов',
      },
      {
        li: 'Для неквалифицированных инвесторов'
      },
      {
        subItems: [
          { li: 'Рекомендовано проинформировать о рисках' },
          { li: 'Дополнительное информирование не требуется' },
        ],
      },
    ],
  },
  {
    text: 'Маркировка «Дополнительные ограничения на российские ценные бумаги для иностранцев»',
    imageUrl: markingImage3,
    subItems: [
      {
        text: 'Российская ценная бумага, приобретение которой запрещено нерезидентам из недружественных юрисдикций (Указ Президента Российской Федерации № 520 от 05.08.2022).'
      },
      {
        text: 'Основание для включения в перечень: стратегическое предприятие, пользователь недр и т.п.',
      },
    ],
  },
  {
    text: 'Маркировка «Бумаги, доступные для приобретения на ИИС»',
    imageUrl: markingImage4,
    subItems: [
      {
        text: 'Распоряжение Правительства Российской Федерации от 22 января 2024 г. N 104-р',
      },
      {
        li: 'Ценная бумага доступна для покупки на ИИС',
      },
      {
        li: 'Ценная бумага недоступна для покупки на ИИС',
      },
    ]
  },
];

export const blocks = [
  {
    update: 'Обновления ежедневно в 01:30',
    text: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту, торгуемых на Московской Бирже',
    accessURL: 'https://iss.moex.com/iss/statistics/markup/complex/moex/basic',
    exampleURL: '/download/markers/Markirovka_fin_instrumentov_po_39-FZ_i_Bazovomu_standartu_torguemykh_na_Moex.csv',
  },
  {
    update: 'Обновления ежедневно в 01:30',
    text: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту, торгуемых на Московской Бирже, и причины выбора маркировки',
    accessURL: 'https://iss.moex.com/iss/statistics/markup/complex/moex/detailed',
    exampleURL: '/download/markers/Markirovka_fin_instr_po_39-FZ_i_Baz_stand_torguemykh_na_Moex_srez_na_seg_i_zavtra.csv',
  },
  {
    update: 'Обновления ежедневно в 00:45',
    text: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту, расширенный перечень инструментов (представление включает более 40 тыс. записей)',
    accessURL: 'https://iss.moex.com/iss/statistics/markup/complex/all/basic',
    exampleURL: '/download/markers/Markirovka_fin_instrumentov_po_39-FZ_i_Baz_stand_rasshirennyy_perechen.csv',
  },
  {
    update: 'Обновления ежедневно в 00:45',
    text: 'Маркировка финансовых инструментов по 39-ФЗ и Базовому стандарту, расширенный перечень инструментов (представление включает более 40 тыс. записей) и причины выбора маркировки',
    accessURL: 'https://iss.moex.com/iss/statistics/markup/complex/all/detailed',
    exampleURL: '/download/markers/Markirovka_fin_instrumentov_po_39-FZ_i_Baz_stand_rasshirennyy_perechen.csv'
  },
  {
    update: 'Обновления ежедневно в 00:45',
    text: 'Маркировка «Дополнительные ограничения на иностранные ценные бумаги»',
    accessURL: 'https://iss.moex.com/iss/statistics/markup/hostile/all/basic',
    exampleURL: '/download/markers/Markirovka_dop_ogranicheniya_na_inostrannye_tsen_bumagi.csv',
  },
  {
    update: 'Обновления ежедневно в 00:45',
    text: 'Маркировка «Дополнительные ограничения на российские ценные бумаги для иностранцев»',
    accessURL: 'https://iss.moex.com/iss/statistics/markup/stratrus/all/basic',
    exampleURL: '/download/markers/Markirovka_dop_ogranicheniya_na_rossiyskie_tsen_bumagi_dlya_inostrantsev.csv'
  },
  {
    update: 'Обновления ежедневно в 01:30',
    text: 'Маркировка "Ценные бумаги, доступные для покупки на ИИС", торгуемых на Московской Бирже',
    accessURL: 'http://iss.moex.com/iss/statistics/markup/iia/moex/basic',
    exampleURL: '/download/markers/Markirovka_tsennykh_bumag_dlya_pokupki_na_IIS_torguemykh_na_Moex.csv',
  },
  {
    update: 'Обновления ежедневно в 00:45',
    text: 'Маркировка "Ценные бумаги, доступные для покупки на ИИС", расширенный перечень инструментов',
    accessURL: 'https://iss.moex.com/iss/statistics/markup/iia/all/basic',
    exampleURL: '/download/markers/Markirovka_tsennykh_bumag_dlya_pokupki_na_IIS_rasshirennyy_perechen.csv',
  },
];
