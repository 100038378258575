import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
    icon: string;
    title: string;
    description: string;
};

export const InstrumentItem = ({ icon, title, description }: Props) => {
    return (
        <div className={classNames(styles.instrumentItemWrapper, 'flex', 'flex-column')}>
            <img src={icon} />
            <div className={classNames('flex', 'flex-column')}>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
};
