import classNames from 'classnames';
import { Button } from 'primereact/button';

import styles from './styles.module.scss';

type Props = {
    title: string;
    points: string[];
    closeModal: () => void;
};

export const DescriptionModal = ({ title, points, closeModal }: Props) => {
    return (
        <div className={classNames(styles.descriptionModalWrapper, 'flex', 'flex-column')}>
            <h2>{title}</h2>
            <ul>
                {points.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
            <Button label={'Закрыть'} onClick={closeModal} />
        </div>
    );
};
