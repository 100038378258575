import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import classNames from 'classnames';
import { endOfToday, format } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import {
    getIsinsGroupedByEmitter,
    getIsinsVirtualGroupedByEmitter,
    getTradeDate,
} from '@modules/PriceCenterProduct/InteractiveMap/utils';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { getInstrumentsDataThunk } from '@store/store/thunk/pricecenter/getInstrumentsData.thunk';
import { Button } from 'primereact/button';
import { type Bond, type Emitter } from '@modules/PriceCenterProduct/types';
import { ReactComponent as CalendarIcon } from '@modules/PriceCenterProduct/img/CalendarIcon.svg';
import { setDataUploadError, setDataUploadSelectedBonds } from '@store/store/slices/pricecenter.slice';

import styles from '../../styles.module.scss';
import { ControlsPanel } from '../ControlsPanel';
import { handleFileDownload } from '../../utils';

const schema = yup.object().shape({
    dateStart: yup.date().required('Необходимо указать дату'),
    isinList: yup.array().of(yup.string()).required('Необходимо выбрать инструмент'),
});

export const InstrumentsForm = ({ bonds, emitters }: { bonds: Bond[]; emitters: Emitter[] }) => {
    const [downloadError, setDonwloadError] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const {
        formState: { errors },
        handleSubmit,
        reset,
        watch,
        setValue,
        control,
    } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const { dateStart, isinList } = watch();

    const onSubmit = (data: any) => {
        const { dateStart, isinList } = data;

        dispatch(
            getInstrumentsDataThunk({
                list: isinList,
                dates: [format(dateStart, 'yyyy-MM-dd')],
            }),
        ).then((res) => {
            if ((res as any).payload.data.data.length === 0) {
                dispatch(setDataUploadError('NO_DATA'));
            }
        });
    };

    const opts = useMemo(() => {
        return getIsinsVirtualGroupedByEmitter(bonds, emitters);
    }, [bonds, emitters]);

    const handleUnselectBond = (isin: string) => {
        const newIsinList = isinList?.filter((i: any) => i !== isin);

        newIsinList?.length === 0 ? reset() : setValue('isinList', newIsinList);
    };

    const handleDownloadCSV = async () => {
        if (!isinList) {
            setDonwloadError('Необходимо выбрать инструменты');

            return;
        }

        await handleFileDownload(isinList, [format(dateStart, 'yyyy-MM-dd')], 'csv', 'instrument_list_day').catch(
            (err) => setDonwloadError(err.message),
        );
    };

    const handleDownloadXLS = async () => {
        if (!isinList) {
            setDonwloadError('Необходимо выбрать инструменты');

            return;
        }
        await handleFileDownload(isinList, [format(dateStart, 'yyyy-MM-dd')], 'xls', 'instrument_list_day').catch(
            (err) => setDonwloadError(err.message),
        );
    };

    const handleResetForm = () => {
        reset();
        setDonwloadError(null);
        dispatch(setDataUploadSelectedBonds([]));
    };

    const getBondsNames = bonds?.filter((bond: any) => isinList?.includes(bond.isin));
    const bondsTemplates = isinList && (
        <div className="flex align-items-center flex-wrap gap-2 mt-4 ">
            {getBondsNames?.map((item: any) => {
                return (
                    <Button
                        key={item?.name}
                        type="button"
                        onClick={() => handleUnselectBond(item.isin)}
                        className={classNames(styles.selectedItem)}
                    >
                        <span>{item?.name}</span>
                        <i className="pi pi-times" />
                    </Button>
                );
            })}
        </div>
    );

    const getFormErrorMessage = (name: string) => {
        return (
            (errors as Record<string, string>)[name] && (
                <small className="p-error">{(errors as Record<string, any>)[name].message}</small>
            )
        );
    };

    const itemTemplate = (option: any) => {
        if (option.label.startsWith('Group-')) {
            return (
                <div className={classNames('flex', styles.dropdownGroup)}>
                    <p className={classNames(styles.dropdownGroupItem)}>{option.label.slice(6)}</p>
                    <div className={styles.divider} />
                </div>
            );
        } else {
            return <p className={classNames('flex', styles.dropdownItem)}>{option.label}</p>;
        }
    };

    return (
        <form action="post" onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className={styles.mainBlock}>
                <div className={classNames(styles.instrumentsSelectWrapper)}>
                    <p>Выбор инструментов</p>
                    <div className="flex align-items-center gap-3">
                        <Controller
                            control={control}
                            name="isinList"
                            render={({ field, fieldState }) => (
                                <MultiSelect
                                    value={field.value}
                                    id={field?.name}
                                    options={opts}
                                    onChange={(e) => {
                                        field.onChange(e);

                                        if (e.value.length === 0) {
                                            reset();
                                        }
                                        setDonwloadError(null);
                                    }}
                                    focusOnHover={false}
                                    autoOptionFocus={false}
                                    optionLabel="label"
                                    optionGroupTemplate={itemTemplate}
                                    placeholder="ISIN, название инструмента"
                                    className={classNames(styles.isinBlockParamSearchInput, {
                                        'border-red-500': fieldState.invalid || downloadError,
                                    })}
                                    filter
                                    filterBy="label,value"
                                    pt={multiSelectPt}
                                    showSelectAll={false}
                                    emptyMessage="Ничего не найдено"
                                    emptyFilterMessage="Ничего не найдено"
                                    appendTo={'self'}
                                    virtualScrollerOptions={{ itemSize: 25, step: 1, autoSize: true }}
                                    itemTemplate={itemTemplate}
                                />
                            )}
                        />
                    </div>
                    {downloadError && <small className="p-error">Ошибка при скачивании: {downloadError}</small>}
                </div>

                <div className={classNames(styles.dateBlock)}>
                    <p>Данные за дату</p>
                    <div className="flex align-items-center gap-2">
                        <Controller
                            name="dateStart"
                            control={control}
                            defaultValue={getTradeDate()}
                            render={({ field }) => (
                                <Calendar
                                    id={field?.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    dateFormat="dd.mm.yy"
                                    readOnlyInput
                                    showIcon
                                    className={styles.calendarInput}
                                    appendTo={'self'}
                                    locale="ru"
                                    pt={{
                                        header: { className: styles.calendarHeader },
                                    }}
                                    maxDate={endOfToday()}
                                    icon={<CalendarIcon />}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            {bondsTemplates}

            <ControlsPanel
                errors={errors}
                callbacks={{ handleDownloadCSV, handleDownloadXLS, handleUnselectBond, handleResetForm }}
            />

            {getFormErrorMessage('isinList')}
        </form>
    );
};

const multiSelectPt = {
    header: () => ({ className: styles.multiSelectHeader }),
    filterContainer: () => ({ className: styles.multiSelectFilterInput }),
    list: () => ({ className: styles.multiSelectList, style: { paddingLeft: '10px' } }),
    item: () => ({ className: styles.multiSelectListItem }),
    itemGroup: () => ({ className: styles.multiSelectListItemGroup }),
    panel: () => ({ className: styles.multiSelectPanel }),
    closeButton: () => ({ className: styles.multiSelectCloseButton }),
};
