import { Button } from 'primereact/button';
import { ReactComponent as PlayBlackIcon } from '@shared/images/svg/playWhite.svg';
import { Link, useLocation } from 'react-router-dom';
import { useAuthorize } from '@libs/utils';

import styles from './styles.module.scss';

export const TrialVersion = () => {
    const location = useLocation();
    const { isAuthorized, loginHandler } = useAuthorize();

    return (
        <section className={styles.trialVersionWrapper}>
            <div className={styles.contentWrapper}>
                <h2>Попробуйте пробную версию Pro pack</h2>
                {isAuthorized
? (
                    <Link to={location.pathname + '/examples?tab=volatility'}>
                        <PlayBlackIcon />
                        <span>Пробная версия</span>
                    </Link>
                )
: (
                    <Button label={'Пробная версия'} icon={<PlayBlackIcon />} onClick={() => loginHandler()} />
                )}
            </div>
        </section>
    );
};
