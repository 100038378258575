import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { type IFormData, schema } from './schema';
import { sendAccessRequest } from '../../services/accessRequest.service';
import styles from './styles.module.scss';

type ContactRequestFormProps = {
    onSubmit: () => void;
    productGuid?: string;
};

export const ContactRequestForm = React.memo(({ onSubmit, productGuid }: ContactRequestFormProps) => {
    const [isRequestSending, setRequestSending] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        watch,
        trigger,
        formState: { errors },
    } = useForm<IFormData>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const showErrorBlock = useCallback(() => {
        return !!(errors.last_name || errors.first_name || errors.company || errors.user_email || errors.phone_number);
    }, [errors]);

    const { last_name, first_name, company, user_email, phone_number } = watch();

    const isInvalidData = useCallback(() => {
        return !last_name || !first_name || !company || !user_email || !phone_number || showErrorBlock();
    }, [last_name, first_name, company, user_email, phone_number]);

    const onFormSubmit = useCallback(
        async (data: IFormData) => {
            try {
                setRequestSending(true);
                const telephone = `+${data.phone_number.replace(/\D/g, '')}`;
                const resp = await sendAccessRequest({
                    ...data,
                    phone_number: telephone,
                    product_guid: productGuid!,
                });

                if (resp.data.data) {
                    onSubmit();
                }
            } catch (e) {
                console.error('e', e);
            } finally {
                reset();
                setRequestSending(false);
            }
        },
        [onSubmit, reset],
    );

    return (
        <section className={classNames(styles.contactRequestForm, 'flex flex-column')}>
            <h3>Отправить заявку</h3>

            {showErrorBlock() && (
                <div className={classNames(styles.errorBlock, 'flex', 'flex-column')}>
                    {errors.last_name && <p>Фамилия: {errors.last_name.message}</p>}
                    {errors.first_name && <p>Имя: {errors.first_name.message}</p>}
                    {errors.company && <p>Название компании: {errors.company.message}</p>}
                    {errors.user_email && <p>Email: {errors.user_email.message}</p>}
                    {errors.phone_number && <p>Телефон: {errors.phone_number.message}</p>}
                </div>
            )}

            <form className={classNames(styles.form, 'flex', 'flex-column')} onSubmit={handleSubmit(onFormSubmit)}>
                <div className={styles.formFields}>
                    <Controller
                        control={control}
                        defaultValue=""
                        name="last_name"
                        render={({ field }) => (
                            <InputText
                                id="last_name"
                                placeholder={'Фамилия'}
                                className={classNames(styles.input, errors.last_name && styles.redBorder)}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue=""
                        name="first_name"
                        render={({ field }) => (
                            <InputText
                                id="first_name"
                                placeholder={'Имя'}
                                className={classNames(styles.input, errors.first_name && styles.redBorder)}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue=""
                        name="company"
                        render={({ field }) => (
                            <InputText
                                {...field}
                                id="company"
                                placeholder={'Название компании'}
                                className={classNames(styles.input, errors.company && styles.redBorder)}
                                onBlur={() => {
                                    field.onChange(field.value.trim());
                                    trigger(['company']);
                                }}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue=""
                        name={'user_email'}
                        render={({ field }) => (
                            <InputText
                                id="user_email"
                                placeholder={'Email'}
                                className={classNames(styles.input, errors.user_email && styles.redBorder)}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue=""
                        name={'phone_number'}
                        render={({ field }) => (
                            <InputMask
                                id="phone_number"
                                placeholder={'Телефон'}
                                mask="+7 (999) 999-99-99"
                                className={classNames(styles.input, errors.phone_number && styles.redBorder)}
                                {...field}
                            />
                        )}
                    />
                </div>

                <p>
                    Нажимая кнопку «Отправить», Вы соглашаетесь на обработку персональных данных в соответствии с{' '}
                    <a
                        href={
                            'https://finuslugi.ru/disclosure/politika_konfidencialnosti?_gl=1*16lrmb2*_ga*NTMwMDk1ODE1LjE3MTYyMTEwNzU.*_ga_Z0GRHZ4BLP*MTcxNjU0NDY1Ny42LjAuMTcxNjU0NDY2NC41My4wLjA'
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Политикой обработки персональных данных
                    </a>
                </p>

                <Button
                    className={styles.submitBtn}
                    type={'submit'}
                    label={'Отправить'}
                    disabled={isInvalidData() || isRequestSending}
                />
            </form>
        </section>
    );
});
