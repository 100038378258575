import { ModalWindow } from '@libs/components';
import React from 'react';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { ReactComponent as ArrowLeftGrey } from '@shared/images/svg/ArrowLeftGrey.svg';

import styles from './styles.module.scss';

type ConfirmModalProp = {
    children: React.ReactNode;
    header: string;
    confirmButtonText: string;
    onConfirm: any;
    onClose: () => void;
    isVisible: boolean;
    className?: string;
    isConfirmButtonDisable?: boolean;
    isCloseButtonDisable?: boolean;
    withBackButton?: boolean;
    onClickBackButton?: () => void;
    backButtonName?: string;
};

export const ConfirmModal = ({
    children,
    header,
    onClose,
    isVisible,
    confirmButtonText,
    onConfirm,
    className,
    isConfirmButtonDisable,
    isCloseButtonDisable,
    withBackButton,
    onClickBackButton,
    backButtonName,
}: ConfirmModalProp) => {
    return (
        <ModalWindow className={className} closeModal={isCloseButtonDisable ? () => {} : onClose} isVisible={isVisible}>
            <div className={classNames(styles.confirmContainer)}>
                {withBackButton && (
                    <div onClick={onClickBackButton} className={classNames(styles.backButtonContainer, 'flex')}>
                        <ArrowLeftGrey />
                        <span>Назад</span>
                    </div>
                )}
                <h2>{header}</h2>
                {children}
                <div className={classNames('flex', styles.buttonContainer)}>
                    <Button onClick={onConfirm} disabled={isConfirmButtonDisable} label={confirmButtonText} />
                    <Button onClick={onClose} disabled={isCloseButtonDisable} label={backButtonName || 'Отменить'} />
                </div>
            </div>
        </ModalWindow>
    );
};
