import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import zoomPlugin from 'chartjs-plugin-zoom';
import classNames from 'classnames';
import { Slider, type SliderChangeEvent } from 'primereact/slider';
import { Chart } from 'primereact/chart';
import {
    type PriceCenterStateChartsData,
    type PriceCenterChartPoint,
    type PriceCenterBond,
    type PriceCenterChart,
    type PriceCenterValuation,
} from '@store/store/thunk/pricecenter/Models';
import { type ChartData, type ChartOptions } from 'chart.js';

import { MarketPointPanel } from '../ChartTooltips';
import styles from './styles.module.scss';
import { drawLine, drawTitles, handleChartZoom, handleDrawTooltips } from '../../utils';

type ChartVisibleLinesProps = Array<PriceCenterChart<Array<PriceCenterBond | PriceCenterValuation>>>;

type RangeChartProps = {
    groupChartCode: string;
    rawChartData: any | object;
    rawChartOptions: object | undefined;
    selectedPoints?: Array<PriceCenterChartPoint<any>>;
    unSelectPoint: (point: PriceCenterChartPoint<any>) => void;
    titles: {
        top: string;
        bottom: string;
    };
    plugins?: string;
    tossPoints?: (point: PriceCenterChartPoint<any>) => void;
};

export const RangeChart = (props: RangeChartProps) => {
    const {
        groupChartCode,
        rawChartData = {},
        rawChartOptions = {},
        selectedPoints = [],
        unSelectPoint,
        titles,
        plugins,
        tossPoints,
    } = props;

    const chartRef = useRef<Chart>(null);

    const [chartData, setChartData] = useState<ChartData | undefined>();
    const [chartOptions, setChartOptions] = useState<ChartOptions | undefined>();
    const [sliderChartData, setSliderChartData] = useState<ChartData | undefined>();

    const [hRangeValue, setHRangeValue] = useState<[number, number]>([0, 100]);
    const [vRangeValue, setVRangeValue] = useState<[number, number]>([0, 100]);

    useEffect(() => {
        const el = chartRef.current?.getElement();

        setChartData(rawChartData);
        setChartOptions(rawChartOptions);
        const newSliderChartData = {
            datasets: (rawChartData.datasets ?? [])
                .filter((set: any) => set.showLine === true)
                .map((set: any) => {
                    return {
                        ...set,
                        backgroundColor: '#D8E1EB',
                        borderColor: '#D8E1EB',
                        borderWidth: 1,
                        tension: 0.5,
                        width: '100%',
                    };
                }),
            labels: [],
        };
        setSliderChartData(newSliderChartData);

        const hRangeChanged = hRangeValue[0] > 0 || hRangeValue[1] < 100;
        const vRangeChanged = vRangeValue[0] > 0 || vRangeValue[1] < 100;
        const isZoomed = hRangeChanged || vRangeChanged;

        if (isZoomed && el) {
            el.style.opacity = '0';
            el.style.transition = 'none';
        }

        const timer = setTimeout(() => {
            const chartInstance = chartRef?.current?.getChart();

            if (isZoomed) {
                if (hRangeChanged) {
                    handleChartZoom({ chart: chartInstance, value: hRangeValue, axis: 'x' });
                }

                if (vRangeChanged) {
                    handleChartZoom({ chart: chartInstance, value: vRangeValue, axis: 'y' });
                }
            } else {
                chartInstance?.resetZoom();
            }

            if (el) {
                el.style.transition = 'opacity 0.5s ease';
                el.style.opacity = '1';
            }
        }, 50);

        return () => clearTimeout(timer);
    }, [rawChartData, chartRef]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const chartInstance = chartRef?.current?.getChart();

            if (chartInstance && !isEmpty(selectedPoints)) {
                handleDrawTooltips(chartInstance, selectedPoints, groupChartCode);
            }
        }, 250);

        return () => clearTimeout(timer);
    }, [hRangeValue, vRangeValue, selectedPoints, chartData]);

    const handleSliderChange = (e: SliderChangeEvent, axis: string) => {
        const inst = chartRef.current?.getChart();

        if (Array.isArray(e.value)) {
            if (e.value[0] > e.value[1] - 3) {
                return null;
            } else {
                axis === 'y' ? setVRangeValue(e.value) : setHRangeValue(e.value);
                handleChartZoom({ chart: inst, value: e.value, axis });
            }
        }
    };

    const renderStaticTooltips = selectedPoints.map((point) => {
        const id = `${groupChartCode}-tooltip-${point.code}-${point.dataPointIndex}`;

        return (
            <div key={id} id={id} className={classNames(styles.staticTooltip)}>
                <MarketPointPanel
                    tossPoints={tossPoints}
                    onClick={() => unSelectPoint(point)}
                    point={point}
                    selected={true}
                />
            </div>
        );
    });

    const renderHoverTooltip = (
        <div
            id={`${groupChartCode}-hover-tooltip`}
            key={`${groupChartCode}-hover-tooltip`}
            className={classNames(styles.hoveredTooltip)}
        />
    );

    return (
        <div className={classNames(styles.chartSection)}>
            <div id={`ds-${groupChartCode}-chart-container`} className={classNames(styles.chartContainer)}>
                {renderStaticTooltips}
                {renderHoverTooltip}

                <Chart
                    id={`ds-${groupChartCode}-chart`}
                    className={classNames(styles.chart)}
                    ref={chartRef}
                    data={chartData}
                    options={chartOptions}
                    style={{ opacity: '0' }}
                    plugins={[zoomPlugin, drawTitles(titles), ...(plugins ? [drawLine] : [])]}
                />

                <Slider
                    className={classNames(styles.verticalSliderRoot)}
                    value={vRangeValue}
                    onChange={(e) => handleSliderChange(e, 'y')}
                    orientation="vertical"
                    pt={{
                        range: {
                            className: classNames(styles.range),
                        },
                        handle: {
                            className: classNames(styles.handle),
                        },
                    }}
                    range
                />
            </div>
            <div className={styles.horizontalSliderContainer}>
                <Chart data={sliderChartData} options={sliderOptions} className={styles.chartPreview} />
                <div className={styles.sliderOverlay}>
                    <Slider
                        className={styles.sliderRoot}
                        value={hRangeValue}
                        onChange={(e) => handleSliderChange(e, 'x')}
                        pt={{
                            range: {
                                className: classNames(styles.range),
                            },
                            handle: {
                                className: classNames(styles.handle),
                            },
                        }}
                        range
                        max={100}
                        min={0}
                    />
                </div>
            </div>
        </div>
    );
};

const sliderOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
        },
    },
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        },
    },
};
