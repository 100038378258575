import { Button } from 'primereact/button';
import classNames from 'classnames';
import { ReactComponent as CheckMarkIcon } from '@shared/images/svg/checkMarkWhite.svg';

import styles from './styles.module.scss';

type Props = {
    price?: number | string;
    discountCost: number | string | null;
    discountEndDate: string | null;
    discountPercent: number | null;
    onClick: () => void;
    isActive?: boolean;
    disabledBtn?: boolean;
};

export const PriceFooter = ({
    price,
    discountCost,
    discountEndDate,
    discountPercent,
    onClick,
    isActive = false,
    disabledBtn = false,
}: Props) => {
    const priceBlock = discountPercent
? (
        <>
            <div className={styles.discountPriceWrapper}>
                <p className={classNames(styles.oldPriceWrapper, 'flex')}>
                    {price}
                    <span>₽</span>
                </p>
                <p className={classNames(styles.priceWrapper, 'flex')}>
                    {discountCost}
                    <span>₽</span>
                </p>
            </div>
            <div className={classNames(styles.discountWrapper, 'flex', 'align-items-baseline')}>
                скидка <span>{discountPercent}%</span>
            </div>
        </>
    )
: (
        <p className={classNames(styles.priceWrapper, 'flex')}>
            {price || '0'}
            <span>₽</span>
        </p>
    );

    return (
        <div className={classNames(styles.priceFooterWrapper, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'justify-content-between')}>{!isActive && priceBlock}</div>

            {isActive
? (
                <div className={classNames(styles.active)}>
                    <CheckMarkIcon />
                    <span>Ваш тариф</span>
                </div>
            )
: (
                <Button
                    label={'Оформить подписку'}
                    onClick={onClick}
                    className={classNames('flex', 'justify-content-center', 'align-items-center')}
                    disabled={disabledBtn}
                />
            )}

            {!!discountPercent && !isActive && discountEndDate && (
                <span className={styles.discountDateWrapper}>*акция действует до {discountEndDate}</span>
            )}
        </div>
    );
};
