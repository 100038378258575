import { Card } from 'primereact/card';
import styles from '@libs/components/pageBlocks/Navigation/Notifications/components/NotificationCard/styles.module.scss';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react';
import { type Notification } from '@libs/types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '@store/store';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { addNotificationImage, addReadNotification } from '@store/store/slices/notifications.slice';
import {
    getFormatedDate,
    getReadNotificationFromLocalStorage,
} from '@libs/components/pageBlocks/Navigation/Notifications/utils';
import { useInView } from 'react-intersection-observer';
import { Skeleton } from 'primereact/skeleton';
import { useAuthorize } from '@libs/utils';

type NotificationCardProps = {
    notification: Notification;
    notificationImages: Record<string, string>;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onCardClick: (arg: Notification) => void;
    readNotifications: number[];
};

export const NotificationCard = ({
    notification,
    setIsVisible,
    onCardClick,
    notificationImages,
    readNotifications,
}: NotificationCardProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { isAuthorized } = useAuthorize();
    const notificationIds = getReadNotificationFromLocalStorage();
    const hasModal = notification.modalText ?? notification.modalTitle;
    const isRead = isAuthorized
        ? notification?.isRead || readNotifications.includes(notification.id)
        : notificationIds.includes(notification.id);
    const [loading, setLoading] = useState<boolean>(false);

    const onElementInView = (inView: boolean) => {
        if (inView && !isRead) {
            dispatch(addReadNotification(notification.id));
        }
    };

    const { ref } = useInView({
        onChange: onElementInView,
        skip: isRead,
    });

    const getImage = () => {
        try {
            const { image } = notification;

            if (image && !notificationImages[notification.id]) {
                setLoading(true);

                dispatch(
                    downloadFileThunk({
                        bucket: image.bucket,
                        ticket: image.fileId,
                    }),
                )
                    .then((resp) => {
                        const result = unwrapResult(resp);

                        dispatch(
                            addNotificationImage({
                                notificationId: notification.id,
                                image: result,
                            }),
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => setLoading(false));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const isNotificationRead = isAuthorized ? !notification.isRead : !notificationIds.includes(notification.id);

    useEffect(() => {
        getImage();
    }, [notification]);

    const image = () => {
        if (loading) {
            return (
                <div className={styles.extraContent}>
                    <Skeleton width="100%" height="150px" />
                </div>
            );
        }

        if (notificationImages[notification.id]) {
            return (
                <div className={styles.imageWrapper}>
                    <img className={styles.image} src={notificationImages[notification.id]} alt={notification.title} />
                </div>
            );
        }
    };

    return (
        <Card
            unstyled
            onClick={() => {
                if (hasModal) {
                    onCardClick(notification);
                    setIsVisible(true);
                }
            }}
            className={hasModal ? styles.clickableCard : undefined}
        >
            <div ref={isNotificationRead ? ref : null} className={styles.card}>
                <div className={styles.dotWrapper}>
                    <div
                        className={classNames(styles.dot, {
                            [styles.dotActive]: isAuthorized
                                ? !notification?.isRead
                                : !notificationIds.includes(notification.id),
                        })}
                    />
                </div>
                <div className={styles.cardContent}>
                    <p>{notification?.title}</p>

                    {image()}

                    {notification?.buttonText && notification?.buttonUrl
? (
                        <div className={styles.extraContent}>
                            <Link
                                className={styles.buttonLink}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                to={notification.buttonUrl}
                                target={'_blank'}
                            >
                                <Button className={styles.button} severity="danger">
                                    {notification.buttonText}
                                </Button>
                            </Link>
                        </div>
                    )
: null}

                    <div className={styles.cardFooter}>
                        <p className={styles.smallText}>{getFormatedDate(notification.publicationDate)}</p>
                        {notification?.isUpdated && <p className={styles.smallText}>Отредактированно</p>}
                    </div>
                </div>
            </div>
        </Card>
    );
};
