import React from 'react';
import { ReactComponent as ArrowSvg } from '@shared/images/helpcenter/svg/arrow.svg';
import classNames from 'classnames';

import styles from './styles.module.scss';

export type ProductCardProps = {
    title: string;
    icon: any;
    titleLink: string;
    questions: Array<{ url: string; text: string }>;
    link: string;
};

export const ProductCard = ({ title, icon, questions, titleLink, link }: ProductCardProps) => {
    return (
        <article className={classNames(styles.wrapper)}>
            <a className={classNames(styles.titleLink, 'flex', 'align-items-end')} href={titleLink}>
                <img src={icon} />
            </a>
            <div className={classNames(styles.questionsWrapper)}>
                {questions.map(({ url, text }, index) => (
                    <a key={index} href={url} className={classNames('flex', 'align-items-top')}>
                        <ArrowSvg />
                        <span>{text}</span>
                    </a>
                ))}
            </div>
            <a className={classNames(styles.questionLink)} href={link}>
                Справка по {title}
            </a>
        </article>
    );
};
