import { Node, mergeAttributes, type NodeViewProps } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import React, { useState } from 'react';

import styles from './BigCarousel.module.scss';
import carousel from '../Carousel/styles.module.scss';

type BigCarouselComponentProps = {
    node: {
        attrs: {
            images: string[];
            texts: string[][];
            position: 'left' | 'center' | 'right';
            width: number;
            height: number;
        };
    };
};

export const BigCarousel = Node.create({
    name: 'bigCarousel',
    group: 'block',
    content: 'block*',
    selectable: true,
    draggable: true,

    addAttributes() {
        return {
            images: {
                default: [],
                parseHTML: (element: HTMLElement) => JSON.parse(element.getAttribute('data-images') || '[]'),
                renderHTML: (attributes: { images: string[] }) => {
                    return {
                        'data-images': JSON.stringify(attributes.images),
                    };
                },
            },
            texts: {
                default: [],
                parseHTML: (element: HTMLElement) => JSON.parse(element.getAttribute('data-texts') || '[]'),
                renderHTML: (attributes: { texts: string[][] }) => {
                    return {
                        'data-texts': JSON.stringify(attributes.texts),
                    };
                },
            },
            position: {
                default: 'center',
                parseHTML: (element: HTMLElement) => element.getAttribute('data-position') || 'center',
                renderHTML: (attributes: { position: 'left' | 'center' | 'right' }) => {
                    return {
                        'data-position': attributes.position,
                    };
                },
            },
            width: {
                default: 60,
                parseHTML: (element: HTMLElement) => Number(element.getAttribute('data-width')) || 60,
                renderHTML: (attributes: { width: number }) => {
                    return {
                        'data-width': attributes.width,
                    };
                },
            },
            height: {
                default: 30,
                parseHTML: (element: HTMLElement) => Number(element.getAttribute('data-height')) || 30,
                renderHTML: (attributes: { height: number }) => {
                    return {
                        'data-height': attributes.height,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-big-carousel]',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(HTMLAttributes, { 'data-big-carousel': '' }), 0];
    },

    addNodeView() {
        return ReactNodeViewRenderer(BigCarouselComponent);
    },
});

const BigCarouselComponent: React.FC<NodeViewProps> = ({ node }) => {
    const { images } = node.attrs;
    const { texts } = node.attrs;
    const { position } = node.attrs;
    const { width } = node.attrs;
    const { height } = node.attrs;
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <NodeViewWrapper
            className={styles.big_carousel}
            contentEditable={false}
            draggable
            data-drag-handle
            style={{ justifyContent: position }}
        >
            <div
                className={styles.big_carousel_inner}
                style={{ width: `${width}vw`, height: `${height}vw`, maxWidth: '100%' }}
            >
                <button className={styles.prev} onClick={handlePrev}>
                    &#10094;
                </button>
                <div
                    className={styles.carousel_text_block}
                    style={{ width: `calc(${width / 2}vw - 40px)`, height: `${height}vw`, maxWidth: '50%' }}
                >
                    <div
                        className={styles.carousel_text_inner}
                        style={{
                            transform: `translateX(-${(currentIndex * 100) / texts.length}%)`,
                        }}
                    >
                        {texts.map((item: any[], index: number) => (
                            <div
                                key={index}
                                className={styles.carousel_text}
                                style={{
                                    width: `calc(${width / 2}vw - 20px)`,
                                    height: `calc(${height}vw - 40px)`,
                                    maxWidth: '100%',
                                }}
                            >
                                <span>{item[0]}</span>
                                <h3>{item[1]}</h3>
                                <span>{item[2]}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={carousel.carousel_images} style={{ maxWidth: '50%' }}>
                    <div
                        className={carousel.carousel_images_inner}
                        style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                            width: `${width / 2}vw`,
                            height: `${height}vw`,
                            maxWidth: '100%',
                        }}
                    >
                        {images.map((src: string | undefined, index: number) => (
                            <img
                                key={index}
                                src={src}
                                alt={`Slide ${index}`}
                                style={{ width: `${width / 2}vw`, height: `${height}vw`, maxWidth: '100%' }}
                            />
                        ))}
                    </div>
                </div>
                <button className={styles.next} onClick={handleNext}>
                    &#10095;
                </button>
            </div>
        </NodeViewWrapper>
    );
};
