import { type SearchEntityType, type SearchObject } from '@libs/types/search.type';
import { TabPanel, TabView } from 'primereact/tabview';
import { useCallback, useEffect, useState } from 'react';
import { HighlightText } from '@libs/components';
import { objectToBase64String } from '@libs/utils';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { SEARCH_TABS, SEARCH_TYPE_NAME } from '@libs/constants';
import { AngleRightIcon } from 'primereact/icons/angleright';

import styles from './styles.module.scss';

type Props = {
    list: SearchObject[];
    searchValue?: string;
    entitySearch: SearchEntityType[];
    changeEntitySearch: (value: SearchEntityType[]) => void;
    setIsOpen?: (value: boolean) => void;
    isMainSearch?: boolean;
};

export const SearchDropdown = ({
    list,
    searchValue,
    entitySearch,
    changeEntitySearch,
    setIsOpen,
    isMainSearch = false,
}: Props) => {
    const navigate = useNavigate();

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const tab = SEARCH_TABS.find((item) => item.entities.toString() === entitySearch.toString());
        setActiveIndex(tab?.index || 0);
    }, []);

    useEffect(() => {
        const tab = SEARCH_TABS.find((item) => item.index === activeIndex);
        changeEntitySearch(tab ? tab.entities : ['ARTICLE', 'REFERENCE']);
    }, [activeIndex]);

    const highlight = useCallback(
        (str: string) => {
            const style = { fontWeight: 700 };

            return HighlightText({ filter: searchValue, str, style });
        },
        [searchValue],
    );

    const goToSearchPage = () => {
        setIsOpen && setIsOpen(false);
        navigate(`/search-page?params=${objectToBase64String({ search: searchValue, type: entitySearch })}`);
    };

    const goToPage = (item: SearchObject) => {
        setIsOpen && setIsOpen(false);
        navigate(
            item.type.includes('ARTICLE')
                ? `/articles/${item.objectId}`
                : `/help-center/references/${item.referenceId}/article/${item.objectId}`,
        );
    };

    return (
        <div className={styles.searchDropdownWrapper}>
            <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
                pt={{
                    root: { className: classNames(styles.tabViewStyles, isMainSearch && styles.nonBorder) },
                    navContainer: { className: styles.navContainerStyles },
                }}
            >
                {SEARCH_TABS.map((tab) => (
                    <TabPanel
                        key={tab.index}
                        header={tab.title}
                        pt={{
                            header: {
                                className: classNames(
                                    styles.headerStyles,
                                    tab.index === activeIndex && styles.selected,
                                ),
                            },
                            headerTitle: { className: styles.headerTitleStyles },
                            content: { className: classNames(styles.contentStyles, 'flex', 'flex-column') },
                        }}
                    >
                        {list.map((item) => (
                            <div
                                key={item.objectId}
                                className={classNames(styles.itemWrapper, 'flex', 'flex-column')}
                                onClick={() => goToPage(item)}
                            >
                                <div className={classNames(styles.titleWrapper, 'flex', 'flex-column')}>
                                    <span>{SEARCH_TYPE_NAME[item.type[0]]}</span>
                                    <span>{item.title}</span>
                                </div>
                                <span className={styles.descriptionWrapper}>{highlight(item.textSnippet)}</span>
                            </div>
                        ))}

                        {list.length !== 0
? (
                            <div
                                className={classNames(styles.footerWrapper, 'flex', 'align-items-center')}
                                onClick={() => goToSearchPage()}
                            >
                                <AngleRightIcon className={styles.arrowStyle} />
                                <span>Все результаты поиска</span>
                            </div>
                        )
: (
                            <span className={styles.noResults}>Ничего не найдено</span>
                        )}
                    </TabPanel>
                ))}
            </TabView>
        </div>
    );
};
