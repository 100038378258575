import { DatashopTariff, TariffCards, TariffFilterItem } from '@libs/types';
import { AllTariffPeriods } from '@libs/components/pageBlocks/TariffBlock/data';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const getPreparedTariffData = (datashopTariffs: DatashopTariff[]) => {
    const resultTariffs: TariffCards = {
        month: [],
        month3: [],
        month6: [],
        year: [],
    };

    const resultFilterList: TariffFilterItem[] = [];

    datashopTariffs.forEach((datashopTariff) => {
        const title = datashopTariff.name;
        const tariffGuid = datashopTariff.guid;

        //ищем billingType у тарифа из глобального списка datashopTariffs из которого нужно будет брать всю информацию
        const tariffInfo = datashopTariff?.billingTables?.find(
            (item) => item.billingType === 'TC_BT_SUBSCRIPTION_BASED_DATA',
        );

        if (tariffInfo) {
            // ищем фильтр периодичности
            const tariffParam = tariffInfo?.tariffParams?.find((item) => item.position === 'COLUMN');

            if (tariffParam) {
                const periodFilterList = tariffParam.values;

                //проходимся по фильтру периодичности и распределяем тарифы по массивам периодичности
                periodFilterList?.forEach((periodFilter) => {
                    tariffInfo?.tariffPlans?.forEach((tariffPlan) => {
                        //проверям существует ли тарифный план с заданной периодичностью
                        const tariffIsExist = tariffPlan?.tariffParams?.some(
                            (param) => param.valueGuid === periodFilter.guid,
                        );
                        if (tariffIsExist) {
                            //находим код фильтра и добавляем карточку тарифа в нужный массив
                            const filterItem = AllTariffPeriods.find((item) => item.title === periodFilter.value);
                            if (filterItem) {
                                resultTariffs[filterItem.code as keyof TariffCards].push({
                                    title: title || '',
                                    tariffGuid: tariffGuid || '',
                                    vat: datashopTariff.vat?.percent || 0,
                                    ...tariffPlan,
                                });
                            }
                        }
                    });
                });
            }
        }
    });

    Object.keys(resultTariffs).forEach((key) => {
        resultTariffs[key as keyof TariffCards] = resultTariffs[key as keyof TariffCards].sort(
            (a, b) => a.cost - b.cost,
        );

        if (resultTariffs[key as keyof TariffCards].length) {
            resultFilterList.push(AllTariffPeriods.find((item) => item.code === key)!);
        }
    });

    resultFilterList.sort((a, b) => a.orderValue - b.orderValue);

    return { resultTariffs: resultTariffs, resultFilterList: resultFilterList };
};

export const formatDate = (date?: string) => {
    if (!date) return '';

    return format(date, 'dd.MM.yyyy', { locale: ru });
};
