import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as Xcel } from '@modules/PriceCenterProduct/img/Xcel.svg';

import styles from './styles.module.scss';

export const ControlsPanel = ({ callbacks }: { callbacks: any; errors: any }) => {
    const { handleDownloadXLS, handleDownloadCSV, handleResetForm } = callbacks;

    return (
        <div className={classNames(styles.saveButtonsContainer)}>
            <div className={styles.saveButtons}>
                <Button type="submit" label="Просмотр" className={styles.saveButton} />
                <Button
                    type="button"
                    label="Скачать Excel"
                    icon={<Xcel />}
                    className={styles.saveButton}
                    onClick={() => handleDownloadXLS()}
                />
                <Button
                    type="button"
                    label="Скачать CSV"
                    className={styles.saveButton}
                    onClick={() => handleDownloadCSV()}
                />
                <Button
                    type="button"
                    label="Сбросить"
                    className={styles.clearButton}
                    onClick={() => handleResetForm()}
                />
            </div>
        </div>
    );
};
