import { format, differenceInMinutes, differenceInHours, differenceInDays, differenceInYears } from 'date-fns';
import { declensionByNum } from '@libs/utils';
import { ru } from 'date-fns/locale';
import { HOURS, MINUTES } from '@libs/constants';

export const getFormatedDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();

    const minutesDiff = differenceInMinutes(now, date);
    const hoursDiff = differenceInHours(now, date);
    const daysDiff = differenceInDays(now, date);
    const yearsDiff = differenceInYears(now, date);

    if (minutesDiff < 1) {
        return 'менее минуты назад';
    } else if (hoursDiff < 1) {
        return `${minutesDiff} ${declensionByNum(minutesDiff, MINUTES)} назад`;
    } else if (daysDiff < 1) {
        return `${hoursDiff} ${declensionByNum(hoursDiff, HOURS)} назад`;
    } else if (yearsDiff < 1) {
        return format(date, 'dd MMMM', { locale: ru });
    } else {
        return format(date, 'dd.MM.yyyy');
    }
};

export const getReadNotificationFromLocalStorage = (): number[] => {
    const readNotificationsLocalStorage = localStorage.getItem('read_notifications');
    const notificationIds: number[] = readNotificationsLocalStorage ? JSON.parse(readNotificationsLocalStorage) : [];

    return notificationIds.map(Number);
};

export const getUniqIds = (ids: number[]): number[] => {
    const uniqueIds = new Set<number>([]);

    return ids.filter((value) => {
        if (!uniqueIds.has(value)) {
            uniqueIds.add(value);

            return true;
        }

        return false;
    });
};
