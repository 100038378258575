import { ServiceItem } from '@modules/ProPackLanding/shared/components/ServiceItem';
import { productCapabilities } from '@modules/ProPackLanding/components/Volatility/data';
import type { RefObjectType } from '@libs/types';
import { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { ReactComponent as PlayBlackIcon } from '@shared/images/svg/playBlack.svg';
import { scrollElementByRef, useAuthorize } from '@libs/utils';
import { Link, useLocation } from 'react-router-dom';
import serviceItemStyles from '@modules/ProPackLanding/shared/components/ServiceItem/styles.module.scss';

import { ReactComponent as StarIcon } from '../../../../shared/images/svg/star.svg';
import headerBg from '../../shared/images/png/volatility/headerBg.png';
import { ProductCapabilities } from '../../shared/components/ProductCapabilities';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
    setRefs: (refs: RefObjectType<HTMLDivElement>) => void;
};

export const Volatility = ({ refs, setRefs }: Props) => {
    const location = useLocation();
    const { isAuthorized, loginHandler } = useAuthorize();

    const volatilityRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setRefs({ volatilityRef });
    }, [setRefs]);

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <ServiceItem
            ref={volatilityRef}
            image={headerBg}
            title={'Волатильность'}
            description={'Вмененная волатильность базового актива опциона, соответствующая различным страйкам'}
            buttons={[
                <Button
                    key={0}
                    className={serviceItemStyles.redBtn}
                    label={'Получить доступ'}
                    icon={<StarIcon />}
                    onClick={() => handleButtonClick('gainAccessRef')}
                />,
                isAuthorized
? (
                    <Link
                        key={1}
                        to={location.pathname + '/examples?tab=volatility'}
                        className={serviceItemStyles.whiteBtn}
                    >
                        <PlayBlackIcon />
                        <span>Пробная версия</span>
                    </Link>
                )
: (
                    <Button
                        key={1}
                        className={serviceItemStyles.whiteBtn}
                        label={'Пробная версия'}
                        icon={<PlayBlackIcon />}
                        onClick={() => loginHandler()}
                    />
                ),
            ]}
        >
            <ProductCapabilities list={productCapabilities} />
        </ServiceItem>
    );
};
