import type { RefObjectType } from '@libs/types';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useResize } from '@libs/utils';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ReactComponent as PlusIcon } from '@shared/images/algopack/plusIcon.svg';
import { ReactComponent as CrossIcon } from '@shared/images/algopack/crossIcon.svg';
import { MarkingBlock } from '@modules/ProPackLanding/components/TariffBlock/MarkingBlock';
import { RiskRatesBlock } from '@modules/ProPackLanding/components/TariffBlock/RiskRatesBlock';
import { DuplicateIISBlock } from '@modules/ProPackLanding/components/TariffBlock/DuplicateIISBlock';

import styles from './styles.module.scss';

type Props = {
    setRefs: (refs: RefObjectType<HTMLDivElement>) => void;
};

export const TariffBlock = ({ setRefs }: Props) => {
    const { isScreenXl } = useResize();
    const tariffRef = useRef<HTMLDivElement>(null);

    const [openedTabs, setOpenedTabs] = useState<number[]>([]);
    const addTab = (tab: number) => setOpenedTabs([...openedTabs, tab]);
    const closeTab = (tab: number) => setOpenedTabs(openedTabs.filter((tb) => tb !== tab));
    const isTabOpen = (tab: number) => openedTabs.includes(tab);

    useEffect(() => {
        setRefs({ tariffRef });
    }, [setRefs]);

    const accTabPt = {
        header: {
            className: styles.accordionHeader,
        },
        content: {
            className: styles.accordionContent,
        },
    };

    return (
        <section
            ref={tariffRef}
            className={classNames(
                'flex',
                styles.tariffBlockWrapper,
                'justify-content-between',
                'flex-row',
                !isScreenXl && 'flex-column',
            )}
        >
            <h2>Тарифы</h2>
            <Accordion
                multiple={true}
                className={classNames(styles.apiContainer)}
                onTabClose={(e) => closeTab(e.index)}
                onTabOpen={(e) => addTab(e.index)}
            >
                <AccordionTab
                    pt={accTabPt}
                    children={<RiskRatesBlock />}
                    header={() => {
                        return (
                            <div
                                className={classNames(
                                    'flex',
                                    'justify-content-between',
                                    'align-items-center',
                                    styles.tariffItem,
                                    isTabOpen(0) && styles.open,
                                )}
                            >
                                <h6>Ставки риска</h6>
                                {isTabOpen(0) ? <CrossIcon /> : <PlusIcon />}
                            </div>
                        );
                    }}
                />
                <AccordionTab
                    pt={accTabPt}
                    children={<MarkingBlock />}
                    header={() => {
                        return (
                            <div
                                className={classNames(
                                    'flex',
                                    'justify-content-between',
                                    'align-items-center',
                                    styles.tariffItem,
                                    isTabOpen(1) && styles.open,
                                )}
                            >
                                <h6>Маркировки</h6>
                                {isTabOpen(1) ? <CrossIcon /> : <PlusIcon />}
                            </div>
                        );
                    }}
                />
                <AccordionTab
                    pt={accTabPt}
                    children={<DuplicateIISBlock />}
                    header={() => {
                        return (
                            <div
                                className={classNames(
                                    'flex',
                                    'justify-content-between',
                                    'align-items-center',
                                    styles.tariffItem,
                                    isTabOpen(2) && styles.open,
                                )}
                            >
                                <h6>Дубликаты ИИС</h6>
                                {isTabOpen(2) ? <CrossIcon /> : <PlusIcon />}
                            </div>
                        );
                    }}
                />
            </Accordion>
        </section>
    );
};
