import classNames from 'classnames';
import { TariffItem } from '@modules/ProPackLanding/components/TariffBlock/TariffItem';
import { useResize } from '@libs/utils';
import { tariffItems } from '@modules/ProPackLanding/components/TariffBlock/RiskRatesBlock/data';

import styles from './styles.module.scss';

export const RiskRatesBlock = () => {
    const { isScreenSm } = useResize();

    return (
        <div className={classNames(styles.riskRatesBlockWrapper)}>
            <span>
                {isScreenSm
? (
                    <>Все цены указаны без учета НДС. Ежемесячно</>
                )
: (
                    <>
                        Ежемесячно <br />
                        *без учета НДС
                    </>
                )}
            </span>
            <div className={classNames('flex', 'flex-column')}>
                {tariffItems.map((item) => (
                    <TariffItem key={item.title} {...item} />
                ))}
            </div>
        </div>
    );
};
