import { ModalWindow } from '@libs/components';
import { Button } from 'primereact/button';
import { type Notification } from '@libs/types';
import { type Dispatch, type SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

type NotificationModalProps = {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    content: Notification;
    notificationImages: Record<string, string>;
};

export const NotificationModal = ({ setIsVisible, isVisible, content, notificationImages }: NotificationModalProps) => {
    return (
        <ModalWindow isVisible={isVisible} closeModal={() => setIsVisible(false)}>
            <div className={styles.modal}>
                <h3 className={styles.header}>{content?.modalTitle}</h3>
                {notificationImages[content.id] && (
                    <div className={styles.imageWrapper}>
                        <img src={notificationImages[content.id]} className={styles.image} alt={content?.modalTitle} />
                    </div>
                )}

                <div className={styles.content}>
                    <p className={styles.contentText}>{content?.modalText}</p>
                </div>

                {content.modalButtonText && content?.modalButtonUrl && (
                    <Link className={styles.buttonLink} to={content?.modalButtonUrl} target={'_blank'}>
                        <Button severity={'danger'} className={styles.button}>
                            {content.modalButtonText}
                        </Button>
                    </Link>
                )}
            </div>
        </ModalWindow>
    );
};
