import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import {
    GetMarketBondsReqBody,
    GetMarketBondsRespBody,
    type GetMarketPointsReqBody,
    GetMarketPointsRespBody,
    GetPricePointsReqBody,
    GetPricePointsRespBody,
    GetProfitPointsRespBody,
    type ResponseInfo,
} from '@libs/types';
import { type PriceCenterBond } from '@store/store/thunk/pricecenter/Models';

export const PROFIT_CURVE_URL = '/moex-propack-integrationservice/api/curves/v1/profit-curve';
export const PRICES_URL = '/moex-propack-integrationservice/api/v1/instrument-prices';

export const SEARCH_URL = '/moex-propack-integrationservice/api/price-center/v1/bonds/search';

export const YIELD_POINTS_URL = '/moex-propack-integrationservice/api/price-center/v1/bonds/yield-points';

export const POINTS_URL = '/moex-propack-integrationservice/api/v1/instrument-points';
export const BONDS_URL = '/moex-datashop-instrument-service/api/v1/bonds/bonds_params';

export const INSTRUMENTS_DATA_URL = '/moex-propack-integrationservice/api/v1/instruments-data/isin';
export const DATAUPLOAD_FILE_URL = ' /moex-datashop-attachmentservice/api/file-service/v1/upload-file';

const getDemoPrefix = (isDemo: boolean) => (isDemo ? '/demo' : '');

export const getProfitCurves = (date: string): Promise<AxiosResponse<ResponseInfo<PriceCenterBond[]>>> =>
    httpClient.post(PROFIT_CURVE_URL, { date });

export const getMarketPoints = (
    body: GetMarketPointsReqBody | GetMarketBondsReqBody,
    isDemo: boolean = false,
): Promise<AxiosResponse<GetMarketPointsRespBody[]>> =>
    httpClient.post(`${YIELD_POINTS_URL}${getDemoPrefix(isDemo)}`, body);

export const getMarketBonds = (
    body: { trade_date: string | Date } | { tradeDate: string | Date },
    isDemo: boolean = false,
): Promise<AxiosResponse<GetMarketBondsRespBody>> => httpClient.post(isDemo ? BONDS_URL : SEARCH_URL, body);

export const getPricePoints = (
    body: GetPricePointsReqBody,
    isDemo: boolean = false,
): Promise<AxiosResponse<GetPricePointsRespBody>> =>
    httpClient.post(`${PRICES_URL}${getDemoPrefix(isDemo)}/isin/`, body);

export const getProfitPoints = (
    body: GetPricePointsReqBody,
    isDemo: boolean = false,
): Promise<AxiosResponse<GetProfitPointsRespBody>> => {
    return httpClient.post(`${POINTS_URL}${getDemoPrefix(isDemo)}/isin`, body);
};

export const getIndexesPrices = (body: any, isDemo: boolean = false): Promise<AxiosResponse<GetPricePointsRespBody>> =>
    httpClient.post(`${PRICES_URL}${getDemoPrefix(isDemo)}/secId/`, body);

export const getIndexesPoints = (body: any, isDemo: boolean = false): Promise<AxiosResponse<GetProfitPointsRespBody>> =>
    httpClient.post(`${POINTS_URL}${getDemoPrefix(isDemo)}/secId`, body);

export const getDataUpload = (body: any): Promise<AxiosResponse<GetProfitPointsRespBody>> =>
    httpClient.post(SEARCH_URL, body);

export const getInstrumentsData = (body: any): Promise<AxiosResponse<GetProfitPointsRespBody>> =>
    httpClient.post(INSTRUMENTS_DATA_URL, body);
