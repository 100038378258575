import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import type { RefObjectType } from '@libs/types';
import { scrollElementByRef } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const Header = ({ refs }: Props) => {
    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <div className={styles.headerWrapper}>
            <div className={styles.contentWrapper}>
                <h2>Центр корпоративной информации</h2>
                <p>
                    Полная картина данных по эмитентам и ценным бумагам: корпоративные действия, сущфакты, рейтинги,
                    финансовые показатели, календари событий, отчетности МСФО И РСБУ
                </p>
                <div className={classNames(styles.buttonWrapper, 'flex')}>
                    <Button
                        className={classNames(styles.redBtn)}
                        label={'Получить доступ'}
                        icon={<StarIcon />}
                        onClick={() => handleButtonClick('gainAccessRef')}
                    />
                    {/*<Button className={classNames(styles.whiteBtn)} label={'Документация'} />*/}
                </div>
            </div>
        </div>
    );
};
