import { LogoBlock } from '@modules/ComplianceToolLanding/MainContent/components/LogoBlock';
import { GainAccess } from '@libs/components';
import { type RefObjectType } from '@libs/types';
import { ComplianceServices } from '@modules/ComplianceToolLanding/MainContent/components/ComplianceServices';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';

export const MainContent = () => {
    const refs: RefObjectType<HTMLDivElement> = {};

    const productGuids = useSelector((state: RootState) => state.product.productGuids);
    const isProductLoading = useSelector((state: RootState) => state.product.isLoading);

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    return (
        <>
            <LogoBlock refs={refs} />
            <ComplianceServices />
            {!isProductLoading && (
                <GainAccess
                    setRefs={setChildRefs}
                    telephoneNumber={'+7 (495) 363-32-32 доб. 5656'}
                    productGuid={productGuids[ProductMnemoCodes.COMPLIENCE_TOOL]}
                />
            )}
        </>
    );
};
