import { PaginationController } from '@libs/components';
import { useEffect, useState } from 'react';
import { useResize } from '@libs/utils';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { getArticlesThunk } from '@store/store/thunk/articles.thunk';
import { type AppDispatch } from '@store/store';
import { type ArticleListItem } from '@libs/types/articles/articleListItem.type';
import { unwrapResult } from '@reduxjs/toolkit';
import { ImgArticleBlock } from '@modules/Articles/ArticleListPage/components/ArticlesList/ImgArticleBlock';
import { TextArticleBlock } from '@modules/Articles/ArticleListPage/components/ArticlesList/TextArticleBlock';
import { ArticleCard } from '@modules/Articles/ArticleListPage/components/ArticlesList/ArticleCard';

import styles from './styles.module.scss';

const bodyReq = {
    pageSize: 20,
    pageNumber: 0,
    sortField: 'titleDate',
    sortOrder: 'desc',
};

export const ArticlesList = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isScreenSm } = useResize();

    const [list, setList] = useState<ArticleListItem[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPage, setTotalPage] = useState<number>(0);

    useEffect(() => {
        getArticles();

        if (!isScreenSm) {
            window.scrollTo(0, 0);
        }
    }, [currentPage]);

    const getArticles = () => {
        dispatch(getArticlesThunk({ pageable: { ...bodyReq, pageNumber: currentPage } })).then((res) => {
            const result = unwrapResult(res);
            setList((prev) => (isScreenSm ? [...prev, ...result.rows] : result.rows));
            setTotalPage(result.totalPages);
        });
    };

    return (
        <div className={styles.articlesListWrapper}>
            <div
                className={classNames(
                    styles.contentWrapper,
                    'flex',
                    'justify-content-between',
                    isScreenSm && 'flex-column',
                )}
            >
                {!isScreenSm
? (
                    <>
                        <ImgArticleBlock list={list.filter((item) => !!item.image)} />
                        <TextArticleBlock list={list.filter((item) => !item.image)} />
                    </>
                )
: (
                    list.map((item) => <ArticleCard key={item.id} item={item} />)
                )}
            </div>

            <PaginationController
                total={totalPage}
                current={currentPage}
                setAsyncCurrent={setCurrentPage}
                nextBtn={'Cледующая страница'}
                prevBtn={'Предыдущая страница'}
            />
        </div>
    );
};
