import axios from 'axios';

const httpClient = axios.create({
    baseURL: '',
    headers: {
        'Content-Type': 'application/json',
    },
});

const httpClientBlob = axios.create({
    baseURL: '',
    responseType: 'blob',
    headers: {
        'Content-Type': 'application/json',
    },
});

export { httpClient, httpClientBlob };
