import { GainAccess } from '@libs/components/pageBlocks/GainAccess';
import { ContentSlider } from '@libs/components';
import { contentSliderList } from '@modules/MarketDive/data';
import { type RefObjectType } from '@libs/types';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

import { ProductOpportunity } from './ProductOpportunity';
import { LogoBlock } from './LogoBlock';

export const MarketDiveMain = () => {
    const refs: RefObjectType<HTMLDivElement> = {};

    const productGuids = useSelector((state: RootState) => state.product.productGuids);
    const isProductLoading = useSelector((state: RootState) => state.product.isLoading);

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    return (
        <>
            <LogoBlock refs={refs} />
            <ProductOpportunity />
            <ContentSlider list={contentSliderList} />
            {!isProductLoading && (
                <GainAccess setRefs={setChildRefs} productGuid={productGuids[ProductMnemoCodes.MARKET_DIVE]} />
            )}
        </>
    );
};
