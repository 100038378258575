import { createAsyncThunk } from '@reduxjs/toolkit';
import { type OrderReq } from '@libs/types';
import { createOrder, getBanks, getAmountRefund, createRefundOrder } from '@libs/services';
import { CreateRefundReq, RefundReq } from '@libs/types/refundOrder.type';

export const createOrderThunk = createAsyncThunk('order/createOrder', async (body: OrderReq, { rejectWithValue }) => {
    try {
        const { data } = await createOrder(body);

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const getAmountRefundThunk = createAsyncThunk(
    'order/getAmountRefundThunk',
    async (body: RefundReq, { rejectWithValue }) => {
        try {
            const { data } = await getAmountRefund(body);
            return data.data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getBanksThunk = createAsyncThunk('order/getBanksThunk', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getBanks();
        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const createRefundOrderThunk = createAsyncThunk(
    'order/createRefundOrderThunk',
    async ({ orderId, body }: { orderId: string; body: CreateRefundReq }, { rejectWithValue }) => {
        try {
            const { data } = await createRefundOrder(orderId, body);
            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
