export type StatusType = 'CREATED' | 'CANCELLED' | 'EXPIRED' | 'ACTIVE';

export type PageModelSubscriptionData = {
    totalRows: number;
    totalPages: number;
    size: number;
    rows: SubscriptionData[];
    pageNumber: number;
};

export type SubscriptionData = {
    id: number;
    productGuid: string;
    tariffGuid?: string;
    startDate: string;
    endDate?: string;
    status: StatusType;
    cost: number;
    vatCost: number;
    orderId: string;
    externalOrderId: string;
    orderNumber: string;
    renewal?: boolean;
    tariffParams: TariffParam[];
    renewalTerms?: RenewalTerm;
    apiKey?: string;
    refundId?: string;
};

type TariffParam = {
    paramId: string;
    valueId: string;
};

type RenewalTerm = {
    renewal: boolean;
    tariffGuid: string;
    tariffParams: TariffParam[];
};

export type UpdateSubscriptionReq = {
    renewalTerms?: {
        tariffId: string;
        renewal: boolean;
        tariffParams: TariffParam[];
    };
};

export enum PriceCentrePermissions {
    REAL_POINTS = 'real_points',
    EXPORTS = 'exports',
    API_DOCS = 'api_docs',
}

export type PermissionResponse = {
    permissions: PriceCentrePermissions[];
};
