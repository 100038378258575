import { DatashopTariff } from '@libs/types';
import { addHours, parseISO, setHours, setMinutes, setSeconds } from 'date-fns';

export const getTariffName = (datashopTariffs: DatashopTariff[], tariffGuid: string): string => {
    const tariff = datashopTariffs.find((tariff) => tariff.guid === tariffGuid);
    return tariff?.name || '';
};

export const getPeriodName = (datashopTariffs: DatashopTariff[], tariffGuid: string, periodId: string): string => {
    const tariff = datashopTariffs.find((tariff) => tariff.guid === tariffGuid);

    if (tariff) {
        const billingTable = tariff.billingTables?.find(
            (table) => table.billingType === 'TC_BT_SUBSCRIPTION_BASED_DATA',
        );

        if (billingTable) {
            const period = billingTable.tariffParams?.find((item) =>
                item.values?.find((value) => value.guid === periodId),
            );

            if (period) {
                return period.values?.find((item) => item.guid === periodId)?.value || '';
            }
        }
    }

    return '';
};

export const convertDateToMSK = (dateTimeStr?: string) => {
    const utcDate = parseISO(dateTimeStr || new Date().toISOString());

    return addHours(utcDate, 3).toISOString();
};

export const updateDateToMidnight = (dateTimeStr?: string) => {
    return setSeconds(setMinutes(setHours(dateTimeStr || new Date().toISOString(), 23), 59), 0);
};
