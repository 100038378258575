import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { type Dispatch, useMemo, useState } from 'react';
import { ReactComponent as EditEmailIcon } from '@shared/images/personalAccount/svg/edit_email_icon.svg';
import { ReactComponent as CheckEmailIcon } from '@shared/images/personalAccount/svg/check_email_icon.svg';
import { ReactComponent as CheckDisableEmailIcon } from '@shared/images/personalAccount/svg/check_disable_email_icon.svg';
import { ReactComponent as EmailIcon } from '@shared/images/personalAccount/svg/email_icon.svg';
import { ReactComponent as CrossIcon } from '@shared/images/svg/CrossIconGray.svg';
import { useModal, validateEmail } from '@libs/utils';
import { NoticeModal } from '@libs/components';
import { useDispatch } from 'react-redux';
import { type AppDispatch, confirmEmailThunk, updateEmailThunk } from '@store/store';

import styles from './styles.module.scss';

type Props = {
    isEmailVerified?: boolean;
    email: string;
    isEditEmail: boolean;
    setShowChangeForm: Dispatch<React.SetStateAction<boolean>>;
};

export const ChangeEmailComponent = ({ isEmailVerified, email, isEditEmail, setShowChangeForm }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isDisableEmail, setDisableEmail] = useState<boolean>(!isEmailVerified && email !== '');
    const [newEmail, setNewEmail] = useState<string>(email);
    const [showEmailBtn, setShowEmailBtn] = useState<boolean>(email !== '');

    const isEmailCorrect = useMemo(() => validateEmail(newEmail), [newEmail]);
    const { isOpen, openModal, closeModal } = useModal();

    const handlerCancelClick = () => {
        if (isEditEmail) {
            setShowChangeForm(false);
        } else if (email === '') {
            setShowEmailBtn(false);
        } else {
            setDisableEmail(true);
        }
        setNewEmail(email);
    };

    const handlerClick = async () => {
        if (isDisableEmail) {
            setDisableEmail(false);
        } else if (isEmailCorrect) {
            if (newEmail !== email) {
                await dispatch(updateEmailThunk({ email: newEmail }));
                await confirmEmail();
            }

            setShowChangeForm(false);
            setDisableEmail(true);
        }
    };

    const confirmEmail = async () => {
        await dispatch(confirmEmailThunk());
        openModal();
    };

    return (
        <>
            <div className={classNames(styles.infoWrapper, styles.notConfirmed)}>
                <div className={classNames('flex', styles.notConfirmedEmailContainer)}>
                    <span className={classNames('p-input-icon-left', styles.inputContainer)}>
                        <i className="pi">
                            <EmailIcon />
                        </i>
                        <InputText
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            disabled={isDisableEmail}
                            className={styles.emailInput}
                            placeholder="Введите ваш email"
                            onFocus={() => setShowEmailBtn(true)}
                            onBlur={() => {
                                if (email === '' && newEmail === '') {
                                    setShowEmailBtn(false);
                                }
                            }}
                        />
                    </span>
                    {showEmailBtn && (
                        <>
                            {!isDisableEmail && (
                                <Button
                                    className={classNames('flex', 'justify-content-center')}
                                    onClick={handlerCancelClick}
                                    icon={<CrossIcon />}
                                />
                            )}
                            <Button
                                className={classNames(!isEmailCorrect && !isDisableEmail && styles.incorrect)}
                                onClick={handlerClick}
                            >
                                {isDisableEmail
? (
                                    <EditEmailIcon />
                                )
: isEmailCorrect
? (
                                    <CheckEmailIcon />
                                )
: (
                                    <CheckDisableEmailIcon />
                                )}
                            </Button>
                        </>
                    )}
                </div>
                {!isEmailVerified && email && (
                    <div className={classNames('flex', 'justify-content-between', styles.notConfirmedMessage)}>
                        <p>Email не подтвержден</p>
                        <span onClick={confirmEmail} role={'button'}>
                            Отправить повторно
                        </span>
                    </div>
                )}
            </div>
            <NoticeModal
                className={styles.noticeEmailModal}
                isVisible={isOpen}
                closeModal={closeModal}
                headerText={'Подтверждение почты'}
                mainText={
                    'На указанный Вами адрес электронной почты было отправлено письмо со ссылкой для подтверждения.'
                }
                closeButtonText={'Закрыть'}
            />
        </>
    );
};
