import marketLogo from '@shared/images/algopack/dataConstitution/supeCandlesLogo.svg';
import candlesLogo from '@shared/images/algopack/dataConstitution/marketDataLogo.svg';
import futoiLogo from '@shared/images/algopack/dataConstitution/futoiLogo.svg';
import hi2Logo from '@shared/images/algopack/dataConstitution/hi2Logo.svg';
import megaAlertsLogo from '@shared/images/algopack/dataConstitution/megaAlertsLogo.svg';

export const DataItems = [
    {
        title: 'Super Candles',
        tags: ['Акции', 'Фьючерсы', 'Валюта'],
        description: 'Аналитические сигналы, рассчитанные на потоке сделок, заявок и данных в стакане.',
        logo: candlesLogo,
        dataUpdateTime: '5 мин',
        historicalData: 'с 2020 года',
        isPythonLib: true,
        link: 'https://moexalgo.github.io/modules/supercandles/',
    },
    {
        title: 'Real-time Market Data',
        tags: ['Акции', 'Фьючерсы'],
        description: 'Стандартная онлайн маркет-дата в виде тиковых сделок, стакана котировок и свечей.',
        logo: marketLogo,
        dataUpdateTime: '10 сек',
        isPythonLib: true,
        link: 'https://moexalgo.github.io/modules/realtime/',
    },
    {
        title: 'FUTOI',
        tags: ['Фьючерсы'],
        description: 'Позиции юридических и физических лиц на срочном рынке с разбивкой на long и short.',
        logo: futoiLogo,
        dataUpdateTime: '5 мин',
        historicalData: 'с 2020 года',
        isPythonLib: true,
        link: 'https://moexalgo.github.io/modules/futoi/',
    },
    {
        title: 'HI2',
        tags: ['Акции', 'Фьючерсы', 'Валюта'],
        description: 'Индекс рыночной концентрации активности трейдеров по величине объемов по каждой бумаге.',
        logo: hi2Logo,
        dataUpdateTime: 'Ежедневно',
        historicalData: 'с 2020 года',
        isPythonLib: true,
        link: 'https://moexalgo.github.io/modules/hi2/',
    },
    {
        title: 'Mega Alerts',
        tags: ['Акции', 'Фьючерсы', 'Валюта'],
        description: 'Сигналы об аномальных движениях цен, рассчитанных ML алгоритмами',
        logo: megaAlertsLogo,
        dataUpdateTime: '1 мин',
        historicalData: 'с 2024 года',
        isPythonLib: false,
        link: 'https://moexalgo.github.io/modules/megaalerts/',
    },
];
