import classNames from 'classnames';
import { SearchIcon } from 'primereact/icons/search';
import { InputText } from 'primereact/inputtext';
import { useEffect, useRef } from 'react';
import { SearchDropdown } from '@libs/components/misc/SearchDropdown';
import { useSearch } from '@libs/utils/hooks/useSearch';

import styles from './styles.module.scss';

export const Header = () => {
    const articlesSearchRef = useRef<HTMLDivElement>(null);

    const {
        debouncedSearch,
        searchValue,
        entitySearch,
        setEntitySearch,
        resultList,
        handleInputChange,
        isOpenDropdown,
        setIsOpenDropdown,
    } = useSearch(4, '', ['ARTICLE']);

    useEffect(() => {
        const onClick = (e: MouseEvent) => {
            if (articlesSearchRef && articlesSearchRef.current) {
                setIsOpenDropdown(articlesSearchRef.current.contains(e.target as Node));
            }
        };
        document.addEventListener('click', onClick);

        return () => document.removeEventListener('click', onClick);
    }, []);

    useEffect(() => {
        debouncedSearch(searchValue || '');
    }, [searchValue, entitySearch]);

    return (
        <div className={styles.headerWrapper}>
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                <h1>Статьи</h1>
                <span ref={articlesSearchRef} className={classNames('p-input-icon-right')}>
                    <SearchIcon className={styles.searchIcon} />
                    <InputText
placeholder={'Поиск'} value={searchValue} onChange={handleInputChange}
maxLength={250} />
                    {isOpenDropdown && (
                        <SearchDropdown
                            list={resultList}
                            searchValue={searchValue}
                            entitySearch={entitySearch}
                            changeEntitySearch={setEntitySearch}
                        />
                    )}
                </span>
            </div>
        </div>
    );
};
