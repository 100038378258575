import React, { useEffect } from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';
import { addMetaTag, removeMetaTag } from '@libs/utils';

import { Header } from './components/Header';
import { ArticlesList } from './components/ArticlesList';

export const ArticleListPage = () => {
    useEffect(() => {
        document.title = 'Статьи';
        addMetaTag('title', 'Полезные статьи про инвестиции: глубокий анализ рынка и инвестиционные стратегии');
        addMetaTag(
            'description',
            'На странице статей вы найдете полезную информацию об инвестициях, рынке, компаниях, инвестиционных стратегиях и финансовых инструментах. Наши статьи помогут вам понять сложности рынка, выбрать правильные активы для инвестиций и использовать инвестиционные инструменты наиболее эффективно. Раскрываем разные темы: от анализа текущих тенденций до рассмотрения основных финансовых понятий.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <Navigation />
            <Header />
            <ArticlesList />
            <Feedback />
            <Footer />
        </>
    );
};
