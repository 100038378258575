import { ServiceItem } from '@modules/ProPackLanding/shared/components/ServiceItem';
import type { RefObjectType } from '@libs/types';
import { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { instrumentItems } from '@modules/ProPackLanding/components/MarkingFinInstruments/data';
import { InstrumentItem } from '@modules/ProPackLanding/components/MarkingFinInstruments/InstrumentItem';
import { scrollElementByRef } from '@libs/utils';
import serviceItemStyles from '@modules/ProPackLanding/shared/components/ServiceItem/styles.module.scss';

import styles from './styles.module.scss';
import { ReactComponent as StarIcon } from '../../../../shared/images/svg/star.svg';
import headerBg from '../../shared/images/png/markingFinInstruments/headerBg.png';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
    setRefs: (refs: RefObjectType<HTMLDivElement>) => void;
};

export const MarkingFinInstruments = ({ refs, setRefs }: Props) => {
    const markingFinInstrumentsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setRefs({ markingFinInstrumentsRef });
    }, [setRefs]);

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <ServiceItem
            ref={markingFinInstrumentsRef}
            image={headerBg}
            title={'Маркировка финансовых инструментов'}
            description={
                'Источник данных для разграничения доступа к финансовым инструментам для различных категорий клиентов в автоматизированных системах'
            }
            buttons={[
                <Button
                    key={0}
                    className={classNames(serviceItemStyles.redBtn, serviceItemStyles.widthBtn)}
                    label={'Получить доступ'}
                    icon={<StarIcon />}
                    onClick={() => handleButtonClick('gainAccessRef')}
                />,
            ]}
        >
            <div className={classNames(styles.instrumentsWrapper)}>
                {instrumentItems.map((item) => (
                    <InstrumentItem key={item.title} {...item} />
                ))}
            </div>
        </ServiceItem>
    );
};
