import classNames from 'classnames';
import { capabilities } from '@modules/CKI/components/ProductCapabilities/data';
import { CapabilityItem } from '@modules/CKI/components/ProductCapabilities/CapabilityItem';

import styles from './styles.module.scss';

export const ProductCapabilities = () => {
    return (
        <section className={classNames(styles.productCapabilitiesWrapper, 'flex', 'flex-column')}>
            {capabilities.map((item, index) => (
                <CapabilityItem key={item.title} {...item} imagePosition={index % 2 ? 'left' : 'right'} />
            ))}
        </section>
    );
};
