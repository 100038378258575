import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import {
    clearPriceSelectedPoints,
    clearProfitSelectedPoints,
    unSelectPricePoint,
    unSelectProfitPoint,
} from '@store/store/slices/pricecenter.slice';
import { InputSwitch } from 'primereact/inputswitch';
import { SelectedPointsModal } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/SelectedPointsModal';
import styles from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/styles.module.scss';

import { MarketPointPanel } from '../ChartTooltips';

type PriceProfitHeaderProps = {
    profitSelected: boolean;
    onChange: () => void;
};

export const PriceProfitHeader = (props: PriceProfitHeaderProps) => {
    const { profitSelected, onChange } = props;

    const dispatch = useDispatch<AppDispatch>();

    const charts = useSelector((state: RootState) => state.priceCenter.charts);

    const activePoints = profitSelected ? charts.data.profitSelectedPoints : charts.data.priceSelectedPoints;
    const activeAction = profitSelected ? unSelectProfitPoint : unSelectPricePoint;
    const activeClearFn = profitSelected ? clearProfitSelectedPoints : clearPriceSelectedPoints;

    return (
        <div className={classNames(styles.headerWrapper)}>
            <div className={styles.mapHeaderTitle}>
                <h2
                    className={classNames({
                        [styles['disabled']]: profitSelected,
                    })}
                    onClick={onChange}
                >
                    Цена
                </h2>
                <InputSwitch checked={profitSelected} onChange={onChange} className={classNames(styles.switch)} />
                <h2
                    className={classNames({
                        [styles['disabled']]: !profitSelected,
                    })}
                    onClick={onChange}
                >
                    Доходность
                </h2>
            </div>
            <SelectedPointsModal
                handleClearPanel={() => dispatch(activeClearFn())}
                handleUnselectPoint={(point) => dispatch(activeAction(point))}
                selectedMarkers={activePoints ?? []}
            />
        </div>
    );
};
