export enum PriceCenterProductTabs {
    INTERACTIVE_MAP = 'interactiveMap',
    DATA_UPLOAD = 'dataUpload',
    API_DESCRIPTION = 'apiDescription',
}

export type PriceCenterProductTab = {
    text: string;
    tab: PriceCenterProductTabs;
    disabled: boolean;
};

export type Bond = { isin: string; emitterId: number; mat_date: string | Date; name: string; security_type: string };

export type Emitter = { id: number; name: string };

export type InstrumentsFormData = {
    dateStart: Date;
    dateEnd: Date;
    isinShortList: string;
};
