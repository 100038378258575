import { useSearchParams } from "react-router-dom";
import { base64StringToObject, objectToBase64String } from "../utils";

export const useQueryParams = (paramName: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const base64Params = searchParams.get(paramName);
  return {
    params:
      typeof base64Params === "string"
        ? base64StringToObject(base64Params)
        : {},
  };
};
