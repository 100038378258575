import { Navigation } from '@libs/components';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { Header } from '@modules/Investorpro/shared/components/Header';

import styles from './styles.module.scss';
import { Footer } from '../../shared/components/Footer';

export const CasePage = () => {
    return (
        <div className={styles.pageWrapper}>
            <Navigation className={styles.screenersNavigationWrapper}>
                <Header />
            </Navigation>
            <Outlet />
            <Footer />
        </div>
    );
};
