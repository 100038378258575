import { Button } from 'primereact/button';
import classNames from 'classnames';
import { SliderButtons } from '@libs/components';
import { useEffect, useRef, useState } from 'react';
import { useResize, useModal } from '@libs/utils';

import { DetailsModal } from './DetailsModal';
import styles from './styles.module.scss';
import { examplesUnion, type SolutionType } from './data';

export const Examples = () => {
    const { isOpen, closeModal, openModal } = useModal();
    const [activeSlide, setActiveSlide] = useState(0);
    const { width, isScreenXl, isScreenMd, isScreenSm, isScreenLessSm } = useResize();
    const [solutionObj, setSolutionObj] = useState<SolutionType>();
    const [gap, setGap] = useState<number>(isScreenXl ? 24 : 18);

    const [extendedSlides, setExtendedSlides] = useState<SolutionType[]>([]);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const carouselRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // В начале и конце добавляем клон последних и первых слайдов
        if (width > 1023) {
            setExtendedSlides([...examplesUnion.slice(3), ...examplesUnion, ...examplesUnion.slice(0, 3)]);
            setActiveSlide(3);
        } else if (isScreenMd) {
            setExtendedSlides([...examplesUnion.slice(4), ...examplesUnion, ...examplesUnion.slice(0, 2)]);
            setActiveSlide(2);
        } else if (isScreenSm) {
            setExtendedSlides([...examplesUnion.slice(3), ...examplesUnion, ...examplesUnion.slice(0, 3)]);
            setActiveSlide(3);
        }
    }, []);

    useEffect(() => {
        if (width > 1023 && activeSlide === examplesUnion.length - 2) {
            setActiveSlide(examplesUnion.length - 3);
        } else if (isScreenMd && activeSlide === examplesUnion.length - 3) {
            setActiveSlide(examplesUnion.length - 2);
        } else if (isScreenSm) {
            setActiveSlide((prev) => (prev ? 1 : 0));
        }

        setGap(isScreenXl ? 24 : 18);
    }, [width]);

    // Убираем анимацию и "прыгаем" на реальный слайд после перехода
    useEffect(() => {
        if (isTransitioning) {
            const handleTransitionEnd = () => {
                setIsTransitioning(false);

                // возвращаемся к первому реальному слайду
                if (width > 1023 && activeSlide === extendedSlides.length - 3) {
                    setActiveSlide(3);
                } else if (isScreenMd && activeSlide === extendedSlides.length - 2) {
                    setActiveSlide(2);
                } else if (isScreenSm && activeSlide === 3) {
                    setActiveSlide(1);
                } else if (activeSlide === 0) {
                    setActiveSlide(isScreenSm ? 2 : examplesUnion.length); // возвращаемся к последнему реальному слайду
                }
            };

            const carousel = carouselRef.current;
            carousel!.addEventListener('transitionend', handleTransitionEnd);

            return () => {
                carousel!.removeEventListener('transitionend', handleTransitionEnd);
            };
        }
    }, [activeSlide, isTransitioning, extendedSlides.length]);

    const numberOfVisibleSlides = isScreenSm || isScreenLessSm ? 1 : isScreenMd ? 2 : 3;
    const slideWidth = `calc(100% / ${numberOfVisibleSlides} - ${(1 - 1 / numberOfVisibleSlides) * gap}px)`;

    return (
        <section className={classNames(styles.exampleSection, 'flex', 'flex-column')}>
            <h2>Примеры Реализации</h2>
            <div className={styles.exampleList}>
                <div
                    ref={carouselRef}
                    className={classNames('flex')}
                    style={{
                        transition: isTransitioning ? 'transform 0.5s ease' : 'none',
                        transform: `translateX(calc(${-activeSlide} * (${slideWidth} + ${gap}px)))`,
                    }}
                >
                    {extendedSlides.map((item: SolutionType, index: number) => (
                        <div
                            key={index}
                            style={{
                                width: slideWidth,
                                flexShrink: 0,
                                gridRow: isScreenSm ? (index % 3) + 1 : undefined,
                                borderBottom:
                                    isScreenSm && (index % 3) + 1 === 3 ? '1px solid var(--ds-border-gray)' : 'none',
                            }}
                            className={classNames(isScreenSm && (index % 3) + 1 === 3 && styles.hideBorder)}
                        >
                            <article key={item.name} className={classNames(styles.example, 'flex', 'flex-column')}>
                                <div>
                                    <h6>{item.name}</h6>
                                    {item.label && item.color && (
                                        <span title={item.label} className={styles[item.color]}>
                                            {item.label}
                                        </span>
                                    )}
                                </div>
                                <p>{item.text}</p>
                                <div className={classNames('flex', 'h-full', 'flex-column', 'justify-content-end')}>
                                    <Button
                                        label={'Подробнее'}
                                        className={styles.extraInfoButton}
                                        onClick={() => {
                                            setSolutionObj(item);
                                            openModal();
                                        }}
                                    />
                                </div>
                            </article>
                        </div>
                    ))}
                </div>
                <SliderButtons
                    className={styles.sliderButton}
                    setActiveSlide={setActiveSlide}
                    isTransitioning={isTransitioning}
                    setIsTransitioning={setIsTransitioning}
                />
            </div>
            <DetailsModal solution={solutionObj} closeModal={closeModal} isOpen={isOpen} />
        </section>
    );
};
