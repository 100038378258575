import React, { useEffect } from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';
import { useLocation, useNavigate } from 'react-router-dom';

import { MainImageContainer } from './components/MainImageContainer';
import { MainContent } from './components/MainContent';

export const PersonalAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = 'Личный кабинет';
    }, []);

    useEffect(() => {
        // Проверяем наличие параметра в URL с ошибкой, которую попросили убрать в рамках задачи IDSHMOEX-2355
        if (location.hash.includes('error=user_denial')) {
            // Убираем хэш из URL
            const newPath = location.pathname + location.search; // сохраняем путь и параметры запроса
            navigate(newPath, { replace: true }); // переходим на новый путь без хэша
        }
    }, [location, navigate]);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <MainImageContainer />
                    <MainContent />
                    <Feedback />
                </div>
            </div>
            <Footer />
        </>
    );
};
