import classNames from 'classnames';
import { ReactComponent as CheckMark } from '@shared/images/svg/checkMarkRed.svg';
import { ReactComponent as Plus } from '@shared/images/svg/plusGray.svg';
import { options, dopOptions } from '@modules/PriceCenter/PriceBlock/data';

import styles from './styles.module.scss';

export const PriceBlock = () => {
    return (
        <section className={classNames(styles.priceBlockWrapper, 'flex')}>
            <div className={classNames(styles.descriptionWrapper, 'flex', 'flex-column')}>
                <h2>Стоимость</h2>
                <p>Абонентская плата за предоставление данных, рублей в месяц без НДС</p>
            </div>

            <div className={classNames(styles.priceWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.includeTariff, 'flex', 'flex-column')}>
                    <h4>Включено в тариф</h4>
                    <div className={classNames('flex', 'flex-column', 'flex-wrap')}>
                        {options.map((item) => (
                            <div key={item} className={classNames(styles.itemWrapper, 'flex', 'align-items-center')}>
                                <CheckMark />
                                <span>{item}</span>
                            </div>
                        ))}
                    </div>
                    <span>10000 ₽/Мес</span>
                </div>

                <div className={styles.divider} />

                <div className={classNames(styles.dopInfo, 'flex', 'flex-column')}>
                    <h4>дополнительно</h4>
                    <div className={classNames('flex', 'flex-column')}>
                        {dopOptions.map((item) => (
                            <div
                                key={item.title}
                                className={classNames(styles.dopOptionItem, 'flex', 'justify-content-between')}
                            >
                                <div className={classNames('flex')}>
                                    <Plus />
                                    <span>{item.title}</span>
                                </div>
                                <span>
                                    {item.price} <span>₽</span>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};
