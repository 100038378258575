import { useEffect, useState } from 'react';
import { PaginationController } from '@libs/components';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ReactComponent as SortIcon } from '@shared/images/priceCenter/svg/sortIcon.svg';
import { ReactComponent as SortIconUp } from '@shared/images/priceCenter/svg/sortIconUp.svg';
import { ReactComponent as SortIconDown } from '@shared/images/priceCenter/svg/sortIconDown.svg';
import classNames from 'classnames';

import { BondTemplate } from '../BondTemplate';
import styles from './styles.module.scss';

const LENGTH_OF_TABLE_PAGE = 10;

export const TableWrapper = ({ rows, cols, type }: { rows: any; cols: any; type: string }) => {
    const getDataForCurrentPage = (currentPage: number) => {
        const items =
            rows.map((item: any) => ({
                ...item,
                method_number: isNaN(Number(item.method_number))
                    ? item.method_number
                    : Number(item.method_number).toFixed(0),
            })) ?? [];

        return [...items].splice(currentPage * LENGTH_OF_TABLE_PAGE, LENGTH_OF_TABLE_PAGE);
    };

    const [currentPage, setCurrentPage] = useState(0);
    const [dataForCurrentPage, setDataForCurrentPage] = useState(getDataForCurrentPage(0));

    const handleChangeCurrentPage = (newCurrentPage: number) => {
        setCurrentPage(newCurrentPage);
        setDataForCurrentPage(getDataForCurrentPage(newCurrentPage));
    };

    useEffect(() => {
        setDataForCurrentPage(getDataForCurrentPage(currentPage));
    }, [currentPage, rows]);
    const getSortIcon = (options: any) => {
        if (options.sorted && options.sortOrder) {
            return options.sortOrder > 0 ? <SortIconUp /> : <SortIconDown />;
        } else {
            return <SortIcon />;
        }
    };

    const bond = type === 'period' && rows?.[0] ? <BondTemplate bond={rows[0]} /> : null;

    return (
        <>
            {bond}
            <DataTable
                value={dataForCurrentPage}
                className={classNames(styles.table)}
                sortIcon={getSortIcon}
                emptyMessage="Ничего не найдено"
            >
                {cols?.map((item: any) => {
                    return (<Column
key={item.field} field={item.field} header={item.header}
sortable />);
                })}
            </DataTable>
            <PaginationController
                total={Math.ceil((rows ?? [])?.length / 10)}
                current={currentPage}
                setAsyncCurrent={handleChangeCurrentPage}
                currentPageCustomClass={styles.currentPage}
                customClass={styles.customPagination}
                needMobileView={false}
            />
        </>
    );
};
