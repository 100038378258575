import { Link, useSearchParams } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { PriceCentrePermissions } from '@libs/types/subscription.type';
import { useEffect, useState } from 'react';
import { type PriceCenterProductTab, PriceCenterProductTabs } from '@modules/PriceCenterProduct/types';
import { ReactComponent as PriceCenterLogo } from '@shared/images/priceCenter/svg/PriceCenterProductLogo.svg';
import { ReactComponent as LockIcon } from '@shared/images/svg/Lock.svg';
import { useResize } from '@libs/utils';
import { SCREEN_XL } from '@libs/constants';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

import styles from './styles.module.scss';

export const priceCenterNavigations: PriceCenterProductTab[] = [
    {
        text: 'Интерактивная карта',
        tab: PriceCenterProductTabs.INTERACTIVE_MAP,
        disabled: false,
    },
    {
        text: 'Выгрузка данных',
        tab: PriceCenterProductTabs.DATA_UPLOAD,
        disabled: true,
    },
    {
        text: 'Описание API',
        tab: PriceCenterProductTabs.API_DESCRIPTION,
        disabled: true,
    },
];

type HeaderProps = {
    permissions: PriceCentrePermissions[];
};

export const Header = ({ permissions }: HeaderProps) => {
    const [tabs, setTabs] = useState<PriceCenterProductTab[]>(priceCenterNavigations);
    const [searchParams, setSearchParams] = useSearchParams();

    const productList = useSelector((state: RootState) => state.product.productList);

    const activeTab = searchParams.get('tab');

    const { width } = useResize();

    const isDesktop = width > SCREEN_XL;

    useEffect(() => {
        const tabsWithPermission: Record<PriceCenterProductTabs, boolean> = {
            [PriceCenterProductTabs.INTERACTIVE_MAP]: false,
            [PriceCenterProductTabs.DATA_UPLOAD]: !permissions.includes(PriceCentrePermissions.EXPORTS),
            // Временно недоступно
            // [PriceCenterProductTabs.API_DESCRIPTION]: !permissions.includes(PriceCentrePermissions.API_DOCS),
            [PriceCenterProductTabs.API_DESCRIPTION]: true,
        };

        const tabsByPermissions = tabs.map((tab) => ({
            ...tab,
            disabled: tabsWithPermission[tab.tab],
        }));

        setTabs(tabsByPermissions);
    }, [permissions]);

    useEffect(() => {
        if (!searchParams.has('tab')) {
            setSearchParams({ tab: PriceCenterProductTabs.INTERACTIVE_MAP });
        }
    }, [searchParams]);

    const newMobileMenu = (
        <ul id="module-navigation" className={classNames(styles.mobileMenu)}>
            {tabs.map((i) => {
                return (
                    <li
                        id="module-navigation-tab"
                        data-label={i.disabled ? 'tab-disabled' : 'tab'}
                        className={classNames(
                            'flex gap-2 align-items-center cursor-pointer',
                            activeTab === i.tab ? styles.activeTabMobile : styles.inactiveTabMobile,
                            {
                                [styles.disabledLink]: i.disabled,
                            },
                        )}
                        key={i.tab}
                        aria-disabled={i.disabled}
                    >
                        {i.disabled && (
                            <div className={styles.icon}>
                                <LockIcon />
                            </div>
                        )}

                        <span>{i.text}</span>
                    </li>
                );
            })}
        </ul>
    );

    const desktopMenuLayout = (
        <nav className="w-full flex justify-content-between">
            <Link
                to={
                    '/' +
                    (productList.find((el) => el.mnemoCode === ProductMnemoCodes.TPC_CENOVOI_CENTR)?.landingPage ??
                        'products/priceCenter')
                }
                replace
            >
                <PriceCenterLogo className={styles.headerLogo} />
            </Link>
            <ul className={styles.tabWrapper}>
                {tabs.map(({ text, tab, disabled }, index) => (
                    <li
                        key={tab}
                        className={classNames(
                            styles.tabItem,
                            activeTab === tab ? styles.activeTab : styles.inactiveTab,
                            {
                                [styles.disabledLink]: disabled,
                            },
                        )}
                        onClick={() => !disabled && setSearchParams({ tab })}
                    >
                        {disabled && (
                            <div className={styles.icon}>
                                <LockIcon />
                            </div>
                        )}
                        <div>{text}</div>
                    </li>
                ))}
            </ul>
        </nav>
    );

    return <div className={classNames(styles.headerWrapper)}>{isDesktop ? desktopMenuLayout : newMobileMenu}</div>;
};
