import {
    DataTable,
    type DataTableSortEvent,
    type DataTableProps,
    type DataTableValueArray,
} from 'primereact/datatable';
import { Column as ColumnComponent } from 'primereact/column';
import classNames from 'classnames';
import { ColoredCheckbox } from '@modules/ProPack/shared/components/ColoredCheckbox';
import { ReactComponent as SortIcon } from '@shared/images/priceCenter/svg/sortIcon.svg';
import { ReactComponent as SortIconUp } from '@shared/images/priceCenter/svg/sortIconUp.svg';
import { ReactComponent as SortIconDown } from '@shared/images/priceCenter/svg/sortIconDown.svg';
import { useState } from 'react';

import styles from './styles.module.scss';
import { type SelectedObject, type TableWithCheckBoxColumn } from './types';

type TableWithCheckBoxProps = {
    data: DataTableValueArray;
    columns: Array<TableWithCheckBoxColumn<any>>;
    selected: Array<SelectedObject<any>>;
    handleSelect: (arg: SelectedObject<any>) => void;
} & Omit<DataTableProps<any>, 'data' | 'columns'>;

export const TableWithCheckBox = ({ data, columns, selected, handleSelect }: TableWithCheckBoxProps) => {
    const [sortField, setSortField] = useState<DataTableSortEvent['sortField']>('');
    const [sortOrder, setSortOrder] = useState<DataTableSortEvent['sortOrder']>(null);

    const onSort = (e: DataTableSortEvent) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    };

    const getSortIcon = () => {
        if (sortField && sortOrder) {
            return sortOrder > 0 ? <SortIconUp /> : <SortIconDown />;
        } else {
            return <SortIcon />;
        }
    };
    const checkboxTemplate = (rowData: SelectedObject<any>) => {
        const selectedRow = selected.find((item: any) => item.assetId === rowData.assetId);

        const handleSelectChange = () => handleSelect({
                ...rowData,
            });

        return (
            <ColoredCheckbox
                handleClick={handleSelectChange}
                checkedColor={selectedRow?.color ?? 'black'}
                checked={!!selectedRow}
            />
        );
    };

    return (
        <DataTable
            selection={selected}
            selectionMode="multiple"
            pt={{
                thead: { className: classNames(styles.tableHeader) },
                headerRow: { className: classNames(styles.tableHeaderRow) },
                tbody: { className: classNames(styles.content) },
            }}
            value={data}
            scrollable
            scrollHeight="526px"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            sortIcon={getSortIcon()}
            emptyMessage="Ничего не найдено"
        >
            <ColumnComponent body={checkboxTemplate} />
            {columns.map(
                ({
                    id,
                    label,
                    className,
                    field,
                    render,
                    align = 'right',
                    width,
                    fontSize,
                    fontWeight,
                    sortable = false,
                }) => {
                    const getRenderValue = () => {
                        if (render) return render;

                        return (option: any) => {
                            return option[field!];
                        };
                    };

                    return (
                        <ColumnComponent
                            key={id}
                            field={field as string}
                            body={(option: any) => <span className={styles[align]}>{getRenderValue()(option)}</span>}
                            header={label}
                            style={{ width, fontSize, fontWeight }}
                            className={classNames(styles.tableColumn, className)}
                            align={align}
                            sortable={sortable}
                        />
                    );
                },
            )}
        </DataTable>
    );
};
