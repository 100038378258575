import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse } from 'axios';
import { PageModelDatashopTariff, ResponseInfo, TariffFilter } from '@libs/types';
import { TariffPlanCostInfo, TariffPlanCostReq } from '@libs/types/tariff/tariffPlanCost.type';

export const TARIFF_URL = '/moex-datashop-productservice/api/tariff/v1/';

export const getTariffs = (body: TariffFilter): Promise<AxiosResponse<ResponseInfo<PageModelDatashopTariff>>> =>
    httpClient.post(TARIFF_URL + 'list', body);

export const getCost = (body: TariffPlanCostReq): Promise<AxiosResponse<ResponseInfo<TariffPlanCostInfo[]>>> =>
    httpClient.post(TARIFF_URL + 'get-cost', body);
