import classNames from 'classnames';
import { Checkbox } from '@libs/components';
import { ReactComponent as QuestionIcon } from '@shared/images/svg/question.svg';
import { useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

type Props = {
    value: boolean;
    setValue: (value: boolean) => void;
};

export const RenewalCheckbox = ({ value, setValue }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [topValue, setTopValue] = useState<string>('0');
    const [showPanel, setShowPanel] = useState<boolean>(false);

    const handleResize = () => {
        if (ref && ref.current) {
            const boundingObj = ref.current.getBoundingClientRect();
            setTopValue(`${boundingObj.bottom}px`);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={classNames(styles.checkboxWrapper, 'flex', 'justify-content-between', 'align-items-center')}>
            <div ref={ref} className={classNames('flex')}>
                <span>Автопродление</span>
                <QuestionIcon
                    onClick={() => {
                        handleResize();
                        setShowPanel((prev) => !prev);
                    }}
                />
                {showPanel && (
                    <div className={styles.overlayPanelWrapper} style={{ top: topValue }}>
                        <p>
                            <span>Внимание.</span> Устанавливая флаг автопродления, выбранные вами условия на следующий
                            период, будут автоматически продлеваться по истечению срока действующей подписки.
                        </p>
                    </div>
                )}
            </div>
            <Checkbox className={styles.checkbox} onClick={() => setValue(!value)} checked={value} />
        </div>
    );
};
