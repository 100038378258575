import { type Dispatch, type SetStateAction } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowRed } from '@shared/images/svg/ArrowRed.svg';
import { ReactComponent as ArrowWhite } from '@shared/images/svg/ArrowWhite.svg';

import styles from './styles.module.css';

type Props = {
    setActiveSlide: Dispatch<SetStateAction<number>>;
    className?: string;
    isTransitioning?: boolean;
    setIsTransitioning?: Dispatch<SetStateAction<boolean>>;
};

export const SliderButtons = ({ setActiveSlide, className, isTransitioning, setIsTransitioning }: Props) => {
    const increment = () => {
        if (!isTransitioning) {
            setActiveSlide((prev) => prev + 1);

            if (setIsTransitioning) {
                setIsTransitioning(true);
            }
        }
    };

    const decrement = () => {
        if (!isTransitioning) {
            setActiveSlide((prev) => prev - 1);

            if (setIsTransitioning) {
                setIsTransitioning(true);
            }
        }
    };

    return (
        <div className={classNames('flex', className)}>
            <button onClick={decrement} className={classNames(styles.Button, styles.LeftButton)}>
                <div className={classNames(styles.IconWrapper, styles.Red)}>
                    <div>{<ArrowRed />}</div>
                </div>
            </button>
            <button onClick={increment} className={classNames(styles.Button, styles.RightButton)}>
                <div className={classNames(styles.IconWrapper, styles.White)}>
                    <div>{<ArrowWhite />}</div>
                </div>
            </button>
        </div>
    );
};
