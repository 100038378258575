import { type PlotData } from 'plotly.js';
import { useRef } from 'react';
import Plot from 'react-plotly.js';
import { withModeBarConfig } from '@modules/ProPack/shared/utils/plotConfig';
import { useResize } from '@libs/utils';

import './style.scss';
import { ModeBar } from '../../../../shared/components/ModeBar';

const DEFAULT_3DCHART_POSITION = { x: 1.64, y: 1.64, z: 1.1 };

type SurfacechartProps = {
    surfaceData: Partial<PlotData> | any;
};

export const SurfaceChart = ({ surfaceData }: SurfacechartProps) => {
    const xValues: Plotly.Datum[] | any = surfaceData?.x?.[0];
    const yValues: Plotly.Datum[] | any = surfaceData?.y?.map((arrValue: any) => arrValue[0]);
    const eyePositionRef = useRef<Plotly.Point | undefined>(DEFAULT_3DCHART_POSITION);
    const { isScreenLg, isScreenMd, isScreenSm, isScreenLessSm } = useResize();
    const chartSize = { width: 734, height: 440 };
    const surfaceHoverTemplate =
        '<br>Страйк(Y):   <b>%{y}</b><br>Волатильность(Z):   <b>%{z:.2f} </b><br>Дней до погашения(X):  <b>%{x}</b></br><extra></extra>';

    if (isScreenLessSm || isScreenSm) {
        chartSize.width = 324;
        chartSize.height = 392.12;
    } else if (isScreenMd) {
        chartSize.width = 718;
        chartSize.height = 436.12;
    } else if (isScreenLg) {
        chartSize.width = 648;
        chartSize.height = 438;
    } else {
        chartSize.width = 734;
        chartSize.height = 440;
    }
    const addSurfaceData = [
        {
            ...surfaceData,
            type: 'surface',
            colorscale: 'Bluered',
            hovertemplate: surfaceHoverTemplate,
            colorbar: {
                x: 0,
                xanchor: 'left',
                y: 0,
                yanchor: 'bottom',
                len: 0.4,
                borderwidth: 0,
                outlinecolor: 'transparent',
                tickfont: { color: '#88919A', family: 'Inter', size: 11 },
                title: {
                    font: {
                        color: '#576273',
                        family: 'Inter',
                        size: 11,
                        weight: 'bold',
                    },
                    text: '% годовых',
                },
                tickformat: '.1f',
                ticklabelposition: 'outside-right',
                thickness: 30,
            },
            contours: {
                x: {
                    highlight: true,
                    highlightcolor: 'white',
                },
            },
            opacity: 0.5,
        },
    ] as Plotly.Data[];

    const layoutSurfaceData: Partial<Plotly.Layout> = {
        width: chartSize.width,
        height: chartSize.height,
        margin: {
            l: 50,
            r: 0,
            b: 0,
            t: 10,
            pad: 5,
        },
        scene: {
            xaxis: {
                title: 'Дней до погашения',
                linewidth: 2,
                mirror: true,
                showspikes: false,
                spikesides: false,
                autorange: 'reversed',
                tickangle: 2,
                zeroline: false,
                categoryorder: 'array',
                categoryarray: xValues,
                linecolor: '#6C7073',
            },
            yaxis: {
                title: 'Страйк',
                showspikes: false,
                spikesides: false,
                tickangle: 2,
                zeroline: false,
                type: 'category',
                categoryorder: 'array',
                categoryarray: yValues,
                linewidth: 2,
                mirror: true,
                linecolor: '#6C7073',
            },
            zaxis: {
                title: 'Волатильность',
                spikecolor: 'blue',
                zeroline: false,
                linewidth: 2,
                mirror: true,
                linecolor: '#6C7073',
            },
            camera: {
                eye: eyePositionRef.current,
            },
        },
        showlegend: false,
        hoverlabel: {
            bgcolor: '#FFF',
        },
        autosize: true,
        font: {
            family: 'Inter',
            size: 12,
            color: '#6C7073',
        },
        paper_bgcolor: 'rgba(0,0,0,0)',
    };
    const relayoutHandler = (event: Readonly<Plotly.PlotRelayoutEvent | any>) => {
        if (Object.keys(event).length) {
            eyePositionRef.current = event['scene.camera']?.eye;
        }
    };

    const chartRef = useRef<Plot>(null);

    return (
        <div className="custom-volat-surface-chart">
            <Plot
                ref={chartRef}
                data={addSurfaceData}
                layout={layoutSurfaceData}
                onRelayout={relayoutHandler}
                config={withModeBarConfig}
            />
            {surfaceData?.x?.length > 0 ? <div className="custom-volat-surface-chart__volatColorbar" /> : null}
            {surfaceData?.x?.length > 0 ? <ModeBar chartRef={chartRef} /> : null}
        </div>
    );
};
