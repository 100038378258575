import classNames from 'classnames';
import { ReactComponent as ArrowLeft } from '@shared/images/svg/ArrowLeft.svg';
import { tabs } from '@modules/Algopack/AboutAlgopackPage/components/MainContent/data';
import { type Reference } from '@modules/HelpCenter/HelpCenterPage/services/helpCenter.types';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

type Props = {
    setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    activeIndex?: number;
    reference: Reference;
    articleId: string;
};

export const ChangePageTabs = ({ activeIndex, setActiveIndex, reference, articleId }: Props) => {
    const index = reference.articles.findIndex((item) => item.article_id === +articleId);
    const navigate = useNavigate();

    const getTheNextName = () => reference.articles[index + 1]?.title;
    const getThePrevName = () => reference.articles[index - 1]?.title;

    const isShowTheNextButton = !!getTheNextName();
    const isShowThePrevButton = !!getThePrevName();

    const changeActivePage = (isIncrease?: boolean) => {
        const newIndex = isIncrease ? (index || 0) + 1 : (index || 0) - 1;
        setActiveIndex(newIndex);
        navigate(`/help-center/references/${reference.id}/article/${reference.articles[newIndex].article_id}`);
        window.scrollTo(0, 0);
    };

    return (
        <div
            className={classNames(
                styles.changePageWrapper,
                'flex',
                !isShowThePrevButton && isShowTheNextButton ? 'justify-content-end' : 'justify-content-between',
            )}
        >
            {isShowThePrevButton && (
                <div className={classNames('flex', 'justify-content-start')} onClick={() => changeActivePage(false)}>
                    <div
                        className={classNames(
                            styles.iconWrapper,
                            'flex',
                            'justify-content-center',
                            'align-items-center',
                        )}
                    >
                        <ArrowLeft className={styles.rotate} />
                    </div>
                    <div
                        className={classNames(
                            'flex',
                            'justify-content-start',
                            'flex-column',
                            activeIndex === tabs.length - 1 && styles.onRight,
                        )}
                    >
                        <p>{getThePrevName()}</p>
                        <span>Ранее</span>
                    </div>
                </div>
            )}
            {isShowTheNextButton && (
                <div className={classNames('flex', 'justify-content-end')} onClick={() => changeActivePage(true)}>
                    <div
                        className={classNames(
                            'flex',
                            'justify-content-start',
                            'flex-column',
                            activeIndex === 0 && styles.onLeft,
                        )}
                    >
                        <p>{getTheNextName()}</p>
                        <span>Далее</span>
                    </div>
                    <div
                        className={classNames(
                            styles.iconWrapper,
                            'flex',
                            'justify-content-center',
                            'align-items-center',
                        )}
                    >
                        <ArrowLeft />
                    </div>
                </div>
            )}
        </div>
    );
};
