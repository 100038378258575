import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse } from 'axios';
import { PageableType, ResponseInfo } from '@libs/types';
import { Article } from '@libs/types/articles/article.types';
import { PageModelArticleList } from '@libs/types/articles/articleListItem.type';

export const ARTICLES_URL = '/moex-datashop-reference-service/api/articles/v1';

export const getArticle = (id: string): Promise<AxiosResponse<Article>> => httpClient.get(`${ARTICLES_URL}/${id}`);

export const getArticles = (body: {
    pageable: PageableType;
}): Promise<AxiosResponse<ResponseInfo<PageModelArticleList>>> => httpClient.post(`${ARTICLES_URL}/search`, body);
