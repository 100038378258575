export const tariffItems = [
    {
        title: 'Инструменты валютного рынка',
        price: '6 000',
    },
    {
        title: 'Инструменты фондового рынка',
        price: '6 000',
    },
    {
        title: 'Инструменты срочного рынка',
        price: '6 000',
    },
    {
        title: 'Инструменты срочного рынка и относительные ставки',
        price: '10 000',
    },
];
