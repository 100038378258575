import interestRatesIcon from '@modules/ProPackLanding/shared/images/svg/navigationIcons/interestRates.svg';
import volatilityIcon from '@modules/ProPackLanding/shared/images/svg/navigationIcons/volatility.svg';
import duplicatesIISIcon from '@modules/ProPackLanding/shared/images/svg/navigationIcons/duplicatesIIS.svg';
import riskRatesIcon from '@modules/ProPackLanding/shared/images/svg/navigationIcons/riskRates.svg';
import markingFinInstrumentsIcon from '@modules/ProPackLanding/shared/images/svg/navigationIcons/markingFinInstruments.svg';

export const navigationItems = [
    {
        icon: interestRatesIcon,
        title: 'Процентные ставки',
        description:
            'Сервис анализа временной структуры процентных ставок, включающий в себя семейство кривых на рынках облигаций (КБД), РЕПО (индикаторы RUSFAR), СПФИ (дисконтные, форвардные, СВОП-кривые) Московской Биржи',
        navigationRef: 'interestRatesRef',
    },
    {
        icon: volatilityIcon,
        title: 'Волатильность',
        description: 'Вмененная волатильность базового актива опциона, соответствующая различным страйкам',
        navigationRef: 'volatilityRef',
    },
    {
        icon: duplicatesIISIcon,
        title: 'Дубликаты ИИС',
        description: 'Онлайн сервис проверки Индивидуальных Инвестиционных Счетов физических лиц на задвоенность',
        navigationRef: 'duplicatesIISRef',
    },
    {
        icon: riskRatesIcon,
        title: 'Ставки риска',
        description:
            'Индикативные ставки риска, рассчитываемые НКЦ для инструментов, с которыми могут совершаться сделки купли-продажи и/или РЕПО на рынках Московской Биржи',
        navigationRef: 'riskRatesRef',
    },
    {
        icon: markingFinInstrumentsIcon,
        title: 'Маркировка финансовых инструментов',
        description:
            'Источник данных для разграничения доступа к финансовым инструментам для различных категорий клиентов в автоматизированных системах',
        navigationRef: 'markingFinInstrumentsRef',
    },
];
