import markToMarket from '../../shared/images/png/interestRates/markToMarket.png';
import interestRateDynamics from '../../shared/images/png/interestRates/interestRateDynamics.png';
import yieldCurvePlotting from '../../shared/images/png/interestRates/yieldCurvePlotting.png';
import ratesInOneWindow from '../../shared/images/png/interestRates/ratesInOneWindow.png';
import multiAnalysis from '../../shared/images/png/interestRates/multiAnalysis.png';

export const productCapabilities = [
    {
        image: markToMarket,
        title: 'Mark-to-Market',
        description: 'Определение текущей рыночной стоимости финансовых инструментов',
        points: [],
    },
    {
        image: interestRateDynamics,
        title: 'Динамика процентной ставки',
        description:
            'Визуальный анализ динамики процентных ставок для выбранных инструментов на заданные сроки исполнения и период времени',
        points: [],
    },
    {
        image: yieldCurvePlotting,
        title: 'Построение кривой доходности',
        description: 'Информативный график поверхностей и кривых в 3D для различных инструментов за выбранный период',
        points: [],
    },
    {
        image: ratesInOneWindow,
        title: 'Вся информация о временной структуре ставок в одном окне',
        description: 'Возможность сравнивать ставки между рынками облигаций, РЕПО и СПФИ',
        points: [],
    },
    {
        image: multiAnalysis,
        title: 'Многомерный анализ индикаторов временной стоимости денег',
        description: 'Графическое представление динамики ставок за несколько лет для всех сроков (от 1 дня до 10 лет).',
        points: [],
    },
];
