import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';

export type FileDownloadReqBody = {
    isin: string[];
    date: Date[];
    fileType: string;
    fileFormat: string;
};

export type FileFormat = 'csv' | 'xls';

export const DATAUPLOAD_FILE_URL = ' /moex-propack-integrationservice/api/v1/download/instruments-file/isin';

export const getFileDownload = (
    isin: string[],
    dates: string[],
    fileFormat: FileFormat,
    fileType: string,
): Promise<AxiosResponse<File>> => httpClient.post(DATAUPLOAD_FILE_URL, { isin, date: dates, fileType, fileFormat });
