import { Calendar } from 'primereact/calendar';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { type Nullable } from 'primereact/ts-helpers';
import { marketData, firstDateWithData, initSurfaceEndDate } from '@modules/ProPack/components/YieldCurves/dataConfig';
import classNames from 'classnames';

import { type Curve, type CurveGroup, type Market, type CalculateTime } from '../../types';
import { ReactComponent as InfoIcon } from '../../../../shared/images/svg/InfoIcon.svg';
import styles from './styles.module.scss';

type SurfaceControlPanelProps = {
    calendarDates: Date[] | null;
    setCalendarDates: (arg: Nullable<Array<Date | null>>) => void;
    selectedMarket: Market;
    handleMarketChange: (item: Market) => void;
    selectedCurveGroup: CurveGroup;
    handleCurveGroupChange: (curveGroupName: string) => void;
    selectedCurve: Curve;
    handleCurveChange: (curveName: string) => void;
    selectedCalculateTime?: CalculateTime;
    handleCalculateTimeChange: (time: number) => void;
};

export const SurfaceControlPanel = ({
    calendarDates,
    setCalendarDates,
    selectedMarket,
    handleMarketChange,
    selectedCurveGroup,
    handleCurveGroupChange,
    selectedCurve,
    handleCurveChange,
    selectedCalculateTime,
    handleCalculateTimeChange,
}: SurfaceControlPanelProps) => {
    return (
        <div className={classNames(styles.controlsWrapper)}>
            <div className={styles.selectorWrapper}>
                {marketData.map((item: Market) => (
                    <div
                        key={item.id}
                        onClick={() => handleMarketChange(item)}
                        className={classNames(
                            styles.labelSelector,
                            selectedMarket?.label === item.label && styles.activeSelector,
                        )}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
            <div className={classNames(styles.bottomSection)}>
                <div className={classNames(styles.dropdownWrapper)}>
                    <div className={classNames(styles.titleWrapper)}>
                        <p>Выбрать период</p>
                    </div>
                    <div className={classNames(styles.calendarWrapper)}>
                        <Calendar
                            value={calendarDates}
                            onChange={(e) => setCalendarDates(e.value)}
                            selectionMode="range"
                            readOnlyInput
                            dateFormat="dd.mm.yy"
                            locale="ru"
                            showIcon
                            minDate={selectedCurve?.firstDate ? new Date(selectedCurve?.firstDate) : firstDateWithData}
                            maxDate={new Date()}
                            hideOnRangeSelection
                            pt={{
                                header: {
                                    className: styles.calendarHeader,
                                },
                                tableBody: { className: styles.calendarTableBody },
                                dropdownButton: { root: { className: styles.calendarDropdownButton } },
                            }}
                            inputClassName={classNames(styles.calendarInput)}
                            panelClassName={classNames(styles.surfaceCalendarPanel)}
                        />
                    </div>
                </div>
                <p className={styles.divider} />
                <div className={classNames(styles.dropdownsBlockWrapper)}>
                    {selectedMarket.curveGroups.length > 1 ? (
                        <div className={classNames(styles.dropdownWrapper)}>
                            <div className={classNames(styles.titleWrapper, 'inline-flex')}>
                                <p>{selectedMarket.groupTitle}</p>
                                {/* <InfoIcon /> */}
                            </div>
                            <div className={classNames('flex justify-content-start', styles.smallDropdown)}>
                                <Dropdown
                                    value={selectedCurveGroup}
                                    onChange={(e) => handleCurveGroupChange(e.value)}
                                    options={selectedMarket.curveGroups}
                                    optionValue="value"
                                    optionLabel="label"
                                    placeholder={selectedCurveGroup.label}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className={classNames(styles.dropdownWrapper)}>
                        <div className={classNames(styles.titleWrapper, 'inline-flex')}>
                            <p>{selectedMarket.curveTitle}</p>
                            {/* <InfoIcon /> */}
                        </div>
                        <div
                            className={classNames(
                                'flex justify-content-start',
                                selectedMarket.curveGroups.length > 1 ? styles.smallDropdown : styles.dropdown,
                                styles.curveDropdown,
                            )}
                        >
                            <Dropdown
                                value={selectedCurve}
                                optionValue="value"
                                onChange={(e) => handleCurveChange(e.value)}
                                options={selectedCurveGroup.curves}
                                optionLabel="label"
                                placeholder={selectedCurve.label}
                            />
                        </div>
                    </div>
                    {selectedCurveGroup.times && (
                        <div className={classNames(styles.dropdownWrapper)}>
                            <div className={classNames(styles.titleWrapper, 'inline-flex')}>
                                <p>Время</p>
                            </div>
                            <div
                                className={classNames(
                                    'flex justify-content-start',
                                    selectedMarket.curveGroups.length > 1 ? styles.smallDropdown : styles.dropdown,
                                    styles.curveDropdown,
                                )}
                            >
                                <Dropdown
                                    value={selectedCalculateTime}
                                    optionValue="value"
                                    onChange={(e) => handleCalculateTimeChange(e.value)}
                                    options={selectedCurveGroup.times}
                                    optionLabel="label"
                                    placeholder={selectedCalculateTime?.label}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
