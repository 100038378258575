export const documentationBlocks = [
  {
    title: 'Методика расчета',
    note: 'См. пункты 3, 4, 6, 7',
    downloadURL: 'https://www.nationalclearingcentre.ru/catalog/0302/6926',
    downloadText: 'Скачать методику',
    target: '_blank',
  },
  {
    title: 'Способ получения',
    note: 'ИСС (форматы xml, csv, json, html)',
    links: [
      {text: 'Руководство для разработчиков', link: 'https://fs.moex.com/files/6523  '},
      {text: 'Описание методов ИСС', link: 'https://iss.moex.com/iss/reference/'},
    ],
    target: '_blank',
  },
  {
    title: 'Фондовый рынок',
    note: 'Описание полей (csv) и примеры данных (csv, xml, json)',
    descriptionURL: '/download/riskRates/irr-stock-columns.csv',
    exampleURL: '/download/riskRates/irr-stock.csv',
  },
  {
    title: 'Валютный рынок',
    note: 'Описание полей (csv) и примеры данных (csv, xml, json)',
    descriptionURL: '/download/riskRates/irr-currency-columns.csv',
    exampleURL: '/download/riskRates/irr-currency.csv',
  },
  {
    title: 'Срочный рынок',
    note: 'Описание полей (csv), примеры по инструментам и примеры относительных ставок (csv, xml, json)',
    descriptionURL: '/download/riskRates/irr-futures-columns.csv',
    exampleURL: '/download/riskRates/irr-futures-pair.csv',
  },
  {
    title: 'Условия предоставления данных',
    downloadURL: 'https://fs.moex.com/files/8611',
    downloadText: 'Запросить',
    target: '_blank',
  },
];
