import { ChangeEvent, useState } from 'react';
import { searchObjectsThunk } from '@store/store/thunk/search.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { SearchEntityType, SearchObject } from '@libs/types/search.type';

export const useSearch = (
    pageSize: number,
    value: string | undefined = '',
    type: SearchEntityType[] | undefined = ['ARTICLE', 'REFERENCE'],
) => {
    const dispatch = useDispatch<AppDispatch>();

    const [searchValue, setSearchValue] = useState<string>(value);
    const [entitySearch, setEntitySearch] = useState<SearchEntityType[]>(type);
    const [resultList, setResultList] = useState<SearchObject[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

    const search = (searchValue: string) => {
        dispatch(
            searchObjectsThunk({
                searchText: searchValue,
                entities: entitySearch,
                pageable: { pageNumber: currentPage, pageSize: pageSize },
            }),
        )
            .then((res) => {
                const result = unwrapResult(res);
                setResultList(result.rows);
                setTotalPages(result.totalPages);
            })
            .catch((e) => console.error(e));
    };

    const debouncedSearch = debounce(search, 300);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        if (!isOpenDropdown) {
            setIsOpenDropdown(true);
        }
    };

    return {
        debouncedSearch,
        searchValue,
        setSearchValue,
        entitySearch,
        setEntitySearch,
        resultList,
        totalPages,
        setTotalPages,
        handleInputChange,
        isOpenDropdown,
        setIsOpenDropdown,
        currentPage,
        setCurrentPage,
    };
};
