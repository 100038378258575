import { useEffect } from 'react';
import { Footer, Navigation, ContentSlider, GainAccess } from '@libs/components';
import { Header } from '@modules/PriceCenter/Header';
import { ProductCapabilities } from '@modules/PriceCenter/ProductCapabilities';
import { contentSliderList } from '@modules/PriceCenter/data';
import { type RefObjectType } from '@libs/types';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { checkSubscriptionThunk } from '@store/store/thunk/pricecenter/subscription.thunk';
import { PriceBlock } from '@modules/PriceCenter/PriceBlock';
import { addMetaTag, removeMetaTag } from '@libs/utils';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

export const PriceCenter = () => {
    const dispatch = useDispatch<AppDispatch>();
    const refs: RefObjectType<HTMLDivElement> = {};

    const productGuids = useSelector((state: RootState) => state.product.productGuids);
    const isProductLoading = useSelector((state: RootState) => state.product.isLoading);

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    useEffect(() => {
        document.title = 'Ценовой центр';
        addMetaTag('title', 'Ценовой центр - сервис по анализу и оценке стоимости ценных бумаг');
        addMetaTag(
            'description',
            'Сервис “Ценовой центр” — надежный инструмент для оценки стоимости и риска ценных бумаг. Пользуйтесь данными НРД для определения справедливой стоимости активов, включая ликвидные и неликвидные сегменты рынка, для обоснованных инвестиционных решений.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    useEffect(() => {
        if (
            !productGuids[ProductMnemoCodes.TPC_CENOVOI_CENTR] ||
            productGuids[ProductMnemoCodes.TPC_CENOVOI_CENTR] === ''
        ) {
            return;
        }
        dispatch(checkSubscriptionThunk(productGuids[ProductMnemoCodes.TPC_CENOVOI_CENTR]));
    }, [productGuids]);

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <Header refs={refs} />
                    <ProductCapabilities />
                    <ContentSlider list={contentSliderList} />
                    {!isProductLoading && (
                        <GainAccess
                            setRefs={setChildRefs}
                            productGuid={productGuids[ProductMnemoCodes.TPC_CENOVOI_CENTR]}
                        />
                    )}
                    <PriceBlock />
                </div>
            </div>

            <Footer />
        </>
    );
};
