import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { isEmpty } from 'lodash';
import { Button } from 'primereact/button';
import {
    type PriceCenterBond,
    type PriceCenterChart,
    type PriceCenterChartPoint,
    type PriceCenterValuation,
} from '@store/store/thunk/pricecenter/Models';
import { ReactComponent as PinIcon } from '@shared/images/priceCenter/svg/pinIcon.svg';
import {
    getBorderColor,
    getPriceProfitCheckboxColor,
    priceCenterPriceProfitColor,
} from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/utils';

import styles from './styles.module.scss';

export type PointProps = PriceCenterChartPoint<PriceCenterBond> | PriceCenterChartPoint<PriceCenterValuation>;

export type MarketPointPanelProps = {
    point: PointProps;
    onClick?: (point: PointProps, e: React.SyntheticEvent) => void;
    selected?: boolean;
    tossPoints?: (point: any) => void;
};

/**
 * Шаблон тултипа Карты рынка
 */
export const MarketPointPanel = (props: MarketPointPanelProps) => {
    const {
        point,
        onClick = () => {
            return null;
        },
        selected,
        tossPoints = () => {
            return null;
        },
    } = props;

    const { name } = point.rawData;
    const { chartName } = point;

    const isIndexTypeGraph = ['RGBI', 'RUCBTRNS', 'RUMBTRNS'].includes(point.chartCode);
    const isPointIndexType = isIndexTypeGraph ? point.chartCode : null;

    const valueColor = isIndexTypeGraph
        ? getPriceProfitCheckboxColor(point)
        : getBorderColor(point.type ?? point.rawData.securityType);

    return (
        <div className={classNames(styles.marketPointPanel)}>
            <Button
                type="button"
                className={classNames(styles.panelItemButton)}
                onClick={(e) => {
                    e.stopPropagation();
                    tossPoints(point);
                }}
            >
                <span>{isPointIndexType || name || chartName || 'Нет названия'}</span>
                <span
                    style={{
                        color: valueColor,
                    }}
                >
                    {`${point?.rawData?.value.toFixed(2)}%`}
                </span>
            </Button>

            <Button type="button" className={classNames(styles.panelPinButton)} onClick={(e) => onClick(point, e)}>
                <PinIcon
                    fill={selected ? 'var(--ds-main-blue)' : 'var(--ds-main-light-gray)'}
                    stroke={selected ? 'var(--ds-main-blue)' : 'var(--ds-main-light-gray)'}
                />
            </Button>
        </div>
    );
};

export const IndexTooltip = (props: PricePointPanelProps) => {
    return (
        <div className={classNames(styles.indextTooltip)}>
            <span>{`${props.point?.rawData?.value}`}</span>
        </div>
    );
};

export type PricePointPanelProps = {
    point: PriceCenterChartPoint<PriceCenterValuation>;
    onClick?: (point: PriceCenterChartPoint<PriceCenterValuation>) => void;
};

/**
 * Шаблон тултипа Цены/Доходности
 */
export const PricePointPanel = (props: PricePointPanelProps) => {
    const {
        point,
        onClick = () => {
            return null;
        },
    } = props;

    return (
        <Button
            type="button"
            className={classNames(styles.pricePointPanel)}
            onClick={() => {
                if (onClick) {
                    onClick(point);
                }
            }}
        >
            <span
                style={{
                    color: priceCenterPriceProfitColor[point?.datasetIndex],
                }}
            >
                {`${point?.rawData?.value}`}
            </span>
        </Button>
    );
};

export type PointHoverData<T> = {
    groupChartCode: string;
    styles: React.CSSProperties;
    point: PriceCenterChartPoint<T>;
    chartCode: string;
};

/**
 * Позиционирование и отрисовка блока тултипа
 * по заданным координатам
 */
export const PointHover = (props: PointHoverData<any>) => {
    const [target, setTarget] = useState<Element>(document.body);
    const [tooltipType, setTootipType] = useState<string>('default');

    useEffect(() => {
        const element = document.getElementById(`${props.groupChartCode}-hover-tooltip`);

        const indexType = ['RGBI', 'RUCBTRNS', 'RUMBTRNS'].includes(props.chartCode);

        const defaultView = (element: HTMLElement) => {
            element.style.opacity = '1';
            element.style.top = String(props.styles.top) ?? '0';
            element.style.left = String(props.styles.left) ?? '0';
            setTarget(element);
            setTootipType('default');
        };

        const indexView = (element: HTMLElement) => {
            element.style.opacity = '1';
            element.style.top = String(props.styles.top) ?? '0';
            element.style.left = String(props.styles.left) ?? '0';
            setTarget(element);
            setTootipType('index');
        };

        if (element && !indexType) defaultView(element);

        if (element && indexType) indexView(element);
    }, [props]);

    const view =
        tooltipType === 'default' ? <MarketPointPanel point={props.point} /> : <IndexTooltip point={props.point} />;

    return createPortal(view, target);
};

/**
 * Функция настроек внешнего тултипа для ChartJS.
 * Забирает координаты тултипа с кладет их в стейт
 */
export const priceCenterTooltipCallback = (
    groupChartCode: string,
    charts: Array<PriceCenterChart<any[]>>,
    selectedPoints: Array<PriceCenterChartPoint<any>>,
    onHover: (object: PointHoverData<any> | null) => void,
) => {
    return (context: any) => {
        if (context.tooltip.opacity !== 0) {
            const tooltipDataPoint = context.tooltip.dataPoints[0];

            if (tooltipDataPoint) {
                const { datasetIndex } = tooltipDataPoint;
                const pointIndex = tooltipDataPoint.dataIndex;

                // не показываем тултип для кривой доходности
                if (groupChartCode === 'market' && datasetIndex === 0) {
                    return;
                }

                const alreadySelected = selectedPoints.find(
                    (point) => point.datasetIndex === datasetIndex && point.dataPointIndex === pointIndex,
                );

                if (alreadySelected === undefined) {
                    const chart = charts[datasetIndex];
                    const chartPoint = chart.data[pointIndex];

                    const indexType = ['RGBI', 'RUCBTRNS', 'RUMBTRNS'].includes(chart.chartCode);
                    const marketChart = ['LOCAL_BONDS_CHART', 'GOV_BONDS_CHART', 'CORP_BONDS_CHART'].includes(
                        chart.chartCode,
                    );

                    if (chartPoint) {
                        const meta = context.chart.getDatasetMeta(datasetIndex);
                        const coordinates = meta.data[pointIndex].getCenterPoint();

                        const tooltipMaxWidth = indexType ? 60 : 200;

                        const tooltipMaxHeight = indexType ? 45 : 37;

                        const getXCoodTrueMargin = indexType ? 20 : 0;
                        const getXCoodFalseMargin = indexType ? tooltipMaxWidth + 20 : tooltipMaxWidth - 20;
                        const getYCoordTrueMargin = indexType ? -15 : 8;
                        const getYCoordFalseMargin = indexType ? 15 : 43;

                        const getXcoord =
                            context.chart.chartArea.right - coordinates.x > tooltipMaxWidth
                                ? coordinates.x + getXCoodTrueMargin
                                : coordinates.x - getXCoodFalseMargin;
                        const getYcoord =
                            coordinates.y - context.chart.chartArea.top < tooltipMaxHeight
                                ? coordinates.y + getYCoordTrueMargin
                                : coordinates.y - getYCoordFalseMargin;

                        onHover({
                            groupChartCode,
                            chartCode: chart.chartCode,

                            styles: {
                                left: getXcoord + 'px',
                                top: getYcoord + 'px',
                                bottom: context.chart.chartArea.bottom,
                            },
                            point: {
                                datasetIndex,
                                dataPointIndex: pointIndex,
                                code: chartPoint.code,
                                chartCode: chart.chartCode,
                                chartName: chart.chartName || chartPoint.chartName,
                                rawData: chartPoint,
                                type: chart.type,
                            },
                        });
                    }
                }
            }
        } else {
            onHover(null);
        }
    };
};

/**
 * Функция обработки клика по точке для ChartJS.
 */
export const priceCenterClickCallback = (
    charts: Array<PriceCenterChart<any[]>>,
    selectedPoints: Array<PriceCenterChartPoint<any>>,
    onSelect: (point: PriceCenterChartPoint<any>) => void,
    onUnSelect: (point: PriceCenterChartPoint<any>) => void,
) => {
    return (event: any, elements: any[], chart: any) => {
        if (elements && !isEmpty(elements)) {
            const { datasetIndex } = elements[0];
            const pointIndex = elements[0].index;
            const alreadySelected = selectedPoints.find(
                (point) => point.datasetIndex === datasetIndex && point.dataPointIndex === pointIndex,
            );

            if (alreadySelected) {
                onUnSelect(alreadySelected);
            } else {
                const chart = charts[datasetIndex];
                const chartPoint = chart.data[pointIndex];

                if (chart.chartCode === 'PROFIT_CURVE') {
                    return;
                }

                if (chartPoint) {
                    onSelect({
                        datasetIndex,
                        dataPointIndex: pointIndex,
                        code: chartPoint.code,
                        chartCode: chart.chartCode,
                        chartName: chart.chartName || chartPoint.chartName,
                        rawData: chartPoint,
                        type: chartPoint.securityType || chart.type,
                    });
                }
            }
        }
    };
};
