import riskAnalysis from '../../shared/images/png/riskRates/riskAnalysis.png';
import standardCalculationData from '../../shared/images/png/riskRates/standardCalculationData.png';
import graphicDynamics from '../../shared/images/png/riskRates/graphicDynamics.png';

export const productCapabilities = [
    {
        image: riskAnalysis,
        title: 'Анализ риска неблагоприятного изменения цены',
        description: 'Динамика уровня ставки риска позволяет оценить волатильность и ликвидность инструмента',
        points: [],
    },
    {
        image: standardCalculationData,
        title: 'Данные для расчета нормативов и требований Банка России',
        description: '',
        points: [
            'Начальная маржа клиентов',
            'Показатель краткосрочной ликвидности',
            'Показатель достаточности капитала',
        ],
    },
    {
        image: graphicDynamics,
        title: 'Динамика ставок риска за период',
        description: 'Графическое представление динамики ставок за несколько лет',
        points: [],
    },
];
