import classNames from 'classnames';
import { SubscriptionCarousel } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/SubscriptionBlock/SubscriptionCarousel';
import { SubscriptionCard } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/SubscriptionBlock/SubscriptionCard';
import { EmptyProductCard } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/SubscriptionBlock/EmptyProductCard';
import { DropdownWrapper } from '@libs/components';
import { useResize } from '@libs/utils';
import { useEffect } from 'react';
import { subscriptionThunk } from '@store/store/thunk/user/subscription.thunk';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@store/store';

import styles from './styles.module.scss';

export const SubscriptionBlock = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { width } = useResize();

    const subscriptionList = useSelector((state: RootState) => state.user.subscriptionList);

    useEffect(() => {
        dispatch(subscriptionThunk());
    }, []);

    return (
        <DropdownWrapper title={'Ваши продукты'}>
            <div className={classNames(styles.productsWrapper, 'flex', 'flex-wrap')}>
                {width < 768
? (
                    <SubscriptionCarousel subscriptionList={subscriptionList} />
                )
: (
                    <>
                        {subscriptionList.map((data) => (
                            <SubscriptionCard key={data.id} item={data} />
                        ))}
                        <EmptyProductCard />
                    </>
                )}
            </div>
        </DropdownWrapper>
    );
};
