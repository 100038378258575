import { FileFormat, getFileDownload } from '@libs/services/fileDownload.service';

export const handleFileDownload = async (isins: any[], dates: string[], fileFormat: FileFormat, fileType: string) => {
    try {
        const fileRes = await getFileDownload(isins, dates, fileFormat, fileType);
        const url = window.URL.createObjectURL(new Blob([fileRes.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `instruments.${fileFormat}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        await Promise.resolve();
    } catch (error) {
        return await Promise.reject(error);
    }
};

export const getRowsPrepared = (arr: any[]) => {
    if (!arr) return;

    return arr?.map((item: any) => {
        const updatedItem = { ...item };

        for (const key in updatedItem) {
            if (updatedItem[key] === null) {
                updatedItem[key] = '—';
            } else if (typeof updatedItem[key] === 'number') {
                updatedItem[key] = updatedItem[key].toFixed(4);
            }
        }

        return updatedItem;
    });
};
