import { Accordion, AccordionTab } from 'primereact/accordion';
import { type Reference } from '@modules/HelpCenter/HelpCenterPage/services/helpCenter.types';
import { useEffect, useState } from 'react';
import { HeaderComponent } from '@modules/HelpCenter/ReferencePage/components/MainContent/Accordion/HeaderComponent';

import styles from './styles.module.scss';

type Props = {
    setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    activeIndex?: number;
    reference: Reference;
    refDisplayContent: React.RefObject<HTMLDivElement>;
    referenceId: string;
    articleId: string;
};

export const AccordionComponent = ({
    activeIndex,
    setActiveIndex,
    reference,
    refDisplayContent,
    referenceId,
    articleId,
}: Props) => {
    const [childrenIndex, setChildrenIndex] = useState<number | undefined>(activeIndex);

    const openAccTab = (i: number) => {
        setActiveIndex(i);
        setChildrenIndex(i);
    };

    const closeAccTab = () => {
        setActiveIndex(undefined);
    };

    const handleChangeTabs = (e: any) => {
        const prevIndex = activeIndex;

        if (e.index !== null) {
            setActiveIndex(e.index as number);
            setChildrenIndex(e.index as number);
        } else {
            setActiveIndex(prevIndex);
        }
    };

    useEffect(() => {
        if (reference && articleId) {
            const index = reference.articles.findIndex((item) => item.article_id === +articleId);
            setActiveIndex(index);
            setChildrenIndex(index);
        }
    }, []);

    const styleObj = {
        header: {
            className: styles.headerWrapper,
        },
        content: {
            className: styles.content,
        },
    };

    const handleNavItemClick = (navItem: string) => {
        if (refDisplayContent.current) {
            const headers = Array.from(refDisplayContent.current.querySelectorAll('h2'));
            const target = headers.find((header) => header.textContent === navItem);

            if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <Accordion
            className={styles.accordionWrapper}
            activeIndex={activeIndex}
            onTabChange={handleChangeTabs}
            onTabClose={closeAccTab}
            onTabOpen={({ index }) => openAccTab(index)}
        >
            {reference.articles.map((article, i) => (
                <AccordionTab
                    key={i}
                    pt={styleObj}
                    header={
                        <HeaderComponent
                            referenceId={referenceId}
                            articleId={article.article_id}
                            withChildren={!!(article.navigation && JSON.parse(article.navigation).navigation?.length)}
                            title={article.title}
                            isActive={activeIndex === i}
                            activeIndex={activeIndex}
                            childrenIndex={childrenIndex}
                            setChildrenIndex={setChildrenIndex}
                        />
                    }
                >
                    {childrenIndex === i &&
                        article.navigation &&
                        JSON.parse(article.navigation).navigation.map((navItem: string) => (
                            <li
                                key={navItem}
                                className={styles.navigationItem}
                                onClick={() => handleNavItemClick(navItem)}
                            >
                                {navItem}
                            </li>
                        ))}
                </AccordionTab>
            ))}
        </Accordion>
    );
};
