import { PriceCenterDict } from '@store/store/thunk/pricecenter/Models';

export const CALC_METHODS_DICT: Array<PriceCenterDict> = [
    {
        code: '1',
        name: '1 метод',
    },
    {
        code: '2',
        name: '2 метод',
    },
    {
        code: '3',
        name: '3 метод',
    },
];

export const STATUS_DICT: Array<PriceCenterDict> = [
    {
        code: 'Прочие',
        name: 'Прочие',
    },
    {
        code: 'Аннулирован',
        name: 'Аннулирован',
    },
    {
        code: 'В обращении',
        name: 'В обращении',
    },
    {
        code: 'Готовится',
        name: 'Готовится',
    },
    {
        code: 'Дефолт',
        name: 'Дефолт',
    },
    {
        code: null,
        name: 'Не определено',
    },
    {
        code: 'Прекращение',
        name: 'Прекращение',
    },
    {
        code: 'Приостановлен',
        name: 'Приостановлен',
    },
    {
        code: 'Размещается',
        name: 'Размещается',
    },
    {
        code: 'Размещен',
        name: 'Размещен',
    },
    {
        code: 'Списано',
        name: 'Списано',
    },
    {
        code: 'Аннулирован как недействительный',
        name: 'Аннулирован как недействительный',
    },
    {
        code: 'Аннулирован как несостоявшийся',
        name: 'Аннулирован как несостоявшийся',
    },
    {
        code: 'Аннулирован при конвертации/редомициляции',
        name: 'Аннулирован при конвертации/редомициляции',
    },
    {
        code: 'Аннулирован при объединении',
        name: 'Аннулирован при объединении',
    },
    {
        code: 'В проекте',
        name: 'В проекте',
    },
    {
        code: 'Погашен',
        name: 'Погашен',
    },
];

export const LISTING_LEVEL_DICT = [
    {
        code: null,
        name: 'Все уровни',
    },
    {
        code: 1,
        name: '1 уровень',
    },
    {
        code: 2,
        name: '2 уровень',
    },
    {
        code: 3,
        name: '3 уровень',
    },
];

export const SECTOR_DICT: Array<PriceCenterDict> = [
    {
        code: null,
        name: 'Все сектора',
    },
    {
        code: 'Гос',
        name: 'Государственные облигации',
    },
    {
        code: 'Корп',
        name: 'Корпоративные облигации',
    },
    {
        code: 'Муни',
        name: 'Муниципальные облигации',
    },
];

export const ISSUER_SECTOR_DICT: Array<PriceCenterDict> = [
    {
        code: null,
        name: 'Все отрасли',
    },
    {
        code: 'Энергоресурсы',
        name: 'Энергоресурсы',
    },
    {
        code: 'Электроэнергетика',
        name: 'Электроэнергетика',
    },
    {
        code: 'Телекоммуникации',
        name: 'Телекоммуникации',
    },
    {
        code: 'СМИ',
        name: 'СМИ',
    },
    {
        code: 'Тяжёлое машиностроение',
        name: 'Тяжёлое машиностроение',
    },
    {
        code: 'Автомобилестроениe',
        name: 'Автомобилестроениe',
    },
    {
        code: 'Металлы и добыча',
        name: 'Металлы и добыча',
    },
    {
        code: 'Финансы',
        name: 'Финансы',
    },
    {
        code: 'Строительство и недвижимость',
        name: 'Строительство и недвижимость',
    },
    {
        code: 'ЖКХ',
        name: 'ЖКХ',
    },
    {
        code: 'Легкая промышленность',
        name: 'Легкая промышленность',
    },
    {
        code: 'Пищевая промышленность',
        name: 'Пищевая промышленность',
    },
    {
        code: 'Химия и химпром',
        name: 'Химия и химпром',
    },
    {
        code: 'Потребительский сектор',
        name: 'Потребительский сектор',
    },
    {
        code: 'Транспорт',
        name: 'Транспорт',
    },
    {
        code: 'Фармацевтика и медицина',
        name: 'Фармацевтика и медицина',
    },
    {
        code: 'ИТ и технологии',
        name: 'ИТ и технологии',
    },
    {
        code: 'Лесная промышленность',
        name: 'Лесная промышленность',
    },
    {
        code: 'Прочие',
        name: 'Прочие',
    },
];
