import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkEmailRequest, confirmEmailRequest, updateEmailRequest } from "@libs/services";
import { type EmailSendRequestType } from "@libs/types";

export const updateEmailThunk = createAsyncThunk('user/updateEmailThunk', async (body: EmailSendRequestType, { rejectWithValue }) => {
    try {
        const { data } = await updateEmailRequest(body);

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const confirmEmailThunk = createAsyncThunk('user/confirmEmailThunk', async (_, { rejectWithValue }) => {
    try {
        const { data } = await confirmEmailRequest();

        return data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const checkEmailThunk = createAsyncThunk('user/checkEmailThunk', async (token: string, { rejectWithValue }) => {
    try {
        const { data } = await checkEmailRequest(token);

        return data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});
