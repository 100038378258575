import { SearchEntityType } from '@libs/types/search.type';

export * from './windowSize';

export const RUSSIAN_PHONE_LENGTH = 11;

export const SEARCH_TYPE_NAME: { [key: string]: string } = {
    ARTICLE: 'Статьи',
    REFERENCE: 'Справочный центр',
};

export const SEARCH_TABS: { title: string; entities: SearchEntityType[]; index: number }[] = [
    {
        title: 'Все разделы',
        entities: ['ARTICLE', 'REFERENCE'],
        index: 0,
    },
    {
        title: 'Справочный центр',
        entities: ['REFERENCE'],
        index: 1,
    },
    {
        title: 'Статьи',
        entities: ['ARTICLE'],
        index: 2,
    },
];

export const MINUTES = ['минута', 'минуты', 'минут'];
export const HOURS = ['час', 'часа', 'часов'];
