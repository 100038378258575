import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse } from 'axios';
import { OrderData, OrderReq, ResponseInfo } from '@libs/types';
import { CreateRefundReq, RefundReq, RefundResp } from '@libs/types/refundOrder.type';
import { BankItem } from '@libs/types/bank.type';

export const ORDER_URL = '/moex-datashop-orderservice/api/datashop/order-service/v1/orders';
export const REFUND_URL = '/moex-datashop-orderservice/api/refunds/v1/';
export const BANK_URL = '/moex-datashop-orderservice/api/banks/v1';

export const createOrder = (requestData: OrderReq): Promise<AxiosResponse<ResponseInfo<OrderData>>> =>
    httpClient.post(ORDER_URL, requestData);

export const getAmountRefund = (body: RefundReq): Promise<AxiosResponse<ResponseInfo<RefundResp>>> =>
    httpClient.post(REFUND_URL + 'amount', body);

export const getBanks = (): Promise<AxiosResponse<ResponseInfo<BankItem[]>>> => httpClient.get(BANK_URL);

export const createRefundOrder = (orderId: string, body: CreateRefundReq): Promise<AxiosResponse<ResponseInfo<any>>> =>
    httpClient.post(`${ORDER_URL}/${orderId}/refund`, body);
