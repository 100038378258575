import { SelectedPointsModal } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/SelectedPointsModal';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { unSelectMarketPoint, clearMarketSelectedPoints } from '@store/store/slices/pricecenter.slice';
import classNames from 'classnames';
import styles from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/styles.module.scss';

export const MarketHeader = () => {
    const dispatch = useDispatch<AppDispatch>();
    const charts = useSelector((state: RootState) => state.priceCenter.charts);

    return (
        <div className={classNames(styles.headerWrapper)}>
            <div className={styles.mapHeaderTitle}>
                <h2>Карта рынка</h2>
            </div>
            <SelectedPointsModal
                handleClearPanel={() => dispatch(clearMarketSelectedPoints())}
                selectedMarkers={charts.data.marketSelectedPoints ?? []}
                handleUnselectPoint={(point) => dispatch(unSelectMarketPoint(point))}
            />
        </div>
    );
};
