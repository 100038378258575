import classNames from 'classnames';

import styles from './styles.module.scss';

export const ErrorMsg = () => {
    return (
        <div className={classNames(styles.msgWrapper)}>
            <svg
width="40" height="40" viewBox="0 0 40 40"
fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle
cx="20" cy="20" r="16"
stroke="#A5B1C0" strokeWidth="1.8" strokeLinecap="round" />
                <path
d="M20 21.3866V10.8572" stroke="#A5B1C0" strokeWidth="1.8"
strokeLinecap="round" />
                <path
d="M20 28.2437V26.8571" stroke="#A5B1C0" strokeWidth="1.8"
strokeLinecap="round" />
            </svg>

            <span>Поиск не дал результатов.</span>
            <span>Попробуйте изменить свой запрос.</span>
        </div>
    );
};
