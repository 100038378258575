import pricingNonStandardOptions from '../../shared/images/png/volatility/pricingNonStandardOptions.png';
import volatilitySurfaces from '../../shared/images/png/volatility/volatilitySurfaces.png';
import volatilityAnalysis from '../../shared/images/png/volatility/volatilityAnalysis.png';
import toolComparisonService from '../../shared/images/png/volatility/toolComparisonService.png';
import historicalVolatility from '../../shared/images/png/volatility/historicalVolatility.png';
import listsFavoriteInstruments from '../../shared/images/png/volatility/listsFavoriteInstruments.png';

export const productCapabilities = [
    {
        image: pricingNonStandardOptions,
        title: 'Ценообразование нестандартных опционов',
        description:
            'Анализ данных о волатильности торгуемых опционов для расчета теоретической цены неторгуемого контракта',
        points: [],
    },
    {
        image: volatilitySurfaces,
        title: 'Графический анализ «Поверхности волатильности» в 3D',
        description: 'Удобный и информативный график в 3D c осями «Волатильность», «Цены страйк» и «Серия опциона»',
        points: [],
    },
    {
        image: volatilityAnalysis,
        title: 'Анализ волатильности по разным сериям опционов',
        description:
            'Размещение на одном экране сразу нескольких графиков «Улыбка волатильности» на заданные календарные даты для фиксированных серий опционов',
        points: [],
    },
    {
        image: toolComparisonService,
        title: 'Сервис сравнения инструментов',
        description: 'Выбирайте и сравнивайте до 5 инструментов по параметрам:',
        points: ['Актив', 'Дата экспирации', 'Дата расчета'],
    },
    {
        image: historicalVolatility,
        title: 'Историческая волатильность',
        description: 'Выгружайте необходимые данные по выбранным инструментам за заданный период',
        points: [],
    },
    {
        image: listsFavoriteInstruments,
        title: 'Списки избранных инструментов',
        description: 'Сохраняйте и отслеживайте изменения по выбранным инструментам в списках избранного',
        points: [],
    },
];
