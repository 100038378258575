import { type ArticleListItem } from '@libs/types/articles/articleListItem.type';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { getDate } from '@modules/Articles/ArticleListPage/components/ArticlesList/ArticleCard/utils';
import { Link } from 'react-router-dom';
import { timeToReadArticleString } from '@libs/utils';

import mainStyles from '../styles.module.scss';
import styles from './styles.module.scss';

type Props = {
    item: ArticleListItem;
};

export const BigImageCard = ({ item }: Props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [image, setImage] = useState<string>();

    useEffect(() => {
        if (item.image) {
            dispatch(downloadFileThunk({ bucket: item.image.bucket, ticket: item.image.field_id }))
                .then((resp) => {
                    const result = unwrapResult(resp);
                    setImage(result);
                })
                .catch((e) => console.error(e));
        }
    }, []);

    return (
        <div
            className={classNames(styles.bigImageCardWrapper)}
            style={{ backgroundImage: `url(${image}`, backgroundSize: 'cover' }}
        >
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.infoWrapper, mainStyles.infoWrapper, 'flex', 'align-items-baseline')}>
                    {item.category && (
                        <>
                            <span className={mainStyles.categoryWrapper}>{item.category}</span>
                            <div className={mainStyles.divider} />
                        </>
                    )}
                    <span className={mainStyles.dateWrapper}>{getDate(item.titleDate)}</span>
                </div>

                <div className={classNames(styles.mainWrapper, 'flex', 'flex-column')}>
                    <Link to={`/articles/${item.id}`} className={styles.linkWrapper}>
                        <h4>{item.title}</h4>
                    </Link>
                    <p>{item.subtitle}</p>
                </div>

                <div className={classNames(styles.timeWrapper, 'flex')}>
                    <span className={classNames(styles.icon, 'pi pi-clock')} />
                    <span className={styles.text}>{timeToReadArticleString(item.timeToRead)}</span>
                </div>
            </div>
        </div>
    );
};
