import { createAsyncThunk } from '@reduxjs/toolkit';
import { SearchObjectsReq } from '@libs/types/search.type';
import { searchObjects } from '@libs/services/search.service';

export const searchObjectsThunk = createAsyncThunk(
    'search/searchObjectsThunk',
    async (body: SearchObjectsReq, { rejectWithValue }) => {
        try {
            const { data } = await searchObjects(body);
            return data.data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
