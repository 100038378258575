import { ReactComponent as ArrowLeft } from '@shared/images/svg/ArrowLeft.svg';
import { ReactComponent as ShareIcon } from '@shared/images/svg/Share.svg';
import { ReactComponent as TelegramIcon } from '@shared/images/svg/Telegram.svg';
import { ReactComponent as VkIcon } from '@shared/images/svg/Vk.svg';
import { ReactComponent as WhatsAppIcon } from '@shared/images/svg/WhatsApp.svg';
import { ReactComponent as LinkIcon } from '@shared/images/svg/Link.svg';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';

import styles from './styles.module.scss';

type Props = {
    date?: string;
    category?: string;
};

export const SharedTitle = ({ date, category }: Props) => {
    const modalRef = useRef<OverlayPanel>(null);

    const currentUrl = window.location.href;

    const handleCopyLink = () => {
        navigator.clipboard.writeText(currentUrl).catch((err) => {
            console.error('Ошибка копирования ссылки: ', err);
        });
    };

    const formatDate = (dateString: string) => {
        const months = [
            'января',
            'февраля',
            'марта',
            'апреля',
            'мая',
            'июня',
            'июля',
            'августа',
            'сентября',
            'октября',
            'ноября',
            'декабря',
        ];

        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    return (
        <div className={classNames('flex', 'justify-content-between', styles.sharedTitleWrapper)}>
            <a href="/articles" className={classNames('flex', styles.linkWrapper)}>
                <ArrowLeft />
                <p>Статьи</p>
            </a>
            <div className={classNames('flex', styles.buttonsWrapper)}>
                <Button
                    className={styles.iconBtn}
                    onClick={(e) => {
                        modalRef.current?.toggle(e);
                    }}
                >
                    <ShareIcon />
                    <OverlayPanel ref={modalRef} showCloseIcon className={styles.overlayPanelWrapper}>
                        <h4>ПОДЕЛИТЬСЯ</h4>
                        <div className={classNames('flex', 'align-items-center', styles.socialWrapper)}>
                            <a
                                href={`https://t.me/share/url?url=${encodeURIComponent(currentUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button style={{ paddingRight: '3px' }}>
                                    <TelegramIcon />
                                </Button>
                            </a>

                            <a
                                href={`https://vk.com/share.php?url=${encodeURIComponent(currentUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button>
                                    <VkIcon />
                                </Button>
                            </a>

                            <a
                                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button>
                                    <WhatsAppIcon />
                                </Button>
                            </a>

                            <Button onClick={handleCopyLink}>
                                <LinkIcon />
                            </Button>
                        </div>
                    </OverlayPanel>
                </Button>
                <div className={styles.sharedButton}>
                    {category && (
                        <>
                            <span className={styles.category}>{category}</span>
                            <div />
                        </>
                    )}
                    <span>{date && formatDate(date)}</span>
                </div>
            </div>
        </div>
    );
};
