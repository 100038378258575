import classNames from 'classnames';
import { getReferences } from '@modules/HelpCenter/HelpCenterPage/services/helpCenter.service';
import { type PageableRequest, type Reference } from '@modules/HelpCenter/HelpCenterPage/services/helpCenter.types';
import HeaderLogoIcon from '@shared/images/svg/HeaderLogo.svg';
import { useEffect, useState } from 'react';
import { downloadFile, getProductList } from '@libs/services';
import { useConfig } from '@dynamic-env/useConfig';
import { useAuthorize } from '@libs/utils';
import { useRoutes } from '@libs/utils/hooks/useRoutes';

import { ProductCard, type ProductCardProps } from './ProductCard';
import styles from './styles.module.scss';

const requestBody: PageableRequest = {
    pageable: {
        pageNumber: 0,
        pageSize: 100,
        sortField: 'priority',
        sortOrder: 'asc',
    },
};

export const ProductsList = () => {
    const { config } = useConfig();
    const [products, setProducts] = useState<ProductCardProps[]>([]);
    const [productsWithoutGuid, setProductsWithoutGuid] = useState<ProductCardProps[]>([]);
    const { isAuthorized } = useAuthorize();
    const productRoute = useRoutes();

    useEffect(() => {
        getReferences(requestBody)
            .then((response) => {
                const { data } = response.data;
                processProducts(data.rows.filter((item) => !!item.articles.length));
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const processProducts = async (data: Reference[]) => {
        const productsWithGuid: ProductCardProps[] = [];
        const productsWithoutGuid: ProductCardProps[] = [];
        const productGuids = data.reduce((acc: string[], item) => {
            if (item.product_guid) {
                acc.push(item.product_guid);
            }

            return acc;
        }, []);

        try {
            const response = await getProductList({
                productGuids,
                pageable: {
                    pageSize: 100,
                    pageNumber: 0,
                    sortField: 'lastUpdateDate',
                    sortOrder: 'desc',
                },
            });

            const products = response.data.data.rows;

            for (const item of data) {
                let icon = HeaderLogoIcon;
                let titleLink = '/';

                const questions = item.articles.slice(0, 5).map((article) => ({
                    text: article.title,
                    url: `help-center/references/${item.id}/article/${article.article_id}`,
                }));

                if (item.product_guid) {
                    const product = products.find((unit) => unit.productGuid === item.product_guid);

                    if (product?.cartImage) {
                        try {
                            const { data } = await downloadFile(product.cartImage.bucket, product.cartImage.fileId);
                            icon = URL.createObjectURL(data);
                        } catch (error) {
                            console.error('Error downloading image:', error);
                        }
                    }

                    const mnemoCode = product?.mnemoCode;
                    titleLink = mnemoCode ? productRoute(config)[mnemoCode] : '/';
                }

                const productCard: ProductCardProps = {
                    title: item.title,
                    icon,
                    questions,
                    titleLink,
                    link: questions[0].url || '',
                };

                if (item.product_guid) {
                    productsWithGuid.push(productCard);
                } else {
                    productsWithoutGuid.push(productCard);
                }
            }

            setProducts(productsWithGuid);
            setProductsWithoutGuid(productsWithoutGuid);
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };

    return (
        <div className={classNames(styles.wrapper)}>
            <div className={classNames(styles.productsWithoutGuid)}>
                {productsWithoutGuid.map((data, index) => (
                    <ProductCard key={index} {...data} />
                ))}
            </div>
            <h2>Справка по продуктам</h2>
            <div>
                {products.map((data, index) => (
                    <ProductCard key={index} {...data} />
                ))}
            </div>
        </div>
    );
};
