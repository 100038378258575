import classNames from 'classnames';
import { type RefObject } from 'react';

import styles from './styles.module.scss';

type DescriptionBlockProps = {
    title: string;
    text?: string;
    getAccessLink?: RefObject<HTMLDivElement>;
    tariffsLink?: RefObject<HTMLDivElement>;
    className?: string;
    list?: string[];
};

export const DescriptionBlock = ({
    title,
    text,
    getAccessLink,
    tariffsLink,
    className,
    list,
}: DescriptionBlockProps) => {
    const handleScrollToGainAccess = () => {
        if (getAccessLink) {
            getAccessLink.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScrollToTariffs = () => {
        if (tariffsLink) {
            tariffsLink.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={classNames(styles.wrapper, className)}>
            <h1>{title}</h1>
            {text && <p>{text}</p>}
            {list && (
                <ul>
                    {list.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            )}
            <div className={styles.links}>
                {getAccessLink && (
                    <a className={styles.getAccessLink} onClick={handleScrollToGainAccess}>
                        Получить доступ
                    </a>
                )}
                {tariffsLink && (
                    <a className={styles.tariffsLink} onClick={handleScrollToTariffs}>
                        Тарифы
                    </a>
                )}
            </div>
        </div>
    );
};
