import classNames from 'classnames';
import { Button } from 'primereact/button';
import {
    Paginator,
    type PaginatorNextPageLinkOptions,
    type PaginatorPageLinksOptions,
    type PaginatorPrevPageLinkOptions,
    type PaginatorTemplateOptions,
} from 'primereact/paginator';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';

type PaginationControllerProps = {
    total: number;
    current: number;
    setAsyncCurrent: (num: number) => void;
    paginatorOptions?: PaginatorTemplateOptions;
    nextBtn?: string;
    prevBtn?: string;
    currentPageCustomClass?: string;
    customClass?: string;
    needMobileView?: boolean;
};

export const PaginationController = ({
    total,
    current,
    setAsyncCurrent,
    paginatorOptions,
    nextBtn,
    prevBtn,
    currentPageCustomClass,
    customClass,
    needMobileView = true,
}: PaginationControllerProps) => {
    const { isScreenSm } = useResize();

    const previousButton = (option: PaginatorPrevPageLinkOptions) => !option.disabled
? (
            <Button
                onClick={option.onClick}
                className={classNames(styles.textButton, styles.prevButton)}
                label={prevBtn || 'Предыдущая'}
            />
        )
: (
            <></>
        );

    const nextButton = (option: PaginatorNextPageLinkOptions) => !option.disabled
? (
            <Button onClick={option.onClick} className={classNames(styles.textButton)} label={nextBtn || 'Cледующая'} />
        )
: (
            <></>
        );

    const paginationTemplate = paginatorOptions || {
        layout: 'PrevPageLink PageLinks NextPageLink',
        PrevPageLink: previousButton,
        NextPageLink: nextButton,
        PageLinks: (options: PaginatorPageLinksOptions) => {
            const showStartPage = options.view.startPage === options.page && options.view.startPage !== 0;
            const showEndPage = options.view.endPage === options.page && options.page + 1 !== options.totalPages;

            if (showStartPage || showEndPage) {
                const className = classNames(options.className, { 'p-disabled': true }, styles.threeDotsP);

                return (
                    <>
                        {showStartPage && (
                            <Button
                                className={classNames(
                                    options.className,
                                    styles.pagButton,
                                    current === options.page && (currentPageCustomClass || styles.current),
                                )}
                                onClick={() => setAsyncCurrent(0)}
                            >
                                1
                            </Button>
                        )}
                        <span className={className} style={{ userSelect: 'none' }}>
                            ...
                        </span>
                        {showEndPage && (
                            <Button
                                className={classNames(
                                    options.className,
                                    styles.pagButton,
                                    current === options.page && (currentPageCustomClass || styles.current),
                                )}
                                onClick={() => setAsyncCurrent(total - 1)}
                            >
                                {total}
                            </Button>
                        )}
                    </>
                );
            }

            return (
                <Button
                    className={classNames(
                        options.className,
                        styles.pagButton,
                        current === options.page && (currentPageCustomClass || styles.current),
                    )}
                    onClick={options.onClick}
                >
                    {options.page + 1}
                </Button>
            );
        },
    };

    if (current === total - 1 && isScreenSm && needMobileView) {
        return null;
    }

    return isScreenSm && needMobileView
? (
        <Button
            label={'Показать еще'}
            onClick={() => current < total && setAsyncCurrent(current + 1)}
            className={styles.paginationBtn}
        />
    )
: (
        <Paginator
            className={classNames('flex', 'justify-content-start', customClass, styles.paginatorWrapper)}
            template={paginationTemplate}
            first={current}
            rows={1}
            totalRecords={total}
            onPageChange={(e) => setAsyncCurrent(e.page)}
        />
    );
};
