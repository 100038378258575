import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { SliderButtons } from '@libs/components';
import parse from 'html-react-parser';

import styles from './styles.module.scss';

type Props = {
    list: ContentSliderItem[];
};

type ContentSliderItem = {
    heading: string;
    title: string;
    text: string;
    image: string;
    styles?: string;
    customHtmlText?: string;
};

export const ContentSlider = ({ list }: Props) => {
    const [currentSlide, setCurrentSlide] = useState<number>(1);

    const [isTransitioning, setIsTransitioning] = useState(false);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLDivElement | null>(null);

    // В начале и конце добавляем клон последних и первых слайдов
    const extendedSlides = [list[list.length - 1], ...list, list[0]];

    // Убираем анимацию и "прыгаем" на реальный слайд после перехода
    useEffect(() => {
        if (isTransitioning) {
            const handleTransitionEnd = () => {
                setIsTransitioning(false);

                if (currentSlide === extendedSlides.length - 1) {
                    setCurrentSlide(1); // возвращаемся к первому реальному слайду
                } else if (currentSlide === 0) {
                    setCurrentSlide(list.length); // возвращаемся к последнему реальному слайду
                }
            };

            const content = contentRef.current;
            const image = imageRef.current;
            content!.addEventListener('transitionend', handleTransitionEnd);
            image!.addEventListener('transitionend', handleTransitionEnd);

            return () => {
                content!.removeEventListener('transitionend', handleTransitionEnd);
                image!.removeEventListener('transitionend', handleTransitionEnd);
            };
        }
    }, [currentSlide, isTransitioning, extendedSlides.length]);

    const DotList = () => (
        <div className={classNames(styles.dotsList)}>
            {list.map((_, i) => {
                return (
                    <div
                        key={_.title}
                        className={classNames(i + 1 === currentSlide && styles.activeDot)}
                        onClick={() => {
                            setCurrentSlide(i + 1);
                            setIsTransitioning(true);
                        }}
                    />
                );
            })}
        </div>
    );

    return (
        <section className={classNames(styles.sliderWrapper, 'flex')}>
            <div className={classNames(styles.textBlock)}>
                <div>
                    <div
                        ref={contentRef}
                        style={{
                            transform: `translateX(-${currentSlide * 100}%)`,
                            transition: isTransitioning ? 'transform 0.5s ease' : 'none',
                        }}
                    >
                        {extendedSlides.map((item) => (
                            <div key={item.title} className={classNames(styles.textItem, item.styles)}>
                                <p>{item.heading}</p>
                                <h2>{item.title}</h2>
                                <span>{item.text}</span>
                                {item.customHtmlText && parse(item.customHtmlText)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <DotList />
            <div>
                <div className={classNames(styles.imageBlock)}>
                    <div
                        ref={imageRef}
                        style={{
                            transform: `translateX(-${currentSlide * 100}%)`,
                            transition: isTransitioning ? 'transform 0.5s ease' : 'none',
                        }}
                    >
                        {extendedSlides.map((item) => {
                            return <img key={item.title} src={item.image} />;
                        })}
                    </div>
                </div>
                <SliderButtons
                    className={styles.sliderButton}
                    setActiveSlide={setCurrentSlide}
                    isTransitioning={isTransitioning}
                    setIsTransitioning={setIsTransitioning}
                />
            </div>
        </section>
    );
};
