import operationalRiskReduction from '../../shared/images/svg/markingFinInstruments/operationalRiskReduction.svg';
import reducingMarketRisk from '../../shared/images/svg/markingFinInstruments/reducingMarketRisk.svg';
import reducingComplianceRisk from '../../shared/images/svg/markingFinInstruments/reducingComplianceRisk.svg';
import screeningBasedSecurities from '../../shared/images/svg/markingFinInstruments/screeningBasedSecurities.svg';
import uploadingArchives from '../../shared/images/svg/markingFinInstruments/uploadingArchives.svg';
import expandedListTools from '../../shared/images/svg/markingFinInstruments/expandedListTools.svg';

export const instrumentItems = [
    {
        icon: operationalRiskReduction,
        title: 'Снижение операционного риска',
        description:
            'Возможность для участников торгов автоматизировать соблюдение регуляторных требований по ограничениям для неквалифицированных инвесторов',
    },
    {
        icon: reducingMarketRisk,
        title: 'Снижение рыночного риска',
        description:
            'Определите перечень активов, предоставление доступа к которым требует более высокой квалификации со стороны инвесторов',
    },
    {
        icon: reducingComplianceRisk,
        title: 'Снижение комплаенс риска',
        description:
            'Определите перечень российских ценных бумаг, приобретение которых запрещено нерезидентам из недружественных юрисдикций',
    },
    {
        icon: screeningBasedSecurities,
        title: 'Скрининг ценных бумаг по различным признакам',
        description:
            'Отфильтруйте список торгуемых и неторгуемых на Мосбирже бумаг по доступности к покупке для неквалифицированного инвестора',
    },
    {
        icon: uploadingArchives,
        title: 'Выгрузка архивов значений маркировки',
        description: 'Выгружайте исторические данные по всем видам ограничений за выбранный период',
    },
    {
        icon: expandedListTools,
        title: 'Расширенный перечень инструментов',
        description: 'Более 40 тысяч ценных бумаг, торгуемых как на бирже, так и на внебиржевом рынке',
    },
];
