import { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    PriceCenterBond,
    PriceCenterChart,
    PriceCenterChartPoint,
    PriceCenterFilter,
    PriceCenterStateChartsData,
    PriceCenterValuation,
} from '@store/store/thunk/pricecenter/Models';
import { differenceInDays, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import {
    getIndexesPoints,
    getIndexesPrices,
    getMarketBonds,
    getMarketPoints,
    getPricePoints,
    getProfitCurves,
    getProfitPoints,
} from '@libs/services';
import {
    GetMarketBond,
    GetMarketBondsRespBody,
    GetMarketPointsRespBody,
    GetPricePointsRespBody,
    GetProfitPointsRespBody,
    ResponseInfo,
} from '@libs/types';
import { getDateTransformed, getRequestFilters, getTradeDate } from '@modules/PriceCenterProduct/InteractiveMap/utils';

export const DEFAULT_PRICE_PROFIT_INDEXES = ['RGBI', 'RUCBTRNS', 'RUMBTRNS'];

const getAllChartsThunk = createAsyncThunk(
    'priceCenter/getAllChartsThunk',
    async (filter: PriceCenterFilter, { rejectWithValue }) => {
        try {
            const processed = getRequestFilters(filter);
            const profitCurveResponse = await getProfitCurves(getDateTransformed(filter.tradeDate) ?? '');
            const marketPointsResponse = await getMarketPoints(processed, filter?.isDemo);

            let bondsResponse = {} as GetMarketBondsRespBody;

            let count = 0;
            let bonds = [];

            while (count <= 5 || !bonds.length) {
                const date = getDateTransformed(getTradeDate(count)) ?? getTradeDate(count);
                const dtoValue = filter?.isDemo
                    ? {
                          trade_date: date,
                      }
                    : {
                          tradeDate: date,
                      };
                const dataResponse = await getMarketBonds(dtoValue, filter?.isDemo);

                bondsResponse = dataResponse.data;
                bonds = bondsResponse.bonds;
                count++;

                if (bonds.length) {
                    break;
                }
            }

            const indexesPriceResponse = await getIndexesPrices(
                { secIdList: DEFAULT_PRICE_PROFIT_INDEXES },
                filter?.isDemo,
            );
            const indexesPointsResponse = await getIndexesPoints(
                { secIdList: DEFAULT_PRICE_PROFIT_INDEXES },
                filter?.isDemo,
            );

            const pricesResponse = await getPricePoints(
                {
                    isinList: DEFAULT_PRICE_PROFIT_INDEXES,
                },
                filter?.isDemo,
            );
            const profitResponse = await getProfitPoints(
                {
                    isinList: DEFAULT_PRICE_PROFIT_INDEXES,
                },
                filter?.isDemo,
            );

            return {
                marketCharts: mapMarketCharts(profitCurveResponse.data, marketPointsResponse.data, bondsResponse),
                marketSelectedPoints: [],

                priceCharts: mapPricesCharts(indexesPriceResponse.data),
                priceSelectedPoints: [],

                profitCharts: mapProfitCharts(indexesPointsResponse.data),
                profitSelectedPoints: [],
                allBonds: bondsResponse,
            } as unknown as PriceCenterStateChartsData;
        } catch (e) {
            console.error('e', e);
            return rejectWithValue(e);
        }
    },
);

/**
 * Маппинг данных для графиков Карты рынка
 */
export const mapMarketCharts = (
    profitCurve: ResponseInfo<PriceCenterBond[]>,
    marketPoints: GetMarketPointsRespBody[],
    bonds?: GetMarketBondsRespBody,
): Array<PriceCenterChart<Array<PriceCenterBond>>> => {
    const gov: Array<PriceCenterBond> = [];
    const corp: Array<PriceCenterBond> = [];
    const local: Array<PriceCenterBond> = [];
    for (const bond of bonds?.bonds ?? []) {
        const result = mapMarketPoint(bond, marketPoints ?? [], bonds);
        if (result) {
            if (bond.security_type === 'Гос' || bond.security_type === 'ЕвроГос') {
                gov.push(result);
            } else if (bond.security_type === 'Корп' || bond.security_type === 'ЕвроКорп') {
                corp.push(result);
            } else if (bond.security_type === 'Муни') {
                local.push(result);
            }
        }
    }
    return [
        {
            chartCode: 'PROFIT_CURVE',
            chartName: 'Кривая бескупонной доходности',
            visible: true,
            data: profitCurve.data,
        },
        {
            chartCode: 'GOV_BONDS_CHART',
            chartName: 'Гос. облигации',
            visible: true,
            data: gov,
        },
        {
            chartCode: 'CORP_BONDS_CHART',
            chartName: 'Корп. облигации',
            visible: true,
            data: corp,
        },
        {
            chartCode: 'LOCAL_BONDS_CHART',
            chartName: 'Муниципальные облигации',
            visible: true,
            data: local,
        },
    ];
};

/**
 * Сопоставление и маппинг точки из integrationservice/instrument-points и
 * instrument/bonds_params для Карты рынка
 */
export const mapMarketPoint = (
    bond: GetMarketBond,
    points: Array<GetMarketPointsRespBody>,
    bonds?: GetMarketBondsRespBody,
): PriceCenterBond | null => {
    if (bond.mat_date && !isEmpty(bond.mat_date)) {
        const point = points.find((point) => point.isin === bond.isin);
        if (point) {
            const valDate = +(
                differenceInDays(parse(bond.mat_date, 'yyyy-MM-dd', new Date()), new Date()) / 365
            ).toFixed(2);
            return {
                code: point.isin,
                name: bond.name ?? point.isin,
                securityType: point.securityType ?? bond.security_type,
                valDate: valDate,
                value: point.value,
                emitterId: bonds?.emitters?.filter((i) => i.id === bond.emitterId)[0].name,
            };
        }
    }
    return null;
};

/**
 * Маппинг данных для графиков Цена
 */
export const mapPricesCharts = (
    pricesData: GetPricePointsRespBody,
    point?: PriceCenterChartPoint<PriceCenterBond>,
): Array<PriceCenterChart<Array<PriceCenterValuation>>> => {
    const result: Array<PriceCenterChart<Array<PriceCenterValuation>>> = [];
    for (const [key, value] of Object.entries(pricesData)) {
        let chartName;
        switch (key) {
            case 'RGBI':
                chartName = 'Индекс гос. облигаций (RGBI)';
                break;
            case 'RUCBTRNS':
                chartName = 'Индекс корп. облигаций (RUCBTRNS)';
                break;
            case 'RUMBTRNS':
                chartName = 'Индекс муниципальных облигаций (RUMBTRNS)';
                break;
            default:
                chartName = point?.rawData.name ?? 'unknown';
        }
        const data = (value ?? []).map((point) => {
            return {
                code: point.isin,
                date: point.valDate,
                value: point.rate,
            };
        });
        result.push({
            chartCode: key,
            chartName: chartName,
            visible: true,
            data: data,
            type: point?.type,
        });
    }
    return result;
};

/**
 * Маппинг данных для графиков Доходность
 */
export const mapProfitCharts = (
    profitData: GetProfitPointsRespBody,
    point?: PriceCenterChartPoint<PriceCenterBond>,
): Array<PriceCenterChart<Array<PriceCenterValuation>>> => {
    const result: Array<PriceCenterChart<Array<PriceCenterValuation>>> = [];
    for (const [key, value] of Object.entries(profitData)) {
        let chartName;
        switch (key) {
            case 'RGBI':
                chartName = 'Индекс гос. облигаций (RGBI)';
                break;
            case 'RUCBTRNS':
                chartName = 'Индекс корп. облигаций (RUCBTRNS)';
                break;
            case 'RUMBTRNS':
                chartName = 'Индекс муниципальных облигаций (RUMBTRNS)';
                break;
            default:
                chartName = point?.rawData.name ?? 'unknown';
        }
        const data = (value ?? []).map((point) => {
            return {
                code: point.isin,
                date: point.tradeDate,
                value: point.value,
            };
        });
        result.push({
            chartCode: key,
            chartName: chartName,
            visible: true,
            data: data,
            type: point?.type,
        });
    }
    return result;
};

export { getAllChartsThunk };
