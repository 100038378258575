import { createSlice } from '@reduxjs/toolkit';
import { checkSubscriptionThunk } from '@store/store/thunk/investorpro.thunk';
import { SubscriptionData } from '@libs/types/subscription.type';

export type IState = {
    activeTariff: SubscriptionData | null;
};

export const initialState: IState = {
    activeTariff: null,
};

export const investorProSlice = createSlice({
    name: 'investorPro',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkSubscriptionThunk.fulfilled, (state, action) => {
            state.activeTariff = action.payload || null;
        });
    },
});

export const investorProReducer = investorProSlice.reducer;
