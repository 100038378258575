/* eslint-disable no-constant-condition */
import { TariffCard } from '@libs/components/pageBlocks/TariffBlock/components/TariffCard';
import { PriceFooter } from '@libs/components/pageBlocks/TariffBlock/components/TariffCard/PriceFooter';
import classNames from 'classnames';
import { TariffFilter } from '@libs/components/pageBlocks/TariffBlock/components/TariffFilter';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { PaymentModal } from '@libs/components/pageBlocks/TariffBlock/components/PaymentModal';
import { useAuthorize, useModal } from '@libs/utils';
import { getTariffsThunk } from '@store/store/thunk/tariff.thunk';
import {
    type RefObjectType,
    type TariffFilter as TariffFilterReq,
    type TariffCardType,
    type TariffFilterItem,
    type TariffCards,
} from '@libs/types';
import { getPreparedTariffData, formatDate } from '@libs/components/pageBlocks/TariffBlock/utils/utils';
import { initialTariffs } from '@libs/components/pageBlocks/TariffBlock/data';
import { type SubscriptionData } from '@libs/types/subscription.type';
import { InvestorProCard } from '@libs/components/pageBlocks/TariffBlock/components/InvestorProCard';
import { checkPurchasePossibility } from '@libs/services';
import { setShowOnboardingModal } from '@store/store/slices/user.slice';

import styles from './styles.module.scss';

type Props = {
    setRefs: (refs: RefObjectType<HTMLDivElement>) => void;
    productGuid?: string;
    activeTariff: SubscriptionData | null;
    isInvestorProPage?: boolean;
    className?: string;
};

const bodyReq: TariffFilterReq = {
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'id',
        sortOrder: 'asc',
    },
};

export const TariffBlock = ({ setRefs, productGuid, activeTariff, isInvestorProPage = false, className }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const tariffsRef = useRef<HTMLDivElement>(null);

    const [tariffs, setTariffs] = useState<TariffCards>(initialTariffs);
    const [tariffPeriodList, setTariffPeriodList] = useState<TariffFilterItem[]>([]);
    const [selectedPeriodTariff, setSelectedPeriodTariff] = useState<TariffFilterItem>();
    const [selectedTariffPlan, setSelectedTariffPlan] = useState<TariffCardType>();

    const { isAuthorized, loginHandler } = useAuthorize();
    const { closeModal, openModal, isOpen } = useModal();

    const datashopTariffs = useSelector((state: RootState) => state.tariff.datashopTariffs);

    useEffect(() => {
        if (productGuid && productGuid !== '') {
            dispatch(getTariffsThunk({ ...bodyReq, productGuid, status: 'ACTIVE' }));
        }
    }, [productGuid]);

    useEffect(() => {
        const { resultTariffs, resultFilterList } = getPreparedTariffData(datashopTariffs);
        setTariffs(resultTariffs);
        setTariffPeriodList(resultFilterList);

        let selectedPeriodCode = '';

        Object.entries(resultTariffs).forEach((el) => {
            if (
                activeTariff &&
                el[1].some((item) => item.tariffParams?.some((param) => activeTariff.tariffParams.find((activeParam) => param.valueGuid === activeParam.valueId),
                    ),
                )
            ) {
                selectedPeriodCode = el[0];
            }
        });

        if (resultFilterList.length) {
            setSelectedPeriodTariff(
                selectedPeriodCode
                    ? resultFilterList.find((item) => item.code === selectedPeriodCode)
                    : resultFilterList[0],
            );
        }
    }, [datashopTariffs]);

    useEffect(() => {
        setRefs({ tariffsRef });
    }, [setRefs]);

    const checkActiveTariff = (item: TariffCardType, period: string) => {
        return (
            !!(
                activeTariff &&
                item.tariffParams?.some((param) => activeTariff.tariffParams.find((activeParam) => param.valueGuid === activeParam.valueId),
                )
            ) ||
            // если есть активный тариф, то на всех периодах показываем его активным
            !!(
                activeTariff &&
                tariffs[period as keyof TariffCards].find(
                    (tariff) => tariff.tariffGuid === activeTariff.tariffGuid && tariff.tariffGuid === item.tariffGuid,
                )
            )
        );
    };

    const handleButton = async (item: TariffCardType) => {
        if (isAuthorized) {
            await checkPurchasePossibility({ productGuid: productGuid || '' })
                .then(({ data }) => {
                    const { result, blockedBy } = data.data;

                    if (result === 'ALLOWED') {
                        setSelectedTariffPlan(item);
                        openModal();
                    } else {
                        dispatch(setShowOnboardingModal(true));
                    }
                })
                .catch((e) => {
                    console.error(e);
                    // loginHandler();
                });
        } else {
            loginHandler();
        }
    };

    const cost = (cost: number, vat: number) => {
        const vatValue = (cost * vat) / 100;
        const result = Number(vatValue.toFixed(2)) + cost;

        return Number.isInteger(result) ? result : result.toFixed(2);
    };

    return (
        <div
            ref={tariffsRef}
            className={classNames(styles.tariffsWrapper, 'flex', 'justify-content-between', className)}
        >
            <div className={styles.titleWrapper}>
                <h2>Тарифы</h2>
                <p>
                    Абонентская плата за предоставление <br /> данных, рублей за период
                </p>
            </div>

            {isInvestorProPage || (datashopTariffs.length && selectedPeriodTariff)
? (
                <>
                    <div className={classNames(styles.contentWrapper, 'flex flex-column  w-full')}>
                        {selectedPeriodTariff && (
                            <TariffFilter
                                selectedTariff={selectedPeriodTariff}
                                setSelectedTariff={setSelectedPeriodTariff}
                                list={tariffPeriodList}
                            />
                        )}

                        <div
                            className={classNames(
                                styles.tariffsCardWrapper,
                                'flex justify-content-start w-full flex-wrap',
                            )}
                        >
                            {isInvestorProPage && <InvestorProCard />}
                            {selectedPeriodTariff &&
                                tariffs[selectedPeriodTariff.code as keyof TariffCards].map((item) => (
                                    <TariffCard
                                        key={item.id}
                                        title={item.title}
                                        list={item.dopInfo?.options}
                                        description={item.dopInfo?.firstLineDescription}
                                        isActive={checkActiveTariff(item, selectedPeriodTariff.code)}
                                        activePeriod={formatDate(activeTariff?.endDate)}
                                        footerBlock={
                                            <PriceFooter
                                                price={cost(item.cost, item.vat)}
                                                onClick={async () => await handleButton(item)}
                                                isActive={checkActiveTariff(item, selectedPeriodTariff.code)}
                                                disabledBtn={!!activeTariff}
                                                discountCost={cost(item.discountCost || 0, item.vat)}
                                                discountEndDate={item.discountEndDate}
                                                discountPercent={item.discountPercent}
                                            />
                                        }
                                    />
                                ))}
                        </div>
                    </div>
                </>
            )
: (
                <div className={styles.titleWrapper}>Тарифный план не добавлен</div>
            )}
            <PaymentModal
                closeModal={closeModal}
                isVisible={isOpen}
                productGuid={productGuid!}
                selectedPlan={selectedTariffPlan!}
                tariffPeriodList={tariffPeriodList}
                selectedTariffPeriod={selectedPeriodTariff}
            />
        </div>
    );
};
