export enum ProrpackTabs {
    VOLATILITY = 'volatility',
    YIELD_CURVES = 'yieldCurves',
    MARKERS = 'markers',
    RISK_RATES = 'riskRates',
    IIS_DUPLICATES = 'IISDuplicates',
}

export type PropackTab = {
    title: string;
    tab: ProrpackTabs;
    disabled: boolean;
};
