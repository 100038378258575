import { ChevronLeftIcon } from 'primereact/icons/chevronleft';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import styles from './styles.module.scss';

const HEADER_URL = 'https://www.moex.com/';

const hasBredcrumsPagesUrls = [
    'product?tab=interactiveMap',
    'product?tab=dataUpload',
    'examples?tab=volatility',
    'examples?tab=yieldCurves',
];

const getIsPageWithBredcrums = (pathname: string) => {
    let result = false;
    const pathsPices = pathname.split('/');
    pathsPices.forEach((item) => {
        if (hasBredcrumsPagesUrls.includes(item)) result = true;
    });

    return result;
};

export const Header = () => {
    const path = useLocation();

    const currentLocation = `${path.pathname}${path.search}`;
    const hasBredcrums = getIsPageWithBredcrums(currentLocation);

    return (
        <header className={classNames(styles.headerWrapper)}>
            <div className={styles.linkWrapper}>
                <Link to={HEADER_URL}>
                    <ChevronLeftIcon className={styles.icon} color={'#88919A'} />
                    <p className={styles.title}>МОСКОВСКАЯ БИРЖА</p>
                </Link>
                {hasBredcrums && (
                    <Link to="/">
                        <ChevronLeftIcon className={styles.icon} color={'#88919A'} />
                        <p className={styles.title}>DATASHOP</p>
                    </Link>
                )}
            </div>
        </header>
    );
};
