import classNames from 'classnames';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    image: string;
    title: string;
    description: string;
    points: string[];
    imagePosition: 'left' | 'right';
};

export const CapabilityItem = ({ image, title, description, points, imagePosition }: Props) => {
    const { isScreenSm } = useResize();

    return (
        <div
            className={classNames(
                styles.capabilityItemWrapper,
                'flex',
                isScreenSm ? 'flex-column' : imagePosition === 'left' ? 'flex-row-reverse' : 'flex-row',
            )}
        >
            <div
                className={classNames(
                    imagePosition === 'left' && styles.rightPos,
                    'flex',
                    'flex-column',
                    'justify-content-center',
                )}
            >
                <h4>{title}</h4>
                <p>{description}</p>
                {!!points.length && (
                    <ul>
                        {points.map((point) => (
                            <li key={point}>{point}</li>
                        ))}
                    </ul>
                )}
            </div>
            <img src={image} />
        </div>
    );
};
