import { createAsyncThunk } from '@reduxjs/toolkit';
import { PriceCenterBond, PriceCenterChartPoint } from '@store/store/thunk/pricecenter/Models';
import { getPricePoints, getProfitPoints } from '@libs/services';
import { mapPricesCharts, mapProfitCharts } from '@store/store/thunk/pricecenter/getAllCharts.thunk';

const selectMarketPointThunk = createAsyncThunk(
    'priceCenter/selectMarketPoint',
    async (point: PriceCenterChartPoint<PriceCenterBond>) => {
        const pricesResponse = await getPricePoints(
            {
                isinList: [point.code],
            },
            point.isDemo,
        );
        const profitResponse = await getProfitPoints(
            {
                isinList: [point.code],
            },
            point.isDemo,
        );
        return {
            point: point,
            priceCharts: mapPricesCharts(pricesResponse.data, point),
            profitCharts: mapProfitCharts(profitResponse.data, point),
        };
    },
);

export { selectMarketPointThunk };
