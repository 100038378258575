import { httpClient } from '@configs/httpclient.config';
import {
    NotificationArgs,
    NotificationResponse,
    NotificationStatusArgs,
    UnreadNotificationsCountResponse,
} from '@libs/types';
import { type AxiosResponse } from 'axios';

export const NOTIFICATIONS_URL = '/moex-datashop-notificationservice/api/notifications/v1';

export const getNotifications = (args: Partial<NotificationArgs>): Promise<AxiosResponse<NotificationResponse>> =>
    httpClient.post(`${NOTIFICATIONS_URL}/search`, args);

export const setNotificationStatus = (args: NotificationStatusArgs): Promise<AxiosResponse<string>> =>
    httpClient.post(`${NOTIFICATIONS_URL}/set-read`, args);

export const getUnreadNotificationsCount = (): Promise<AxiosResponse<UnreadNotificationsCountResponse>> =>
    httpClient.post(`${NOTIFICATIONS_URL}/count-unread`);
