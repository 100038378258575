import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useResize } from '@libs/utils';
import { SCREEN_XL } from '@libs/constants';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { ProductMnemoCodes } from '@libs/components/misc/ProductCard/types';

import { ReactComponent as LockIcon } from '../../images/svg/LockIcon.svg';
import { ReactComponent as ProPackLogo } from '../../images/svg/ProPackLogo.svg';
import { type PropackTab, ProrpackTabs } from './types';
import styles from './styles.module.scss';

export const proPackNavigation: PropackTab[] = [
    { title: 'Волатильность', tab: ProrpackTabs.VOLATILITY, disabled: false },
    { title: 'Процентные ставки', tab: ProrpackTabs.YIELD_CURVES, disabled: false },
    { title: 'Маркировка', tab: ProrpackTabs.MARKERS, disabled: false },
    { title: 'Ставки риска', tab: ProrpackTabs.RISK_RATES, disabled: false },
    { title: 'Дубликаты ИИС', tab: ProrpackTabs.IIS_DUPLICATES, disabled: false },
];

export const Header = () => {
    const [tabs, setTabs] = useState<PropackTab[]>(proPackNavigation);
    const [searchParams, setSearchParams] = useSearchParams();

    const productList = useSelector((state: RootState) => state.product.productList);

    const activeTab = searchParams.get('tab');

    const { width } = useResize();

    const isDesktop = width > SCREEN_XL;

    useEffect(() => {
        if (!searchParams.has('tab')) {
            setSearchParams({ tab: ProrpackTabs.VOLATILITY });
        }
    }, [searchParams]);

    const mobileMenu = (
        <ul id="module-navigation" className={classNames(styles.mobileMenu, 'gap-2')}>
            {tabs.map((i) => {
                return (
                    <li
                        id="module-navigation-tab"
                        data-label={i.disabled ? 'tab-disabled' : 'tab'}
                        className={classNames(
                            'flex gap-2 align-items-center bg-white border-none',
                            activeTab === i.tab ? styles.activeTabMobile : styles.inactiveTabMobile,
                            {
                                [styles.disabledLink]: i.disabled,
                            },
                        )}
                        key={i.tab}
                        aria-disabled={i.disabled}
                    >
                        {i.disabled && (
                            <div className={styles.icon}>
                                <LockIcon />
                            </div>
                        )}

                        <span>{i.title}</span>
                    </li>
                );
            })}
        </ul>
    );

    const desktopMenuLayout = (
        <nav className="w-full flex justify-content-between">
            <Link
                to={
                    '/' +
                    (productList.find((el) => el.mnemoCode === ProductMnemoCodes.TPC_PRO_PACK)?.landingPage ??
                        'products/propack')
                }
                replace
            >
                <ProPackLogo className={styles.headerLogo} />
            </Link>
            <ul className={styles.tabWrapper}>
                {tabs.map(({ title, tab, disabled }, index) => (
                    <li
                        key={tab}
                        className={classNames(
                            styles.tabItem,
                            activeTab === tab ? styles.activeTab : styles.inactiveTab,
                            {
                                [styles.disabledLink]: disabled,
                            },
                        )}
                        onClick={() => !disabled && setSearchParams({ tab })}
                    >
                        {disabled && (
                            <div className={styles.icon}>
                                <LockIcon />
                            </div>
                        )}
                        <div>{title}</div>
                    </li>
                ))}
            </ul>
        </nav>
    );

    return <div className={classNames(styles.headerWrapper)}>{isDesktop ? desktopMenuLayout : mobileMenu}</div>;
};
