import { useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';

type TooltipChartHookArgs = {
    debounceTime?: number;
    handleToolTipHover?: (context: any) => void;
    xOffset?: number;
    yOffset?: number;
    setTooltipPosition?: (context: any, el: HTMLDivElement) => void;
    mapTooltipData?: (data: any) => any;
    disableDefouncer?: boolean;
    sortTooltipData?: (a: any, b: any) => any;
};

export const useChartTooltip = <T>({
    debounceTime = 50,
    handleToolTipHover,
    xOffset = 0,
    yOffset = 0,
    setTooltipPosition,
    mapTooltipData,
    sortTooltipData,
}: TooltipChartHookArgs = {}): [React.RefObject<HTMLDivElement>, any[], (context: any) => void] => {
    const [tooltipData, setTooltipData] = useState<any[]>([]);
    const ref = useRef<HTMLDivElement>(null);

    const tooltipCallback = useCallback(
        debounce((context: any) => {
            const tooltipEl = ref.current;

            if (!tooltipEl) {
                return;
            }

            const tooltipModel = context.tooltip;

            if (!tooltipModel.opacity) {
                tooltipEl.style.opacity = '0';
                return;
            }

            tooltipEl.classList.remove('above', 'below', 'no-transform');

            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            let tooltipData = tooltipModel.dataPoints;
            tooltipData = mapTooltipData ? tooltipData.map(mapTooltipData) : tooltipData;
            tooltipData = sortTooltipData ? tooltipData.sort(sortTooltipData) : tooltipData;
            setTooltipData(tooltipData);

            if (handleToolTipHover) handleToolTipHover(context);

            tooltipEl.style.opacity = '1';

            if (setTooltipPosition) {
                setTooltipPosition(context, ref.current);
            } else {
                tooltipEl.style.left = tooltipModel.caretX + xOffset + 'px';
                tooltipEl.style.top = tooltipModel.caretY + yOffset + context.chart.chartArea.top + 'px';
            }
        }, debounceTime),
        [ref.current, debounceTime],
    );

    return [ref, tooltipData, tooltipCallback];
};
