import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse } from 'axios';
import { ResponseInfo } from '@libs/types';
import { PageableRequest, PageModelReferences, Reference } from './helpCenter.types';

const REFERENCES_URL = '/moex-datashop-reference-service/api/references/v1';

export const getReferences = (body: PageableRequest): Promise<AxiosResponse<ResponseInfo<PageModelReferences>>> =>
    httpClient.post(REFERENCES_URL + '/search', body);

export const getReference = (id: string): Promise<AxiosResponse<Reference>> =>
    httpClient.get(`${REFERENCES_URL}/${id}`);
