import { type AxiosResponse } from 'axios';
import { httpClient } from '@configs/httpclient.config';
import { ResponseInfo } from '@libs/types';
import {
    PageModelSubscriptionData,
    PermissionResponse,
    SubscriptionData,
    UpdateSubscriptionReq,
} from '@libs/types/subscription.type';

export const SUBSCRIPTIONS_URL = '/moex-datashop-datashopservice/api/subscriptions/v1';
export const PERMISSIONS_URL = '/moex-datashop-datashopservice/api/permissions/v1/';

export const getActiveSubscriptions = (
    productGuids: Array<string> = [],
): Promise<AxiosResponse<ResponseInfo<PageModelSubscriptionData>>> => {
    const body: any = {
        statuses: ['ACTIVE'],
        pageable: {
            pageSize: 100,
            pageNumber: 0,
            sortField: 'id',
            sortOrder: 'desc',
        },
    };

    if (productGuids.length) {
        body.productGuids = productGuids;
    }

    return httpClient.post(SUBSCRIPTIONS_URL + '/search', body);
};

export const subscribeById = (): Promise<AxiosResponse<any>> => httpClient.post(SUBSCRIPTIONS_URL);

export const updateSubscription = (
    subscriptionId: number,
    body: UpdateSubscriptionReq,
): Promise<AxiosResponse<SubscriptionData>> => httpClient.post(`${SUBSCRIPTIONS_URL}/${subscriptionId}`, body);

export const updateApiKey = (subscriptionId: number): Promise<AxiosResponse<SubscriptionData>> =>
    httpClient.post(`/moex-datashop-datashopservice/api/subscriptions/v1/${subscriptionId}/update-token`);

export const getPermissions = (productId: string): Promise<AxiosResponse<PermissionResponse>> =>
    httpClient.post(
        `${PERMISSIONS_URL}access`,
        {},
        {
            params: { productId },
        },
    );
