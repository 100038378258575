import { scrollElementByRef } from '@libs/utils';
import { type RefObjectType } from '@libs/types';

export const tabs = (refs: RefObjectType<HTMLDivElement>) => {
    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return [
        { title: 'Начало работы', childrenHTML: '' },
        {
            title: 'О продукте',
            childrenHTML: (
                <ul>
                    <li onClick={() => handleButtonClick('whatAlgopackBlockRef')}>Что такое ALGOPACK</li>
                    <li onClick={() => handleButtonClick('tasksAlgopackBlockRef')}>Какие задачи решает</li>
                    <li onClick={() => handleButtonClick('candlesAlgopackBlockRef')}>ALGOPACK: SuperCandles</li>
                    <li onClick={() => handleButtonClick('megaAlertsAlgopackBlockRef')}>ALGOPACK: MegaAlerts</li>
                </ul>
            ),
        },
        {
            title: 'Описание API AlgoPack',
            childrenHTML: (
                <ul>
                    <li onClick={() => handleButtonClick('tradeStatsGetRef')}>Tradestats</li>
                    <li onClick={() => handleButtonClick('orderStatsGetRef')}>Orderstats</li>
                    <li onClick={() => handleButtonClick('obstatsRefGetRef')}>OBstats</li>
                </ul>
            ),
        },
        { title: 'Описание набора данных', childrenHTML: '' },
        {
            title: 'Методика расчета',
            childrenHTML: (
                <ul>
                    <li onClick={() => handleButtonClick('tradeStatsRef')}>Trades</li>
                    <li onClick={() => handleButtonClick('orderStatsRef')}>Orders</li>
                    <li onClick={() => handleButtonClick('orderBookStatsRef')}>OrderBook</li>
                </ul>
            ),
        },
        { title: 'Python библиотека', childrenHTML: '' },
        { title: 'Полезная информация', childrenHTML: '' },
    ];
};
