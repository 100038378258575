import { MainItem } from "@modules/ProPack/shared/components/LineItem";
import IisDuplicates1 from './images/IisDuplicates_1.png';
import IisDuplicates2 from './images/IisDuplicates_2.png';

export const items: MainItem[] = [
  {
    text: 'Открыть новый ИИС',
    imageUrl: IisDuplicates1,
    subItems: [
      {
        li: 'Нет дубликата',
        subItems: [
          { li: 'Брокер регистрирует ИИС клиента' },
        ],
      },
      {
        li: 'Есть дубликат',
        subItems: [
          { li: 'Брокер уведомляет клиента о наличии Дубликата ИИС' },
        ],
      },
    ],
  },
  {
    text: 'Перенести ИИС от другого брокера',
    imageUrl: IisDuplicates2,
    subItems: [
      {
        li: 'Нет дубликата',
        subItems: [
          { li: 'Брокер вносит в свою базу клиентов признак «перевод» и регистрирует ИИС клиента на бирже' },
        ],
      },
      {
        li: 'Есть дубликат',
        subItems: [
          { li: 'Брокер просит клиента подать Заявление на перенос ИСС' },
        ],
      },
    ],
  },
];

export const documentationBlocks = [
  {
    update: 'Услуга предоставляется по договору',
    text: 'Оказания услуг информационно-технического обеспечения Публичного Акционерного Общества «Московская Биржа ММВБ-РТС»',
    downloadURL: 'https://fs.moex.com/files/4266',
    downloadText: 'Скачать договор',
    target: '_blank',
  },
  {
    update: 'Тарифы на услугу',
    text: 'Предоставление информации о наличии открытых Индивидуальных Инвестиционных Счетов у физических лиц',
    downloadURL: 'https://www.moex.com/s324#dublicate',
    downloadText: 'Скачать тарифы',
    target: '_blank',
  },
  {
    update: 'Заявление на услугу',
    text: 'Предоставление информации о наличии открытых Индивидуальных Инвестиционных Счетов у физических лиц',
    downloadURL: 'https://www.moex.com/a1819#sed',
    downloadText: 'Скачать заявление',
    target: '_blank',
  },
];
