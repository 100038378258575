import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotifications, getUnreadNotificationsCount, setNotificationStatus } from '@libs/services';
import { type NotificationArgs, type NotificationStatusArgs } from '@libs/types';
import { type AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';

const getNotificationsValue = async (args: Partial<NotificationArgs>, { rejectWithValue }: any) => {
    try {
        const res = await getNotifications(args);

        return res.data.data;
    } catch (error) {
        rejectWithValue(error);
    }
};

export const setNotificationStatusThunk = createAsyncThunk(
    'notifications/setNotificationStatus',
    async (args: NotificationStatusArgs, { rejectWithValue }) => {
        try {
            const res = await setNotificationStatus(args);

            return res.data;
        } catch (error) {
            rejectWithValue(error);
        }
    },
);

export const getUnreadNotificationsCountThunk = createAsyncThunk(
    'notifications/getUnreadNotificationsCount',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getUnreadNotificationsCount();

            return response.data.data;
        } catch (error) {
            console.error('error', error);

            return rejectWithValue(error);
        }
    },
);

export const getNotificationsThunk = createAsyncThunk('notifications/getNotifications', getNotificationsValue);
export const getExtraNotificationsThunk = createAsyncThunk(
    'notifications/getExtraNotifications',
    getNotificationsValue,
);
