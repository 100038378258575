import classNames from 'classnames';
import { ReactComponent as ArrowLeft } from '@shared/images/svg/ArrowLeft.svg';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

type TopBlockProps = {
    title: string;
};

export const TopBlock = ({ title }: TopBlockProps) => {
    const navigate = useNavigate();

    return (
        <div className={classNames(styles.wrapper)}>
            <div onClick={() => navigate('/help-center')} className={classNames('inline-flex')}>
                <ArrowLeft />
                <p>Справочный центр</p>
            </div>
            <h1>{title}</h1>
        </div>
    );
};
