import { type TariffFilterItem } from '@libs/types';
import classNames from 'classnames';
import { useResize } from '@libs/utils';
import { Dropdown } from 'primereact/dropdown';

import styles from './styles.module.scss';

type Props = {
    selectedTariff: TariffFilterItem;
    setSelectedTariff: (newVal: TariffFilterItem) => void;
    list: TariffFilterItem[];
};

export const TariffFilter = ({ selectedTariff, setSelectedTariff, list }: Props) => {
    const { isScreenSm } = useResize();

    const dropdownFilter = (
        <Dropdown
            options={list}
            optionValue="code"
            optionLabel="title"
            value={selectedTariff.code}
            panelClassName={styles.panelStyle}
            onChange={(e) => {
                setSelectedTariff(list.find((item) => item.code === e.value)!);
            }}
            className={styles.dropdownFilter}
        />
    );

    const buttonFilter = (
        <div className={classNames(styles.tariffFilterWrapper, 'flex')}>
            {list.map((item) => (
                <div
                    key={item.code}
                    onClick={() => setSelectedTariff(item)}
                    className={classNames(styles.tariffFilterItem, selectedTariff.code === item.code && styles.active)}
                >
                    {item.title}
                </div>
            ))}
        </div>
    );

    return !isScreenSm ? buttonFilter : dropdownFilter;
};
